import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import useService from '@yojee/helpers/hooks/useService';
import {
  getOrderTemplateIdSelector,
  getTemplateDetailSelector,
  isOrderFromTransferSelector,
} from '@yojee/ui/new-order-booking/selectors';
import serviceLocator from '@yojee/ui/new-order-booking/serviceLocator';

import { setTemplateDetail, updateState } from '../../../saga/actions';
import { useIsCreateOrder, useIsEditOrder } from '../../helpers/hooks';

export default function useGetDisplayTemplates({ setTemplateId }) {
  const dispatch = useDispatch();
  const isEditOrder = useIsEditOrder();
  const isCreateOrder = useIsCreateOrder();
  const orderTemplateId = useSelector(getOrderTemplateIdSelector);
  const isOrderFromTransfer = useSelector(isOrderFromTransferSelector);
  const templateDetail = useSelector(getTemplateDetailSelector);
  const [displayTemplates, setDisplayTemplates] = useState([]);
  const [searchParams] = useSearchParams();
  const isDuplicate = searchParams.get('duplicate');

  const { data: templates } = useService(serviceLocator.templateApiV4.getPublicCompanyActivatedTemplates, {
    storeKey: 'templates',
    updateStoreAction: updateState,
  });

  useEffect(() => {
    if (templates) setDisplayTemplates(templates);
  }, [templates]);

  useEffect(() => {
    if ((isEditOrder || isDuplicate) && orderTemplateId) {
      setTemplateId(orderTemplateId);
    }
  }, [orderTemplateId, isEditOrder, isDuplicate, setTemplateId]);

  // Auto select default template in create page
  useEffect(() => {
    if (isCreateOrder && !isDuplicate && templates) {
      setTemplateId(templates?.find((template) => !!template.default_at)?.id || templates?.[0]?.id);
    }
  }, [templates, isCreateOrder, isDuplicate, setTemplateId]);

  // Order from transfer so template id in order not include in list templates of DS
  useEffect(() => {
    if (templateDetail?.id && isOrderFromTransfer) {
      setDisplayTemplates([
        ...(templates || []),
        {
          id: templateDetail.id,
          template_type_name: `partner_${templateDetail.type}`,
        },
      ]);
    }
  }, [isOrderFromTransfer, templateDetail, templates]);

  // In edit page, if order not from transfer and order have template id not include in list template => de-active template version
  useEffect(() => {
    const isFetchedAllTemplateData = templateDetail?.id && templates?.length;
    if (isEditOrder && isFetchedAllTemplateData && !isOrderFromTransfer) {
      const isCurrentTemplateNotIncludeInListTemplate = !templates?.some((t) => t.id === templateDetail?.id);
      if (isCurrentTemplateNotIncludeInListTemplate) {
        const { type, version, id } = templateDetail;
        setDisplayTemplates([
          ...(templates || []),
          {
            id,
            template_type_name: `${type}${version ? ` (${version})` : ''} (inactive)`,
          },
        ]);
      }
    }
  }, [isEditOrder, isOrderFromTransfer, templateDetail, templates]);

  // For handle case old template, old template return new template id in template detail api
  // So we need to manual set old template to it for avoid auto update new template id when save order
  useEffect(() => {
    if (templateDetail?.id && orderTemplateId && templateDetail.id !== orderTemplateId) {
      dispatch(setTemplateDetail({ ...templateDetail, id: orderTemplateId }));
    }
  }, [dispatch, orderTemplateId, templateDetail]);

  return displayTemplates;
}
