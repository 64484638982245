export const createStepAddressMarker = ({ step, taskTypes }) => {
  const taskTypeData = taskTypes?.find((t) => t.slug === step.type) || {};

  const divContainer = document.createElement('div');
  divContainer.className = 'marker-icon-container';
  divContainer.style.backgroundColor = taskTypeData.marker_color;

  const spanText = document.createElement('div');
  spanText.innerText = taskTypeData.marker_text;
  spanText.className = 'icon-stop-text';

  divContainer.appendChild(spanText);

  return divContainer;
};
