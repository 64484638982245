import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  children: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    width: theme.spacing(18.75),
    alignItems: 'flex-start',
    '& button': {
      textTransform: 'none',
    },
  },
  moreHorizIcon: {
    padding: 0,
    marginRight: theme.spacing(2),
  },
}));

export default useStyles;
