const defaultState = {
  loading: {
    updateCompanyLanguage: false,
  },
  status: 'normal',
  info: {
    partnerShipInfo: {
      cip: undefined,
    },
  },
};

export const companySettingsMessagesSuccessEvents = {
  UPDATE_COMPANY_SETTINGS_SUCCESS: ({ result: { message } }) => message,
};

export const companySettingsMessagesErrorEvents = {
  UPDATE_COMPANY_SETTINGS_FAILED: ({ error }) => (error ? error.message : ''),
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  GET_PARTNERSHIP_INFO_SUCCESS: (state, data) => {
    return {
      ...state,
      info: { ...state.info, partnerShipInfo: data.partnerShipInfo },
    };
  },
  SET_COMPANY_SETTINGS_LOADING: (state) => {
    return { ...state, status: 'loading' };
  },
  UPDATE_COMPANY_SETTINGS_SUCCESS: (state) => {
    return {
      ...state,
      status: 'normal',
    };
  },
  UPDATE_COMPANY_SETTINGS_FAILED: (state) => {
    return {
      ...state,
      status: 'error',
    };
  },
  CLEAR_MESSAGES: (state) => {
    return {
      ...state,
      state: 'normal',
    };
  },
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
