import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Button from '@yojee/ui/base/furnitures/Button';
import useQuery from '@yojee/ui/hooks/useQuery';

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(1),
  },
}));

const GoBackBtn = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const query = useQuery();

  const onClick = () => {
    if (query.get('fromPage')) {
      navigate(query.get('fromPage'));
    } else {
      navigate(-1);
    }
  };

  return (
    <Button size="small" variant="outlined" className={classes.root} onClick={onClick}>
      <Trans>Go Back</Trans>
    </Button>
  );
};

export default GoBackBtn;
