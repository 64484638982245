import { IconButton, makeStyles } from '@material-ui/core';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import copy from 'copy-to-clipboard';
import { t } from 'i18next';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { FEATURES_MAP } from '@yojee/helpers/SettingResolver';
import useIsFeatureEnabled from '@yojee/ui/feature-management/hooks/useIsFeatureEnabled';
import {
  getOrderGroupDataSelector,
  getOrderInfoSelector,
  getOrderItems,
  getTemplateDetailSelector,
  getTemplates,
} from '@yojee/ui/new-order-booking/selectors';

import checkIfIsAllowedToGetDocuments from '../../helpers/checkIfIsAllowedToGetDocuments';
import { useIsCanShowMap } from '../../helpers/utils';
import MapToggleButton from '../MapToggleButton';
import ActionLink from './components/ActionLink';
import DocLinks from './components/DocLinks';
import OrderID from './components/OrderID';
import TdLabel from './components/TdLabel';
import OrderDuplicateDialog from './OrderDuplicateDialog';

type OrderGroupData = {
  number: string;
};

type OrderInfo = {
  number: string;
  status: string;
  is_from_transfer: boolean;
  external_id: string;
  inserted_at: string;
  external_carrier_references?: Array<ExtCarrierRef>;
  upstream_company: {
    id: number;
    name: string;
    partnership_id: number;
  } | null;
};

type ExtCarrierRef = {
  name: string;
  order_id: number;
  value: string;
};

type OrderItem = {
  transfer_info?: PartnerInfo;
};

type PartnerInfo = {
  partner_name: string;
  partner_company_id: number;
  partnership_id: number;
};

const useStyles = makeStyles({
  copyIcon: {
    padding: '0.4em',
    marginLeft: '0.1em',
    marginBottom: '0.2em',
    '& svg': {
      fontSize: 16,
    },
  },
});

const formatCreationDate = (rawStrDate: string) => {
  return moment(rawStrDate).format('DD MMMM YYYY, HH:mm');
};

const extractUpstreamPartnerInfo = (orderInfo?: OrderInfo) => {
  if (!orderInfo) return null;
  if (!orderInfo.is_from_transfer) return null;
  if (!orderInfo.upstream_company) return null;

  const { name, id, partnership_id } = orderInfo.upstream_company;

  return {
    partner_name: name,
    partner_company_id: id,
    partnership_id: partnership_id,
  } as PartnerInfo;
};

const extractUniqueDownstreamPartnerInfos = (orderItems?: OrderItem[]) => {
  if (!orderItems?.length) return [];

  const partnerMap = orderItems
    .map((orderItem) => orderItem.transfer_info)
    .reduce((acc, partnerInfo) => {
      if (partnerInfo?.partnership_id) acc[partnerInfo.partnership_id] = partnerInfo;
      return acc;
    }, {} as Record<number, PartnerInfo>);

  return Object.values(partnerMap);
};

const PartnerHyperlink: React.FC<{ partnerInfo: PartnerInfo }> = ({ partnerInfo }) => {
  const navigate = useNavigate();
  const partnerDetailPagePath = `/partners/${partnerInfo.partnership_id}/detail`;

  return <ActionLink onClick={() => navigate(partnerDetailPagePath)}>{partnerInfo.partner_name}</ActionLink>;
};

const extractUniqueCarrierReferences = (external_carrier_refs: Array<ExtCarrierRef>) => {
  return external_carrier_refs.map((ref) => ref.value).filter((val, idx, arr) => arr.indexOf(val) === idx);
};

const OrderMetadata = () => {
  const [isOrderDuplicateDialogOpen, setIsOrderDuplicateDialogOpen] = useState(false);
  const classes = useStyles();
  const isCanShowMap = useIsCanShowMap();
  const orderInfo: OrderInfo | undefined = useSelector(getOrderInfoSelector);
  const orderGroupData: OrderGroupData | undefined = useSelector(getOrderGroupDataSelector);
  const orderItems: Array<OrderItem> | undefined = useSelector(getOrderItems);
  const currentTemplate = useSelector(getTemplateDetailSelector);
  const templates = useSelector(getTemplates);

  // if the latest version of the current template is active
  const latestTemplateActive = templates?.some(
    ({ template_type_id }: { template_type_id: number }) => template_type_id === currentTemplate?.template_type_id
  );

  const isUsingDocumentPortalFeature = useIsFeatureEnabled(FEATURES_MAP.DOCUMENT_PORTAL);

  const batchID = orderGroupData?.number;
  const isAllowedToGetDocuments = checkIfIsAllowedToGetDocuments(isUsingDocumentPortalFeature, orderInfo);

  const usPartnerInfo = extractUpstreamPartnerInfo(orderInfo);
  const dsPartnerInfos = extractUniqueDownstreamPartnerInfos(orderItems);
  const carrierRefs = extractUniqueCarrierReferences(orderInfo?.external_carrier_references ?? []);

  const closeDialog = () => {
    setIsOrderDuplicateDialogOpen(false);
  };

  return (
    <div className="flex pt-20 pb-15 justify-between">
      <div className="flex flex-1">
        {orderInfo && (
          <div>
            <div>
              {orderInfo.external_id && <OrderID>{orderInfo.external_id}</OrderID>}
              <OrderID>{orderInfo.number}</OrderID>
            </div>
            <div>
              <table>
                <tbody>
                  <tr>
                    <TdLabel>{t('Creation date')}</TdLabel>
                    <td>{formatCreationDate(orderInfo.inserted_at)}</td>
                  </tr>

                  {batchID && (
                    <tr>
                      <TdLabel>{t('Batch ID')}</TdLabel>
                      <td>
                        {batchID}{' '}
                        <IconButton className={classes.copyIcon} onClick={() => copy(batchID)}>
                          <FileCopyIcon fontSize="small" htmlColor="#1babe0" />
                        </IconButton>
                      </td>
                    </tr>
                  )}

                  {!orderInfo.is_from_transfer && latestTemplateActive && (
                    <tr>
                      <TdLabel>{t('Action')}</TdLabel>
                      <td>
                        <ActionLink
                          onClick={() => {
                            setIsOrderDuplicateDialogOpen(true);
                          }}
                        >
                          {t('Duplicate')}
                        </ActionLink>
                      </td>
                    </tr>
                  )}

                  {usPartnerInfo && (
                    <tr>
                      <TdLabel>{t('Received from')}</TdLabel>
                      <td>
                        <PartnerHyperlink partnerInfo={usPartnerInfo} />
                      </td>
                    </tr>
                  )}

                  {dsPartnerInfos.length > 0 &&
                    dsPartnerInfos.map((dsPartnerInfo, idx) => (
                      <tr key={`ds-partner-hyperlink-${dsPartnerInfo.partner_company_id}`}>
                        {idx === 0 ? <TdLabel>{t('Partner')}</TdLabel> : <TdLabel children="" />}
                        <td>
                          <PartnerHyperlink partnerInfo={dsPartnerInfo} />
                        </td>
                      </tr>
                    ))}

                  {carrierRefs.length > 0 && (
                    <tr>
                      <TdLabel>{t('Carrier reference')}</TdLabel>
                      <td>{carrierRefs.join(', ')}</td>
                    </tr>
                  )}

                  {isAllowedToGetDocuments && (
                    <tr>
                      <TdLabel>{t('Documents')}</TdLabel>
                      <td>
                        <DocLinks orderNumber={orderInfo.number} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      {isOrderDuplicateDialogOpen && orderInfo?.number && <OrderDuplicateDialog onClose={closeDialog} />}
      {isCanShowMap && <MapToggleButton />}
    </div>
  );
};

export default React.memo(OrderMetadata);
