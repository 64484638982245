import React, { useEffect, useState } from 'react';
import { connectField } from 'uniforms';
import { AutoField } from 'uniforms-material';

import { getHistoryData } from '@yojee/helpers/windowHelpers';
import { useIsEditOrder } from '@yojee/ui/new-order-booking/components/helpers/hooks';

import { useCustomOnChange, useCustomOnKeyDown } from '../../helpers/formHelpers';
import { customFilterDOMProps } from '../../helpers/SchemaHelpers';
import getCustomField from './getCustomField';
import getExtraProps from './getExtraProps';

function getProps(props) {
  if (getCustomField(props)) {
    return { ...props, ...getExtraProps(props) };
  }

  return customFilterDOMProps(props);
}

const InputLabelProps = {
  shrink: true,
};

const CustomAutoField = (props) => {
  const isEditOrder = useIsEditOrder();
  const Component = getCustomField(props) || AutoField;

  const [classes, setClasses] = useState('');

  const { customOnChange } = useCustomOnChange(props);
  const { customOnKeyDown } = useCustomOnKeyDown(props);

  // This one for new booking portal
  const historyData = getHistoryData();

  useEffect(() => {
    if (historyData) {
      if ((props.field.key === 'quantity' && props.value === null) || props.value === '') {
        props.onChange(historyData['quantity'], 'quantity');
      }
    }
  }, []);

  useEffect(() => {
    if (historyData && props.value === null) {
      if (props.stepIndex === 0) {
        if (props.field.key === 'address') {
          props.onChange(historyData.pickUp.name, 'address');
        } else if (props.field.key === 'country') {
          props.onChange(historyData.pickUp.country, 'country');
        } else if (props.field.key === 'postal_code') {
          props.onChange(historyData.pickUp.postalCode, 'postal_code');
        }
      } else if (props.stepIndex === 1) {
        if (props.field.key === 'address') {
          props.onChange(historyData.dropOff.name, 'address');
        } else if (props.field.key === 'country') {
          props.onChange(historyData.dropOff.country, 'country');
        } else if (props.field.key === 'postal_code') {
          props.onChange(historyData.dropOff.postalCode, 'postal_code');
        }
      }
    }
  }, [props]);

  return (
    <div
      className={`custom-field cat-${props.field.category} field-${props.name} ${classes}`}
      data-cy={`${props.name}`}
    >
      <Component
        {...getProps(props)}
        className={`form-field-${props.name}`}
        placeholder={props.field.sample_value}
        name=""
        InputLabelProps={InputLabelProps}
        onChange={customOnChange}
        onKeyDown={customOnKeyDown}
        setClasses={setClasses}
        isEditOrder={isEditOrder}
      />
    </div>
  );
};

export default connectField(CustomAutoField);
