import { makeStyles } from '@material-ui/styles';

const useLinkStyles = makeStyles({
  metadataLink: {
    color: '#1BACE0',
    cursor: 'pointer',
    textDecoration: 'none',
    fontSize: '1rem',
    fontFamily: 'SF Pro, Roboto, Helvetica, Arial, sans-serif',
  },
});

export default useLinkStyles;
