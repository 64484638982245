import { debounce, get as lodashGet } from 'lodash-es';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connectField, useForm } from 'uniforms';

import { getFormRefsSelector } from '@yojee/ui/new-order-booking/selectors';

import { syncFormModel, updateFormMetaData } from '../../../saga/actions';

const HandleSyncFormStatusForAutoForm = ({ formKeyPath, onFormChange }) => {
  const dispatch = useDispatch();
  const { submitted, changed, error, model } = useForm();
  const isHaveError = !!error;
  const formRefs = useSelector(getFormRefsSelector);

  const syncFormModelFunc = useCallback(
    (newModel) => {
      const formRef = lodashGet(formRefs, formKeyPath);
      // Check formRef here to prevent case after we remove formRef (because template change, ...) but it still sync
      // form models => make incorrect orderFormModels structure
      if (formRef) {
        onFormChange?.(newModel);
        dispatch(syncFormModel(formKeyPath, newModel));
      }
    },
    [dispatch, formKeyPath, formRefs]
  );

  // Use debounce here for avoid too many sync in short time
  const syncFormModelDebounceFunc = useMemo(() => debounce(syncFormModelFunc, 300), [syncFormModelFunc]);

  useEffect(() => {
    syncFormModelDebounceFunc(model);

    return () => {
      syncFormModelDebounceFunc.cancel();
    };
  }, [model, syncFormModelDebounceFunc]);

  // Sync form meta data
  useEffect(() => {
    const fomMetaData = { submitted, changed, isHaveError };
    if (submitted) {
      fomMetaData.onceSubmitted = true;
    }
    dispatch(updateFormMetaData(formKeyPath, fomMetaData));
  }, [submitted, changed, dispatch, formKeyPath, isHaveError]);

  return null;
};

export default connectField(HandleSyncFormStatusForAutoForm);
