import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  vesselContainer: {
    marginTop: theme.spacing(1),
  },
  vesselItem: {
    padding: theme.spacing(0, 0.5),
  },
}));

export default useStyles;
