import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

export type AddressData = {
  addressItemData?: unknown;
  center: {
    lat: number;
    lng: number;
  } | null;
  address1: string;
  country: string;
  postalCode: string;
  state: string;
  city: string;
};

export const getFullAddress = async (addressLine: string): Promise<AddressData | null> => {
  const [address] = await geocodeByAddress(addressLine).catch(() => [null]);

  if (!address) return null;

  const latLng = await getLatLng(address).catch(() => null);
  const { administrative_area_level_1, country, postal_code, administrative_area_level_2 } = parseAddressComponent(
    address.address_components
  );

  return {
    center: latLng,
    address1: addressLine || '',
    state: administrative_area_level_1?.short_name || '',
    country: country?.long_name || '',
    postalCode: postal_code?.long_name || '',
    city: administrative_area_level_2?.short_name,
  };
};

const parseAddressComponent = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
  const result: Record<string, { long_name: string; short_name: string }> = {};

  addressComponents.forEach(({ types, long_name, short_name }) => {
    types.forEach((type) => (result[type] = { long_name, short_name }));
  });

  return result;
};
