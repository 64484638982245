import './style.scss';

import { useTheme } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import TooltipWarningIcon from '@yojee/ui/common/TooltipWarningIcon';
import {
  areOriginsDestinationsMatchedSelector,
  isBookingInformationSectionHaveErrorSelector,
} from '@yojee/ui/new-order-booking/selectors';

import CollapseWithLazy from '../../../../../common/CollapseWithLazy';
import OriginDestinationMismatchMessage from '../../common/OriginDestinationMismatchMessage';
import VesselSection from '../../VesselSection';
import { BookingInfoSectionContext } from '../Contexts';
import ItemDetailSection from './ItemDetailsSection';
import LegsSection from './LegsSection';

const BookingInfoSection = () => {
  const theme = useTheme();
  const [formOpened, setFormOpened] = useState(false);
  const { bookingInfoSectionIndex } = useContext(BookingInfoSectionContext);
  const isHaveError = useSelector((state) =>
    isBookingInformationSectionHaveErrorSelector(state, bookingInfoSectionIndex)
  );
  const areOriginsDestinationsMatched = useSelector(areOriginsDestinationsMatchedSelector(bookingInfoSectionIndex));

  useEffect(() => {
    if (bookingInfoSectionIndex === 0) {
      setFormOpened(true);
    }
  }, [bookingInfoSectionIndex]);

  const toggleDisplayForm = useCallback(() => {
    setFormOpened((prev) => !prev);
  }, []);

  return (
    <section
      data-cy="booking-form-booking-information-section"
      className={`mt-15 new-booking-booking-information-section ${formOpened ? '' : 'form-closed'} ${
        isHaveError ? 'error' : ''
      }`}
    >
      <div className="flex align-center h-55">
        <IconButton onClick={toggleDisplayForm} aria-label="settings" data-cy="collapse-btn">
          {formOpened ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
        <label className="section-label font-semibold">
          <Trans>Order Information</Trans>
        </label>
        {!areOriginsDestinationsMatched && (
          <TooltipWarningIcon
            placement="right"
            size={3}
            style={{ marginLeft: theme.spacing(1) }}
            title={<OriginDestinationMismatchMessage />}
          />
        )}
      </div>

      <CollapseWithLazy in={formOpened} timeout="auto">
        <div className="booking-information-body">
          <LegsSection />
          <VesselSection />
          <ItemDetailSection />
        </div>
      </CollapseWithLazy>
    </section>
  );
};

export default React.memo(BookingInfoSection);
