import memoize from 'lodash-es/memoize';
import { Bridge } from 'uniforms';

import { isNumberType } from './formHelpers';
import { htmlInputTypeMap } from './SchemaHelpers';

export default class CustomUniformBridge extends Bridge {
  constructor(
    schema,
    validator,
    options = {
      initialValues: {},
      allow_missing_info: false,
      allow_dispatcher_add_more_legs: false,
      number_of_legs: undefined,
      task_sequence: undefined,
    }
  ) {
    super();

    this.schema = schema;
    this.validator = validator;
    this.initialValues = options.initialValues;
    this.options = options;

    // Memoize for performance and referential equality.
    this.getField = memoize(this.getField);
    this.getSubfields = memoize(this.getSubfields);
    this.getType = memoize(this.getType);
  }

  getError(name, error) {
    return error?.[name];
  }

  getErrorMessage(name, error) {
    return this.getError(name, error);
  }

  getErrorMessages(error) {
    if (error) {
      return Object.values(error);
    }

    return [];
  }

  getField(name) {
    return this.schema?.[name] || {};
  }

  getInitialValue(name, props) {
    return this.initialValues?.[name] || this.getField(name)?.default_value;
  }

  getDefaultModel() {
    const defaultModel = {};

    Object.keys(this.schema)
      .filter((fieldName) => this.schema[fieldName].ui_visible)
      .forEach((fieldName) => {
        let { default_value, type } = this.getField(fieldName);

        if (this._correctType(type) === String) {
          default_value = default_value ?? '';
        }

        defaultModel[fieldName] = default_value;
      });

    return defaultModel;
  }

  getProps(name, props) {
    let { name: label, required, system_enforced_required, min, max, type, disabled } = this.getField(name);
    let htmlFieldType = type;

    if (isNumberType(type)) {
      htmlFieldType = 'number';
    }
    if (['positive_number', 'positive_decimal', 'unit_number'].includes(type)) {
      min = min || 0;
    }

    const newProps = {
      ...props,
      required: system_enforced_required || required,
      label,
      min,
      max,
      decimal: 1,
      type: htmlInputTypeMap[htmlFieldType] ? htmlFieldType : undefined,
    };

    if (disabled) {
      newProps.disabled = disabled;
    }
    if (['integer', 'positive_number'].includes(type)) {
      newProps.inputProps = {
        step: 1,
      };
    }

    return newProps;
  }

  getSubfields({ name = '', exceptFields = [] } = {}) {
    if (name) {
      return [];
    }

    return Object.keys(this.schema)
      .filter((fieldName) => !exceptFields.includes(fieldName))
      .sort((fieldA, fieldB) => this.schema[fieldA].position - this.schema[fieldB].position);
  }

  getType(name) {
    const type = this.getField(name)?.type;
    return this._correctType(type);
  }

  _correctType(type) {
    if (isNumberType(type)) {
      return Number;
    }

    switch (type) {
      case 'string':
        return String;
      case 'boolean':
        return Boolean;
      case 'longitude':
        return Number;
      case 'latitude':
        return Number;
      case 'money':
        return Object;
      default:
        return String;
    }
  }

  getValidator() {
    return this.validator;
  }
}
