import React from 'react';
import { Trans } from 'react-i18next';

import Button from '@yojee/ui/base/furnitures/Button';

type Props = {
  onClick: () => void;
  disabled: boolean;
  isLoading: boolean;
  className: string;
  children: React.ReactNode;
  dataCy: string;
};

const SaveButtonUI: React.FC<Props> = ({ children = <Trans>Save</Trans>, dataCy = 'save-button', ...restProps }) => {
  return (
    <Button size="small" variant="contained" color="primary" data-cy={dataCy} {...restProps}>
      {children}
    </Button>
  );
};

export default SaveButtonUI;
