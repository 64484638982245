import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class ConnoteService extends BaseService {
  constructor({ services }) {
    super();
    this.authService = services;
  }

  getConnote = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/consignment_note_templates'))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  getConnoteSections = () => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/consignment_note_sections'))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  updateConnote = (connote) => {
    const data = this.filterConnoteData(connote.data);
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/consignment_note_templates/${connote.id}`), data)
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  filterConnoteData = (value) => {
    return {
      company: {
        address: value.company.address,
        phone: value.company.phone,
      },
      sections: value.sections.map((item) => {
        const { data, slug } = item;
        return !data ? { slug } : { data, slug };
      }),
    };
  };

  parseConnoteParamsIntoFormData = (connote) => {
    const formData = new FormData();
    const setFormData = (name, value) => {
      if (value) {
        formData.set(name, value);
      }
    };
    const appendFormData = (name, value) => {
      if (value) {
        formData.append(name, value);
      }
    };
    setFormData('company[address]', connote.company.address);
    setFormData('company[consignment_note_logo]', connote.company.consignment_note_logo);
    setFormData('company[phone]', connote.company.phone);

    if (connote.sections.length > 0) {
      connote.sections.forEach((data, idx) => {
        if (!data.data) {
          appendFormData('sections[' + idx + '].data', data.data);
        } else {
          appendFormData('sections[' + idx + '].data.term_and_condition', data.data.term_and_condition);
        }
        appendFormData('sections[' + idx + '].slug', data.slug);
      });
    }

    return formData;
  };
}

export const connoteService = new ConnoteService({ services: authService });
