import moment, { Moment } from 'moment';

import { DEFAULT_TIME_VALUES, TIME_FORMAT, TIME_MAP } from '../constant';
import { Gap, TimeType } from '../types';

export const formatTime = (time: TimeType) => {
  if (!time) return '';
  return time.format(TIME_FORMAT);
};

function isInTimeMap(timeStr: string): timeStr is keyof typeof TIME_MAP {
  return TIME_MAP.hasOwnProperty(timeStr);
}

// https://stackoverflow.com/a/14787410/21617288
export function parseTime(timeStr: string, step?: number): TimeType {
  timeStr = timeStr.toLowerCase();
  if (isInTimeMap(timeStr)) {
    timeStr = TIME_MAP[timeStr];
  }

  let hour, minute, stepMinute;
  let pm = timeStr.match(/p/i) !== null;
  const num = timeStr.replace(/[^0-9]/g, '');

  // Parse for hour and minute
  switch (num.length) {
    case 4:
      hour = parseInt(num[0] + num[1], 10);
      minute = parseInt(num[2] + num[3], 10);
      break;
    case 3:
      hour = parseInt(num[0], 10);
      minute = parseInt(num[1] + num[2], 10);
      break;
    case 2:
    case 1:
      hour = parseInt(num[0] + (num[1] || ''), 10);
      minute = 0;
      break;
    default:
      return null;
  }

  // Make sure hour is in 24 hour format
  if (pm === true && hour > 0 && hour < 12) hour += 12;

  // Force pm for hours between 13:00 and 23:00
  if (hour >= 13 && hour <= 23) pm = true;

  // Handle step
  if (step) {
    // Step to the nearest hour requires 60, not 0
    if (step === 0) step = 60;
    // Round to nearest step`
    stepMinute = (Math.round(minute / step) * step) % 60;
    // Do we need to round the hour up?
    if (stepMinute === 0 && minute >= 30) {
      hour++;
      // Do we need to switch am/pm?
      if (hour === 12 || hour === 24) pm = !pm;
    }
    minute = stepMinute;
  }

  // Keep within range
  if (hour <= 0 || hour >= 24) hour = 0;
  if (minute < 0 || minute > 59) minute = 0;

  return generateTime({ h: hour, m: minute });
}

export const generateTime = ({ h, m }: { h?: number; m?: number }) => {
  return moment().set({ hour: h, minute: m, ...DEFAULT_TIME_VALUES });
};

export const normalizeTime = (time: Moment) => generateTime({ h: time.hour(), m: time.minute() });

export const getTimeOptions = (date: TimeType) =>
  Array.from({ length: 24 }, (_, index) => generateTime({ h: index, m: date?.minute() || 0 }));

const modulo = (dividend: number, divisor: number) => ((dividend % divisor) + divisor) % divisor;

export const getGap = (start: TimeType, end: TimeType): Gap | null => {
  if (!start || !end) return null;
  const normalizedStart = normalizeTime(start);
  const normalizedEnd = normalizeTime(end);
  const minuteGap = normalizedEnd.diff(normalizedStart, 'minute');

  return { m: modulo(minuteGap, 24 * 60) };
};
