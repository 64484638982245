import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles({
  tdLabel: {
    width: '180px',
    height: '30px',
    padding: '0px',
  },
  labelText: {
    fontWeight: 'bolder',
    margin: '0px',
  },
});

const TdLabel: React.FC<{ children: string }> = ({ children }) => {
  const classes = useStyles();
  return (
    <td className={classes.tdLabel}>
      <p className={classes.labelText}>{children}</p>
    </td>
  );
};

export default React.memo(TdLabel);
