import { t } from 'i18next';
import { filterDOMProps } from 'uniforms';

import { SENDER_TYPES } from '@yojee/helpers/constants';
import { generateTime } from '@yojee/ui/common/DateTimeRangePicker/Time/utils';
import { BOOKING_TEMPLATE_CONSUMER_MAP } from '@yojee/ui/new-order-booking/components/helpers/utils';

import { getVesselNameKeyMap, VESSEL_DATETIME_KEYS } from './constants';

export class SchemaHelper {
  constructor({ templateSchema = {}, templateSettings = {}, orderFormModels = {}, isEditOrder } = {}) {
    // Set default value for `only` property in case it didn't have
    Object.values(templateSchema).forEach((field) => {
      field.only = field.only || [BOOKING_TEMPLATE_CONSUMER_MAP.bookingForm, BOOKING_TEMPLATE_CONSUMER_MAP.batchUpload];
    });

    if (isEditOrder) {
      Object.values(templateSchema).forEach((field) => {
        if (
          field.ui_visible === false &&
          field.system_enforced_ui_visible !== false &&
          field.category === 'container'
        ) {
          const itemContainers = orderFormModels.bookingInfoSections
            .map((x) => {
              const itemDetails = x.itemDetails;
              return itemDetails.map((i) => i.item_container);
            })
            .flat()
            .filter((x) => x !== null);

          //@SONAR-STOP: Sonar suggests using optional chain expression, but it is not correct for this case.
          const hasValue = itemContainers.find((itemContainer) => itemContainer && itemContainer[field.key] !== null);
          //@SONAR-START

          if (hasValue) {
            field.data_available = true;
          }
        }
      });
    }

    this.masterSchema = templateSchema;
    this.templateSettings = templateSettings;
    this.orderFormModels = orderFormModels;
    this.isEditOrder = isEditOrder;
    this.senderSchema = this.getSenderSchema();
    this.orderSchema = this._getSubSchema('order');
    this.stepSchema = this.getStepSchema();
    this.itemSchema = this._getSubSchema('item');
    this.containerSchema = this._getSubSchema('container');
    this.originalContainerSchema = this.getOriginalSchema(this.containerSchema);
    this.vesselSchema = this.getVesselSchema();
  }

  getOriginalSchema = (schema) => {
    return Object.values(schema).filter((field) => {
      return (
        field.template_configurable &&
        field.ui_visible &&
        field.only.includes(BOOKING_TEMPLATE_CONSUMER_MAP.bookingForm)
      );
    });
  };

  _getSubSchema = (selectCategory) => {
    const subSchema = {};

    if (this.isHaveMasterSchema()) {
      Object.values(this.masterSchema)
        .filter((field) => {
          return (
            (field.template_configurable &&
              field.ui_visible &&
              field.only.includes(BOOKING_TEMPLATE_CONSUMER_MAP.bookingForm)) ||
            field.data_available
          );
        })
        .forEach((field) => {
          if (field.category === selectCategory) {
            subSchema[field.key] = field;
          }
        });
    }

    return subSchema;
  };

  getSenderSchema = () => {
    let senderSchema = this._getSubSchema('sender');
    // Check this condition for only add extra fields when template data is exists
    if (this.isHaveMasterSchema()) {
      if (this.isEditOrder) {
        const senderData = this.orderFormModels.order?.sender;
        if (senderData?.type === SENDER_TYPES.individual) {
          senderSchema['sender_name'] = {
            category: 'sender',
            key: 'sender_name',
            name: t('Sender name'),
            position: 0,
            required: false,
            system_enforced_required: null,
            type: 'string',
            default_value: senderData?.name,
          };
        } else {
          senderSchema = this.addCorporateRelativeFieldsToSenderForm(senderSchema);
        }
      } else {
        senderSchema = this.addCorporateRelativeFieldsToSenderForm(senderSchema);
      }
    }

    return senderSchema;
  };

  addCorporateRelativeFieldsToSenderForm = (senderSchema) => {
    const newSenderSchema = { ...senderSchema };

    newSenderSchema['corporate'] = {
      category: 'sender',
      key: 'corporate',
      name: t('Corporate account'),
      position: 0,
      required: true,
      system_enforced_required: true,
      type: 'string',
    };
    newSenderSchema['user_profile_id'] = {
      category: 'sender',
      key: 'user_profile_id',
      name: t('Corporate user'),
      position: 0,
      required: true,
      system_enforced_required: true,
      type: 'integer',
    };

    return newSenderSchema;
  };

  getStepSchema = () => {
    const stepSchema = this._getSubSchema('task');
    const fromTimePosition = stepSchema['from_time']?.position ?? 0;

    // Check this condition for only add extra fields when template data is exists
    if (window.IS_EXPLORE_APP && this.isHaveMasterSchema()) {
      stepSchema['address_id'] = {
        category: 'task',
        key: 'address_id',
        name: t('Ext. Address ID'),
        position: 0,
        required: false,
        system_enforced_required: null,
        type: 'string',
      };
    }

    if (stepSchema['from_time']) {
      stepSchema['from_time_time'] = {
        ...stepSchema['from_time'],
        key: 'from_time_time',
        default_value: generateTime({ h: 12, m: 0 }),
        name: t('From Time'),
      };

      stepSchema['from_time'] = {
        ...stepSchema['from_time'],
        name: t('From Date'),
      };
    }

    if (stepSchema['to_time']) {
      stepSchema['to_time_time'] = {
        ...stepSchema['to_time'],
        key: 'to_time_time',
        default_value: generateTime({ h: 13, m: 0 }),
        name: t('To Time'),
      };

      if (window.IS_BOOKING_APP) {
        stepSchema['period_of_time'] = {
          ...stepSchema['to_time'],
          key: 'period_of_time',
          name: t('Period of Time'),
          required: false,
          system_enforced_required: null,
        };
      }

      stepSchema['to_time'] = {
        ...stepSchema['to_time'],
        name: t('To Date'),
      };
    }

    stepSchema['is_empty'] = {
      category: 'task',
      key: 'is_empty',
      name: t('Empty'),
      position: 0,
      required: false,
      system_enforced_required: null,
      type: 'checkbox',
    };

    stepSchema['display_time_conflict_warning'] = {
      type: 'boolean',
      category: 'task',
      key: 'display_time_conflict_warning',
      position: fromTimePosition - 1,
      required: false,
      system_enforced_required: null,
      default_value: false,
    };

    return stepSchema;
  };

  /**
   * Return a mapped vessel schema object by creating `time` keys based on the `datatime` keys.
   * e.g:

          input: {
            ...
            order_voyage_fcl_storage_datetime: {...data},
            order_voyage_receiving_start_datetime: {...data},
          };

          output: {
             ...
            order_voyage_fcl_storage_datetime: {...data, name: customName},
            order_voyage_receiving_start_datetime: {...data, name: customName},
            order_voyage_fcl_storage_time: {...data},
            order_voyage_receiving_start_time: {...data},
          }
   * @returns {Object}
   */
  getVesselSchema = () => {
    const vesselSchema = this._getSubSchema('vessel');
    const vesselNameKeyMap = getVesselNameKeyMap();

    VESSEL_DATETIME_KEYS.forEach((key) => {
      if (vesselSchema[key]) {
        const timeKey = key.replace('date', '');
        vesselSchema[timeKey] = { ...vesselSchema[key], name: vesselNameKeyMap[timeKey], key: timeKey };

        vesselSchema[key].name = vesselNameKeyMap[key];
      }
    });

    return vesselSchema;
  };

  isHaveMasterSchema = () => Object.keys(this.masterSchema).length;
}

export function customFilterDOMProps(props, extraIgnoreFields = []) {
  const {
    itemIndex,
    legIndex,
    stepIndex,
    formKeyPath,
    includeAddressBook,
    units,
    setClasses,
    isEditOrder,
    ...remainProps
  } = props;
  extraIgnoreFields?.forEach((fieldName) => {
    delete remainProps[fieldName];
  });

  return filterDOMProps(remainProps);
}

export const htmlInputTypes = ['text', 'email', 'number', 'range', 'tel', 'url', 'time', 'date', 'color', 'checkbox'];
export const htmlInputTypeMap = htmlInputTypes.reduce((acc, type) => {
  acc[type] = true;

  return acc;
}, {});
