import Button from '@material-ui/core/Button';
import { omit } from 'lodash';
import lodashGet from 'lodash-es/get';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { sendEvent } from '@yojee/ui/event-tracking';
import { getFormKeyPath } from '@yojee/ui/new-order-booking/components/helpers/formHelpers';
import { getFormRefsSelector } from '@yojee/ui/new-order-booking/selectors';

import { addSuccessMessage } from '../../../../../../../../saga/actions';
import { ADD_LEG_ACTION_TYPE, TASK } from '../../../../../constants';
import { BookingInfoSectionContext } from '../../../../../Contexts';

const AddLegAtTheEnd = ({ addNewLeg, closePopover }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formRefs = useSelector(getFormRefsSelector);
  const { bookingInfoSectionIndex } = useContext(BookingInfoSectionContext);

  const addLegAtTheEnd = () => {
    sendEvent('PLANNING', 'ADD_LEG_AT_THE_END');

    // Get legs key path
    const legsKeyPath = `${getFormKeyPath({ bookingInfoSectionIndex })}.legs`;

    // Get number of valid legs
    const numberOfValidLegs = lodashGet(formRefs, legsKeyPath, []).filter((leg) => leg.some((step) => !!step)).length;

    // Get current last stepFormRefs
    const currentLastStepFormRefs = lodashGet(formRefs, `${legsKeyPath}.${numberOfValidLegs - 1}`, []);

    // Get current last DROPOFF task data
    const dropoff = currentLastStepFormRefs[1].getModel();
    const { is_empty, ...copyDropoff } = omit(dropoff, [
      'order_step_group_id',
      'order_step_id',
      'order_item_id',
      'id',
      'step_property',
      'display_time_conflict_warning',
    ]);

    // New Leg Data
    const newLeg = [
      { ...copyDropoff, type: TASK.PICKUP, isNewStep: true, is_empty }, // Pickup = Details copied from the DROPOFF of the previous leg.
      { type: TASK.DROPOFF, isNewStep: true, timezone: copyDropoff.timezone, is_empty }, // Dropoff = Empty, no details, but should take the timezone from the last step
    ];

    addNewLeg(newLeg, ADD_LEG_ACTION_TYPE.ADD_AT_THE_END);

    dispatch(addSuccessMessage(t('A new leg is added at the end')));
    closePopover();
  };

  return (
    <div>
      <Button onClick={addLegAtTheEnd} data-cy="add-new-leg-button">
        {t('Add leg at the end')}
      </Button>
    </div>
  );
};

export default AddLegAtTheEnd;
