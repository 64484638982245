import React, { useCallback } from 'react';
import { Trans } from 'react-i18next';
import { SelectField } from 'uniforms-material';

import { PACKING_MODE_OPTIONS } from '@yojee/helpers/constants';

import { useAddValueAsOptionIfValueMissingInOptionsForSelect } from '../../../helpers/formHelpers';
import { customFilterDOMProps } from '../../../helpers/SchemaHelpers';

const allowedValues = Object.keys(PACKING_MODE_OPTIONS);

function PackingModeSelectField({ InputLabelProps, ...props }) {
  const transform = useCallback((mode) => PACKING_MODE_OPTIONS[mode], []);

  const onChange = (value) => {
    value = value ? value : '';
    props.onChange(value);
  };

  const { newAllowValues, newTransform } = useAddValueAsOptionIfValueMissingInOptionsForSelect({
    value: props.value,
    label: props.value,
    allowedValues,
    transform,
  });

  return (
    <SelectField
      {...customFilterDOMProps(props)}
      placeholder={
        <Trans>
          <em>Please choose</em>
        </Trans>
      }
      onChange={onChange}
      transform={newTransform}
      allowedValues={newAllowValues}
      disabled={props.isEditOrder}
      name=""
      data-cy="order-mode-select"
      MenuProps={{
        'data-cy': 'order-mode-select-menu',
      }}
    />
  );
}

export default PackingModeSelectField;
