import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';

const styles = () => ({
  title: {
    fontSize: '21px',
    fontWeight: 600,
    textAlign: 'center',
    color: '#000',
  },
  content: {
    textAlign: 'center',
    padding: '0 20px',
    color: '#000',
    fontSize: '1rem',
  },
  actions: {
    justifyContent: 'center',
    marginBottom: '32px',
    marginTop: '13px',
    padding: '0 8px',
  },
  okBtn: {
    padding: '14px 7px',
    borderRadius: '10px',
    border: 'solid 1px #979797',
    fontWeight: 600,
    lineHeight: 1.5,
    textTransform: 'none',
    height: '47px',
    minWidth: '100px',
    color: '#000',
  },
});

const InformationStyleDialog = ({ classes, open, onClose, title, body, okText = 'Ok', onOk, dataCy = '' }) => {
  return (
    <Dialog open={open} onClose={onClose} data-cy={dataCy}>
      <DialogTitle disableTypography={true} className={classes.title} data-cy="dialog-title">
        {title}
      </DialogTitle>
      <DialogContent className={classes.content} data-cy="dialog-content">
        {body}
      </DialogContent>
      <DialogActions className={classes.actions} data-cy="dialog-actions">
        <Button className={classes.okBtn} onClick={onOk} data-cy="dialog-action-ok">
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(InformationStyleDialog);
