import React, { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import TooltipReadOnly from '@yojee/ui/common/TooltipReadOnly';
import { getFormKeyPath, getFormRef } from '@yojee/ui/new-order-booking/components/helpers/formHelpers';
import { ReadOnlyContext } from '@yojee/ui/ReadOnlyHelper';

import NormalStyleDialog from '../../../../../../../../../dialog/NormalStyle';
import { addSuccessMessage, deleteFormModels } from '../../../../../../../../saga/actions';
import { getFormRefsSelector } from '../../../../../../../../selectors';
import DeleteIconBtn from '../../../../../../../common/DeleteIconBtn';
import { SchemaHelperContext } from '../../../../../../../helpers/Contexts';
import { updateField, useIsEditOrder, useIsReadOnlyLegSection } from '../../../../../../../helpers/hooks';
import { useBookingInfoSectionIndex } from '../../../../../Contexts';

const DeleteLeg = ({ legIndex, totalLeg, removeLeg, formKeyPath, divider }) => {
  const bookingInfoSectionIndex = useBookingInfoSectionIndex();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const { number_of_legs: templateSettingNumberOfLeg = 1 } = useContext(SchemaHelperContext).templateSettings;
  const isEditOrder = useIsEditOrder();
  // use this one to make this component re-render when the form data changes,
  // so we can get the latest stepFormRefs
  useSelector((state) => state.orderBooking);
  const formRefs = useSelector(getFormRefsSelector);
  const stepFormRefs = getFormRef(formRefs, formKeyPath) || [];
  const isReadOnlyLegSection = useIsReadOnlyLegSection();
  const readOnly = useContext(ReadOnlyContext);
  const closeConfirm = () => setIsOpenConfirm(false);
  const openConfirm = () => setIsOpenConfirm(true);

  const onConfirmToDetete = () => {
    // Handle copy dropoff up to previous leg to avoid deleting the origin leg step in DB
    const dropoff = stepFormRefs[1].getModel();
    const isSplitedLeg = !!dropoff.old_order_step_group_id;

    if (isSplitedLeg) {
      const previousLegFormKeyPath = getFormKeyPath({
        bookingInfoSectionIndex,
        legIndex: legIndex - 1,
      });
      const previousLegRef = getFormRef(formRefs, previousLegFormKeyPath);
      const previousDropOffRef = previousLegRef?.[1];

      if (previousDropOffRef) {
        updateField(previousDropOffRef, {
          order_step_id: dropoff.order_step_id,
          old_order_step_group_id: dropoff.old_order_step_group_id,
        });
      }
    }

    // Start actual removing leg and re-sync model
    dispatch(addSuccessMessage(t('Leg deleted')));
    removeLeg(legIndex);
    syncDeletedFormModels();
  };

  const isNewLeg = () => !!stepFormRefs?.every((stepFormRef) => !!stepFormRef?.getModel()?.isNewStep);

  const syncDeletedFormModels = () => {
    dispatch(deleteFormModels(formKeyPath));
  };

  const disabled =
    isReadOnlyLegSection || (legIndex < templateSettingNumberOfLeg && totalLeg <= templateSettingNumberOfLeg);

  const legNo = legIndex + 1;
  const isHide = isEditOrder && !isNewLeg();

  if (isHide) return null;

  return (
    <>
      {divider}
      <TooltipReadOnly readOnly={readOnly}>
        <DeleteIconBtn isDisabled={disabled} onClick={openConfirm} dataCy="delete-leg-button" />
      </TooltipReadOnly>
      {isOpenConfirm && (
        <NormalStyleDialog
          open={true}
          title={<Trans i18nKey="deleteLegInOrderForm" values={{ legNo }} />}
          body={
            <>
              <Trans>Are you sure you want to delete this Leg information?</Trans>
              <br />
              <span className="font-bold">
                <Trans>This cannot be undone!</Trans>
              </span>
            </>
          }
          onClose={closeConfirm}
          onCancel={closeConfirm}
          onOk={onConfirmToDetete}
          cancelText={t('Cancel')}
          okText={t('Yes, Delete')}
          dataCy="delete-leg-confirm-dialog"
        />
      )}
    </>
  );
};

export default DeleteLeg;
