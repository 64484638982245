import './style.scss';

import { TextField, Tooltip } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { DEBOUNCE_TIME_GOOGLE_API } from '@yojee/helpers/constants';
import useDebounce from '@yojee/helpers/hooks/useDebounce';
import useService from '@yojee/helpers/hooks/useService';

import { getFormRefsSelector, getOrderSenderId } from '../../../../selectors';
import serviceLocator from '../../../../serviceLocator';
import { getFormRef } from '../../../helpers/formHelpers';
import { useIsCreateOrder } from '../../../helpers/hooks';
import { customFilterDOMProps } from '../../../helpers/SchemaHelpers';
import { fillDataFromAddressBook } from '../BaseAddressSelectField/helpers';

const getOptionName = (option) => `${option.external_id || option.id} - ${option.address1}`;

function AddressIDField(props) {
  const { formKeyPath } = props;
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebounce(inputValue, DEBOUNCE_TIME_GOOGLE_API);
  const isCreateOrder = useIsCreateOrder();
  const formRefs = useSelector(getFormRefsSelector);
  const formRef = getFormRef(formRefs, formKeyPath);
  const senderId = useSelector(getOrderSenderId);

  const { data: addressBooksItems = [], execute: getAddressBookItems } = useService(
    serviceLocator.companyService.getCompanyAddressBookEntries,
    {
      lazy: true,
    }
  );

  useEffect(() => {
    if (debouncedInputValue && debouncedInputValue.length > 2) {
      const params = { query: debouncedInputValue, senderId };
      if (!senderId) params.senderLinkType = 'all';
      getAddressBookItems(params);
    }
  }, [debouncedInputValue, getAddressBookItems, senderId]);

  const getOptionLabel = (option) => {
    return (option?.external_id || option?.id || option)?.toString();
  };

  const onChange = (event, newValue) => {
    props.onChange(newValue?.external_id || newValue?.id || '');

    if (newValue) {
      formRef.change('address', newValue.address1 ?? '');
      fillDataFromAddressBook({ addressBookData: newValue, formRef, isCreateOrder });
    }
  };

  return (
    <div className="custom-field-address-id-wrapper">
      <Autocomplete
        className="custom-field-address-id-autocomplete"
        filterOptions={(x) => x}
        freeSolo
        autoComplete
        disabled={props.disabled}
        getOptionLabel={getOptionLabel}
        options={addressBooksItems}
        value={props.value || ''}
        onChange={onChange}
        onInputChange={(event, newInputValue) => {
          // Check event here to ignore in case first time render with default value
          // => no need to call api get prediction
          if (event) {
            setInputValue(newInputValue);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...customFilterDOMProps(props)}
            margin="dense"
            label={props.label}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment className="custom-field-address-id-adornment" position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
          />
        )}
        renderOption={(option) => (
          <Tooltip title={getOptionName(option)}>
            <Typography className="custom-field-address-id-select-option-item" noWrap>
              {getOptionName(option)}
            </Typography>
          </Tooltip>
        )}
      />
      <div className="custom-field-address-id-placeholder-section" />
    </div>
  );
}

export default AddressIDField;
