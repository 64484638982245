import makeStyles from '@material-ui/core/styles/makeStyles';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useService from '@yojee/helpers/hooks/useService';
import { mapFilters } from '@yojee/helpers/search-helper';
import {
  getOriginalOrderFormModelsSelector,
  isLoadingOrderDetailSelector,
  isLoadingTemplateDetailSelector,
} from '@yojee/ui/new-order-booking/selectors';

import { clearMessage, updateOrderDetailState, updateState, updateTasksState } from '../../saga/actions';
import serviceLocator from '../../serviceLocator';
import { useGetOrderNumber } from '../helpers/hooks';

export const useCommonAPIData = () => {
  const orderNumber = useGetOrderNumber();

  useGetTasksOfOrder();

  useService(serviceLocator.companyService.getPublicOrderCompanyInfo, {
    storeKey: 'companyInfo',
    updateStoreAction: updateState,
  });

  useService(serviceLocator.itemTypesApi.getPublicOrderItemTypes, {
    storeKey: 'itemTypes',
    updateStoreAction: updateState,
  });

  useService(serviceLocator.companyService.getTaskTypes, {
    storeKey: 'taskTypes',
    updateStoreAction: updateState,
  });

  useService(serviceLocator.serviceTypesApi.getPublicOrderServiceTypes, {
    storeKey: 'serviceTypes',
    updateStoreAction: updateState,
  });

  useService(serviceLocator.containerTypeApi.getContainerTypes, {
    serviceParams: [
      {
        page_size: 2000,
        page: 1,
      },
    ],
    storeKey: 'containerTypes',
    updateStoreAction: updateState,
  });

  const { execute: getCompanyNonOperationalZones } = useService(
    serviceLocator.companyService.getCompanyNonOperationalZones,
    {
      storeKey: 'nonOperationalZones',
      updateStoreAction: updateState,
      lazy: true,
    }
  );

  const { execute: getOrderDetail } = useService(serviceLocator.orderApiV4.getOrderDetail, {
    updateStoreAction: updateOrderDetailState,
    lazy: true,
  });

  useEffect(() => {
    if (orderNumber) {
      getOrderDetail(orderNumber);
    }
  }, [getOrderDetail, orderNumber]);

  useEffect(() => {
    if (window.IS_BOOKING_APP) {
      getCompanyNonOperationalZones();
    }
  }, [getCompanyNonOperationalZones]);
};

export const useDisableScrollOnNumberField = () => {
  useEffect(() => {
    const disableScrollOnNumberField = () => {
      if (document.activeElement.type === 'number') {
        document.activeElement.blur();
      }
    };
    document.addEventListener('wheel', disableScrollOnNumberField);

    return () => {
      document.removeEventListener('wheel', disableScrollOnNumberField);
    };
  }, []);
};

const useGetTasksOfOrder = () => {
  const orderNumber = useGetOrderNumber();
  const companyId = useSelector((state) => state.auth?.dispatcher_info?.data?.company?.id);
  const serviceTypes = useSelector((state) => state.company?.serviceTypes);
  const originalOrderFormModels = useSelector(getOriginalOrderFormModelsSelector);

  const { execute: getTasks } = useService(serviceLocator.tasksSearchService.search, {
    updateStoreAction: updateTasksState,
    lazy: true,
  });

  useEffect(() => {
    // Add originalOrderFormModels to dependency for re-fetch tasks when fetch order detail
    if (window.IS_EXPLORE_APP && orderNumber && originalOrderFormModels) {
      const masterState = {
        filter: {
          idKey: 'order_number',
          idValue: [orderNumber],
        },
      };

      const filterBody = mapFilters({
        companyId,
        masterState,
        taskState: 'ALL',
        searchState: {},
        regions: [],
        params: { size: 10000 },
        serviceTypes,
      });

      getTasks(filterBody);
    }
  }, [companyId, getTasks, orderNumber, originalOrderFormModels]);
};

const useMessageStyles = makeStyles(() => ({
  toastMessage: {
    whiteSpace: 'break-spaces',
  },
}));

export const useDisplayMessage = () => {
  const classes = useMessageStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { errorMessage, successMessage } = useSelector((state) => state.orderBooking);

  useEffect(() => {
    if (errorMessage || successMessage) {
      enqueueSnackbar(<div className={classes.toastMessage}>{errorMessage || successMessage}</div>, {
        variant: errorMessage ? 'error' : 'success',
      });
      dispatch(clearMessage());
    }
  }, [dispatch, enqueueSnackbar, errorMessage, successMessage]);
};

export const useIsShowLoadingFormStatus = (subFields) => {
  const isLoadingTemplateDetail = useSelector(isLoadingTemplateDetailSelector);
  const isLoadingOrderDetail = useSelector(isLoadingOrderDetailSelector);

  return isLoadingTemplateDetail || isLoadingOrderDetail || !subFields?.length;
};
