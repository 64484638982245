import './style.scss';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';

import { FORMAT_TIME_FOR_NEW_BOOKING } from '@yojee/helpers/constants';

import { customFilterDOMProps } from '../../../helpers/SchemaHelpers';
import { isVesselCategoryField } from '../../../helpers/utils';
import DefaultTimePicker from './../../../../../date-time-picker/RCTimePicker';

function FromTimeField(props) {
  const { setClasses } = props;
  const [open, setOpen] = useState(false);

  const customOnChange = (event) => {
    setOpen(false);
    props.onChange(event);
  };

  useEffect(() => {
    if (isVesselCategoryField(props.field)) return;

    if (window.IS_BOOKING_APP) {
      setClasses('field-hidden');
    }
  }, []);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DefaultTimePicker
        {...customFilterDOMProps(props)}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        minuteStep={5}
        margin="dense"
        onChange={customOnChange}
        value={props.value || null}
        name=""
        label={props.label}
        format={FORMAT_TIME_FOR_NEW_BOOKING}
        placeholder={FORMAT_TIME_FOR_NEW_BOOKING.toLowerCase()}
        error={!!props.error}
        helperText={props.error}
        required={props.required}
        disabled={props?.disabled}
        cont
      />
    </MuiPickersUtilsProvider>
  );
}

export default FromTimeField;
