import { AVAILABLE_ORDER_STATUS_ENUM } from '@yojee/helpers/OrdersHelper';

type OrderInfo = {
  status: string;
  is_from_transfer: boolean;
};

const checkIfIsAllowedToGetDocuments: (isUsingDocumentPortalFeature: boolean, orderInfo?: OrderInfo) => boolean = (
  isUsingDocumentPortalFeature,
  orderInfo
) => {
  if (!orderInfo) return false;

  const isFromTransferAndNotYetAccepted =
    orderInfo.is_from_transfer && orderInfo.status === AVAILABLE_ORDER_STATUS_ENUM.CREATED;

  const isAllowed =
    isUsingDocumentPortalFeature &&
    [
      AVAILABLE_ORDER_STATUS_ENUM.ACCEPTED,
      AVAILABLE_ORDER_STATUS_ENUM.CREATED,
      AVAILABLE_ORDER_STATUS_ENUM.COMPLETED,
    ].includes(orderInfo.status) &&
    !isFromTransferAndNotYetAccepted;

  return isAllowed;
};

export default checkIfIsAllowedToGetDocuments;
