import { styled } from '@material-ui/core';
import React from 'react';

import { COMPONENT_HEIGHT } from '../constant';

const StyledDashContainer = styled('div')({
  height: COMPONENT_HEIGHT,
  padding: 10,
  color: '#686b7a',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
});

const DashDivider = () => <StyledDashContainer>-</StyledDashContainer>;

export default DashDivider;
