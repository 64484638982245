import makeStyles from '@material-ui/core/styles/makeStyles';
import { isEmpty } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ORDER_BOOKING_TAB } from '@yojee/helpers/constants';
import useService from '@yojee/helpers/hooks/useService';
import {
  getCompanyTimezoneSelector,
  getOrderFormModelsSelector,
  getTemplateDetailIdSelector,
  isServiceTypeChangedSelector,
} from '@yojee/ui/new-order-booking/selectors';

import {
  updateEditOrderState,
  updateOrderDetailState,
  updateReapplyOperationsEditOrderState,
} from '../../../../saga/actions';
import { getChargesDataSelector } from '../../../../selectors';
import serviceLocator from '../../../../serviceLocator';
import { ConfigContext, SchemaHelperContext } from '../../../helpers/Contexts';
import { useIsReadOnlyOrder } from '../../../helpers/hooks';
import { getEditOrderRequestPayload, useIsFormChangedChecker } from '../helpers';
import { useIsOrderInvoiceExisted, usePerformVariousChecksForOrder } from '../hooks';
import EditInvoicedOrderConfirmDialog from './EditInvoicedOrderConfirmDialog';
import ReCalculateOrderConfirmDialog from './ReCalculateOrderConfirmDialog';
import SaveButtonUI from './SaveButtonUI';

const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
  },
}));

const SaveEditedOrderBtn = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { orderNumber } = useParams();
  const isReadOnlyOrder = useIsReadOnlyOrder();
  const charges = useSelector(getChargesDataSelector);
  const templateId = useSelector(getTemplateDetailIdSelector);
  const orderFormModels = useSelector(getOrderFormModelsSelector);
  const companyTimezone = useSelector(getCompanyTimezoneSelector);
  const isServiceTypeChanged = useSelector(isServiceTypeChangedSelector);
  const schemaHelper = useContext(SchemaHelperContext);
  const formsChanged = useIsFormChangedChecker();
  const { doChecks, getConfirmDialogs } = usePerformVariousChecksForOrder();
  const [redirectPage, setRedirectPage] = useState(null);
  const [isEditInvoicedOrderConfirmDialogDisplayed, setIsEditInvoicedOrderConfirmDialogDisplayed] = useState(false);
  const isOrderInvoiceExisted = useIsOrderInvoiceExisted();
  const { usingRatingFeature } = useContext(ConfigContext);
  const [isReCalculateConfirmDialogDisplayed, setIsReCalculateConfirmDialogDisplayed] = useState(false);
  const { changeTab } = useContext(ConfigContext);
  const { execute: getOrderDetail } = useService(serviceLocator.orderApiV4.getOrderDetail, {
    updateStoreAction: updateOrderDetailState,
    lazy: true,
  });

  const { execute: editOrder, status: editOrderStatus } = useService(serviceLocator.orderApiV4.editOrder, {
    updateStoreAction: updateEditOrderState,
    lazy: true,
  });

  const { execute: reapplyOperationsForOrders, status: reapplyStatus } = useService(
    serviceLocator.orderApiV4.reapplyOperationsForOrders,
    {
      updateStoreAction: updateReapplyOperationsEditOrderState,
      lazy: true,
    }
  );

  const isEditOrderStatusSuccess = editOrderStatus === 'success';
  const isEditOrderStatusLoading = editOrderStatus === 'loading';
  const isReapplySuccess = reapplyStatus === 'success';

  const location = useLocation();
  const from = location?.state?.from;
  const gotoPreviousPage = useCallback(() => {
    isEditOrderStatusSuccess && navigate(from ?? -1);
  }, [isEditOrderStatusSuccess, navigate, from]);

  useEffect(() => {
    if (isEditOrderStatusSuccess) {
      (async () => {
        if (isServiceTypeChanged) {
          const {
            order_info: { id: orderId },
          } = getEditOrderRequestPayload({ orderFormModels, templateId, companyTimezone, schemaHelper });

          await reapplyOperationsForOrders([orderId]);
        }
        if (isOrderInvoiceExisted) return setIsEditInvoicedOrderConfirmDialogDisplayed(true);
        if (usingRatingFeature && !isEmpty(charges)) {
          setIsReCalculateConfirmDialogDisplayed(true);
        }
        if (redirectPage === 'previous-page' && isEmpty(charges)) {
          gotoPreviousPage();
          setIsReCalculateConfirmDialogDisplayed(false);
        }
        if (redirectPage === 'order-detail' && isEmpty(charges)) {
          getOrderDetail(orderNumber);
          setIsReCalculateConfirmDialogDisplayed(false);
        }
      })();
    }
    /* eslint-disable react-hooks/exhaustive-deps */
    // want to re-execute this hook only when order is updated successfully
    // Do not want to re-execute in any other cases.
    // So intentionally omitting some dependency here
  }, [isEditOrderStatusSuccess]);

  useEffect(() => {
    if (isReapplySuccess) {
      getOrderDetail(orderNumber);
    }
  }, [isReapplySuccess, getOrderDetail, orderNumber]);

  const updateOrderAndReapplyOperationsIfNeeded = useCallback(() => {
    const orderData = getEditOrderRequestPayload({ orderFormModels, templateId, companyTimezone, schemaHelper });
    editOrder(orderNumber, { data: orderData });
  }, [companyTimezone, editOrder, orderFormModels, orderNumber, schemaHelper, templateId]);

  const submit = (page) => {
    formsChanged && doChecks(updateOrderAndReapplyOperationsIfNeeded);
    setRedirectPage(page);
  };

  const editInvoicedOrderConfirmDialogConfigs = {
    onSave: () => {
      getOrderDetail(orderNumber);
      changeTab(ORDER_BOOKING_TAB.charge.value);
      setIsEditInvoicedOrderConfirmDialogDisplayed(false);
    },
  };

  const recalculateOrderConfirmDialogConfigs = {
    onClose: () => {
      if (redirectPage === 'previous-page') {
        gotoPreviousPage();
        setIsReCalculateConfirmDialogDisplayed(false);
      } else {
        getOrderDetail(orderNumber);
        setIsReCalculateConfirmDialogDisplayed(false);
      }
    },
    onSave: () => {
      getOrderDetail(orderNumber);
      changeTab(ORDER_BOOKING_TAB.charge.value, { shouldReCalculate: true });
      setIsReCalculateConfirmDialogDisplayed(false);
    },
  };

  return (
    <div className={classes.buttons}>
      <SaveButtonUI
        onClick={() => {
          submit('order-detail');
        }}
        isLoading={isEditOrderStatusLoading && redirectPage === 'order-detail'}
        disabled={!formsChanged || isReadOnlyOrder || isEditOrderStatusLoading}
      />
      <SaveButtonUI
        onClick={() => {
          submit('previous-page');
        }}
        isLoading={isEditOrderStatusLoading && redirectPage === 'previous-page'}
        disabled={!formsChanged || isReadOnlyOrder || isEditOrderStatusLoading}
      >
        <Trans>Save & Close</Trans>
      </SaveButtonUI>

      {isEditInvoicedOrderConfirmDialogDisplayed && (
        <EditInvoicedOrderConfirmDialog {...editInvoicedOrderConfirmDialogConfigs} />
      )}

      {isReCalculateConfirmDialogDisplayed && (
        <ReCalculateOrderConfirmDialog {...recalculateOrderConfirmDialogConfigs} />
      )}
      {getConfirmDialogs({
        onConfirmOkMissingInfo: updateOrderAndReapplyOperationsIfNeeded,
        onConfirmOkVolumeMismatch: updateOrderAndReapplyOperationsIfNeeded,
        onConfirmToChangeServiceType: updateOrderAndReapplyOperationsIfNeeded,
      })}
    </div>
  );
};

export default SaveEditedOrderBtn;
