import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import { getClassNameByRule } from '../../../utils';

const name = 'MuiTypography-Yojee-Paragraph';
const Paragraph = withStyles(
  (theme) => ({
    sizeSmall: {
      fontSize: '0.875rem',
    },
    sizeLarge: {
      fontSize: '1.25rem',
    },
  }),
  { name }
)(({ size = 'medium', classes, className, ...other }) => {
  const sizeClass = getClassNameByRule(classes, 'size', size);

  return <Typography className={clsx(className, sizeClass)} paragraph={true} {...other} />;
});

Paragraph.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default Paragraph;
