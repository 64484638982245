import PropTypes from 'prop-types';
import React from 'react';

// custom libraries
import TableCheckbox from '../../corners/TableCheckbox';
import TableSkeleton from '../../furnitures/SkeletonLoading/TableSkeleton';

const ActionsTable = ({ tableConfigs, tableLoading }) => {
  /**
   * INFO: to be added more functional components into this
   */
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{tableLoading ? <TableSkeleton /> : <TableCheckbox {...tableConfigs} />}</>
  );
};

ActionsTable.defaultProps = {
  primaryBtns: null,
  overallLoading: false,
  tableLoading: false,
};

ActionsTable.propTypes = {
  primaryBtns: PropTypes.object,
  overallLoading: PropTypes.bool,
  tableConfigs: PropTypes.object,
};

export default ActionsTable;
