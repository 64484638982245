/* eslint-disable @typescript-eslint/no-explicit-any */
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import { t } from 'i18next';
import * as _ from 'lodash-es';
import { useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Dialog, theme } from '@yojee/ui/base';
import { TRACKED_EVENT_NAME, TRACKED_FEATURE } from '@yojee/ui/event-tracking';
import useFeatureTracker from '@yojee/ui/event-tracking/hooks/useFeatureTracker';
import {
  getOrderFormModelsSelector,
  getOrderItems,
  getTemplateDetailSelector,
  getTemplates,
} from '@yojee/ui/new-order-booking/selectors';
import { OrderDuplicationSpec } from '@yojee/ui/new-order-booking/types';

import CheckboxGroup, { Option } from './components/CheckboxGroup/CheckboxGroup';

const useStyles = makeStyles((theme) => ({
  detailsContainer: {
    marginTop: theme.spacing(1),
    '& .MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled': {
      color: 'unset',
    },
  },
  itemLabelContainer: {
    position: 'relative',
  },
  itemLabel: {
    textAlign: 'left',
    fontSize: '1rem',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  lineItem: {
    marginLeft: '20px',
    fontSize: '1rem',
  },
  type: {
    fontSize: '1rem',
  },
  quantity: {
    fontSize: '1rem',
    fontWeight: 'bolder',
  },
  checkbox: { margin: '4px', padding: '0px' },
}));

type OrderDuplicateDialogProps = {
  onClose?(): void;
};

const INIT_ORDER_OPTIONS: (Option & { align?: 'left' | 'right' })[] = [
  {
    label: 'Corporate Account',
    key: 'corporate_account',
    checked: true,
    disabled: true,
  },
  {
    label: 'Corporate User',
    key: 'corporate_user',
    checked: true,
    disabled: false,
  },
  {
    label: 'External order ID',
    key: 'external_order_id',
    checked: true,
    disabled: false,
    align: 'right',
  },
  {
    label: 'Service Type',
    key: 'service_type',
    checked: true,
    disabled: false,
    align: 'right',
  },
];

//@SONAR-STOP
//https://community.sonarsource.com/t/sonarcloud-flags-properties-of-objects-in-an-array-as-duplicate-code-on-initialization/76661
const INIT_TASK_OPTIONS: (Option & { align?: 'left' | 'right' })[] = [
  {
    label: 'Dates & Times',
    key: 'date_time',
    checked: true,
    disabled: false,
  },
  {
    label: 'Address Line 1',
    key: 'address_line_1',
    checked: true,
    disabled: false,
  },
  {
    label: 'Address Line 2',
    key: 'address_line_2',
    checked: true,
    disabled: false,
  },
  {
    label: 'Postal Code',
    key: 'postal_code',
    checked: true,
    disabled: false,
  },
  {
    label: 'City',
    key: 'city',
    checked: true,
    disabled: false,
  },
  {
    label: 'State',
    key: 'state',
    checked: true,
    disabled: false,
  },
  {
    label: 'Country',
    key: 'country',
    checked: true,
    disabled: false,
  },
  {
    label: 'Contact company',
    key: 'contact_company',
    checked: true,
    disabled: false,
    align: 'right',
  },
  {
    label: 'Contact name',
    key: 'contact_name',
    checked: true,
    disabled: false,
    align: 'right',
  },
  {
    label: 'Contact phone',
    key: 'contact_phone',
    checked: true,
    disabled: false,
    align: 'right',
  },
  {
    label: 'Contact email',
    key: 'contact_email',
    checked: true,
    disabled: false,
    align: 'right',
  },
];
//@SONAR-START

const OrderDuplicateDialog = ({ onClose }: OrderDuplicateDialogProps) => {
  const classes = useStyles();
  const orderItemsSelector = useSelector(getOrderItems);
  const orderFormModels = useSelector(getOrderFormModelsSelector);
  const templateDetail = useSelector(getTemplateDetailSelector);
  const templates = useSelector(getTemplates);

  const orderItems = _.cloneDeep(orderItemsSelector);

  const [confirmMode, setConfirmMode] = useState(false);

  const [orderOptions, setOrderOptions] = useState(INIT_ORDER_OPTIONS);
  const [taskOptions, setTaskOptions] = useState(INIT_TASK_OPTIONS);
  const [isVesselSelected, setIsVesselSelected] = useState(true);
  const [isLineItemSelected, setIsLineItemSelected] = useState(true);

  const { sendEvent } = useFeatureTracker(TRACKED_FEATURE.BOOKING);

  function handleChangeOrderOptions(options: Option[]) {
    setOrderOptions(options);
  }

  const handleChangeTaskOptions = (options: Option[]) => {
    setTaskOptions(options);
  };

  const navigate = useNavigate();

  const onSaveDuplicationData = () => {
    setConfirmMode(true);
  };

  const onBack = () => {
    setConfirmMode(false);
  };

  const onConfirmDuplicationData = () => {
    const { order } = orderFormModels;

    sendEvent(TRACKED_EVENT_NAME.BOOKING_DUPLICATE_ORDER, { order_number: order.number });

    // get the latest version of the current template to enforce it on the duplicate order,
    // since the template might have been changed
    const { id: templateId } = templates.find(
      (template: any) => template.template_type_id === templateDetail.template_type_id
    );

    navigate(`/booking/new-order?tab=booking&duplicate=true&original_order_number=${order.number}`, {
      state: JSON.stringify({
        orderFormModels,
        templateId,
        duplicationSpec: {
          sender: orderOptions.find(({ key }) => key === 'corporate_account')?.checked,
          senderUser: orderOptions.find(({ key }) => key === 'corporate_user')?.checked,
          externalId: orderOptions.find(({ key }) => key === 'external_order_id')?.checked,
          serviceType: orderOptions.find(({ key }) => key === 'service_type')?.checked,
          legStep: taskOptions.some(({ checked }) => checked)
            ? {
                from_time: taskOptions.find(({ key }) => key === 'date_time')?.checked,
                to_time: taskOptions.find(({ key }) => key === 'date_time')?.checked,
                timezone: taskOptions.find(({ key }) => key === 'date_time')?.checked,
                address: taskOptions.find(({ key }) => key === 'address_line_1')?.checked,
                address2: taskOptions.find(({ key }) => key === 'address_line_2')?.checked,
                city: taskOptions.find(({ key }) => key === 'city')?.checked,
                state: taskOptions.find(({ key }) => key === 'state')?.checked,
                country: taskOptions.find(({ key }) => key === 'country')?.checked,
                postal_code: taskOptions.find(({ key }) => key === 'postal_code')?.checked,
                contact_company: taskOptions.find(({ key }) => key === 'contact_company')?.checked,
                contact_name: taskOptions.find(({ key }) => key === 'contact_name')?.checked,
                contact_phone: taskOptions.find(({ key }) => key === 'contact_phone')?.checked,
                contact_email: taskOptions.find(({ key }) => key === 'contact_email')?.checked,
              }
            : null,
          voyage: isVesselSelected,
          items: isLineItemSelected,
        } as OrderDuplicationSpec,
      }),
    });
  };

  const handleVesselChange = () => {
    setIsVesselSelected(!isVesselSelected);
  };
  const handleLineItemChange = () => {
    setIsLineItemSelected(!isLineItemSelected);
  };

  return confirmMode ? (
    <MuiThemeProvider theme={theme}>
      <Dialog
        open={true}
        dividers="paper"
        maxWidth="sm"
        title={t('Confirm')}
        primaryBtnText={t('Confirm')}
        secondaryBtnText={t('Back')}
        onClose={onBack}
        onSave={onConfirmDuplicationData}
      >
        <Grid container>
          <Grid container className={classes.detailsContainer}>
            <CheckboxGroup
              leftColumnOptions={orderOptions.filter((option) => option.align !== 'right')}
              rightColumnOptions={orderOptions.filter((option) => option.align === 'right')}
              onChange={handleChangeOrderOptions}
              title={t('Order Details')}
              groupDisabled={true}
              checkboxClassName={classes.checkbox}
            />
          </Grid>
          <Grid container className={classes.detailsContainer}>
            <CheckboxGroup
              leftColumnOptions={taskOptions.filter((option) => option.align !== 'right')}
              rightColumnOptions={taskOptions.filter((option) => option.align === 'right')}
              onChange={handleChangeTaskOptions}
              title={t('Tasks & Contact')}
              hint={t('Selection applies to all legs in the order.')}
              groupDisabled={true}
              checkboxClassName={classes.checkbox}
            />
          </Grid>

          <Grid container className={classes.detailsContainer}>
            <Grid container item xs={12}>
              <FormControlLabel
                label={t('Vessel details')}
                control={<Checkbox checked={isVesselSelected} disabled={true} className={classes.checkbox} />}
              />
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={4}>
                <FormControlLabel
                  label={t('Line items')}
                  control={<Checkbox checked={isLineItemSelected} disabled={true} className={classes.checkbox} />}
                />
              </Grid>
              {isLineItemSelected && (
                <>
                  <Grid item xs={4} className={classes.itemLabelContainer}>
                    <div className={classes.itemLabel}>
                      <Trans>Type</Trans>
                    </div>
                  </Grid>
                  <Grid item xs={4} className={classes.itemLabelContainer}>
                    <div className={classes.itemLabel}>
                      <Trans>Quantity</Trans>
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
            {isLineItemSelected &&
              orderItems.map((item, index) => {
                return (
                  <Grid container item xs={12}>
                    <Grid item xs={4}>
                      <div className={classes.lineItem}>
                        <Trans>Line</Trans> {index + 1}
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className={classes.type}> {item.payload_type} </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className={classes.quantity}> {item.quantity} </div>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </Dialog>
    </MuiThemeProvider>
  ) : (
    <MuiThemeProvider theme={theme}>
      <Dialog
        onClose={onClose}
        open={true}
        dividers="paper"
        maxWidth="sm"
        title={t('Duplicate')}
        primaryBtnText={t('Next')}
        onSave={onSaveDuplicationData}
      >
        <Grid container>
          <Grid container className={classes.detailsContainer}>
            <CheckboxGroup
              leftColumnOptions={orderOptions.filter((option) => option.align !== 'right')}
              rightColumnOptions={orderOptions.filter((option) => option.align === 'right')}
              onChange={handleChangeOrderOptions}
              title={t('Order Details')}
              checkboxClassName={classes.checkbox}
            />
          </Grid>
          <Grid container className={classes.detailsContainer}>
            <CheckboxGroup
              leftColumnOptions={taskOptions.filter((option) => option.align !== 'right')}
              rightColumnOptions={taskOptions.filter((option) => option.align === 'right')}
              onChange={handleChangeTaskOptions}
              title={t('Tasks & Contact')}
              hint={t('Selection applies to all legs in the order.')}
              checkboxClassName={classes.checkbox}
            />
          </Grid>
          <Grid container className={classes.detailsContainer}>
            <Grid item xs={12}>
              <FormControlLabel
                label={t('Vessel details')}
                control={
                  <Checkbox
                    checked={isVesselSelected}
                    onChange={handleVesselChange}
                    color="primary"
                    className={classes.checkbox}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label={t('Line items')}
                control={
                  <Checkbox
                    checked={isLineItemSelected}
                    onChange={handleLineItemChange}
                    color="primary"
                    className={classes.checkbox}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default OrderDuplicateDialog;
