// eslint-disable-next-line no-undef
class BaseMarkerCanvas extends google.maps.OverlayView {
  /**
   * This class only do one job is receive a list markers and draw it in google, map object
   *
   * @param map
   * @param google
   * @param markers [{
   *   id,
   *   div, // html element for display marker
   *   lat,
   *   lng,
   * }]
   */
  constructor({ map, google, markers }) {
    super();
    this.setMap(map);
    this.google = google;
    this.markers =
      markers.map((marker) => {
        const center = this.boundsOfCircle({ lat: marker.lat, lng: marker.lng }, 1);
        return { center, ...marker };
      }) || [];
    this.markersDisplay = [];
    this.map = map;
    this.changeHandler = this.google.maps.event.addListener(this.map, 'bounds_changed', this.handleBoundChange);
  }

  handleBoundChange = () => {
    if (!this.map) return;

    const newBounds = this.map.getBounds();
    const panes = this.getPanes();
    const currentOverlay = panes.floatPane;

    if (!currentOverlay) {
      return;
    }
    currentOverlay.innerHTML = '';

    this.markersDisplay = this.markers.filter((marker) => {
      if (isNaN(marker.lat) || isNaN(marker.lng)) {
        console.error('Marker lat or lng is not a number', marker);
        return false;
      }

      return newBounds?.contains({
        lat: Number(marker.lat),
        lng: Number(marker.lng),
      });
    });
    this.markersDisplay.forEach((marker) => {
      panes.floatPane.appendChild(marker.div);
    });
  };

  onAdd = () => {
    this.handleBoundChange();
  };

  draw = () => {
    const overlayProjection = this.getProjection();

    for (let i = 0; i < this.markersDisplay?.length; i++) {
      const currentMarker = this.markersDisplay[i];
      const point = currentMarker.center;
      const sw = overlayProjection.fromLatLngToDivPixel(point.getSouthWest());
      const ne = overlayProjection.fromLatLngToDivPixel(point.getNorthEast());
      const div = currentMarker.div;
      div.style.top = ne.y + 'px';
      div.style.left = sw.x - 11 + 'px';
    }
  };

  onRemove = () => {
    if (!this.map) {
      return;
    }

    this.map = null;

    if (this.changeHandler) {
      this.google.maps.event.removeListener(this.changeHandler);
      this.changeHandler = null;
    }
    this.markers?.forEach((m) => {
      m.div?.remove();
    });
    this.markers = null;
    this.markersDisplay = null;
  };

  boundsOfCircle = (center, radius) => {
    // eslint-disable-next-line no-undef
    const circle = new google.maps.Circle();
    circle.setCenter(center);
    circle.setRadius(radius);
    return circle.getBounds();
  };
}

export default BaseMarkerCanvas;
