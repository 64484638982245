import { t } from 'i18next';

import {
  batchUploadDefaultState,
  generateBatchUploadReducerMethods,
} from '@yojee/ui/onboarding/helpers/batchUploadHelper';

const defaultState = {
  inProgress: {
    getVehicles: false,
    getVehicle: false,
    createVehicle: false,
    deleteVehicle: false,
    updateVehicle: false,
    searchVehicleTags: false,
    getListVehicles: false,
  },
  info: {
    vehicleTypes: [],
    masterVehicleTypes: [],
    vehicles: [],
    vehicleTags: [],
    vehicle: null,
  },
  pagination: {
    current_page: 1,
    limit_value: 25,
    total_count: 0,
    total_pages: 0,
  },
  vehiclePagePagintation: {
    current_page: 1,
    limit_value: 25,
    total_count: 0,
    total_pages: 0,
  },
  fleetPage: {
    vehicleTypes: [],
    masterVehicleTypes: [],
    vehicles: [],
    vehicleTags: [],
    vehicle: null,
  },
  fleetPagePagination: {
    current_page: 1,
    limit_value: 25,
    total_count: 0,
    total_pages: 0,
  },
  batchUpload: batchUploadDefaultState,
};

export const vehicleMessagesSuccessEvents = {
  RESET_VEHICLE: undefined,
  REQUEST_CREATE_VEHICLE_SUCCESSFUL: 'Vehicle created successfully',
  REQUEST_UPDATE_VEHICLE_SUCCESSFUL: 'Vehicle updated successfully',
  REQUEST_DELETE_VEHICLE_SUCCESSFUL: 'Vehicle deleted successfully',
  BULK_REQUEST_DELETE_VEHICLE_SUCCESSFUL: 'Vehicle deleted successfully',
  REQUEST_DELETE_VEHICLE_IN_PROCESS: 'Vehicle deleted in process',
  REQUEST_DELETE_VEHICLE_TYPES_SUCCESS: 'Vehicle Type deleted successfully',
  REQUEST_CREATE_VEHICLE_TYPE_SUCCESS: 'Vehicle Type created successfully',
  REQUEST_UPDATE_VEHICLE_TYPE_SUCCESS: 'Vehicle Type updated successfully',
  VEHICLE_BATCH_UPLOAD_SUCCEEDED: t('Batch upload completed'),
};

export const vehiclesMessagesErrorEvents = {
  REQUEST_CREATE_VEHICLE_FAILED: ({ error }) => error.message,
  REQUEST_UPDATE_VEHICLE_FAILED: ({ error }) => error.message,
  BULK_REQUEST_DELETE_VEHICLE_FAIL: ({ payload }) => payload || 'Vehicle deleted fail!',
  REQUEST_DELETE_VEHICLE_TYPES_ERROR: () => `Delete Vehicle Types failed`,
  REQUEST_CREATE_VEHICLE_TYPE_ERROR: () => `Create Vehicle Type failed`,
  REQUEST_UPDATE_VEHICLE_TYPE_ERROR: () => `Update Vehicle Type failed`,
};

export const init = () => {
  return defaultState;
};

const ACTION_HANDLERS = {
  START_VEHICLE_PROGRESS: (state, sagaData) => {
    return {
      ...state,
      inProgress: { ...state.inProgress, [sagaData.action]: true },
    };
  },
  REQUEST_GET_VEHICLES_SUCCESSFUL: (state, { data: { data, pagination } }) => {
    return {
      ...state,
      info: {
        ...state.info,
        vehicles: data,
      },
      vehiclePagePagintation: pagination,
      inProgress: {
        ...state.inProgress,
        getVehicles: false,
      },
    };
  },
  REQUEST_GET_VEHICLES_FAILED: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        getVehicles: false,
      },
    };
  },
  REQUEST_GET_VEHICLE_SUCCESSFUL: (state, sagaData) => {
    return {
      ...state,
      info: {
        ...state.info,
        vehicle: sagaData.data,
      },
      inProgress: {
        ...state.inProgress,
        getVehicle: false,
      },
    };
  },
  REQUEST_GET_VEHICLE_FAILED: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        getVehicle: false,
      },
    };
  },
  RESET_VEHICLE: (state) => {
    return {
      ...state,
      info: {
        ...state.info,
        vehicle: null,
      },
    };
  },
  REQUEST_CREATE_VEHICLE_SUCCESSFUL: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        createVehicle: false,
      },
    };
  },
  REQUEST_CREATE_VEHICLE_FAILED: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        createVehicle: false,
      },
    };
  },
  REQUEST_UPDATE_VEHICLE_SUCCESSFUL: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        updateVehicle: false,
      },
    };
  },
  REQUEST_UPDATE_VEHICLE_FAILED: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        updateVehicle: false,
      },
    };
  },
  REQUEST_DELETE_VEHICLE_SUCCESSFUL: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        deleteVehicle: false,
      },
    };
  },
  BULK_REQUEST_DELETE_VEHICLE_SUCCESSFUL: (state, { payload }) => {
    return {
      ...state,
      info: {
        ...state.info,
        vehicles: state.info.vehicles.filter((v) => !payload.includes(v.id)),
      },
    };
  },
  REQUEST_DELETE_VEHICLE_FAILED: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        deleteVehicle: false,
      },
    };
  },
  BULK_REQUEST_DELETE_VEHICLE_FAIL: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        deleteVehicle: false,
      },
    };
  },
  REQUEST_SEARCH_VEHICLE_TAGS_SUCCESS: (state, sagaData) => {
    const { data } = sagaData;
    return {
      ...state,
      info: {
        ...state.info,
        vehicleTags: data,
      },
      inProgress: {
        ...state.inProgress,
        searchVehicleTags: false,
      },
    };
  },
  REQUEST_DELETE_VEHICLE_TYPES_SUCCESS: (state, data) => {
    const deletedVehicleId = data.result;
    const arrVehicleTypes = state.info && state.info.vehicleTypes;
    return {
      ...state,
      info: {
        ...state.info,
        vehicleTypes: arrVehicleTypes.filter((vehicle) => {
          return vehicle.id !== deletedVehicleId;
        }),
      },
    };
  },
  REQUEST_DELETE_VEHICLE_TYPES_ERROR: (state) => {
    return {
      ...state,
    };
  },
  REQUEST_CREATE_VEHICLE_TYPE_SUCCESS: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        createVehicle: false,
      },
    };
  },
  REQUEST_CREATE_VEHICLE_TYPE_ERROR: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        createVehicle: false,
      },
    };
  },
  REQUEST_UPDATE_VEHICLE_TYPE_SUCCESS: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        updateVehicle: false,
      },
    };
  },
  REQUEST_UPDATE_VEHICLE_TYPE_ERROR: (state) => {
    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        updateVehicle: false,
      },
    };
  },
  REQUEST_GET_VEHICLE_TYPES_SUCCESS: (state, { result }) => {
    const { fleetPage } = result;

    if (fleetPage) {
      return {
        ...state,
        inProgress: {
          ...state.inProgress,
          getListVehicles: false,
        },
        fleetPage: { ...state.fleetPage, vehicleTypes: result.data },
        fleetPagePagination: result.pagination,
      };
    }

    return {
      ...state,
      inProgress: {
        ...state.inProgress,
        getListVehicles: false,
      },
      info: { ...state.info, vehicleTypes: result.data },
      pagination: result.pagination,
    };
  },
  REQUEST_GET_VEHICLE_TYPES_ERROR: (state, data) => {
    return {
      ...state,
      errorMessage: `Get list of vehicles error: ${data.message}`,
      inProgress: {
        ...state.inProgress,
        getListVehicles: false,
      },
    };
  },
  REQUEST_GET_VEHICLE_TYPES_MASTER_SUCCESS: (state, data) => {
    return {
      ...state,
      info: { ...state.info, masterVehicleTypes: data.result },
    };
  },
  ...generateBatchUploadReducerMethods({ prefix: 'VEHICLE' }),
};

const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};

export default reducer;
