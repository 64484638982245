import './style.scss';

import { TextField, Tooltip } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { useSelector } from 'react-redux';

import { getContainerTypesSelector } from '../../../../selectors';
import { customFilterDOMProps } from '../../../helpers/SchemaHelpers';
import ListBoxComponent from '../helpers/ListBoxComponent';

function ISOTypeSelectField(props) {
  const { value, onChange, disabled, label } = props;
  const containerTypes = useSelector(getContainerTypesSelector);

  const customOnChange = (event, newValue) => {
    onChange(newValue?.code || '');
  };

  const options = containerTypes || [];

  const getOptionLabel = (option) => {
    return (option?.code || options?.find((containerType) => containerType.code === option)?.code || '')?.toString();
  };

  return (
    <div className="custom-field-iso-type-wrapper">
      <Autocomplete
        className="custom-field-iso-type-autocomplete"
        options={options}
        getOptionLabel={getOptionLabel}
        value={value || ''}
        disabled={disabled}
        onChange={customOnChange}
        renderInput={(params) => (
          <TextField
            {...params}
            {...customFilterDOMProps(props)}
            error={!!props.error}
            helperText={props.error}
            margin="dense"
            label={label}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment className="custom-field-iso-type-adornment" position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
              'data-cy': 'iso-type-input',
            }}
          />
        )}
        renderOption={(option) => (
          <Tooltip title={option?.code}>
            <Typography className="custom-field-iso-type-select-option-item" noWrap>
              {option?.code}
            </Typography>
          </Tooltip>
        )}
        ListboxComponent={ListBoxComponent}
        ListboxProps={{
          'data-cy': 'iso-type-input-menu',
        }}
      />
      <div className="custom-field-iso-type-placeholder-section" />
    </div>
  );
}

export default ISOTypeSelectField;
