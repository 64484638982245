import './style.scss';

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import useArray from '@yojee/helpers/hooks/useArray';

import { getOrderItemDetailsSelector, getOriginalOrderItemDetailsSelector } from '../../../../../selectors';
import { addUniqueIDToItemDetails } from '../../../../helpers/formHelpers';
import { BookingInfoSectionContext } from '../../Contexts';
import ItemDetailsSectionUI from './ItemDetailsSectionUI';

const initialItemDetails = [{ item_container: {}, item: {} }];

const ItemDetailsSection = () => {
  const { bookingInfoSectionIndex } = useContext(BookingInfoSectionContext);
  const itemDetailsData = useSelector((state) => getOrderItemDetailsSelector(state, bookingInfoSectionIndex));
  // Item detail from original order, not yet edited
  const originalItemDetailsData = useSelector((state) =>
    getOriginalOrderItemDetailsSelector(state, bookingInfoSectionIndex)
  );
  const [formOpened, setFormOpened] = useState(false);

  useEffect(() => {
    if (bookingInfoSectionIndex === 0 && (originalItemDetailsData?.length ?? 0) <= 5) {
      setFormOpened(true);
    }
  }, [bookingInfoSectionIndex, originalItemDetailsData?.length]);

  const itemDetailsWithUniqueID = useMemo(
    () => addUniqueIDToItemDetails(itemDetailsData?.length ? itemDetailsData : initialItemDetails),
    [itemDetailsData]
  );
  const { value: itemDetails, add, removeIndex } = useArray(itemDetailsWithUniqueID);

  const removeItem = useCallback(
    (itemIndex) => {
      removeIndex(itemIndex);
    },
    [removeIndex]
  );

  const addNew = useCallback(
    (initialData = {}) => {
      const newItemDetail = addUniqueIDToItemDetails([initialData])[0];

      add(newItemDetail);
    },
    [add]
  );

  const updateItem = (payload) => {
    itemDetails[payload.index].item = payload.modelData;
  };

  const addNewEmptyItem = useCallback(() => addNew(), [addNew]);

  return (
    <ItemDetailsSectionUI
      itemDetails={itemDetails}
      formOpened={formOpened}
      setFormOpened={setFormOpened}
      addNew={addNew}
      removeItem={removeItem}
      addNewEmptyItem={addNewEmptyItem}
      onChangeItemDetail={updateItem}
    />
  );
};

export default ItemDetailsSection;
