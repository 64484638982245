import { useEffect, useRef } from 'react';

const useInterval = (callback, delay) => {
  const savedCallback = useRef(() => {});

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    let interval;
    if (delay !== null) {
      interval = setInterval(() => savedCallback.current(), delay || 0);
    }
    return () => interval && clearInterval(interval);
  }, [delay]);
};

export default useInterval;
