import Button from '@material-ui/core/Button';
import MapIcon from '@material-ui/icons/Map';
import { makeStyles } from '@material-ui/styles';
import { t } from 'i18next';
import React, { useContext } from 'react';

import { ConfigContext, ConfigType } from '../../helpers/Contexts';

const useStyles = makeStyles({
  mapToggleBtn: {
    fontSize: '16px !important',
    lineHeight: '19px !important',
    fontWeight: 600,
    color: '#000000 !important',
    textTransform: 'none',
    fontStyle: 'italic',
  },
  outerDiv: {
    padding: '10px 0px',
  },
});

const MapToggleButton = () => {
  const classes = useStyles();
  const { isShowMap, setConfigValue } = useContext(ConfigContext);

  const toggleMap = () => {
    setConfigValue?.((prev: ConfigType) => {
      return { ...prev, isShowMap: !prev.isShowMap };
    });
  };

  return (
    <div className={classes.outerDiv}>
      <Button className={classes.mapToggleBtn} onClick={toggleMap} startIcon={<MapIcon />} data-cy="map-toggle-btn">
        {isShowMap ? t('Hide Map') : t('Open Map')}
      </Button>
    </div>
  );
};

export default React.memo(MapToggleButton);
