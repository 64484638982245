import '../OrderForm/style.scss';

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getOrderFieldTemplate, setOrderFieldTemplateSender } from '../../saga/actions';
import OrderForm from '../OrderForm/OrderForm';

const SenderDefaultOrderValues = ({ sender }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (sender) {
      dispatch(setOrderFieldTemplateSender(sender));
      dispatch(getOrderFieldTemplate(sender.id));
    }
  }, [dispatch, sender]);

  return (
    <div className="new-booking">
      <OrderForm />
    </div>
  );
};

export default SenderDefaultOrderValues;
