import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';

const DeleteIconBtn = ({ className = '', isDisabled, onClick, dataCy = '' }) => {
  return (
    <IconButton className={className} disabled={isDisabled} onClick={onClick} data-cy={dataCy}>
      <DeleteIcon />
    </IconButton>
  );
};

export default DeleteIconBtn;
