import { bindTrigger } from 'material-ui-popup-state';
import { bindPopper, PopupState } from 'material-ui-popup-state/core';

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;
type TriggerProps = ReturnType<typeof bindTrigger>;
type PopperProps = ReturnType<typeof bindPopper>;

export const bindInputTriggers = (popupState: PopupState) => {
  const triggerProps: Optional<TriggerProps, 'onClick'> = bindTrigger(popupState);
  delete triggerProps.onClick;

  return {
    ...(triggerProps as Omit<TriggerProps, 'onClick'>),
    onFocus: popupState.open,
    autoComplete: 'off',
  };
};

export const bindInputPopper = (popupState: PopupState) => {
  const popperProps: Optional<PopperProps, 'onMouseLeave'> = bindPopper(popupState);
  delete popperProps.onMouseLeave;

  return {
    ...(bindPopper(popupState) as Omit<PopperProps, 'onMouseLeave'>),
    placement: 'bottom-start' as const,
    modifiers: {
      flip: {
        enabled: false,
      },
      preventOverflow: {
        enabled: false,
      },
    },
  };
};
