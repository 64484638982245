import { MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useField } from 'uniforms';

const useStyles = makeStyles(() => ({
  select: {
    borderTopLeftRadius: 'unset!important',
    borderBottomLeftRadius: 'unset!important',
    borderLeft: 'unset!important',
    '& .MuiSelect-root': {
      paddingRight: '24px!important',
    },
    '& .manage-MuiSelect-root': {
      paddingRight: '24px!important',
    },
  },
}));

function UnitSelectField(props) {
  const classes = useStyles();
  const { name, units, disabled } = props;
  const [{ value, onChange }] = useField(name, {}, { absoluteName: true });
  const [{ onChange: onWidthUnitChange }] = useField('width_unit', {}, { absoluteName: true });
  const [{ onChange: onLengthUnitChange }] = useField('length_unit', {}, { absoluteName: true });

  useEffect(() => {
    if (!value) {
      const defaultValue = units?.[0]?.value;
      onChange(defaultValue);

      if (name === 'height_unit') {
        onWidthUnitChange(defaultValue);
        onLengthUnitChange(defaultValue);
      }
    }
  }, [onChange, units, value]);

  const onChangeValue = (e) => {
    const newValue = e.target.value;
    onChange(newValue);
    if (name === 'height_unit') {
      onWidthUnitChange(newValue);
      onLengthUnitChange(newValue);
    }
  };

  return (
    <Select
      disabled={disabled || window.IS_BOOKING_APP}
      className={classes.select}
      value={value || ''}
      onChange={onChangeValue}
    >
      {units?.map((unit) => (
        <MenuItem key={unit.value} value={unit.value || ''}>
          {unit.label}
        </MenuItem>
      ))}
    </Select>
  );
}

export default UnitSelectField;
