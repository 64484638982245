import Button from '@material-ui/core/Button';
import { omit } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { sendEvent } from '@yojee/ui/event-tracking';
import { getFormRefsFromPathSelector } from '@yojee/ui/new-order-booking/selectors';

import { addSuccessMessage } from '../../../../../../../../saga/actions';
import { clearForm, updateField } from '../../../../../../../helpers/hooks';
import { ADD_LEG_ACTION_TYPE, TASK } from '../../../../../constants';

const SplitCurrentLeg = ({ formKeyPath, addNewLeg, closePopover }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const stepFormRefs = useSelector((state) => getFormRefsFromPathSelector(state, formKeyPath, []));

  const splitLeg = () => {
    sendEvent('PLANNING', 'SPLIT_LEG');

    const [, dropoffRef] = stepFormRefs;
    const originalDropoffData = dropoffRef.getModel();

    // Get current DROPOFF task data
    const copyDropOff = omit(originalDropoffData, [
      'order_step_group_id',
      'order_step_id',
      'order_item_id',
      'step_property',
      'id',
      'display_time_conflict_warning',
    ]);

    const { step_group, step_sequence, is_empty } = originalDropoffData;

    const newPickup = {
      type: TASK.PICKUP,
      isNewStep: true,
      timezone: copyDropOff.timezone,
      step_group: step_group + 1,
      step_sequence: step_sequence + 1,
      is_empty,
    };

    const newDropoff = {
      ...copyDropOff,
      id: originalDropoffData.id,
      order_step_id: originalDropoffData.order_step_id,
      old_order_step_group_id: originalDropoffData.old_order_step_group_id || originalDropoffData.order_step_group_id,
      type: TASK.DROPOFF,
      isNewStep: true,
      step_group: step_group + 1,
      step_sequence: step_sequence + 2,
      is_empty,
    };

    const newLeg = [newPickup, newDropoff];

    // Clear current DROPOFF task
    clearForm(dropoffRef);

    updateField(dropoffRef, {
      id: undefined,
      order_step_id: undefined,
      is_empty,
    });

    // Get current leg index
    const [currentLegIndex] = formKeyPath.split('.').reverse();

    addNewLeg(newLeg, ADD_LEG_ACTION_TYPE.SPLIT, { start: parseFloat(currentLegIndex) + 1 });

    dispatch(addSuccessMessage(t('Splitted leg')));
    closePopover();
  };

  return (
    <div>
      <Button onClick={splitLeg} data-cy="split-this-leg-button">
        {t('Split this leg')}
      </Button>
    </div>
  );
};

export default SplitCurrentLeg;
