import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@yojee/ui/base';
import { getFormRef } from '@yojee/ui/new-order-booking/components/helpers/formHelpers';
import { getFormRefsSelector } from '@yojee/ui/new-order-booking/selectors';

import { addSuccessMessage, clearDateTimeInputStart } from '../../../../../../../../../saga/actions';
import { clearForm, useIsReadOnlyLegSection } from '../../../../../../../../helpers/hooks';

const useStyles = makeStyles((theme) => ({
  btn: { color: theme.palette.error.light },
}));

const ClearStep = ({ formKeyPath, readOnly }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const classes = useStyles();
  const isReadOnlyLegSection = useIsReadOnlyLegSection();
  const formRefs = useSelector(getFormRefsSelector);

  const onClear = () => {
    const formRef = getFormRef(formRefs, formKeyPath);
    dispatch(clearDateTimeInputStart(formKeyPath));
    clearForm(formRef);
    dispatch(addSuccessMessage(t('Step information cleared')));
  };

  return (
    <Button
      size="small"
      disableTouchRipple
      className={classes.btn}
      onClick={onClear}
      disabled={isReadOnlyLegSection || readOnly}
    >
      {t('Clear')}
    </Button>
  );
};

export default ClearStep;
