import './style.scss';

import React, { useCallback, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SelectField } from 'uniforms-material';

import { getTaskTypesSelector } from '../../../../selectors';
import { useAddValueAsOptionIfValueMissingInOptionsForSelect } from '../../../helpers/formHelpers';
import { customFilterDOMProps } from '../../../helpers/SchemaHelpers';

const textFieldProps = {
  helperText: '',
};

function TaskTypeSelectField({ InputLabelProps, ...props }) {
  const taskTypes = useSelector(getTaskTypesSelector);

  const allowedValues = useMemo(() => taskTypes?.map((v) => v.slug) || [], [taskTypes]);

  const transform = useCallback(
    (taskTypeSlug) => {
      return taskTypes?.find((v) => v.slug === taskTypeSlug)?.display_name;
    },
    [taskTypes]
  );

  const { newAllowValues, newTransform } = useAddValueAsOptionIfValueMissingInOptionsForSelect({
    value: props.value,
    label: props.value,
    allowedValues,
    transform,
  });

  if (props.readOnly) {
    return <p className="font-16 font-semibold">{transform(props.value)}</p>;
  }

  return (
    <SelectField
      {...customFilterDOMProps(props)}
      transform={newTransform}
      allowedValues={newAllowValues}
      name=""
      value={props.value || ''}
      placeholder={
        <Trans>
          <em className="font-medium">Please choose</em>
        </Trans>
      }
      textFieldProps={textFieldProps}
      data-cy="step-task-type-select"
    />
  );
}

export default TaskTypeSelectField;
