import { useCompanyDefaultPhoneCountry } from '@yojee/helpers/common-reducers';
import { PhoneNumber } from '@yojee/ui/base';

import { FormFieldComponent } from '../types';

export type PhoneInputProps = React.ComponentProps<typeof PhoneNumber> & { defaultCountry?: string };

export const PhoneInput: FormFieldComponent<'phone'> = ({
  fieldName,
  formField: { label, disabled, fieldProps },
  value,
  setValue,
  required,
  errorMessage,
}) => {
  const companyCountry = useCompanyDefaultPhoneCountry();
  const defaultCountry = fieldProps.defaultCountry || companyCountry;

  return (
    <PhoneNumber
      {...fieldProps}
      defaultCountry={defaultCountry}
      dataCy={fieldName}
      label={label}
      required={required}
      disabled={disabled}
      value={value}
      error={!!errorMessage}
      helperText={errorMessage}
      onChange={(phone: string) => {
        setValue(phone);
      }}
    />
  );
};
