import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { MAX_INPUT_LENGTH } from '@yojee/helpers/constants';
import { isValidEmail, phoneValidation } from '@yojee/helpers/validators';
import PhoneNumber from '@yojee/ui/base/corners/PhoneNumber';
import Input from '@yojee/ui/base/furnitures/Input';
import TooltipBackground from '@yojee/ui/base/furnitures/TooltipBackground';

export const SENDER_FORM = {
  name: {
    label: <Trans>Name</Trans>,
    key: 'name',
    value: '',
    type: 'text',
    options: [],
  },
  phone: {
    label: <Trans>Phone</Trans>,
    key: 'phone',
    value: '',
    type: 'text',
  },
  email: {
    label: <Trans>Email</Trans>,
    key: 'email',
    value: '',
    type: 'text',
  },
  address: {
    label: <Trans>Address</Trans>,
    key: 'address',
    value: '',
    type: 'text',
  },
  external_id: {
    label: <Trans>External ID</Trans>,
    key: 'external_id',
    value: '',
    type: 'text',
  },
};

export const BILLING_FORM = {
  name: {
    label: <Trans>Billing Party Name</Trans>,
    key: 'name',
    value: '',
    type: 'text',
    options: [],
  },
  contact_name: {
    label: <Trans>Contact Name</Trans>,
    key: 'contact_name',
    value: '',
    type: 'text',
  },
  phone: {
    label: <Trans>Contact Phone</Trans>,
    key: 'phone',
    value: '',
    type: 'text',
  },
  email: {
    label: <Trans>Email</Trans>,
    key: 'email',
    value: '',
    type: 'text',
  },
  address: {
    label: <Trans>Address</Trans>,
    key: 'address',
    value: '',
    type: 'text',
  },
};

export const CONST_VARIABLE = {
  NAME: 'name',
  PHONE: 'phone',
  EMAIL: 'email',
  ADDRESS: 'address',
  EXTERNAL_ID: 'external_id',
  CONTACT_NAME: 'contact_name',
};

const useStyles = makeStyles(() => ({
  arrow: {
    marginLeft: '50%',
  },
}));

const useForm = (type = 'sender') => {
  const [form, setForm] = useState(type === 'sender' ? SENDER_FORM : BILLING_FORM);

  const [error, setError] = useState({
    [CONST_VARIABLE.NAME]: '',
    [CONST_VARIABLE.CONTACT_NAME]: '',
    [CONST_VARIABLE.PHONE]: '',
    [CONST_VARIABLE.EMAIL]: '',
    [CONST_VARIABLE.ADDRESS]: '',
    [CONST_VARIABLE.EXTERNAL_ID]: '',
  });

  const setErrorHelper = (key, value) => {
    setError((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleFieldChange = (value, key) => {
    setErrorHelper(key, '');
    setForm((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        value,
      },
    }));
  };

  const isValid = () => {
    let valid = true;
    const checkRequired = (key) => {
      if (form[key] && !form[key].value) {
        setErrorHelper(key, 'Field is required');
        valid = false;
      }
    };
    checkRequired(CONST_VARIABLE.NAME);
    checkRequired(CONST_VARIABLE.CONTACT_NAME);
    checkRequired(CONST_VARIABLE.PHONE);
    checkRequired(CONST_VARIABLE.EMAIL);
    checkRequired(CONST_VARIABLE.ADDRESS);
    if (form[CONST_VARIABLE.EMAIL].value && !isValidEmail(form[CONST_VARIABLE.EMAIL].value)) {
      setErrorHelper(CONST_VARIABLE.EMAIL, 'Email is invalid');
      valid = false;
    }
    if (form[CONST_VARIABLE.PHONE].value && !phoneValidation(form[CONST_VARIABLE.PHONE].value)) {
      setErrorHelper(CONST_VARIABLE.PHONE, 'Phone is invalid');
      valid = false;
    }
    return valid;
  };

  return { error, setError, form, setForm, handleFieldChange, isValid, setErrorHelper };
};

export const FormContent = ({ type, form, error, samePhone, sameEmail, handleFieldChange }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const keyForm = type === 'sender' ? CONST_VARIABLE.EMAIL : CONST_VARIABLE.CONTACT_NAME;

  return (
    <Grid container spacing={1}>
      {Object.values(form).map((field, index) => {
        if (field.key === 'phone')
          return (
            <>
              <Grid item xs={6} key={field.key + '-' + index}>
                <PhoneNumber
                  label={field.label}
                  required
                  error={!!error[field.key]}
                  helperText={error[field.key]}
                  onChange={(phone) => handleFieldChange(phone, field.key)}
                  value={(form && form[field.key] && form[field.key].value) || ''}
                />
              </Grid>
              {type === 'billing' && field.key === CONST_VARIABLE.PHONE && samePhone && (
                <Grid item xs={12}>
                  <TooltipBackground
                    customClassArrow={classes.arrow}
                    title={t(
                      'This phone number has been used. Please make sure that you want to use the same phone number'
                    )}
                  />
                </Grid>
              )}
            </>
          );
        else
          return (
            <>
              <Grid item xs={field.key === keyForm ? 6 : 12} key={field.key + '-' + index}>
                <Input
                  fullWidth
                  label={field.label}
                  required={field.key !== CONST_VARIABLE.EXTERNAL_ID}
                  error={!!error[field.key]}
                  helperText={error[field.key]}
                  onChange={(event) => handleFieldChange(event.target.value, field.key)}
                  value={(form && form[field.key] && form[field.key].value) || ''}
                  inputProps={{ maxLength: MAX_INPUT_LENGTH }}
                />
              </Grid>
              {type === 'billing' && field.key === CONST_VARIABLE.EMAIL && sameEmail && (
                <Grid item xs={12}>
                  <TooltipBackground
                    title={t('This email has been used. Please make sure that you want to use the same email')}
                  />
                </Grid>
              )}
            </>
          );
      })}
    </Grid>
  );
};
export default useForm;
