import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 'bold',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}));

const Hyperlink = ({ children, className = '', ...restProps }) => {
  const classes = useStyles();

  return (
    <Link {...restProps} className={clsx(className, classes.link)}>
      {children}
    </Link>
  );
};

export default Hyperlink;
