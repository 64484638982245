import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { NoPermission } from '@yojee/ui/base';

import LoadingOverlay from './common/LoadingOverlay/LoadingOverlay';
import { ReadOnlyContext } from './ReadOnlyHelper';

export const ROLE_PERMISSION = {
  DISPATCHER_ORDERS_READ: 'dispatcher.orders.read',
  DISPATCHER_ROUTES_READ: 'dispatcher.routes.read',
  DISPATCHER_DRIVERS_READ: 'dispatcher.drivers.read',
  DISPATCHER_PARTNERS_READ: 'dispatcher.partners.read',
  DISPATCHER_REPORTS_READ: 'dispatcher.reports.read',
  DISPATCHER_ITEMS_READ: 'dispatcher.items.read',
  DISPATCHER_DASHBOARD_READ: 'dispatcher.dashboard.read',
  DISPATCHER_EXPLORE_READ: 'dispatcher.explore.read',
  DISPATCHER_NETWORK_READ: 'dispatcher.network.read',
  DISPATCHER_OPERATIONS_READ: 'dispatcher.operations.read',
  DISPATCHER_INVOICES_READ: 'dispatcher.invoices.read',
  DISPATCHER_MANAGE_READ: 'dispatcher.manage.read',
  DISPATCHER_MANAGE_ACCOUNT_READ: 'dispatcher.manage.account.read',
  DISPATCHER_MANAGE_TEAM_READ: 'dispatcher.manage.team.read',
  DISPATCHER_MANAGE_FLEET_READ: 'dispatcher.manage.fleet.read',
  DISPATCHER_MANAGE_DRIVERS_READ: 'dispatcher.manage.drivers.read',
  DISPATCHER_MANAGE_ADDRESSES_READ: 'dispatcher.manage.addresses.read',
  DISPATCHER_MANAGE_CUSTOMERS_READ: 'dispatcher.manage.customers.read',
  DISPATCHER_MANAGE_PARTNERS_READ: 'dispatcher.manage.partners.read',
  DISPATCHER_MANAGE_ORDERS_READ: 'dispatcher.manage.orders.read',
  DISPATCHER_MANAGE_BOOKING_READ: 'dispatcher.manage.booking.read',
  DISPATCHER_MANAGE_PLANNING_READ: 'dispatcher.manage.planning.read',
  DISPATCHER_MANAGE_DOCUMENTATION_READ: 'dispatcher.manage.documentation.read',
  DISPATCHER_MANAGE_WORKFLOW_READ: 'dispatcher.manage.workflow.read',
  DISPATCHER_MANAGE_FINANCE_READ: 'dispatcher.manage.finance.read',
  DISPATCHER_ORDERS_WRITE: 'dispatcher.orders.write',
  DISPATCHER_ROUTES_WRITE: 'dispatcher.routes.write',
  DISPATCHER_DRIVERS_WRITE: 'dispatcher.drivers.write',
  DISPATCHER_PARTNERS_WRITE: 'dispatcher.partners.write',
  DISPATCHER_REPORTS_WRITE: 'dispatcher.reports.write',
  DISPATCHER_ITEMS_WRITE: 'dispatcher.items.write',
  DISPATCHER_DASHBOARD_WRITE: 'dispatcher.dashboard.write',
  DISPATCHER_EXPLORE_WRITE: 'dispatcher.explore.write',
  DISPATCHER_NETWORK_WRITE: 'dispatcher.network.write',
  DISPATCHER_OPERATIONS_WRITE: 'dispatcher.operations.write',
  DISPATCHER_INVOICES_WRITE: 'dispatcher.invoices.write',
  DISPATCHER_MANAGE_WRITE: 'dispatcher.manage.write',
  DISPATCHER_MANAGE_ACCOUNT_WRITE: 'dispatcher.manage.account.write',
  DISPATCHER_MANAGE_TEAM_WRITE: 'dispatcher.manage.team.write',
  DISPATCHER_MANAGE_FLEET_WRITE: 'dispatcher.manage.fleet.write',
  DISPATCHER_MANAGE_DRIVERS_WRITE: 'dispatcher.manage.drivers.write',
  DISPATCHER_MANAGE_ADDRESSES_WRITE: 'dispatcher.manage.addresses.write',
  DISPATCHER_MANAGE_CUSTOMERS_WRITE: 'dispatcher.manage.customers.write',
  DISPATCHER_MANAGE_PARTNERS_WRITE: 'dispatcher.manage.partners.write',
  DISPATCHER_MANAGE_ORDERS_WRITE: 'dispatcher.manage.orders.write',
  DISPATCHER_MANAGE_BOOKING_WRITE: 'dispatcher.manage.booking.write',
  DISPATCHER_MANAGE_PLANNING_WRITE: 'dispatcher.manage.planning.write',
  DISPATCHER_MANAGE_DOCUMENTATION_WRITE: 'dispatcher.manage.documentation.write',
  DISPATCHER_MANAGE_WORKFLOW_WRITE: 'dispatcher.manage.workflow.write',
  DISPATCHER_MANAGE_FINANCE_WRITE: 'dispatcher.manage.finance.write',
};

const roleKeyMapPermission = {
  explore: [ROLE_PERMISSION.DISPATCHER_EXPLORE_READ, ROLE_PERMISSION.DISPATCHER_EXPLORE_WRITE],
  dashboard: [ROLE_PERMISSION.DISPATCHER_DASHBOARD_READ, ROLE_PERMISSION.DISPATCHER_DASHBOARD_WRITE],
  reports: [ROLE_PERMISSION.DISPATCHER_REPORTS_READ, ROLE_PERMISSION.DISPATCHER_REPORTS_WRITE],
  items: [ROLE_PERMISSION.DISPATCHER_ITEMS_READ, ROLE_PERMISSION.DISPATCHER_ITEMS_WRITE],
  orders: [ROLE_PERMISSION.DISPATCHER_ORDERS_READ, ROLE_PERMISSION.DISPATCHER_ORDERS_WRITE],
  network: [ROLE_PERMISSION.DISPATCHER_NETWORK_READ, ROLE_PERMISSION.DISPATCHER_NETWORK_WRITE],
  partners: [ROLE_PERMISSION.DISPATCHER_PARTNERS_READ, ROLE_PERMISSION.DISPATCHER_PARTNERS_WRITE],
  invoices: [ROLE_PERMISSION.DISPATCHER_INVOICES_READ, ROLE_PERMISSION.DISPATCHER_INVOICES_WRITE],
  manage: [ROLE_PERMISSION.DISPATCHER_MANAGE_READ, ROLE_PERMISSION.DISPATCHER_MANAGE_WRITE],
};

export function grantPermission(roleKey, userPermissions = {}) {
  let accessPermission = undefined;
  const rolePermissions = roleKeyMapPermission[roleKey];
  if (!rolePermissions) return undefined;

  for (const permission of rolePermissions) {
    if (userPermissions[permission]) {
      if (permission.includes('.write')) {
        return 'write';
      } else if (permission.includes('.read')) {
        accessPermission = 'read';
      }
    }
  }

  return accessPermission;
}

export const useIsReadOnlyRolePermission = (roleKey) => {
  const { permissions } = useSelector((state) => state.auth?.dispatcher_info?.data ?? {});

  return useMemo(() => {
    const permission = grantPermission(roleKey, permissions);
    return permission !== 'write';
  }, [permissions]);
};

export const useGetUserPermission = (roleKey) => {
  const { permissions } = useSelector((state) => state.auth?.dispatcher_info?.data ?? {});

  return useMemo(() => {
    return grantPermission(roleKey, permissions);
  }, [permissions]);
};

const PERMISSION_WITH_URL = [
  {
    name: 'dashboard',
    url: '/dashboard',
  },
  {
    name: 'items',
    url: '/explore-listview',
  },
  {
    name: 'orders',
    url: '/orders',
  },
  {
    name: 'partners',
    url: '/partners',
  },
  {
    name: 'reports',
    url: '/reports',
  },
  {
    name: 'network',
    url: '/network',
  },
  {
    name: 'invoices',
    url: '/invoices',
  },
  {
    name: 'manage',
    url: '/manage',
  },
];

function Authorization({ component: Component, role, ...rest }) {
  const permissions = useSelector(
    (state) =>
      state.login?.accountInfo.dispatcher_info.permissions ?? state.auth?.dispatcher_info.data?.permissions ?? {}
  );
  const isLoadingDispatcherInfo = useSelector(
    (state) => state.login?.loading.getUserInfo ?? state.auth?.loading.getDispatcherInfo
  );

  const permission = grantPermission(role, permissions);
  const readOnly = permission === 'read';

  if (!permission && role === 'explore') {
    for (const { name, url } of PERMISSION_WITH_URL) {
      const specificPermission = grantPermission(name, permissions);
      if (['read', 'write'].includes(specificPermission)) {
        return <Navigate to={url} />;
      }
    }
  }

  if (!permission) {
    return isLoadingDispatcherInfo ? <LoadingOverlay active data-cy="loading-overlay" /> : <NoPermission />;
  }

  return (
    <ReadOnlyContext.Provider value={readOnly}>
      <Component readOnly={readOnly} {...rest} />
    </ReadOnlyContext.Provider>
  );
}

export default Authorization;
