import _ from 'lodash-es';
import moment from 'moment';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { DateUtils } from 'react-day-picker';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { billingPartyApi } from '@yojee/api/v4/billingPartyApi';
import { FORMAT_DATE_FOR_MOMENT, ORDER_BOOKING_TAB } from '@yojee/helpers/constants';
import useService from '@yojee/helpers/hooks/useService';
import { FEATURES_MAP } from '@yojee/helpers/SettingResolver';
import useIsFeatureEnabled from '@yojee/ui/feature-management/hooks/useIsFeatureEnabled';
import { isReadOnlyOrderSection } from '@yojee/ui/new-order-booking/components/helpers/utils';
import { getSelectedServiceTypeSelector, getServiceTypesSelector } from '@yojee/ui/new-order-booking/selectors';
import { ReadOnlyContext } from '@yojee/ui/ReadOnlyHelper';

import { registerForm, syncFormModel } from '../../saga/actions';
import {
  getFormRefsSelector,
  getNonOperationalZonesSelector,
  getOrderFieldTemplateSenderSelector,
  getOrderFormModelsSelector,
  getOrderInfoSelector,
  getTemplateTaskSequence,
  isOrderAlreadyPaidSelector,
  isOrderFromTransferSelector,
  isTransferredOrderAcceptedSelector,
} from '../../selectors';
import { ConfigContext, SchemaHelperContext } from './Contexts';
import CustomUniformBridge from './CustomUniformBridge';
import { getFormRef } from './formHelpers';
import { schemaValidator } from './schemaValidator/index';

export function useClearForm(formKeyPath) {
  const formRefs = useSelector(getFormRefsSelector);
  const formRef = getFormRef(formRefs, formKeyPath);

  return useCallback(() => clearForm(formRef), [formRef]);
}

export function useClearForms(formKeyPath) {
  const allFormRefs = useSelector(getFormRefsSelector);
  const formRefs = _.get(allFormRefs, formKeyPath, []);

  return useCallback(() => {
    if (formRefs?.length) {
      formRefs.forEach((formRef) => {
        clearForm(formRef);
      });
    }
  }, [formRefs]);
}

export function clearForm(formRef) {
  if (formRef) {
    const defaultModel = formRef.props.schema.getDefaultModel();
    defaultModel['isNewStep'] = !!formRef.getModel()['isNewStep'];

    const schema = formRef.props.schema.schema;

    Object.keys(formRef.state.model).forEach((fieldName) => {
      const defaultFieldValue = defaultModel[fieldName];

      if (fieldName === 'type') {
        const isStepForm = schema[fieldName]?.category === 'task';
        if (isStepForm && !defaultFieldValue) return;
      }

      if (
        [
          'id',
          'old_order_step_group_id',
          'order_step_group_id',
          'order_step_id',
          'step_group',
          'step_sequence',
          'is_empty',
          'uniqueID',
        ].includes(fieldName)
      )
        return;

      formRef.change(fieldName, defaultFieldValue);
    });
  }
}

export function updateField(formRef, newValues) {
  if (formRef) {
    Object.entries(newValues).forEach(([fieldName, fieldValue]) => {
      formRef.change(fieldName, fieldValue);
    });
  }
}

export function useRegisterForm(formKeyPath) {
  const dispatch = useDispatch();
  const isCreateOrder = useIsCreateOrder();
  const [formRef, setFormRef] = useState(null);
  const [searchParams] = useSearchParams();
  const isDuplicate = searchParams.get('duplicate');

  useEffect(() => {
    if (formRef) {
      dispatch(registerForm(formKeyPath, formRef));
    }

    return () => {
      dispatch(registerForm(formKeyPath, undefined));
      // In create order, if we change template, it also change structure of forms (can remove leg, step)
      // so we need sync to form model also.
      // not do in edit page, because in edit page we have virtualization list of item (scroll up/down can make unregister form)
      // and in edit page we don't change template
      if (isCreateOrder && !isDuplicate) {
        dispatch(syncFormModel(formKeyPath, undefined));
      }
    };
  }, [dispatch, formKeyPath, formRef, isCreateOrder, isDuplicate]);

  return { formRef, setFormRef };
}

export function useSchema(schema, initialValues, extraOptions) {
  const templateSettings = useContext(SchemaHelperContext).templateSettings;
  const orderFormModels = useSelector(getOrderFormModelsSelector);
  const nonOperationalZones = useSelector(getNonOperationalZonesSelector);

  const validator = useMemo(() => {
    const options = {
      ...(extraOptions || {}),
      ...(templateSettings ?? {}),
      orderFormModels,
      nonOperationalZones,
    };

    return schemaValidator(schema, options);
  }, [extraOptions, nonOperationalZones, orderFormModels, schema, templateSettings]);

  return useMemo(() => {
    const options = {
      initialValues,
      ...(templateSettings ?? {}),
    };

    return new CustomUniformBridge(schema, validator, options);
  }, [initialValues, templateSettings, schema, validator]);
}

export function useTab() {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(
    new URLSearchParams(location.search).get('tab') || ORDER_BOOKING_TAB.booking.value
  );
  const [tabData, setTabData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const currentTabInURl = new URLSearchParams(location.search).get('tab');
    if (currentTabInURl) setActiveTab(currentTabInURl);
  }, [location.search]);

  useEffect(() => {
    const currentUrlSearchParams = new URLSearchParams(location.search);

    if (activeTab && activeTab !== currentUrlSearchParams.get('tab')) {
      currentUrlSearchParams.set('tab', activeTab);
      navigate({
        pathname: `${location.pathname}`,
        search: `?${currentUrlSearchParams.toString()}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const changeTab = useCallback(
    (tab, newTabData) => {
      setActiveTab(tab);
      setTabData(newTabData);
    },
    [setActiveTab, setTabData]
  );

  return useMemo(() => ({ activeTab, tabData, changeTab }), [activeTab, tabData, changeTab]);
}

export function useIsInSenderDefaultValuesPage() {
  const sender = useSelector(getOrderFieldTemplateSenderSelector);

  return !!sender;
}

export function useIsEditOrder() {
  const orderNumber = useGetOrderNumber();
  return !!orderNumber;
}

export function useIsCreateOrder() {
  const orderNumber = useGetOrderNumber();
  return !orderNumber;
}

export function useGetOrderNumber() {
  const { orderNumber } = useParams();

  return orderNumber;
}

export function useIsAllowDispatcherAllMoreLegs() {
  const { allow_dispatcher_add_more_legs = false } = useContext(SchemaHelperContext).templateSettings;
  const isReadOnlyBookingInfoSection = useIsReadOnlyLegSection();

  return window.IS_EXPLORE_APP && allow_dispatcher_add_more_legs && !isReadOnlyBookingInfoSection;
}

export function useIsOrderAlreadyPaid() {
  return useSelector(isOrderAlreadyPaidSelector);
}

export function useIsOrderExistsAtLeastOneProformaInvoice(charges) {
  return charges.some((charge) => charge.invoice_number);
}

export function useIsUsingProformaInvoiceFeature() {
  return useIsFeatureEnabled(FEATURES_MAP.USE_PROFORMA_INVOICE);
}

export function useIsTransferredOrderAccepted() {
  return useSelector(isTransferredOrderAcceptedSelector);
}

export function useIsOrderFromTransfer() {
  return useSelector(isOrderFromTransferSelector);
}

export function useIsProformaInvoiceFeatureHidden() {
  const isOrderFromTransfer = useIsOrderFromTransfer();
  const isTransferredOrderAccepted = useIsTransferredOrderAccepted();
  const isUsingProformaInvoiceFeature = useIsUsingProformaInvoiceFeature();
  const isOrderAlreadyPaid = useIsOrderAlreadyPaid();

  if (isOrderFromTransfer && !isTransferredOrderAccepted) {
    return true;
  }

  return !isUsingProformaInvoiceFeature || isOrderAlreadyPaid;
}

export function useShouldCheckBillingPartyMissingInfor() {
  const isProformaInvoiceFeatureHidden = useIsProformaInvoiceFeatureHidden();
  return !isProformaInvoiceFeatureHidden;
}

export function useIsReadOnlyOrder() {
  const orderInfoData = useSelector(getOrderInfoSelector);
  const isOrderAlreadyPaid = useIsOrderAlreadyPaid();
  const isEditOrder = useIsEditOrder();
  const { isForceOrderFormReadOnly } = useContext(ConfigContext);
  const readOnly = useContext(ReadOnlyContext);

  return (
    isForceOrderFormReadOnly || (isEditOrder && orderInfoData?.editable === false) || isOrderAlreadyPaid || readOnly
  );
}

export function useIsReadOnlyOrderSection() {
  const orderInfoData = useSelector(getOrderInfoSelector);
  const isEditOrder = useIsEditOrder();
  const { isForceOrderFormReadOnly } = useContext(ConfigContext);
  const readOnly = useContext(ReadOnlyContext);

  return isReadOnlyOrderSection({ isForceOrderFormReadOnly, globalReadOnly: readOnly, isEditOrder, orderInfoData });
}

export function useIsReadOnlyLegSection() {
  return useIsReadOnlyOrderSection();
}

export function useIsOrderCompleted() {
  const orderInfoData = useSelector(getOrderInfoSelector);

  return orderInfoData?.status === 'completed';
}

export function useIsOrderCreatedStatus() {
  const orderInfoData = useSelector(getOrderInfoSelector);

  return orderInfoData?.status === 'created';
}

export function useIsOrderCompletedOrCancelledStatus() {
  const orderInfoData = useSelector(getOrderInfoSelector);

  return ['completed', 'cancelled'].includes(orderInfoData?.status);
}

export function useIsInvoiceGenerated(charges) {
  return useMemo(() => {
    const validCharges = charges.filter(
      (charge) => !charge.deleted && !charge.missingInfo && !!charge.billing_party_id
    );

    return validCharges.length > 0 && validCharges.every((charge) => charge.invoice_number);
  }, [charges]);
}

export function useIsAutoRateButtonDisplayed(isChargesTableLoading, charges = []) {
  const isOrderAlreadyPaid = useIsOrderAlreadyPaid();
  const isInvoiceGenerated = useIsInvoiceGenerated(charges);

  if (isChargesTableLoading) return false;

  return !isOrderAlreadyPaid && !isInvoiceGenerated;
}

export function useIsAllowToUpdateCharge() {
  const { allowToEditChargeForCompletedOrder } = useContext(ConfigContext);
  const isOrderCompleted = useIsOrderCompleted();
  const orderInfoData = useSelector(getOrderInfoSelector);

  return (
    !['created', 'completed'].includes(orderInfoData?.status) ||
    (isOrderCompleted && allowToEditChargeForCompletedOrder)
  );
}

export function useIsReadOnlyItem() {
  return useIsReadOnlyOrderSection();
}

export function useTimeSlots() {
  const serviceType = useSelector(getSelectedServiceTypeSelector);
  const company = useSelector((state) => state.company?.companyInfo);
  const serviceTypeData = useSelector(getServiceTypesSelector);
  const nonOperatingDates = serviceTypeData?.data?.non_operating_dates;
  const templateTaskSequence = useSelector(getTemplateTaskSequence);

  const serviceRequiredTimeslot = useMemo(() => {
    return !!templateTaskSequence?.length && _.isEqual(_.sortBy(templateTaskSequence), _.sortBy(['pickup', 'dropoff']));
  }, [serviceType, templateTaskSequence]);

  const isDayDisabledForPickup = useCallback(
    (momentObj = null) => {
      const _serviceTypeObject = serviceType;

      const _day = momentObj ? momentObj : moment();
      //  if today's date falls in a non-operating date or non-operating day of the week
      //  disable the date on calendar
      if (_serviceTypeObject && _serviceTypeObject.non_operating_days) {
        const _dayOfWeek = _day.clone().day();
        if (_serviceTypeObject.non_operating_days.findIndex((nonOperatingDay) => _dayOfWeek === nonOperatingDay) >= 0) {
          return true;
        }
      }
      if (nonOperatingDates && nonOperatingDates.length > 0) {
        const timeNow = _day.clone();
        if (nonOperatingDates.find((nonOperatingDate) => timeNow.isSame(moment(nonOperatingDate), 'day'))) {
          return true;
        }
      }

      //  if last pickup cutoff time is found, use it to determine if today's date is disabled
      //  else, use minWindowSize and endHour to determine

      if (_serviceTypeObject && _serviceTypeObject.lastPickupCutoffTime) {
        const _lastPickupCutoffTime = _serviceTypeObject['lastPickupCutoffTime'];
        const timeNow = _day.clone().format('HH:mm:ss');

        return timeNow > _lastPickupCutoffTime;
      }

      if (
        company &&
        company?.deliveryOptions &&
        company?.deliveryOptions?.endHour &&
        _serviceTypeObject &&
        _serviceTypeObject?.min_window_size
      ) {
        const { endHour } = company.deliveryOptions;
        const { min_window_size } = _serviceTypeObject;
        const timeNow = _day.clone();
        return parseInt(endHour) - (2 * parseInt(min_window_size)) / 60 < timeNow.hours();
      }

      return false;
    },
    [company, nonOperatingDates, serviceType]
  );

  const generateSlotsPayload = useCallback(
    (_pickUpDate, serviceType, timezone) => {
      const _pickUpDateTime = _pickUpDate ? moment(_pickUpDate) : moment();
      while (isDayDisabledForPickup(_pickUpDateTime)) {
        _pickUpDateTime
          .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .add(1, 'days');
      }

      return {
        serviceType,
        pickUpDate: _pickUpDateTime.format(FORMAT_DATE_FOR_MOMENT),
        timezone,
      };
    },
    [isDayDisabledForPickup]
  );

  const disableNonOperatingDays = useCallback(
    (date) => {
      let nonOperatingDaysForServiceType = null;
      let nonOperatingDaysForCompany = null;
      const dateInterdites = [];
      if (serviceType && serviceType.non_operating_days) {
        nonOperatingDaysForServiceType = serviceType.non_operating_days.includes(date.getDay());
      }

      if (nonOperatingDates) {
        nonOperatingDates.forEach((date) => {
          dateInterdites.push(new Date(date + 'T00:00').getTime());
        });
        nonOperatingDaysForCompany = dateInterdites.includes(date.getTime());
      }
      return nonOperatingDaysForServiceType || nonOperatingDaysForCompany;
    },
    [serviceType, nonOperatingDates]
  );

  return useMemo(
    () => ({
      generateSlotsPayload,
      serviceType,
      disableNonOperatingDays,
      serviceRequiredTimeslot,
    }),
    [generateSlotsPayload, serviceType, disableNonOperatingDays, serviceRequiredTimeslot]
  );
}

export function useGreyOutHoursMinutes(fromTime, fromDate, toDate) {
  const getDisabledHours = useCallback(() => {
    const hours = [];
    const _fromDate = fromDate ? fromDate : new Date();
    const _toDate = toDate ? toDate : new Date();
    if (DateUtils.isSameDay(new Date(_fromDate), new Date(_toDate)) && fromTime) {
      for (let i = 0; i < fromTime.hour(); i++) {
        hours.push(i);
      }
    }
    return hours;
  }, [fromTime, fromDate, toDate]);

  const getDisabledMinutes = useCallback(
    (selectedHour) => {
      const minutes = [];
      const _fromDate = fromDate ? fromDate : new Date();
      const _toDate = toDate ? toDate : new Date();
      if (DateUtils.isSameDay(new Date(_fromDate), new Date(_toDate)) && fromTime) {
        if (selectedHour === fromTime.hour()) {
          for (let i = 0; i < fromTime.minute(); i++) {
            minutes.push(i);
          }
        }
      }
      return minutes;
    },
    [fromTime, fromDate, toDate]
  );

  return useMemo(() => ({ getDisabledHours, getDisabledMinutes }), [getDisabledHours, getDisabledMinutes]);
}

export function useGetBillingParties(orderSenderId) {
  const {
    data: billingParties,
    execute: getBillingParties,
    status,
  } = useService(billingPartyApi.getBillingParties, {
    lazy: true,
  });

  useEffect(() => orderSenderId && getBillingParties(orderSenderId), [orderSenderId]);

  return useMemo(() => {
    const activeBillingParties = billingParties?.filter((billingParty) => !billingParty['deactivated_at']);
    const defaultBillingParty = activeBillingParties?.find((billingParty) => billingParty['is_default']);

    return {
      isLoading: status === 'loading',
      defaultBillingParty,
      billingParties: activeBillingParties,
      getBillingParties,
    };
  }, [billingParties]);
}
