import Button from '@material-ui/core/Button';
import { omit } from 'lodash';
import lodashGet from 'lodash-es/get';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { sendEvent } from '@yojee/ui/event-tracking';
import { getFormKeyPath } from '@yojee/ui/new-order-booking/components/helpers/formHelpers';
import { getFormRefsSelector } from '@yojee/ui/new-order-booking/selectors';

import { addSuccessMessage } from '../../../../../../../../saga/actions';
import { ADD_LEG_ACTION_TYPE, TASK } from '../../../../../constants';
import { BookingInfoSectionContext } from '../../../../../Contexts';

const AddLegInFront = ({ addNewLeg, closePopover }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formRefs = useSelector(getFormRefsSelector);
  const { bookingInfoSectionIndex } = useContext(BookingInfoSectionContext);

  const onAddLegInFront = () => {
    sendEvent('PLANNING', 'ADD_LEG_IN_FRONT');

    // Get first leg key path
    const firstLegKeyPath = getFormKeyPath({ bookingInfoSectionIndex, legIndex: 0 });

    // Get current first stepFormRefs
    const currentFirstStepFormRefs = lodashGet(formRefs, firstLegKeyPath, []);

    // Get current first PICKUP task data
    const pickup = currentFirstStepFormRefs[0].getModel();
    const { is_empty, ...clonedPickup } = omit(pickup, [
      'order_step_group_id',
      'order_step_id',
      'order_item_id',
      'id',
      'step_property',
      'display_time_conflict_warning',
    ]);

    // New Leg Data
    const newLeg = [
      { type: TASK.PICKUP, isNewStep: true, timezone: clonedPickup.timezone, is_empty }, // Pickup = Empty, no details, but should take the timezone from the first step
      { ...clonedPickup, type: TASK.DROPOFF, isNewStep: true, is_empty }, // Dropoff = Details copied from the PICKUP of the first leg
    ];

    addNewLeg(newLeg, ADD_LEG_ACTION_TYPE.ADD_IN_FRONT);

    dispatch(addSuccessMessage(t('A new leg is added in front')));
    closePopover();
  };

  return (
    <div>
      <Button onClick={onAddLegInFront} data-cy="add-leg-in-front-button">
        {t('Add leg in front')}
      </Button>
    </div>
  );
};

export default AddLegInFront;
