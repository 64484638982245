import './style.scss';

import React, { useContext, useState } from 'react';

import { VOLUME_UNITS } from '@yojee/helpers/constants';

import { getFormKeyPath } from '../../../helpers/formHelpers';
import { BookingInfoSectionContext } from '../../BookingInfoSections/Contexts';
import WithUnitField from '../WithUnitField';
import { useVolumeValueFromSystemCalculated } from './calculateVolumeValue';
import {
  useDetectVolumeValueNotMatchValueFromSystemCalculated,
  useUpdateVolumeWhenDimensionFieldsChange,
} from './helpers';

function VolumeField(props) {
  const [additionalError, setAdditionalError] = useState('');
  const { bookingInfoSectionIndex } = useContext(BookingInfoSectionContext);
  const formKeyPath = getFormKeyPath({ bookingInfoSectionIndex, itemIndex: props.itemIndex, type: 'item' });

  const volumeFromSystemCalculate = useVolumeValueFromSystemCalculated();
  useUpdateVolumeWhenDimensionFieldsChange(formKeyPath, props.onChange, volumeFromSystemCalculate);
  useDetectVolumeValueNotMatchValueFromSystemCalculated({
    currentVolumeValue: props.value,
    formKeyPath,
    setAdditionalError,
    itemIndex: props.itemIndex,
    volumeFromSystemCalculate,
  });

  return <WithUnitField {...props} error={props.error || additionalError} units={VOLUME_UNITS} />;
}

export default VolumeField;
