import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      margin: 'auto',
    },
    errorColor: {
      color: theme.palette.error.main,
    },
    paper: {
      height: 230,
      overflow: 'auto',
      border: '1px solid #c4c4c4',
      boxShadow: 'none',
    },
    paperError: {
      borderColor: theme.palette.error.main,
    },
    listTitle: {
      fontWeight: 'bold',
      color: `${theme.palette.black.B60} !important`,
      fontSize: '0.75rem',
      marginBottom: theme.spacing(1),
    },
    searchBox: {
      marginBottom: theme.spacing(1),
      width: '100%',
    },
    listGrid: {
      flex: 1,
    },
    groupButtons: {
      marginTop: theme.spacing(8.75), // search height + title height
      alignSelf: 'center',
    },
    button: {
      margin: theme.spacing(0.5, 0),
      minWidth: theme.spacing(5),
      maxWidth: theme.spacing(5),
      height: theme.spacing(5),
    },
    emptyZone: {
      fontSize: '0.875rem',
    },
  };
});
