import { Input as TextField } from '@yojee/ui/base';

import { FormFieldComponent } from '../types';

export type InputFieldProps = Omit<React.ComponentProps<typeof TextField>, 'label' | 'required'>;

export const Input: FormFieldComponent<'input'> = ({
  fieldName,
  formField: { label, disabled, fieldProps },
  value,
  setValue,
  required,
  errorMessage,
}) => {
  const { fullWidth } = fieldProps;

  return (
    <TextField
      {...fieldProps}
      dataCy={fieldName}
      fullWidth={!!fullWidth || true}
      label={label}
      required={required}
      disabled={disabled}
      error={!!errorMessage}
      helperText={errorMessage}
      onChange={(event) => setValue(event.target.value)}
      value={value}
    />
  );
};
