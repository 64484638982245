import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useState } from 'react';

import { FORMAT_TIME_FOR_NEW_BOOKING } from '@yojee/helpers/constants';

import { customFilterDOMProps } from '../../../helpers/SchemaHelpers';
import DefaultTimePicker from './../../../../../date-time-picker/RCTimePicker';

function VesselToTimeField(props) {
  const [open, setOpen] = useState(false);

  const customOnChange = (event) => {
    setOpen(false);
    props.onChange(event);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DefaultTimePicker
        {...customFilterDOMProps(props)}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        hideDisabledOptions
        minuteStep={5}
        margin="dense"
        value={props.value || null}
        onChange={customOnChange}
        format={FORMAT_TIME_FOR_NEW_BOOKING}
        placeholder={FORMAT_TIME_FOR_NEW_BOOKING.toLowerCase()}
        name=""
        label={props.label}
        error={!!props.error}
        helperText={props.error}
        required={props.required}
        disabled={props.disabled}
      />
    </MuiPickersUtilsProvider>
  );
}

export default VesselToTimeField;
