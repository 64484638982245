export type SubTaskRule = {
  id?: number;
  event: SubTaskRuleEvent;
  action: SubTaskRuleAction;
  company_id?: number | null;
  sender_id?: number | null;
  meta?: { signature_message?: string };
  optional: boolean;
};

export enum SubTaskRuleEvent {
  PickupCompleted = 'pickup_completed',
  PickupFailed = 'pickup_failed',
  DropoffCompleted = 'dropoff_completed',
  DropoffFailed = 'dropoff_failed',
}

export enum SubTaskRuleAction {
  UploadSignature = 'upload_signature',
  UploadPhoto = 'upload_photo',
  SubmitOTP = 'submit_otp',
}
