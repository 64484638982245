import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons//ErrorOutline';
import clsx from 'clsx';
import React from 'react';

// custom libraries
import Checkbox from '@yojee/ui/base/furnitures/Checkbox';

import TooltipWrapper from './TooltipWrapper';
import { slugify } from './utils';

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    display: 'flex',
    height: '48px',
    borderBottom: `1px solid ${theme.palette?.black?.['B4']}`,
    backgroundColor: theme.palette?.black?.['B2'],
    flexGrow: 1,
    flexBasis: 0,
    position: 'relative',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  headerLabel: {
    backgroundColor: theme.palette?.black?.['B4'],
    fontSize: 'clamp(0.5rem, 1rem, 1.5rem)',
  },
  headerFirstLabel: {
    height: '48px',
    textAlign: 'left',
    borderBottom: `1px solid ${theme.palette?.black?.['B4']}`,
    borderTopLeftRadius: '8px',
  },
  headerRestLabel: {
    padding: '0px 16px 0px 16px',
    textAlign: 'center',
  },
  headerLastLabel: {
    borderTopRightRadius: '8px',
  },
  headerCheckBoxFirstLabel: {
    height: '48px',
    backgroundColor: theme.palette?.black?.['B2'],
    fontSize: 'clamp(0.5rem, 1rem, 1.5rem)',
    textAlign: 'left',
    borderBottom: `1px solid ${theme.palette?.black?.['B4']}`,
  },
  headerCheckBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoIcon: {
    textAlign: 'center',
    color: theme.palette?.black?.['B60'],
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-40px, -9px)',
  },
  tableRowContainer: {
    display: 'flex',
    width: '100%',
    borderBottom: `1px solid ${theme.palette?.black?.['B4']}`,
    height: '48px',
  },
  tableFirstCol: {
    minWidth: '284px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
  },
  checkboxStyle: {
    margin: 0,
    padding: 0,
  },
}));

const TableHeaderCellFirstLabel = (props) => {
  const classes = useStyles(props);
  const { label } = props;
  return (
    <div className={clsx(classes.tableFirstCol, classes.headerLabel, classes.headerFirstLabel)}>
      <strong>{label}</strong>
    </div>
  );
};

const TableHeaderCellLabel = (props) => {
  const classes = useStyles(props);
  const { label, isLastLabel } = props;
  return (
    <div
      className={clsx(classes.tableHeader, classes.headerLabel, classes.headerRestLabel, {
        [classes.headerLastLabel]: isLastLabel,
      })}
    >
      <strong>{label}</strong>
    </div>
  );
};

const TableHeaderCellCheckBoxLabel = (props) => {
  const classes = useStyles(props);
  const { label } = props;
  return (
    <div className={clsx(classes.tableFirstCol, classes.headerCheckBoxFirstLabel)}>
      <strong>{label}</strong>
    </div>
  );
};

const TableHeaderCellCheckBox = (props) => {
  const {
    checked,
    onClick,
    data = {},
    onChange = () => {},
    disabled = false,
    tooltipConfigs = null,
    headerTooltipConfigs = null,
    dataCy,
  } = props;
  const classes = useStyles(props);

  const handleOnChange = () => {
    onChange({ ...checked, ...data });
  };

  return (
    <div className={clsx(classes.tableHeader, classes.headerCheckBox)}>
      {headerTooltipConfigs?.title && (
        <div className={classes.infoIcon}>
          <TooltipWrapper data-cy="tooltip" {...headerTooltipConfigs}>
            <ErrorOutlineIcon fontSize="small" />
          </TooltipWrapper>
        </div>
      )}
      <TooltipWrapper {...tooltipConfigs}>
        <div style={{ margin: 0, padding: 0 }}>
          <Checkbox
            className={classes.checkboxStyle}
            checked={checked}
            disabled={disabled}
            onChange={handleOnChange}
            onClick={onClick}
            data-cy={`${dataCy}-checkbox`}
          />
        </div>
      </TooltipWrapper>
    </div>
  );
};

const TableHeaderRow = (props) => {
  const { checkboxConfigs, onClick } = props;
  const classes = useStyles(props);

  return (
    <>
      <div className={classes.tableRowContainer}>
        <TableHeaderCellFirstLabel label="Task" />
        {checkboxConfigs?.map((cellProps, idx) => (
          <TableHeaderCellLabel
            key={`table-checkbox-cell-label-${slugify(cellProps.data.action)}`}
            isLastLabel={idx === checkboxConfigs.length - 1}
            {...cellProps}
          />
        ))}
      </div>

      <div className={classes.tableRowContainer}>
        <TableHeaderCellCheckBoxLabel label="Select all" />
        {checkboxConfigs?.map((cellProps) => (
          <TableHeaderCellCheckBox
            key={`table-checkbox-cell-${slugify(cellProps.data.action)}`}
            onClick={onClick}
            {...cellProps}
          />
        ))}
      </div>
    </>
  );
};

export default TableHeaderRow;
