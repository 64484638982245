import { Paper, Popper } from '@material-ui/core';
import { t } from 'i18next';
import { usePopupState } from 'material-ui-popup-state/hooks';
import moment, { Moment } from 'moment';
import React, {
  CSSProperties,
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { DateTimeRangeHandle } from '..';
import DashDivider from '../common/DashDivider';
import StyledInput from '../common/StyledInput';
import { bindInputPopper, bindInputTriggers } from '../common/utils';
import { TIME_FORMAT } from '../constant';
import { TimeChangeCallback, TimeType } from '../types';
import { OPTION_HEIGHT, StyledInputsContainer, StyledMenuItem } from './StyledTimeRangePicker';
import { formatTime, getGap, getTimeOptions, parseTime } from './utils';

type TimeRangePickerProps = {
  start: TimeType;
  end: TimeType;
  onStartChange: TimeChangeCallback;
  onEndChange: TimeChangeCallback;
  disabled?: boolean;
  style?: CSSProperties;
};

const NUM_OPTIONS = 9;
const FIRST_VISIBLE_OPTION = 9;
const INPUT_MAX_WIDTH = 50;

const TimeRangePicker: ForwardRefRenderFunction<DateTimeRangeHandle, TimeRangePickerProps> = (
  { start, end, onStartChange, onEndChange, disabled, style },
  ref
) => {
  const startPopupState = usePopupState({ variant: 'popover', popupId: 'startTimePicker' });
  const startPopperRef = useRef<HTMLDivElement>(null);
  const gapRef = useRef(getGap(start, end));
  const optionsRef = useRef<HTMLDivElement>(null);

  const [startInput, setStartInput] = useState(formatTime(start));
  const [endInput, setEndInput] = useState(formatTime(end));
  const [startInputError, setStartInputError] = useState(false);
  const [endInputError, setEndInputError] = useState(false);

  const syncStartInput = useCallback((start: Moment) => {
    setStartInput(formatTime(start));
    setStartInputError(false);
  }, []);

  const syncEndInput = useCallback((end: Moment) => {
    setEndInput(formatTime(end));
    setEndInputError(false);
  }, []);

  const updateStart = useCallback(
    (startStr: string) => {
      const newStart = parseTime(startStr);
      onStartChange(newStart);

      if (newStart && gapRef.current) {
        onEndChange(moment(newStart).add(gapRef.current));
      }
    },
    [onEndChange, onStartChange]
  );

  const updateEnd = useCallback(
    (endStr: string) => {
      const newEnd = parseTime(endStr);
      onEndChange(newEnd);

      gapRef.current = getGap(start, newEnd);
    },
    [onEndChange, start]
  );

  useEffect(() => {
    if (start != null) {
      syncStartInput(start);
    }
  }, [start, syncStartInput]);

  useEffect(() => {
    if (end != null) {
      syncEndInput(end);
    }
  }, [end, syncEndInput]);

  useEffect(() => {
    if (!startPopupState.open) return;
    optionsRef.current?.scroll({ top: FIRST_VISIBLE_OPTION * OPTION_HEIGHT });
  }, [startPopupState.open]);

  useImperativeHandle(
    ref,
    () => ({
      clear() {
        setStartInput('');
        onStartChange(null);

        setEndInput('');
        onEndChange(null);
      },
    }),
    [onStartChange, onEndChange]
  );

  return (
    <>
      <StyledInputsContainer style={style}>
        <StyledInput
          {...bindInputTriggers(startPopupState)}
          value={startInput}
          placeholder={TIME_FORMAT}
          onChange={(event) => {
            const input = event.target.value;
            const newStart = parseTime(input);
            setStartInput(input);
            setStartInputError(input ? newStart == null : false);
          }}
          onBlur={(event) => {
            updateStart(startInput);
            if (!startPopperRef.current?.contains(event.relatedTarget)) {
              startPopupState.close();
            }
          }}
          disabled={disabled}
          error={startInputError ? t('Invalid time') : null}
          style={{ maxWidth: INPUT_MAX_WIDTH }}
        />
        <DashDivider />
        <StyledInput
          value={endInput}
          placeholder={TIME_FORMAT}
          onChange={(event) => {
            const input = event.target.value;
            const newEnd = parseTime(input);
            setEndInput(input);
            setEndInputError(input ? newEnd == null : false);
          }}
          onBlur={() => {
            updateEnd(endInput);
          }}
          disabled={disabled}
          error={endInputError ? t('Invalid time') : null}
          style={{ maxWidth: INPUT_MAX_WIDTH }}
        />
      </StyledInputsContainer>
      <Popper {...bindInputPopper(startPopupState)} keepMounted ref={startPopperRef}>
        <Paper style={{ height: NUM_OPTIONS * OPTION_HEIGHT, overflowY: 'scroll' }} ref={optionsRef}>
          {getTimeOptions(start).map((hour) => {
            const hourStr = formatTime(hour);
            return (
              <StyledMenuItem
                key={hourStr}
                selected={hourStr === startInput}
                onClick={() => {
                  updateStart(hourStr);
                  startPopupState.close();
                }}
              >
                {hourStr}
              </StyledMenuItem>
            );
          })}
        </Paper>
      </Popper>
    </>
  );
};

export default forwardRef(TimeRangePicker);
