import { makeStyles } from '@material-ui/core/styles';
import lodashGet from 'lodash-es/get';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@yojee/ui/base/furnitures/Button';
import { getFormRefsSelector } from '@yojee/ui/new-order-booking/selectors';

import { addSuccessMessage, clearDateTimeInputStart } from '../../../../../../../../saga/actions';
import { clearForm, useIsReadOnlyLegSection } from '../../../../../../../helpers/hooks';

const useStyles = makeStyles((theme) => ({
  btn: { color: theme.palette.error.light },
}));

const ClearAll = ({ formKeyPath }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const classes = useStyles();
  const allFormRefs = useSelector(getFormRefsSelector);
  const isReadOnlyLegSection = useIsReadOnlyLegSection();

  const onClearAll = () => {
    const formRefs = lodashGet(allFormRefs, formKeyPath, []);

    if (formRefs.length > 0) {
      formRefs.forEach((formRef) => {
        clearForm(formRef);
      });
    }

    // See `getFormKeyPath` defined in `formHelpers.js`
    [0, 1]
      .map((stepIndex) => {
        return `${formKeyPath}.${stepIndex}`;
      })
      .forEach((key) => {
        dispatch(clearDateTimeInputStart(key));
      });

    dispatch(addSuccessMessage(t('All steps information cleared')));
  };

  return (
    <Button
      size="small"
      data-cy="clear-all-steps-data"
      disableTouchRipple
      onClick={onClearAll}
      className={classes.btn}
      disabled={isReadOnlyLegSection}
    >
      {t('Clear All')}
    </Button>
  );
};

export default ClearAll;
