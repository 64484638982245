import Button from '@material-ui/core/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { addSuccessMessage } from '../../../../../../../../saga/actions';
import { useClearForm, useIsReadOnlyItem } from '../../../../../../../helpers/hooks';

const ClearItem = ({ formKeyPath, closePopover }) => {
  const dispatch = useDispatch();
  const isReadOnlyItem = useIsReadOnlyItem();
  const clear = useClearForm(formKeyPath);
  const { t } = useTranslation();

  return (
    <Button
      data-cy="clear-item-info"
      disabled={isReadOnlyItem}
      className="clear-btn"
      onClick={() => {
        clear();
        dispatch(addSuccessMessage(t('Item information cleared')));
        closePopover();
      }}
    >
      Clear
    </Button>
  );
};

export default ClearItem;
