import { Action } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';

import { reasonCodeApi } from '@yojee/api/reasonCodeApi';
import { ReasonCodeType } from '@yojee/types';
import { batchUpload } from '@yojee/ui/onboarding/helpers/batchUploadHelper';

import { reasonCodeService } from '../../services/reasonCodeService';
interface BatchUploadActionResult<T> extends Action<string> {
  type: string;
  payload: T;
}
interface ActionResult<T> extends Action<string> {
  type: string;
  data: T;
  uuid?: string;
}

export default function* sagas() {
  yield takeLatest('REQUEST_LIST_REASON_CODES', listReasonCodes);
  yield takeLatest('REQUEST_BULK_DELETE_REASON_CODES', bulkDeleteReasonCodes);
  yield takeLatest('REQUEST_CREATE_REASON_CODE', createReasonCode);
  yield takeLatest('REQUEST_EDIT_REASON_CODES', editReasonCode);
  yield takeLatest('DOWNLOAD_REASON_CODE_TEMPLATE_SAMPLE', downloadReasonCodeTemplateSample);
  yield takeLatest('REQUEST_REASON_CODE_BATCH_UPLOAD', requestBatchUploadReasonCodes);
}

export type AddEditReasonCodeType = {
  id?: number;
  code: string;
  description: string;
  types: string[];
};

export type ReasonCodeDataType = {
  code: string;
  description: string;
  types: string[];
};

export type ResponseListReasonCodes = {
  data: ReasonCodeType[];
};

type ResponseBulkDeleteReasonCodes = {
  data: number[];
  message: string;
};

type CreateReasonCodeType = {
  data: ReasonCodeDataType;
  uuid: string;
};

export type RequestBatchUploadType = {
  file: string;
  format: string;
};

type RequestDownloadTemplate = { format: string };

export type RequestBulkDeleteType = {
  data: { ids: number[] };
};

function* listReasonCodes() {
  try {
    yield put({ type: 'SET_REASON_CODES_LOADING' });
    const response: ResponseListReasonCodes = yield call(reasonCodeApi.getReasonCodes);
    yield put({ type: 'LIST_REASON_CODE_SUCCESSFUL', data: response['data'] });
  } catch (error) {
    yield put({ type: 'LIST_REASON_CODE_FAILED' });
  }
}

function* bulkDeleteReasonCodes(data: ActionResult<RequestBulkDeleteType>) {
  try {
    yield put({ type: 'SET_REASON_CODES_LOADING' });
    const response: ResponseBulkDeleteReasonCodes = yield call(reasonCodeService.bulkDeleteReasonCodes, data);
    yield put({ type: 'BULK_DELETE_REASON_CODE_SUCCESSFUL', message: response['message'] });
    yield put({ type: 'REQUEST_LIST_REASON_CODES' });
  } catch (error) {
    yield put({ type: 'BULK_DELETE_REASON_CODE_FAILED' });
  }
}

function* createReasonCode(action: ActionResult<CreateReasonCodeType>) {
  const { data, uuid } = action;
  try {
    const response: ResponseBulkDeleteReasonCodes = yield call(reasonCodeService.createReasonCode, { data });
    yield put({ type: 'CREATE_REASON_CODE_SUCCESSFUL', message: response['message'], uuid: uuid });
    yield put({ type: 'REQUEST_LIST_REASON_CODES' });
  } catch (error) {
    yield put({ type: 'CREATE_REASON_CODE_FAILED', error: error });
  }
}

function* editReasonCode(action: ActionResult<CreateReasonCodeType>) {
  const { data, uuid } = action;
  try {
    const response: ResponseBulkDeleteReasonCodes = yield call(reasonCodeService.editReasonCode, { data });
    yield put({ type: 'EDIT_REASON_CODE_SUCCESSFUL', message: response['message'], uuid: uuid });
    yield put({ type: 'REQUEST_LIST_REASON_CODES' });
  } catch (error) {
    yield put({ type: 'EDIT_REASON_CODE_FAILED', error: error });
  }
}

function* requestBatchUploadReasonCodes(action: BatchUploadActionResult<RequestBatchUploadType>) {
  const { file, format } = action.payload;
  yield batchUpload(
    { file, format },
    {
      uploadApiMethod: reasonCodeService.batchUpload,
      statusCheckApiMethod: reasonCodeService.checkBatchStatus,
      events: {
        started: 'REASON_CODE_UPLOAD_STARTED',
        processing: 'REASON_CODE_BATCH_UPLOAD_PROCESSING',
        succeeded: 'REASON_CODE_BATCH_UPLOAD_SUCCEEDED',
        failed: 'REASON_CODE_BATCH_UPLOAD_FAILED',
      },
    }
  );
}

function downloadReasonCodeTemplateSample(action: ActionResult<RequestDownloadTemplate>) {
  const {
    data: { format },
  } = action;
  window.open(reasonCodeService.downloadTemplateSample(format), '_self');
}
