import { countries } from '@yojee/helpers/countryAndTimezoneHelper';

export function fillDataFromAddressBook({ addressBookData, formRef, isCreateOrder }) {
  const country = addressBookData?.country;
  const schema = formRef?.props?.schema?.schema ?? {};

  formRef.change('location', addressBookData?.location);
  formRef.change('address_item_id', addressBookData?.id ?? '');
  formRef.change('address_external_id', addressBookData?.external_id ?? '');
  if (schema.address2) formRef.change('address2', addressBookData?.address2 ?? '');
  if (schema.postal_code) formRef.change('postal_code', addressBookData?.postal_code ?? '');
  if (schema.state) formRef.change('state', addressBookData?.state ?? '');
  if (schema.country) formRef.change('country', country ?? '');
  if (schema.city) formRef.change('city', addressBookData?.city ?? '');
  if (schema.contact_name) formRef.change('contact_name', addressBookData?.contact_name ?? '');
  if (schema.contact_email) formRef.change('contact_email', addressBookData?.contact_email ?? '');
  if (schema.contact_company) formRef.change('contact_company', addressBookData?.contact_company ?? '');

  const timezone = addressBookData?.metadata?.timezone;
  if (schema.timezone) {
    const defaultCountryTimezone = countries.find((c) => c.name === country)?.timezones?.[0];
    formRef.change('timezone', timezone ?? defaultCountryTimezone);
  }

  if (isCreateOrder) onChangePhone({ formRef, schema, country, newContactPhone: addressBookData?.contact_phone });

  formRef.change('address_id', addressBookData?.external_id || addressBookData?.id);
  formRef.change('mapPosition', { id: addressBookData?.id, ...(addressBookData?.location || {}) }); // for display in map
}

export function fillDataFromGoogleAddress({ googleAddressData, formRef, isCreateOrder }) {
  const country = googleAddressData?.country;
  const currentDefaultPhoneCountry = formRef?.state?.model?.default_phone_country;
  const schema = formRef?.props?.schema?.schema ?? {};

  formRef.change('location', {
    lat: googleAddressData?.lat,
    lng: googleAddressData?.lng,
  });
  if (schema.postal_code) formRef.change('postal_code', googleAddressData?.postalCode ?? '');
  if (schema.state) formRef.change('state', googleAddressData?.state ?? '');
  if (schema.country) formRef.change('country', country ?? '');
  if (schema.city) formRef.change('city', googleAddressData?.city ?? '');
  if (schema.timezone) formRef.change('timezone', countries.find((c) => c.name === country)?.timezones?.[0]);

  const newDefaultPhoneCountry = (countries.find((c) => c.name === country)?.country_code ?? '').toLowerCase();
  if (newDefaultPhoneCountry && currentDefaultPhoneCountry !== newDefaultPhoneCountry && isCreateOrder) {
    formRef.change('default_phone_country', newDefaultPhoneCountry);
    if (schema.contact_phone) formRef.change('contact_phone', '');
  }
  if (isCreateOrder) onChangePhone({ formRef, schema, country });

  // Reset address book ids
  formRef.change('address_id', '');
  formRef.change('address_item_id', '');
  formRef.change('address_external_id', '');

  // for display in map
  formRef.change('mapPosition', {
    id: googleAddressData?.id,
    lat: googleAddressData?.lat,
    lng: googleAddressData?.lng,
  });
}

const onChangePhone = ({ formRef, schema, country, newContactPhone }) => {
  const currentDefaultPhoneCountry = formRef?.state?.model?.default_phone_country;

  const newDefaultPhoneCountry = (countries.find((c) => c.name === country)?.country_code ?? '').toLowerCase();
  if (newDefaultPhoneCountry && currentDefaultPhoneCountry !== newDefaultPhoneCountry) {
    formRef.change('default_phone_country', newDefaultPhoneCountry);
    if (schema.contact_phone) formRef.change('contact_phone', '');
  }

  let contactPhone = newContactPhone;
  contactPhone = contactPhone ? (contactPhone.startsWith('+') ? contactPhone : '+' + contactPhone) : '';

  if (schema.contact_phone && contactPhone) formRef.change('contact_phone', contactPhone);
};
