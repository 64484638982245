import { call, put, select, takeLatest } from 'redux-saga/effects';

import { batchUpload } from '@yojee/ui/onboarding/helpers/batchUploadHelper';

import { senderService } from '../../services/senderService';

export default function* sagas() {
  yield takeLatest('REQUEST_LIST_SENDERS', listSenders);
  yield takeLatest('REQUEST_GET_SENDERS', getSenders);
  yield takeLatest('REQUEST_CREATE_SENDER', createSender);
  yield takeLatest('REQUEST_UPDATE_SENDER', updateSender);
  yield takeLatest('REQUEST_DELETE_SENDER', deleteSender);
  yield takeLatest('REQUEST_SENDER_BATCH_UPLOAD', senderBatchUpload);
  yield takeLatest('DOWNLOAD_SENDER_TEMPLATE_SAMPLE', downloadSenderTemplateSample);
}

export const getFilter = (state) => {
  return state.sender.filter;
};

function* listSenders(action) {
  try {
    yield put({ type: 'SET_SENDER_LOADING', key: 'listSenders' });
    const response = yield call(senderService.listSenders, action.params);
    yield put({ type: 'LIST_SENDERS_SUCCESSFUL', senderData: response, params: action.params });
  } catch (error) {
    yield put({ type: 'LIST_SENDERS_FAILED', error });
  }
}

function* getSenders(action) {
  try {
    yield put({ type: 'SET_SENDER_LOADING', key: 'getSenders' });
    const response = yield call(senderService.getSenders, action.params);
    yield put({ type: 'GET_SENDERS_SUCCESSFUL', senderData: response, params: action.params });
  } catch (error) {
    yield put({ type: 'GET_SENDERS_FAILED', error });
  }
}

function* createSender(action) {
  try {
    yield put({ type: 'SET_SENDER_LOADING', key: 'createSender' });
    const senderData = yield call(senderService.createSender, action.sender);
    yield put({ type: 'CREATE_SENDER_SUCCESSFUL', senderData });
    yield put({ type: 'REQUEST_LIST_SENDERS', params: yield select(getFilter) });
  } catch (error) {
    yield put({ type: 'CREATE_SENDER_FAILED', error });
  }
}
function* updateSender(action) {
  try {
    yield put({ type: 'SET_SENDER_LOADING', key: 'updateSender' });
    const senderData = yield call(senderService.updateSender, action.sender);
    yield put({ type: 'UPDATE_SENDER_SUCCESSFUL', senderData });
    yield put({ type: 'REQUEST_LIST_SENDERS', params: yield select(getFilter) });
  } catch (error) {
    yield put({ type: 'UPDATE_SENDER_FAILED', error });
  }
}
function* deleteSender(action) {
  try {
    yield put({ type: 'SET_SENDER_LOADING', key: 'deleteSender' });
    const senderData = yield call(senderService.deleteSender, action.sender.id);
    yield put({ type: 'DELETE_SENDER_SUCCESSFUL', senderData });
    yield put({ type: 'REQUEST_LIST_SENDERS', params: yield select(getFilter) });
  } catch (error) {
    yield put({ type: 'DELETE_SENDER_FAILED', error });
  }
}
function* senderBatchUpload({ payload: { file, format, type } }) {
  yield batchUpload(
    { file, format, type },
    {
      uploadApiMethod: senderService.batchUpload,
      statusCheckApiMethod: senderService.checkBatchStatus,
      events: {
        started: 'SENDERS_BATCH_UPLOAD_STARTED',
        processing: 'SENDERS_BATCH_UPLOAD_PROCESSING',
        succeeded: 'SENDERS_BATCH_UPLOAD_SUCCEEDED',
        failed: 'SENDERS_BATCH_UPLOAD_FAILED',
      },
    }
  );
}

function downloadSenderTemplateSample({ payload: { format, type } }) {
  window.open(senderService.downloadTemplateSample({ format, type }), '_self');
}
