import { MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { Trans } from 'react-i18next';

import { useIsEditOrder } from '../../helpers/hooks';
import { useIsCanShowMap } from '../../helpers/utils';
import MapToggleButton from '../MapToggleButton';

const OrderTemplateUI = ({ templateId, handleChange, displayTemplates, disabled }) => {
  const isEditOrder = useIsEditOrder();
  const isCanShowMap = useIsCanShowMap();

  return (
    <div className="flex pt-20 pb-15 align-center justify-between order-template-select-block">
      <div className="flex flex-1 align-center" data-cy="booking-form-template-section">
        <label className="section-label font-semibold pr-8" data-cy="booking-form-template-section-label">
          <Trans>Order Template</Trans>
        </label>
        <Select
          className="order-template-select"
          disabled={disabled}
          onChange={handleChange}
          value={templateId || ''}
          data-cy="booking-template-dropdown-list"
          MenuProps={{ 'data-cy': 'order-template-select-menu' }}
        >
          {displayTemplates?.map((template) => (
            <MenuItem key={template.id} value={template.id}>
              {template.template_type_name}
            </MenuItem>
          ))}
        </Select>
      </div>

      {!isEditOrder && isCanShowMap && <MapToggleButton />}
    </div>
  );
};

export default OrderTemplateUI;
