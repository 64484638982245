import { allCountries } from './country_phone';

const countryCodes: Record<string, string> = allCountries.reduce(
  (acc, { name, iso2 }) => ({
    ...acc,
    [name]: iso2,
  }),
  {}
);

export default countryCodes;
