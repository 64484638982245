import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { companyService } from '@yojee/api/companyService';
import { senderApi } from '@yojee/api/senderApi';
import { getValue } from '@yojee/helpers/AccessHelper';
import {
  AddressCompetionRadiusUnit,
  getACRUnitOptions,
  preventDecimalInput,
} from '@yojee/helpers/addressCompletionRadiusHelpers';
import {
  CLOSING_TIME_SHOULD_BE_GREATER_THAN_OPENING_TIME,
  OPENING_TIME_SHOULD_BE_LESS_THAN_CLOSING_TIME,
} from '@yojee/helpers/constants';
import { formRules } from '@yojee/helpers/FormValidator';
import { Option } from '@yojee/types';
import { FormSchema } from '@yojee/ui/QuickForm';
import { AddressData } from '@yojee/ui/QuickForm/controls/utils/getFullAddress';

export enum SenderLinkType {
  All = 'all',
  Specific = 'specific',
}

export type AddressFormData = {
  external_id: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postal_code: string;
  country: string;
  contact_name: string;
  contact_company: string;
  contact_phone: string;
  contact_email: string;
  open_from: string;
  closed_by: string;
  tags: Option[];
  type: string;
  follow_sender_address_completion_radius: boolean;
  address_completion_radius: number;
  address_completion_radius_unit: AddressCompetionRadiusUnit;
  sender_link_type: SenderLinkType;
  link_to_sender_ids: number[];
};

export const useAddressFormSchema: (params: {
  defaultAddress: AddressData;
  onAddressChange: (data: AddressData) => void;
}) => FormSchema<AddressFormData> = ({ onAddressChange, defaultAddress }) => {
  const { t } = useTranslation();

  const [cachedOrganisationOptions, setCachedOrganisationOptions] = useState<Option[] | null>(null);

  const locationTypes = useSelector((state) => getValue(state, 'company.companyData.address_item_types', [])).reduce(
    (acc: { label: string; value: string }[], label: string) => {
      acc.push({ label: label, value: label });
      return acc;
    },
    [{ label: 'None', value: '' }]
  );

  return {
    external_id: {
      label: t('External Address ID'),
      fieldType: 'input',
      fieldProps: {},
    },
    address1: {
      label: t('Address line 1'),
      fieldType: 'addressInput',
      validator: [formRules.required()],
      fieldProps: {
        defaultAddress,
        onAddressChange,
      },
    },
    address2: {
      label: t('Address line 2'),
      fieldType: 'input',
      fieldProps: {},
    },
    city: {
      label: t('City'),
      fieldType: 'input',
      fieldProps: {},
    },
    state: {
      label: t('State'),
      fieldType: 'input',
      fieldProps: {},
    },
    postal_code: {
      label: t('Postal code'),
      fieldType: 'input',
      fieldProps: {},
    },
    country: {
      label: t('Country'),
      fieldType: 'input',
      fieldProps: {},
      validator: [formRules.required()],
    },
    contact_name: {
      label: t('Name'),
      fieldType: 'input',
      fieldProps: {},
    },
    contact_company: {
      label: t('Company'),
      fieldType: 'input',
      fieldProps: {},
    },
    contact_phone: {
      label: t('Phone'),
      fieldType: 'phone',
      validator: [formRules.phoneNumber({ isRequired: false })],
      fieldProps: {},
    },
    contact_email: {
      label: t('Email'),
      fieldType: 'input',
      validator: [formRules.email()],
      fieldProps: {},
    },
    open_from: {
      label: t('Opening time'),
      fieldType: 'input',
      fieldProps: {
        type: 'time',
        step: 300,
      },
      validator: [
        formRules.custom({
          isValid: (value: string, formData: AddressFormData) => !formData.closed_by || !!value,
          message: formRules.required().message,
        }),
        formRules.custom({
          isValid: (value: string, formData: AddressFormData) => !formData.closed_by || value < formData.closed_by,
          message: OPENING_TIME_SHOULD_BE_LESS_THAN_CLOSING_TIME(t),
        }),
      ],
    },
    closed_by: {
      label: t('Closing time'),
      fieldType: 'input',
      fieldProps: {
        type: 'time',
        step: 300,
      },
      validator: [
        formRules.custom({
          isValid: (value: string, formData: AddressFormData) => !formData.closed_by || !!value,
          message: formRules.required().message,
        }),
        formRules.custom({
          isValid: (value: string, formData: AddressFormData) => !formData.open_from || value > formData.open_from,
          message: CLOSING_TIME_SHOULD_BE_GREATER_THAN_OPENING_TIME(t),
        }),
      ],
    },
    tags: {
      label: t('Additional Tags'),
      fieldType: 'multiSelect',
      fieldProps: {
        isRawValue: false,
        selectType: 'async',
        loadOptions: async (q: string) => {
          const data = await companyService.getCompanyTags({ q, pageSize: 10, type: undefined });
          return data.map(({ name }: { name: string }) => ({
            value: name,
            label: name,
          }));
        },
      },
    },
    type: {
      label: t('Type'),
      fieldType: 'singleSelect',
      fieldProps: {
        options: locationTypes,
      },
    },
    follow_sender_address_completion_radius: {
      label: t('Follow sender default'),
      fieldType: 'checkbox',
      validator: [],
      fieldProps: {
        value: true,
      },
    },
    address_completion_radius: {
      label: ' ',
      fieldType: 'input',
      validator: [
        formRules.custom({
          isValid: (value: string, formData: AddressFormData) =>
            formData.follow_sender_address_completion_radius || formRules.required().isValid(value),
          message: formRules.required().message,
        }),
        formRules.positiveNumber(),
        formRules.integer(),
      ],
      fieldProps: {
        type: 'number',
        inputProps: {
          min: 0,
          step: 1,
          onKeyDown: preventDecimalInput,
        },
      },
    },
    address_completion_radius_unit: {
      label: ' ',
      fieldType: 'singleSelect',
      validator: [],
      fieldProps: {
        getOptions: () => getACRUnitOptions(t),
      },
    },
    sender_link_type: {
      label: t('Applies to'),
      fieldType: 'singleSelect',
      fieldProps: {
        isClearable: false,
        isSearchable: false,
        options: [
          { label: t('All Senders'), value: SenderLinkType.All },
          { label: t('Specific Senders'), value: SenderLinkType.Specific },
        ],
      },
    },
    link_to_sender_ids: {
      label: t('Select Senders'),
      fieldType: 'transferList',
      fieldProps: {
        leftTitle: t('Available Senders'),
        rightTitle: t('Selected Senders'),
        rightRequired: true,
        loadOptions: async () => {
          if (cachedOrganisationOptions) return cachedOrganisationOptions;

          const organisations = (await senderApi.getOrganisations({
            pageSize: 10000,
            q: null,
            axiosCancelToken: null,
          })) as Organisation[];

          const options = (organisations || []).map((organisation) => ({
            label: organisation.name,
            value: organisation.sender.id,
          }));

          setCachedOrganisationOptions(options);

          return options;
        },
      },
      validator: [
        formRules.custom({
          isValid: (value: AddressFormData['link_to_sender_ids'], formData: AddressFormData) => {
            if (formData.sender_link_type === SenderLinkType.Specific) {
              return (value || []).length > 0;
            }
            return true;
          },
          message: formRules.required().message,
        }),
      ],
    },
  };
};

type Organisation = {
  name: string;
  sender: {
    id: number;
  };
};
