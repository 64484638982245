import 'rc-time-picker/assets/index.css';

import MuiFormHelperText from '@material-ui/core/FormHelperText';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import TimePicker from 'rc-time-picker';
import React from 'react';
import styled from 'styled-components';

import { datePickerTheme } from './CreateTheme';
import { getClassNameByStatus } from './utils';

const StyledTimePicker = styled(TimePicker)`
  width: 100%;

  & input {
    height: 40px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 500;
    color: currentColor;
    padding: 4px 10px;
  }

  & input,
  textarea {
    &::placeholder {
      font-style: italic;
      opacity: 0.5;
    }
    &:disabled {
      opacity: 1;
      background: none;
    }
  }

  & .rc-time-picker-clear-icon {
    display: none;
  }

  & .rc-time-picker-clear {
    display: none;
  }
`;

const styles = (theme) => ({
  rcHidden: {
    display: 'none',
  },
  inputIcon: {
    position: 'absolute',
    right: theme.spacing(1.625),
    top: theme.spacing(1),
    cursor: 'pointer',
  },
  popUp: {
    '& .rc-time-picker-panel-inner': {
      marginTop: '42px',
      borderRadius: '5px',
      width: '100%',
    },
    '& .rc-time-picker-panel-input-wrap': {
      display: 'none',
    },
    '& .rc-time-picker-panel-select-option-selected': {
      color: theme.palette.common.white,
      background: theme.palette.primary.main + ' !important',
      borderRadius: theme.spacing(0.5),
    },
    '& .rc-time-picker-panel-select:nth-child(1) ul': {
      padding: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    },
    '& .rc-time-picker-panel-select:nth-child(2) ul': {
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px 0px`,
    },
    '& li': {
      padding: 0 + ' !important',
      textAlign: 'center !important',
      marginBottom: theme.spacing(1) + ' !important',
    },
    '& .rc-time-picker-panel-select': {
      border: 'none',
      fontSize: '13px',
      '&::-webkit-scrollbar': {
        width: '6px',
      },
      width: '50%',
    },
  },
  label: {
    color: '#858f96',
    display: 'block',
    fontSize: '11px',
    fontWeight: '600',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    transformOrigin: 'top left',
    transform: 'translate(0, 1.5px) scale(0.75)',
    marginTop: '7px',
    marginBottom: '1px',
  },
});

const DefaultTimePicker = (props) => {
  const { classes, helperText, error, className, hide, ...rest } = props;
  const timePickerClassName = getClassNameByStatus(className, props?.disabled);

  return (
    <ThemeProvider theme={datePickerTheme}>
      <div className={hide ? classes.rcHidden : null}>
        <span className={classes.label}>
          {rest?.label ? rest.label : '\u00A0'}
          {rest?.required ? <span> *</span> : ''}
        </span>
        <StyledTimePicker
          {...rest}
          className={timePickerClassName}
          showSecond={false}
          minuteStep={5}
          inputIcon={<AccessTimeIcon className={classes.inputIcon} />}
          clearIcon={null}
          popupClassName={classes.popUp}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
        />
        {helperText && <MuiFormHelperText error={error}>{helperText}</MuiFormHelperText>}
      </div>
    </ThemeProvider>
  );
};

export default withStyles(styles)(DefaultTimePicker);
