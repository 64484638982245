import 'react-datepicker/dist/react-datepicker.css';
import './index.css';

import { ClickAwayListener, Popper } from '@material-ui/core';
import { t } from 'i18next';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React, {
  CSSProperties,
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import DatePicker from 'react-datepicker';

import { DateTimeRangeHandle, DateTimeRangePickerProps } from '..';
import DashDivider from '../common/DashDivider';
import StyledInput from '../common/StyledInput';
import { bindInputPopper, bindInputTriggers } from '../common/utils';
import { DATE_FORMAT } from '../constant';
import { DateType } from '../types';
import { formatDate, isValidDate, parseInput } from './utils';

export type DateRangePickerProps = Pick<
  DateTimeRangePickerProps,
  'start' | 'end' | 'onStartChange' | 'onEndChange' | 'disabled'
> & { style?: CSSProperties; flashError: boolean };

const INPUT_MAX_WIDTH = 80;

const DateRangePicker: ForwardRefRenderFunction<DateTimeRangeHandle, DateRangePickerProps> = (
  { start, end, onStartChange, onEndChange, disabled, style, flashError },
  ref
) => {
  const startInputRef = useRef<HTMLInputElement>(null);
  const endInputRef = useRef<HTMLInputElement>(null);
  const popupState = usePopupState({ variant: 'popover', popupId: 'dateRangePicker' });
  const popperRef = useRef<HTMLDivElement>(null);

  const [startInput, setStartInput] = useState(formatDate(start));
  const [endInput, setEndInput] = useState(formatDate(end));
  const [startInputError, setStartInputError] = useState(false);
  const [endInputError, setEndInputError] = useState(false);

  const syncStartInput = useCallback((start: DateType) => {
    setStartInput(formatDate(start));
    setStartInputError(false);
  }, []);

  const syncEndInput = useCallback((end: DateType) => {
    setEndInput(formatDate(end));
    setEndInputError(false);
  }, []);

  useEffect(() => {
    if (isValidDate(start)) {
      syncStartInput(start);
    }
  }, [start, syncStartInput]);

  useEffect(() => {
    if (isValidDate(end)) {
      syncEndInput(end);
    }
  }, [end, syncEndInput]);

  useEffect(() => {
    // This is needed because react-calendar will be in error state, if start is null but end is not null.
    if (!isValidDate(start) && isValidDate(end)) {
      onEndChange(null);
      syncEndInput(null);
    }
  }, [start, end, onEndChange, syncEndInput]);

  useImperativeHandle(
    ref,
    () => ({
      clear() {
        onStartChange(null);
        syncStartInput(null);

        onEndChange(null);
        syncEndInput(null);
      },
    }),
    [onStartChange, syncStartInput, onEndChange, syncEndInput]
  );

  return (
    <>
      <div style={{ ...{ display: 'flex', flexDirection: 'row' }, ...style }}>
        <StyledInput
          {...bindInputTriggers(popupState)}
          value={startInput}
          placeholder={DATE_FORMAT}
          onChange={(event) => {
            const input = event.target.value;
            setStartInput(input);
            const [newStart, _] = parseInput({ input, refDate: start, otherDate: end });
            setStartInputError(input ? !isValidDate(newStart) : false);
          }}
          onBlur={(event) => {
            if (formatDate(start) !== startInput) {
              const [newStart, newEnd] = parseInput({ input: startInput, refDate: start, otherDate: end });
              onStartChange(newStart);
              onEndChange(newEnd);
            }
            if (!popperRef.current?.contains(event.relatedTarget)) {
              popupState.close();
            }
          }}
          disabled={disabled}
          flashError={flashError}
          error={startInputError ? t('Invalid date') : null}
          ref={startInputRef}
          style={{ maxWidth: INPUT_MAX_WIDTH }}
        />
        <DashDivider />
        <StyledInput
          {...bindInputTriggers(popupState)}
          ref={endInputRef}
          value={endInput}
          placeholder={DATE_FORMAT}
          onChange={(event) => {
            const input = event.target.value;
            setEndInput(input);
            const [newEnd, _] = parseInput({ input, refDate: end, otherDate: start });
            setEndInputError(input ? !isValidDate(newEnd) : false);
          }}
          onBlur={(event) => {
            if (formatDate(end) !== endInput) {
              const [newEnd, newStart] = parseInput({ input: endInput, refDate: end, otherDate: start });
              onStartChange(newStart);
              onEndChange(newEnd);
            }
            if (!popperRef.current?.contains(event.relatedTarget)) {
              popupState.close();
            }
          }}
          disabled={disabled}
          flashError={flashError}
          error={endInputError ? t('Invalid date') : null}
          style={{ maxWidth: INPUT_MAX_WIDTH }}
        />
      </div>
      <Popper {...bindInputPopper(popupState)} anchorEl={startInputRef.current} ref={popperRef}>
        <ClickAwayListener
          onClickAway={(event) => {
            const target = event.target as Element;
            if (!startInputRef.current?.contains(target) && !endInputRef.current?.contains(target)) {
              popupState.close();
            }
          }}
        >
          <DatePicker
            onChange={([newStart, newEnd]) => {
              onStartChange(newStart);
              onEndChange(newEnd);
              syncStartInput(newStart);
              syncEndInput(newEnd);
              if (newEnd) {
                popupState.close();
              } else {
                endInputRef.current?.focus();
              }
            }}
            startDate={start}
            endDate={end}
            selectsRange
            inline
            // https://stackoverflow.com/a/42764495/21617288
            todayButton={<div tabIndex={-1}>{t('Today')}</div>}
            disabled={disabled}
          />
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default forwardRef(DateRangePicker);
