import { MuiThemeProvider } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles/index';
import { Delete as DeleteIcon, Edit as EditIcon, ImportContacts as ImportContactsIcon } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import { saveAs } from 'file-saver';
import debounce from 'lodash-es/debounce';
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux';

import { bulkUpdateService } from '@yojee/api/bulkUpdateService';
import { ADMIN_ACCESSES, CompanyBizService } from '@yojee/helpers/companyHelper';
import { SENDER_TYPES, TOOLTIP_DISABLED } from '@yojee/helpers/constants';
import { FEATURES_MAP } from '@yojee/helpers/SettingResolver';
import { DialogConfirm, HyperLink, ListManageTemplate, theme, useTable } from '@yojee/ui/base';
import { bulkDeleteRequest, updateBulkSelection } from '@yojee/ui/BulkUpdate/redux/bulkActions';
import BaseSettingComponent from '@yojee/ui/common/BaseSettingComponent/BaseSettingComponent';
import useFeatureTracker from '@yojee/ui/event-tracking/hooks/useFeatureTracker';
import useIsFeatureEnabled from '@yojee/ui/feature-management/hooks/useIsFeatureEnabled';

import * as senderActions from '../../sagas/senders/senderActions';
import { getPrefixSelector } from '../../selectors';
import OrderFieldTemplateDialog from './OrderFieldTemplateDialog';
import SenderDialog from './SenderDialog';

const styles = () => ({
  table: {
    minWidth: 400,
  },
  headerCell: {
    color: '#9ea5a9',
    fontSize: '12px',
    padding: '6px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  cell: {
    color: '#2a3236',
    fontSize: '13px',
    padding: '6px',
  },
  actionUtilities: {
    padding: '5px',
  },
  row: {
    height: '40px',
    '&:hover': {
      backgroundColor: '#f0f8fb',
      cursor: 'pointer',
    },
  },
  actionIcon: {
    fontSize: '16px',
  },
  inputLabel: {
    fontFamily: 'Arial',
    fontSize: '13px',
    color: '#5e737d',
    marginBottom: '6px',
  },
});

const headCells = [
  { id: 'id', numeric: true, label: <Trans>Sender ID</Trans>, width: '10%' },
  { id: 'external_id', numeric: false, label: <Trans>External ID</Trans>, width: '15%' },
  { id: 'nameClick', numeric: false, label: <Trans>Name</Trans>, width: '20%' },
  { id: 'phone', numeric: false, label: <Trans>Phone Number</Trans>, width: '15%' },
  { id: 'email', numeric: false, label: <Trans>Email</Trans>, width: 'auto' },
];

const billingPartyCol = { id: 'billingPartyClick', numeric: false, label: <Trans>Billing Party</Trans>, width: '15%' };

const Senders = (props) => {
  useFeatureTracker('senders', true);
  const dispatch = useDispatch();
  const prefix = useSelector(getPrefixSelector);
  const { t } = useTranslation();

  const {
    data,
    loadingListSenders,
    isDeleteEnabled,
    loadSenderData,
    requestDeleteSender,
    isDeletingSender,
    isCreateEnabled,
    isUpdateEnabled,
    readOnly,
  } = props;
  const company = useSelector((state) => state.company?.companyData);
  const canCreateOrder = CompanyBizService.canAccess(company, ADMIN_ACCESSES.ORDERS_CREATE);

  const showOrderFieldTemplate = useIsFeatureEnabled(FEATURES_MAP.ORDER_FIELD_TEMPLATE);
  const completed = useSelector((state) => !!state.messages.success, shallowEqual);

  const [openSenderDialog, setOpenSenderDialog] = useState(false);
  const [openOrderFieldTemplateDialog, setOpenOrderFieldTemplateDialog] = useState(false);
  const [openBulkDeleteDialog, setOpenBulkDeleteDialog] = useState(false);
  const [bulkDeleting, setBulkDeleting] = useState(false);

  const [currentSender, setCurrentSender] = useState(null);
  const [senderToDelete, setSenderToDelete] = useState(undefined);
  const [createdTimestamp, setCreatedTimestamp] = useState(undefined);

  const bulkActionRows = useSelector((state) => state.bulk.checkedRows);
  const bulkActionRowIds = bulkActionRows.map((r) => r.id);

  const {
    pagination: { current_page, limit_value },
  } = props;
  const [query, changeQuery] = useReducer(
    (state, action) => {
      const { payload } = action;
      switch (action.type) {
        case 'CHANGE_PAGE':
          return { ...state, page: payload.page };
        case 'CHANGE_PAGE_SIZE':
          return { ...state, pageSize: payload.pageSize };
        case 'CHANGE_SEARCH_TERM':
          return { ...state, searchTerm: payload.searchTerm, page: 1 };
        default:
          return state;
      }
    },
    { searchTerm: '', page: current_page, pageSize: limit_value }
  );
  const { searchTerm, page, pageSize } = query;

  const shouldShowBillingParty = useIsFeatureEnabled(FEATURES_MAP.DISPLAY_BILLING_PARTY);

  const setBulkActionRows = (rows) => {
    dispatch(updateBulkSelection(rows));
  };

  const openFeatureDialog = () => {
    window.parent.postMessage({ type: 'OPEN_FEATURES_DIALOG' }, '*');
  };

  const fetchSenders = (q = '', page = 1, page_size = 25) => {
    const params = {
      q,
      page,
      page_size,
      sender_type: SENDER_TYPES.individual,
      sort_by: 'id',
    };

    loadSenderData(params);
  };

  const [refreshAt, setRefreshAt] = useState();
  const [searchText, setSearchText] = useState('');
  const searchInput = {
    placeholder: t('Search'),
    icon: <SearchIcon />,
    onChange: (e) => {
      const value = e.target.value.trim();
      setSearchText(value);
      changeDebouncedQuery(value);
    },
    value: searchText,
  };

  useEffect(() => {
    if (!isDeletingSender && completed) {
      setSenderToDelete(undefined);
    }
  }, [isDeletingSender, completed]);

  useEffect(() => {
    setBulkActionRows([]);
    setSelectedCheckbox([]);
    fetchSenders(searchTerm, page, pageSize);
  }, [searchTerm, page, pageSize, refreshAt]);

  const rows = useMemo(() => {
    return data?.map(
      ({
        id,
        external_id,
        name,
        phone,
        email,
        billing_address,
        billing_party,
        inserted_at,
        organisation_name,
        organisation_user_profiles,
        sender_type,
        settings,
        user_profile_id,
      }) => ({
        id,
        external_id,
        name,
        phone,
        email,
        billing_address,
        billing_party,
        inserted_at,
        organisation_name,
        organisation_user_profiles,
        sender_type,
        settings,
        user_profile_id,
        nameClick: <HyperLink to={`${prefix}/senders/individual/${id}?tab=user`}>{name}</HyperLink>,
        billingPartyClick: (
          <HyperLink to={`${prefix}/senders/individual/${id}?tab=billing`}>
            <Trans>View</Trans>
          </HyperLink>
        ),
      })
    );
  }, [data]);

  const {
    selected,
    setSelected: setSelectedCheckbox,
    isSelected,
    handleClick,
    handleSelectAllClick,
    DEBOUNCE_SEARCH_TIME,
  } = useTable({
    rows,
    data: data,
  });

  const changeDebouncedQuery = useCallback(
    debounce((value) => {
      changeQuery({ type: 'CHANGE_SEARCH_TERM', payload: { searchTerm: value } });
    }, DEBOUNCE_SEARCH_TIME),
    []
  );
  useEffect(() => {
    setBulkActionRows([...data.filter((sender) => selected.includes(sender.id))]);
  }, [selected]);

  const actionBtnsShow = selected?.length > 0;

  const primaryBtns = [
    {
      label: t('Add Sender'),
      color: 'primary',
      onClick: () => {
        if (!canCreateOrder) {
          openFeatureDialog();
          return;
        }
        setCurrentSender();
        setCreatedTimestamp(Date.now());
        setOpenSenderDialog(true);
      },
      disabled: readOnly,
      tooltip: readOnly ? TOOLTIP_DISABLED : '',
    },
  ];

  const actionBtns = [
    ...(isDeleteEnabled
      ? [
          {
            color: 'error',
            label: t('Delete'),
            onClick: () => {
              setOpenBulkDeleteDialog(true);
            },
            disabled: readOnly,
            tooltip: readOnly ? TOOLTIP_DISABLED : '',
          },
        ]
      : []),
    {
      label: t('Download'),
      onClick: () => {
        bulkUpdateService.downloadMasterData({ ids: bulkActionRowIds, type: 'sender' }).then((data) => {
          const url = URL.createObjectURL(new Blob([data], { type: 'data:text/csv;charset=utf-8' }));
          saveAs(url, `individual_sender_data_${bulkActionRowIds.length}.csv`);
        });
      },
      disabled: false,
    },
  ];

  const tableConfig = {
    id: 'YJTableDriverSchedules',
    numSelected: selected.length,
    rowCount: rows.length,
    columns: shouldShowBillingParty ? [...headCells, billingPartyCol] : headCells,
    data: rows,
    showCheckBox: true,
    isSelected: isSelected,
    onRowClick: handleClick,
    onSelectAllClick: handleSelectAllClick,
    rowActions: [
      {
        type: 'template',
        title: t('Template'),
        icon: <ImportContactsIcon color="primary" fontSize="small" />,
        onClick: (row) => {
          if (!canCreateOrder) {
            openFeatureDialog();
            return;
          }
          setCurrentSender(row);
          setOpenOrderFieldTemplateDialog(true);
        },
        conditions: () => showOrderFieldTemplate && !readOnly,
      },
      {
        type: 'edit',
        title: t('Edit'),
        icon: <EditIcon color="primary" fontSize="small" />,
        onClick: (row) => {
          if (!canCreateOrder) {
            openFeatureDialog();
            return;
          }
          setCurrentSender(row);
          setOpenSenderDialog(true);
        },
        conditions: () => isUpdateEnabled && !readOnly,
      },
      {
        type: 'delete',
        title: t('Delete'),
        icon: <DeleteIcon color="primary" fontSize="small" />,
        onClick: (row) => {
          if (!canCreateOrder) {
            openFeatureDialog();
            return;
          }
          setSenderToDelete(row);
        },
        conditions: () => isDeleteEnabled && !readOnly,
      },
    ],
  };

  return (
    <MuiThemeProvider theme={theme}>
      <BaseSettingComponent customContentPadding={{ padding: '0px' }}>
        <ListManageTemplate
          title={<Trans>Individual Senders</Trans>}
          primaryBtns={primaryBtns}
          primaryBtnsDisable={!isCreateEnabled}
          actionBtnsShow={actionBtnsShow}
          actionBtns={actionBtns}
          searchInput={searchInput}
          tableConfig={tableConfig}
          loadingConfig={{ pageLoading: false, tableLoading: loadingListSenders }}
          pagination={{
            pagination: props.pagination,
            'data-cy': 'sender-individual-pagination',
            onChangePage: ({ page }) => changeQuery({ type: 'CHANGE_PAGE', payload: { page: page } }),
            onChangeRowsPerPage: ({ _page, page_size: pageSize }) => {
              changeQuery({ type: 'CHANGE_PAGE_SIZE', payload: { pageSize: pageSize } });
            },
          }}
        />
      </BaseSettingComponent>
      <SenderDialog
        key={'sender-dialog:' + (currentSender && currentSender.id > 0 ? currentSender.id : createdTimestamp)}
        className="sender-dialog-container"
        open={openSenderDialog}
        onClose={() => {
          setOpenSenderDialog(false);
          setCurrentSender();
        }}
        sender={currentSender}
        onSuccess={(_refresh) => setRefreshAt(new Date().toISOString())}
      />
      <DialogConfirm
        title={<Trans i18nKey="deleteDialogTitle" />}
        open={!!senderToDelete}
        isLoading={isDeletingSender}
        onClose={() => setSenderToDelete(undefined)}
        onSave={() => {
          requestDeleteSender(senderToDelete);
          setSelectedCheckbox([]); // we need this line because the fetchSenders was executed in saga
        }}
        dividers="paper"
      >
        <Trans i18nKey="confirmToDeleteItem" />
      </DialogConfirm>
      {openOrderFieldTemplateDialog && (
        <OrderFieldTemplateDialog
          key="sender-order-field-template-dialog:"
          className="sender-dialog-container"
          open={openOrderFieldTemplateDialog}
          sender={currentSender}
          onClose={() => {
            setOpenOrderFieldTemplateDialog(false);
          }}
        />
      )}
      <DialogConfirm
        onClose={() => setOpenBulkDeleteDialog(false)}
        open={openBulkDeleteDialog}
        isLoading={bulkDeleting}
        onSave={() => {
          setBulkDeleting(true);
          dispatch(
            bulkDeleteRequest({
              type: 'sender',
              ids: bulkActionRowIds,
              onSuccess: () => {
                setOpenBulkDeleteDialog(false);
                setBulkDeleting(false);
                setRefreshAt(new Date().toISOString());
              },
              onFail: () => {
                setOpenBulkDeleteDialog(false);
                setRefreshAt(new Date().toISOString());
              },
            })
          );
        }}
        title={<Trans i18nKey="deleteDialogTitle" />}
        dividers="paper"
      >
        <Trans i18nKey="confirmToDeleteItem" />
      </DialogConfirm>
    </MuiThemeProvider>
  );
};

const mapStateToProps = (state) => {
  const isDeleteEnabled = state?.company?.companyData?.display_settings?.admin_access?.['senders.delete'];
  const isCreateEnabled = state?.company?.companyData?.display_settings?.admin_access?.['senders.create'];
  const isUpdateEnabled = state?.company?.companyData?.display_settings?.admin_access?.['senders.update'];
  return {
    loadingListSenders: state.sender.loading.listSenders,
    data: state.sender.senderData,
    pagination: state.sender.pagination,
    isDeletingSender: state.sender.loading.deleteSender,
    isDeleteEnabled,
    isCreateEnabled,
    isUpdateEnabled,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadSenderData: (params) => dispatch(senderActions.listSenders(params)),
    requestUpdateSender: (sender) => dispatch(senderActions.updateSender(sender)),
    requestCreateSender: (sender) => dispatch(senderActions.createSender(sender)),
    requestDeleteSender: (sender) => dispatch(senderActions.deleteSender(sender)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Senders));
