import StringUtils from 'lodash-es/string';

export const setHourAndMinute = (event, date) => {
  const time = event.target.value.split(':');
  const copyDate = new Date(date.getTime());
  copyDate.setHours(time[0]);
  copyDate.setMinutes(time[1]);
  return copyDate;
};
export const getHourAndMinute = (date) => {
  if (date) {
    // '1:1' hours:minutes need to change to '01:01'
    return (
      (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
      ':' +
      (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())
    );
  } else {
    return '00:00';
  }
};

export const getClassNameByRule = (classes, rule, value) => {
  return classes?.[`${rule}${StringUtils.capitalize(value)}`];
};

export const getClassNameByStatus = (className, disabled) => {
  if (disabled) {
    return `${className} rc-disabled`;
  }

  return className;
};
