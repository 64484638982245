import React from 'react';
import { Trans } from 'react-i18next';

import Button from '@yojee/ui/base/furnitures/Button';
import { TooltipReadOnly } from '@yojee/ui/common';

const NextBtnUI = ({ goNext, isDisabled, readOnly, ...restProps }) => {
  return (
    <TooltipReadOnly readOnly={readOnly}>
      <Button
        variant="contained"
        size="small"
        data-cy="next-button"
        color="primary"
        onClick={goNext}
        disabled={isDisabled || readOnly}
        {...restProps}
      >
        <Trans>Next</Trans>
      </Button>
    </TooltipReadOnly>
  );
};

export default NextBtnUI;
