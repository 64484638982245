import React, { useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import useArray from '@yojee/helpers/hooks/useArray';
import { getOrderLegsSelector } from '@yojee/ui/new-order-booking/selectors';

import { SchemaHelperContext } from '../../../../helpers/Contexts';
import {
  addUniqueIDToLegAndStep,
  getDefaultLeg,
  getDefaultLegsFromTemplateSettings,
} from '../../../../helpers/formHelpers';
import { useIsEditOrder } from '../../../../helpers/hooks';
import { ADD_LEG_ACTION_TYPE } from '../../constants';
import { BookingInfoSectionContext } from '../../Contexts';
import LegsSectionUI from './LegsSectionUI';

const LegsSection = () => {
  const { bookingInfoSectionIndex } = useContext(BookingInfoSectionContext);
  const legsData = useSelector((state) => getOrderLegsSelector(state, bookingInfoSectionIndex));
  const templateSettings = useContext(SchemaHelperContext).templateSettings;
  const isEditOrder = useIsEditOrder();
  const [searchParams] = useSearchParams();
  const isDuplicate = searchParams.get('duplicate');

  const legsDataWithUniqueID = useMemo(
    () =>
      addUniqueIDToLegAndStep(
        isEditOrder || isDuplicate
          ? legsData?.map((leg) => ({ steps: leg })) || []
          : getDefaultLegsFromTemplateSettings(templateSettings)
      ),
    [isEditOrder, isDuplicate, legsData, templateSettings]
  );

  const { value: legs, add, addInFront, addAt, removeIndex } = useArray(legsDataWithUniqueID);

  const addNewLeg = useCallback(
    (steps, actionType, actParams = {}) => {
      if (!actionType) return;

      // Get new leg
      let newLeg = steps ? { steps } : getDefaultLeg(templateSettings.task_sequence);
      newLeg = addUniqueIDToLegAndStep([newLeg])[0];

      // Do action base on action type
      switch (actionType) {
        case ADD_LEG_ACTION_TYPE.ADD_IN_FRONT:
          addInFront(newLeg);
          break;

        case ADD_LEG_ACTION_TYPE.SPLIT:
        case ADD_LEG_ACTION_TYPE.DUPLICATE:
          addAt(newLeg, actParams.start);
          break;

        case ADD_LEG_ACTION_TYPE.ADD_AT_THE_END:
          add(newLeg);
          break;

        default:
          return;
      }
    },
    [add, addInFront, addAt, templateSettings]
  );

  return <LegsSectionUI legs={legs} removeIndex={removeIndex} addNewLeg={addNewLeg} />;
};

export default LegsSection;
