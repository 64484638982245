import './style.scss';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useService from '@yojee/helpers/hooks/useService';

import {
  recalculateStepsTimeBaseOnTemplate,
  updateSchemaForOrderFieldTemplate,
  updateState,
} from '../../../saga/actions';
import { isOrderFromTransferSelector } from '../../../selectors';
import serviceLocator from '../../../serviceLocator';
import { useIsEditOrder, useIsInSenderDefaultValuesPage } from '../../helpers/hooks';
import OrderTemplateUI from './OrderTemplateUI';
import useGetDisplayTemplates from './useGetDisplayTemplates';

const OrderTemplateSelect = () => {
  const dispatch = useDispatch();
  const isEditOrder = useIsEditOrder();
  const isOrderFromTransfer = useSelector(isOrderFromTransferSelector);
  const [templateId, setTemplateId] = useState(null);
  const isInSenderDefaultValuesPage = useIsInSenderDefaultValuesPage();
  const displayTemplates = useGetDisplayTemplates({ setTemplateId });

  const isOrderTemplateSelectionDisabled = isEditOrder || isOrderFromTransfer;

  const { execute: getTemplateDetail, status: getTemplateDetailStatus } = useService(
    serviceLocator.templateApiV4.getPublicCompanyTemplateDetail,
    {
      storeKey: 'templateDetail',
      updateStoreAction: isInSenderDefaultValuesPage ? updateSchemaForOrderFieldTemplate : updateState,
      lazy: true,
    }
  );

  useEffect(() => {
    if (templateId) {
      getTemplateDetail(templateId);
    }
  }, [getTemplateDetail, templateId]);

  useEffect(() => {
    // We need recalculate from_time, to_time in steps because
    // in case template don't have timezone, we don't get timezone
    // in API, we get current browser timezone
    if (isEditOrder && getTemplateDetailStatus === 'success') {
      dispatch(recalculateStepsTimeBaseOnTemplate());
    }
  }, [isEditOrder, getTemplateDetailStatus, dispatch]);

  const handleChange = (e) => {
    setTemplateId(e.target.value);
  };

  if (isInSenderDefaultValuesPage) {
    return null;
  }

  return (
    <OrderTemplateUI
      templateId={templateId}
      disabled={isOrderTemplateSelectionDisabled}
      handleChange={handleChange}
      displayTemplates={displayTemplates}
    />
  );
};

export default React.memo(OrderTemplateSelect);
