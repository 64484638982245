import { MuiThemeProvider } from '@material-ui/core/styles';
import { Trans, useTranslation } from 'react-i18next';

import { DialogConfirm, theme } from '@yojee/ui/base';
import { TRACKED_EVENT_NAME, TRACKED_FEATURE } from '@yojee/ui/event-tracking';
import useFeatureTracker from '@yojee/ui/event-tracking/hooks/useFeatureTracker';

type Props = {
  onConfirm: () => void;
  onClose: () => void;
};

const ChangeServiceTypeConfirmDialog: React.FC<Props> = ({ onConfirm, onClose }) => {
  const { t } = useTranslation();
  const { sendEvent } = useFeatureTracker(TRACKED_FEATURE.BOOKING);

  const handleConfirmAndCloseDialog = () => {
    sendEvent(TRACKED_EVENT_NAME.BOOKING_CHANGE_SERVICE_TYPE);
    onConfirm();
    onClose();
  };

  return (
    <MuiThemeProvider theme={theme}>
      <DialogConfirm
        dividers="paper"
        open={true}
        title={<Trans i18nKey="confirmToChangeServiceTypeDialogTitle" />}
        onClose={onClose}
        secondaryBtnText={t('No')}
        primaryBtnText={t('Yes')}
        primaryBtnColor="primary"
        onSave={handleConfirmAndCloseDialog}
      >
        <Trans i18nKey="confirmToChangeServiceTypeDialogBody" />
      </DialogConfirm>
    </MuiThemeProvider>
  );
};

export default ChangeServiceTypeConfirmDialog;
