import Collapse from '@material-ui/core/Collapse';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import { AutoForm } from 'uniforms-material';

import CustomAutoField from '../../../../../CustomFields/CustomAutoField';
import HandleSyncFormStatusForAutoForm from '../../../../../CustomFields/HandleSyncFormStatusForAutoForm';
import OriginDestinationTag from './OriginDestinationTag';
import StepHeader from './StepHeader';

const StepSectionUI = ({
  bridge,
  defaultModel,
  onStepFieldChange,
  isReadOnlyLegSection,
  setFormRef,
  stepIndex,
  legIndex,
  formOpened,
  setFormOpened,
  formKeyPath,
  isShowFormLoadingStatus,
  subFields,
  taskStatisticsOfStep,
  stepProperty,
  onFormChange,
}) => {
  return (
    <section data-cy="step-section">
      <AutoForm
        className={`form-body-section new-booking-step-form ${formOpened ? '' : 'closed'}`}
        autoField={CustomAutoField}
        ref={setFormRef}
        schema={bridge}
        model={defaultModel}
        onChange={onStepFieldChange}
        showInlineError={true}
        disabled={isReadOnlyLegSection}
      >
        <StepHeader
          bridge={bridge}
          formOpened={formOpened}
          formKeyPath={formKeyPath}
          setFormOpened={setFormOpened}
          taskStatisticsOfStep={taskStatisticsOfStep}
        />

        <Collapse in={formOpened} timeout="auto" className="step-wrapper-fields">
          {isShowFormLoadingStatus ? (
            Array(9)
              .fill(1)
              .map((_, index) => <Skeleton key={index} height={100} />)
          ) : (
            <>
              {subFields.map((fieldName) => (
                <CustomAutoField
                  formKeyPath={formKeyPath}
                  legIndex={legIndex}
                  stepIndex={stepIndex}
                  key={fieldName}
                  name={fieldName}
                />
              ))}
              <HandleSyncFormStatusForAutoForm formKeyPath={formKeyPath} onFormChange={onFormChange} />
              {stepProperty && <OriginDestinationTag stepProperty={stepProperty} />}
            </>
          )}
        </Collapse>
      </AutoForm>
    </section>
  );
};

export default StepSectionUI;
