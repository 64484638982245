export const COMPONENT_HEIGHT = 35;
export const DATE_FORMAT = 'DD/MM/YYYY';
export const TIME_FORMAT = 'HH:mm';

export const DEFAULT_TIME_VALUES = {
  second: 0,
  millisecond: 0,
};
export const DEFAULT_DATE_VALUES = {
  hour: 0,
  minute: 0,
  ...DEFAULT_TIME_VALUES,
};

export const TIME_MAP = {
  // numbers
  one: '13:00',
  two: '14:00',
  three: '15:00',
  four: '16:00',
  five: '17:00',
  six: '18:00',
  seven: '19:00',
  eight: '08:00',
  nine: '09:00',
  ten: '10:00',
  eleven: '11:00',
  twelve: '12:00',
  // words
  start: '09:00',
  open: '09:00',
  sob: '09:00',
  breakfast: '09:00',
  noon: '12:00',
  siesta: '12:00',
  pm: '12:00',
  lunch: '12:00',
  beer: '17:00',
  'happy hour': '17:00',
  close: '18:00',
  cob: '18:00',
};
