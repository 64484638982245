import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';
import React from 'react';

// custom libraries
import Checkbox from '@yojee/ui/base/furnitures/Checkbox';

import TooltipWrapper from './TooltipWrapper';
import { slugify } from './utils';

const useStyles = makeStyles((theme) => ({
  tableRowContainer: {
    display: 'flex',
    width: '100%',
    borderBottom: `1px solid ${theme.palette?.black?.['B4']}`,
    height: '48px',
  },
  tableFirstCol: {
    minWidth: '284px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
  },
  tableRestCol: {
    flexGrow: 1,
    flexBasis: 0,
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  editIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(40px, -12px)',
  },
  checkboxStyle: {
    margin: 0,
    padding: 0,
  },
  checkboxContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const TableCell = (props) => {
  const {
    checked,
    onClick,
    secondaryBtn = null,
    secondaryBtnShow = false,
    data = {},
    onChange = () => {},
    disabled = false,
    tooltipConfigs = null,
    secondaryTooltipConfigs = null,
    dataCy,
  } = props;
  const classes = useStyles(props);

  const handleOnChange = () => {
    onChange({ ...checked, ...data });
  };

  return (
    <div className={classes.tableRestCol}>
      <div className={classes.checkboxContainer}>
        <TooltipWrapper {...tooltipConfigs}>
          <div style={{ margin: 0, padding: 0 }}>
            <Checkbox
              className={classes.checkboxStyle}
              checked={checked}
              disabled={disabled}
              onChange={handleOnChange}
              onClick={onClick}
              data-cy={`${dataCy}-checkbox`}
            />
          </div>
        </TooltipWrapper>
        {secondaryBtnShow && (
          <TooltipWrapper {...secondaryTooltipConfigs}>
            <IconButton onClick={secondaryBtn} className={classes.editIcon} size="small" data-cy={`${dataCy}-edit`}>
              <EditIcon fontSize="small" />
            </IconButton>
          </TooltipWrapper>
        )}
      </div>
    </div>
  );
};

const TableRow = (props) => {
  const { label, checkboxConfigs, onClick } = props;
  const classes = useStyles(props);

  return (
    <div className={clsx(classes.tableRowContainer)}>
      <div className={classes.tableFirstCol}>{label}</div>
      {checkboxConfigs?.map((cellProps) => (
        <TableCell key={`table-checkbox-cell-${slugify(cellProps.data.action)}`} onClick={onClick} {...cellProps} />
      ))}
    </div>
  );
};

export default TableRow;
