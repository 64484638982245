import { ValuesOf } from '@yojee/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapObject = <T extends Record<string, any>, TparsedValue>(
  data: T,
  parseValue: (key: keyof T, value: ValuesOf<T>) => TparsedValue
): Record<keyof T, TparsedValue> => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    acc[key as keyof T] = parseValue(key, value);

    return acc;
  }, {} as Record<keyof T, TparsedValue>);
};
