import { DefaultTheme, makeStyles } from '@material-ui/styles';
import { Palette } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface BaseTheme extends DefaultTheme {
  palette: Palette & { [key: string]: { [color: string]: string } };
  spacing: (arg: number, arg1?: number) => string;
}

const useStyles = makeStyles((theme: BaseTheme) => ({
  emptyLegContainer: {
    width: 'max-content',
    height: 'max-content',
    padding: theme.spacing(0.4, 1),
    borderRadius: theme.spacing(0.5),
    border: `solid 1px #ccc`,
    backgroundColor: '#fffde4',
    boxSizing: 'content-box',
    lineHeight: 'normal',
  },
  emptyLegLabel: {
    fontSize: theme.spacing(1.5),
    textTransform: 'uppercase',
    color: theme.palette.common.black,
    fontFamily: 'Roboto',
  },
}));

const EmptyLegLabel = ({
  className,
  style,
}: {
  className?: string;
  style?: React.HtmlHTMLAttributes<HTMLDivElement>['style'];
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <span data-cy="empty-leg-label" className={clsx(classes.emptyLegContainer, className)} style={style}>
      <label className={classes.emptyLegLabel}>{t('Empty')}</label>
    </span>
  );
};

export default EmptyLegLabel;
