export class CompanyBizService {
  static canAccess(company, access) {
    if (!company) return false;
    const _displaySettings = company['display_settings'];

    if (!access || !_displaySettings || !_displaySettings['admin_access']) {
      return false;
    }

    if (typeof access === 'string') {
      return !!_displaySettings['admin_access'][access];
    } else if (typeof access === 'object') {
      if (access['required']) {
        return access['required'].every((item) => !!_displaySettings['admin_access'][item]);
      } else if (access['oneOf']) {
        return access['oneOf'].some((item) => !!_displaySettings['admin_access'][item]);
      }
    }

    return false;
  }

  static getText(company, keyName, defaultValue = null) {
    if (!company) return defaultValue;
    const _displaySettings = company['display_settings'];
    if (!keyName || !_displaySettings || !_displaySettings.admin_copy) {
      return null;
    }
    return _displaySettings.admin_copy[keyName] ? _displaySettings.admin_copy[keyName] : defaultValue;
  }

  static getFeatureMode(company, categoryFeatureName) {
    if (categoryFeatureName) {
      const _featureMatrix =
        company.settings && company.settings['feature_matrix'] && company.settings['feature_matrix']['properties'];
      return _featureMatrix && _featureMatrix[categoryFeatureName]
        ? _featureMatrix[categoryFeatureName].toLowerCase()
        : 'enabled';
    }
    return 'enabled';
  }
}

export const companyBizService = new CompanyBizService();

export const COMPANY_DISPLAY_SETTINGS = {
  ORDERS_CCU_TITLE: 'orders.show.ccu_name_title',
  ORDERS_EXTERNAL_ID_TITLE: 'orders.show.external_id_title',
  ORDER_ID_TITLE: 'items.show.order_id_title',
  ITEMS_EXTERNAL_CUSTOMER_ID_TITLE: 'items.show.external_customer_id_title',
  ITEMS_EXTERNAL_CUSTOMER_ID2_TITLE: 'items.show.external_customer_id2_title',
  ITEMS_EXTERNAL_CUSTOMER_ID3_TITLE: 'items.show.external_customer_id3_title',
  ITEMS_INFO_TITLE: 'items.show.info_title',
  DEFAULT_UNIT: 'default.unit',
};

export const TASK_AVAILABLE_TYPES = {
  PICK_UP: 'pickup',
  DROP_OFF: 'dropoff',
};

export const ADMIN_ACCESSES = {
  INVOICES_SHOW: 'invoices.show',

  ORDERS: 'orders',
  ORDERS_INDEX: 'orders.index',
  ORDERS_CREATE_BATCH: 'batches',
  ORDERS_CREATE: 'orders.create',
  ORDERS_UPDATE: 'orders.update',
  ORDERS_PRICE_SHOW: 'orders.show.price',
  ORDERS_PRICE_UPDATE: 'orders.update.price',
  ORDERS_EXTERNAL_ID_SHOW: 'orders.show.external_id',
  ORDERS_ID_SHOW: 'orders.show.order_id',
  ORDERS_SENDER_SHOW: 'orders.show.sender',
  ORDERS_EXTERNAL_ID_UPDATE: 'orders.update.external_id',
  ORDERS_LOAD_QUANTITY_SHOW: 'orders.show.load_quantity',
  ORDERS_CONTAINER_NUMBER_SHOW: 'orders.show.container_no',
  ORDERS_CONTAINER_NUMBER_UPDATE: 'orders.update.container_no',

  ORDER_ITEMS: 'order_items',
  ORDER_ITEMS_INDEX: 'order_items.index',

  PARTNERS_TRANSFER: 'partners.transfer',
};

export const FEATURE_MATRIX = {
  TRANSFER: {
    TRANSFER_TO_PARTNER: 'transfer.transfer_to_partner',
    ADD_PARTNER: 'transfer.add_partner',
  },
};
