import PropTypes from 'prop-types';
import React from 'react';

import TableHeaderRow from './components/TableHeaderRow';
import TableRow from './components/TableRow';

const TableCheckbox = ({ headerConfigs, bodyConfigs, onClick }) => {
  return (
    <div style={{ width: '100%' }}>
      <TableHeaderRow checkboxConfigs={headerConfigs} onClick={onClick} />

      {bodyConfigs?.map(({ label, checkboxConfigs, dataCy }, index) => (
        <TableRow
          key={`table-checkbox-row-${index}`}
          label={label}
          checkboxConfigs={checkboxConfigs}
          onClick={onClick}
          dataCy={dataCy}
        />
      ))}
    </div>
  );
};

TableCheckbox.defaultProps = {
  onClick: () => {},
};

TableCheckbox.propsTypes = {
  headerConfigs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      haveCheckbox: PropTypes.bool,
      onChange: PropTypes.func,
      checked: PropTypes.bool,
      disabled: PropTypes.bool,
      data: PropTypes.object, // store any additional data here
      tooltipConfigs: PropTypes.object, // use material UI tooltip props
      secondaryTooltipConfigs: PropTypes.object, // use material UI tooltip props,
      headerTooltipConfigs: PropTypes.object, // use material UI tooltip props
    })
  ),
  bodyConfigs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      haveCheckbox: PropTypes.bool,
      onChange: PropTypes.func,
      checked: PropTypes.bool,
      disabled: PropTypes.bool,
      data: PropTypes.object, // store any additional data here
      tooltipConfigs: PropTypes.object, // use material UI tooltip props
      secondaryTooltipConfigs: PropTypes.object, // use material UI tooltip props,
    })
  ),
};

export default TableCheckbox;
