import { Box, Grid, makeStyles } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { Trans } from 'react-i18next';

import { isValidPhoneNumber } from '@yojee/auth/utils';
import { AddressCompetionRadiusUnit, DEFAULT_ACR } from '@yojee/helpers/addressCompletionRadiusHelpers';
import countryCodes from '@yojee/helpers/countryCodes';
import { scrollToElement } from '@yojee/helpers/scrollToElement';
import { getTimezoneOffsetStr } from '@yojee/helpers/workerShedule/scheduleHelper';
import { Text, Title, Tooltip } from '@yojee/ui/base';
import { QuickForm, QuickFormRef } from '@yojee/ui/QuickForm';

import { AddressFormData, SenderLinkType, useAddressFormSchema } from './addressFormSchema';

type Cordinates = {
  lat: number;
  lng: number;
};

const defaultData: AddressFormData = {
  external_id: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  postal_code: '',
  country: '',
  contact_name: '',
  contact_company: '',
  contact_phone: '',
  contact_email: '',
  open_from: '',
  closed_by: '',
  tags: [],
  type: '',
  follow_sender_address_completion_radius: true,
  address_completion_radius_unit: DEFAULT_ACR.unit,
  address_completion_radius: DEFAULT_ACR.value,
  sender_link_type: SenderLinkType.All,
  link_to_sender_ids: [],
};

export type AddressApiData = {
  id?: number;
  sender_id?: number;
  external_id: string | null;
  address1: string;
  address2: string | null;
  city: string | null;
  state: string | null;
  country: string;
  postal_code: string | null;
  tags?: string[];
  contact_name: string | null;
  contact_company: string | null;
  contact_phone: string | null;
  contact_email: string | null;
  type: string | null;
  center?: Cordinates;
  location?: Cordinates;
  follow_sender_address_completion_radius: boolean;
  address_completion_radius_unit: AddressCompetionRadiusUnit | null;
  address_completion_radius: number | null;
  sender_link_type: SenderLinkType;
  link_to_sender_ids: number[];
  metadata?: {
    closed_by: string;
    open_from: string;
    timezone: string;
    timezone_offset: string;
  };
};

export type AddressFromBackend = Omit<AddressApiData, 'tags' | 'link_to_sender_ids'> & {
  tags?: Array<{ name: string }>;
  linked_senders: OrganisationSender[];
};

type OrganisationSender = {
  id: number;
  organisation?: {
    id: number;
    name: string;
    phone: string;
  };
};

const getDefaultData = (addressItem?: AddressFromBackend): AddressFormData => {
  if (addressItem) {
    return {
      external_id: addressItem.external_id || '',
      address1: addressItem.address1 || '',
      address2: addressItem.address2 || '',
      city: addressItem.city || '',
      state: addressItem.state || '',
      country: addressItem.country,
      contact_name: addressItem.contact_name || '',
      contact_company: addressItem.contact_company || '',
      contact_email: addressItem.contact_email || '',
      contact_phone: addressItem.contact_phone || '',
      type: addressItem.type || '',
      postal_code: addressItem.postal_code || '',
      closed_by: addressItem.metadata?.closed_by || '',
      open_from: addressItem.metadata?.open_from || '',
      tags: (addressItem.tags ?? []).map(({ name }) => ({ value: name, label: name })),
      follow_sender_address_completion_radius:
        addressItem.follow_sender_address_completion_radius ?? defaultData.follow_sender_address_completion_radius,
      address_completion_radius: addressItem.address_completion_radius ?? defaultData.address_completion_radius,
      address_completion_radius_unit:
        addressItem.address_completion_radius_unit ?? defaultData.address_completion_radius_unit,
      sender_link_type: addressItem.sender_link_type ?? defaultData.sender_link_type,
      link_to_sender_ids: (addressItem.linked_senders || []).map((sender) => sender.id),
    };
  }
  return defaultData;
};

type Props = {
  addressItem?: AddressFromBackend;
  timezone: string;
  onSave: (data: AddressApiData, isTouched: boolean) => void;
};

const useStyles = makeStyles((theme) => ({
  addressCompletionRadiusLabelText: {
    color: theme.palette.black.B60,
  },
  labelContainer: {
    marginBottom: 0,
  },
  completionRadiusField: {
    position: 'relative',
    '& > svg': {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(2.2),
      zIndex: 1,
    },
    '& input': {
      paddingRight: theme.spacing(4),
    },
  },
}));

export const AddressForm = ({ onSave, timezone, addressItem }: Props) => {
  const classes = useStyles();
  const [location, setLocation] = useState<Cordinates | null>(addressItem?.location || null);
  const [isTouched, setIsTouched] = useState(false);
  const formRef = useRef<QuickFormRef<AddressFormData>>(null);
  const formSchema = useAddressFormSchema({
    defaultAddress: {
      center: addressItem?.center || null,
      address1: addressItem?.address1 || '',
      country: '',
      state: '',
      city: '',
      postalCode: '',
    },
    onAddressChange: (addressData) => {
      setLocation(addressData.center);
      formRef.current?.setFieldValue(
        {
          address1: addressData.address1,
          country: addressData.country,
          state: addressData.state,
          city: addressData.city,
          postal_code: addressData.postalCode,
        },
        false
      );
    },
  });

  return (
    <QuickForm
      refForm={formRef}
      formSchema={formSchema}
      defaultData={getDefaultData(addressItem)}
      onFieldChange={(formData, field) => {
        setIsTouched(true);

        if (field === 'follow_sender_address_completion_radius' && !formData.follow_sender_address_completion_radius) {
          const acrInput = document.querySelector<HTMLDivElement>('[data-cy="field-address_completion_radius"]');
          acrInput && scrollToElement(acrInput);
        }

        if (field === 'sender_link_type' && formData.sender_link_type === SenderLinkType.Specific) {
          const senderLinksInput = document.querySelector<HTMLDivElement>('[data-cy="field-link_to_sender_ids"]');
          senderLinksInput && scrollToElement(senderLinksInput);
        }
      }}
      onSubmit={({
        city,
        state,
        country,
        postal_code,
        contact_name,
        contact_email,
        contact_company,
        closed_by,
        open_from,
        contact_phone,
        external_id,
        address1,
        address2,
        type,
        tags: formTags,
        follow_sender_address_completion_radius,
        address_completion_radius,
        address_completion_radius_unit,
        sender_link_type,
        link_to_sender_ids,
      }) => {
        const addressTimezone = addressItem?.metadata?.timezone || timezone;
        const shouldUseArc = !follow_sender_address_completion_radius;

        onSave(
          {
            sender_id: addressItem?.sender_id,
            id: addressItem?.id,
            external_id: external_id,
            address1: address1.trim(),
            address2: address2,
            location: location ?? undefined,
            center: location ?? undefined,
            city: city || null,
            state: state || null,
            postal_code: postal_code || null,
            country,
            contact_name: contact_name || null,
            contact_company: contact_company || null,
            contact_phone: contact_phone && isValidPhoneNumber(contact_phone) ? contact_phone : null,
            contact_email: contact_email || null,
            type: type || null,
            follow_sender_address_completion_radius,
            address_completion_radius: shouldUseArc ? address_completion_radius : null,
            address_completion_radius_unit: shouldUseArc ? address_completion_radius_unit : null,
            sender_link_type: sender_link_type || SenderLinkType.All,
            link_to_sender_ids: link_to_sender_ids || [],
            metadata: {
              closed_by,
              open_from,
              timezone: addressTimezone,
              timezone_offset: getTimezoneOffsetStr(addressTimezone),
            },
            tags: formTags.map((tag) => tag.value as string),
          },
          isTouched
        );
      }}
      render={({ fields, formData }) => (
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Title variant="h5">
              <Trans>Address details</Trans>
            </Title>
          </Grid>
          <Grid item md={6} xs={12}>
            {fields.external_id.render()}
          </Grid>
          <Box width="100%" />
          <Grid item md={6} xs={12}>
            {fields.address1.render()}
          </Grid>
          <Grid item md={6} xs={12}>
            {fields.address2.render()}
          </Grid>
          <Grid item md={3} xs={12}>
            {fields.city.render()}
          </Grid>
          <Grid item md={3} xs={12}>
            {fields.state.render()}
          </Grid>
          <Grid item md={3} xs={12}>
            {fields.postal_code.render()}
          </Grid>
          <Grid item md={3} xs={12}>
            {fields.country.render()}
          </Grid>
          <Grid item md={12} xs={12}>
            <Title variant="h5">
              <Trans>Contact details</Trans>
            </Title>
          </Grid>
          <Grid item md={6} xs={12}>
            {fields.contact_name.render()}
          </Grid>
          <Grid item md={6} xs={12}>
            {fields.contact_company.render()}
          </Grid>
          <Grid item md={6} xs={12}>
            {fields.contact_phone.render({
              fieldProps: {
                defaultCountry: countryCodes[formData.country] || '',
              },
            })}
          </Grid>
          <Grid item md={6} xs={12}>
            {fields.contact_email.render()}
          </Grid>
          <Grid item md={12} xs={12}>
            <Title variant="h5">
              <Trans>Additional info</Trans>
            </Title>
          </Grid>
          <Grid item md={6} xs={12}>
            {fields.open_from.render()}
          </Grid>
          <Grid item md={6} xs={12}>
            {fields.closed_by.render()}
          </Grid>
          <Grid item md={6} xs={12}>
            {fields.tags.render()}
          </Grid>
          <Grid item md={6} xs={12}>
            {fields.type.render()}
          </Grid>
          <Grid item md={12} xs={12}>
            <Title variant="h5">
              <Trans>Geofence settings</Trans>
            </Title>
            <p className={classes.labelContainer}>
              <Text className={classes.addressCompletionRadiusLabelText}>
                <b>
                  <Trans>Address Completion Radius</Trans>
                </b>
              </Text>
            </p>
          </Grid>
          <Grid item md={12} xs={12}>
            {fields.follow_sender_address_completion_radius.render()}
          </Grid>
          {!formData.follow_sender_address_completion_radius && (
            <>
              <Grid item md={3} xs={5}>
                <div className={classes.completionRadiusField}>
                  <Tooltip
                    color="primary"
                    placement="top"
                    title={<Trans>This value will override the value set at sender level.</Trans>}
                  />
                  {fields.address_completion_radius.render()}
                </div>
              </Grid>
              <Grid item md={3} xs={5}>
                {fields.address_completion_radius_unit.render()}
              </Grid>
            </>
          )}

          <Grid item md={12} xs={12}>
            <Title variant="h5">
              <Trans>Link to Senders</Trans>
            </Title>
          </Grid>
          <Grid item md={6} xs={12}>
            {fields.sender_link_type.render()}
          </Grid>

          {formData.sender_link_type === SenderLinkType.Specific && (
            <Grid item md={12} xs={12}>
              {fields.link_to_sender_ids.render()}
            </Grid>
          )}

          <button type="submit" hidden />
        </Grid>
      )}
    />
  );
};
