import { withStyles } from '@material-ui/core/styles';
import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { connect, useSelector } from 'react-redux';

import { SENDER_TYPES } from '@yojee/helpers/constants';
import Dialog from '@yojee/ui/base/corners/Dialog';
import Tab from '@yojee/ui/base/furnitures/Tab';
import { BATCH_UPLOAD_ITEM_TYPES } from '@yojee/ui/onboarding/helpers/batchUploadHelper';

import * as senderActions from '../../sagas/senders/senderActions';
import BatchUploadContent from '../BatchUpload/BatchUploadContent';
import useForm, { FormContent, SENDER_FORM } from './useForm';

const styles = (theme) => ({
  tabPanel: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.25),
  },
});

const SenderDialog = (props) => {
  const sender = props.sender;
  const [loadingAction, setLoadingAction] = useState(false);
  const [tab, setTab] = useState(0);
  const [dataCy, setDataCy] = useState('sender-individual-save-button');
  const successMessage = useSelector((state) => state.messages.success);
  const batchComponentRef = useRef();
  const type = 'sender';
  const { form, error, handleFieldChange, setForm, isValid } = useForm(type);

  const handleSave = () => {
    if (tab === 0) onSave();
    else batchComponentRef.current.saveRef();
  };

  const handleChange = (_event, newValue) => {
    setTab(newValue);
    if (newValue === 0) setDataCy('sender-individual-save-button');
    else setDataCy('batch-upload');
  };

  useEffect(() => {
    if (successMessage) {
      close();
    }
  }, [successMessage]);

  useEffect(() => {
    if (props.loadingAction) {
      setLoadingAction(true);
    } else if (loadingAction) {
      setLoadingAction(false);
    }
  }, [props.loadingAction]);

  const close = () => {
    setForm(cloneDeep(SENDER_FORM));
    props.onClose();
  };

  const onSave = () => {
    if (!isValid() || loadingAction) return false;
    const data = {
      id: sender ? sender.id : 0,
      name: form.name.value,
      phone: form.phone.value,
      email: form.email.value,
      billing_address: form.address.value,
      external_id: form.external_id.value?.trim?.(),
      sender_type: SENDER_TYPES.individual,
    };
    if (data.id) {
      props.requestUpdateSender(data);
    } else {
      props.requestCreateSender(data);
    }
  };

  useEffect(() => {
    const _newForm = cloneDeep(SENDER_FORM);

    if (sender && sender.id) {
      _newForm.name.value = sender.name;
      _newForm.phone.value = sender.phone;
      _newForm.email.value = sender.email;
      _newForm.address.value = sender.billing_address;
      _newForm.external_id.value = sender.external_id;
      setForm(_newForm);
    }
  }, [sender]);

  const isEditMode = sender && sender.id;
  return (
    <Dialog
      onClose={close}
      aria-labelledby="simple-dialog-title"
      open={props.open}
      onSave={handleSave}
      maxWidth={tab === 0 ? 'sm' : 'md'}
      dividers="paper"
      haveTopDivider={!isEditMode}
      isLoading={loadingAction}
      title={
        <>
          <div>{isEditMode ? <Trans i18nKey="Edit Sender" /> : <Trans i18nKey="Create Sender" />}</div>
          {!isEditMode && (
            <div>
              <Tab.Tabs noBorder={true} value={tab} onChange={handleChange}>
                <Tab.Tab label={<Trans>Create New</Trans>} />
                <Tab.Tab label={<Trans>Batch Upload</Trans>} />
              </Tab.Tabs>
            </div>
          )}
        </>
      }
      dataCy={dataCy}
    >
      <div className={props.classes?.tabPanel}>
        {isEditMode ? (
          <FormContent type={type} form={form} error={error} handleFieldChange={handleFieldChange} />
        ) : (
          <>
            <Tab.TabPanel value={tab} index={0}>
              <FormContent type={type} form={form} error={error} handleFieldChange={handleFieldChange} />
            </Tab.TabPanel>
            <Tab.TabPanel value={tab} index={1}>
              <BatchUploadContent
                ref={batchComponentRef}
                onSuccess={() => {
                  props.onSuccess?.(true);
                  props.onClose();
                }}
                uploadItemType={BATCH_UPLOAD_ITEM_TYPES.SENDER}
              />
            </Tab.TabPanel>
          </>
        )}
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.sender.errorMessage,
    loadingAction: state.sender.loading.createSender || state.sender.loading.updateSender,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    requestCreateSender: (sender) => dispatch(senderActions.createSender(sender)),
    requestUpdateSender: (sender) => dispatch(senderActions.updateSender(sender)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SenderDialog));
