import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback } from 'react';

import { getItemPODUrl } from '@yojee/helpers/orders-helper';

import icon from './icons-custom-pod.png';

const useStyles = makeStyles(() => ({
  downloadPOD: {
    height: '40px',
    marginRight: '10px',
    cursor: 'pointer',
  },
}));

const DownloadPod = ({ item }) => {
  const classes = useStyles();

  const downloadPODFile = useCallback(() => {
    const url = getItemPODUrl(item?.tracking_number, 'pdf');
    window.open(url, '_blank');
  }, [item?.tracking_number]);

  return (
    item?.status === 'completed' && (
      <img onClick={downloadPODFile} className={classes.downloadPOD} src={icon} alt="Download POD" />
    )
  );
};

export default DownloadPod;
