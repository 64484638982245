import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DEBOUNCE_TIME_GOOGLE_API } from '@yojee/helpers/constants';
import useDebounce from '@yojee/helpers/hooks/useDebounce';
import { getOrderSenderId } from '@yojee/ui/new-order-booking/selectors';

import { searchAddress } from '../../../../saga/actions';
import BaseAddressSelectField from '../BaseAddressSelectField';

function AddressSelectField(props) {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebounce(inputValue, DEBOUNCE_TIME_GOOGLE_API);
  const addressBooksItems = useSelector((state) => state.orderBooking.addressSearch.addressBookItems);
  const googleAddressItems = useSelector((state) => state.orderBooking.addressSearch.googleAddressItems);
  const senderId = useSelector(getOrderSenderId);
  const senderLinkType = senderId ? null : 'all';
  const options = [...googleAddressItems, ...(addressBooksItems ?? [])];

  useEffect(() => {
    if (debouncedInputValue) {
      dispatch(searchAddress({ searchKey: debouncedInputValue, senderLinkType, senderId }));
    }
  }, [debouncedInputValue, dispatch, senderLinkType, senderId]);

  return <BaseAddressSelectField {...props} options={options} setInputValue={setInputValue} />;
}

export default AddressSelectField;
