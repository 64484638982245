import React from 'react';
import { useLocation } from 'react-router-dom';

/*
  This custom hooks base on the direction from react router site,
  details at https://reactrouter.com/web/example/query-parameters
*/
const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export default useQuery;
