import './styles/style.scss';

import Skeleton from '@material-ui/lab/Skeleton';
import React, { useContext, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { AutoForm } from 'uniforms-material';

import { SchemaHelperContext } from '../../../../../helpers/Contexts';
import { getFormKeyPath } from '../../../../../helpers/formHelpers';
import { useRegisterForm, useSchema } from '../../../../../helpers/hooks';
import CustomAutoField from '../../../../CustomFields/CustomAutoField';
import HandleAutoFormInVirtualizationList from '../../../../CustomFields/HandleAutoFormInVirtualizationList';
import HandleSyncFormStatusForAutoForm from '../../../../CustomFields/HandleSyncFormStatusForAutoForm';
import { useIsShowLoadingFormStatus } from '../../../../helper';
import { BookingInfoSectionContext } from '../../../Contexts';

const ContainerSection = ({ container, itemIndex, isReadOnlyItem }) => {
  const { bookingInfoSectionIndex } = useContext(BookingInfoSectionContext);
  const formKeyPath = getFormKeyPath({ bookingInfoSectionIndex, itemIndex, type: 'container' });
  const schemaHelper = useContext(SchemaHelperContext);
  const bridge = useSchema(schemaHelper.containerSchema, container);
  const containerFormModel = useMemo(() => ({ ...bridge.getDefaultModel(), ...container }), [bridge, container]);
  const { setFormRef, formRef } = useRegisterForm(formKeyPath);

  const isInOriginalSchema = (x) => {
    const originalSchema = schemaHelper.originalContainerSchema;
    return originalSchema.some((field) => {
      return field.key === x;
    });
  };

  const getUpdatedSubFields = () => {
    const s = bridge.getSubfields();
    return s.filter((x) => isInOriginalSchema(x) || (container && container[x] !== null));
  };

  const subFields = useMemo(() => getUpdatedSubFields(), [bridge]);
  const isShowFormLoadingStatus = useIsShowLoadingFormStatus(subFields);

  return (
    <section data-cy="container-section">
      <label className="section-label block font-semibold mt-15">
        <Trans>Container Information</Trans>
      </label>

      <AutoForm
        className="new-booking-container-form"
        autoField={CustomAutoField}
        ref={setFormRef}
        schema={bridge}
        model={containerFormModel}
        showInlineError={true}
        disabled={isReadOnlyItem}
      >
        {isShowFormLoadingStatus ? (
          Array(12)
            .fill(1)
            .map((ele, index) => <Skeleton key={index} height={100} />)
        ) : (
          <>
            {subFields.map((fieldName) => (
              <CustomAutoField key={fieldName} name={fieldName} />
            ))}
            <HandleSyncFormStatusForAutoForm formKeyPath={formKeyPath} />
            <HandleAutoFormInVirtualizationList formRef={formRef} formKeyPath={formKeyPath} />
          </>
        )}
      </AutoForm>
    </section>
  );
};

export default ContainerSection;
