import DateFnsUtils from '@date-io/date-fns';
import MuiFormHelperText from '@material-ui/core/FormHelperText';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import clsx from 'clsx';

import { datePickerTheme } from './CreateTheme';
import { getClassNameByRule } from './utils';

const styles = (theme) => ({
  noDisplayPicker: {
    display: 'none',
  },
  rootDatePicker: {
    width: '100%',
  },
  root: {
    borderRadius: 5,
    height: theme.spacing(5),
    fontSize: '0.875rem',
    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: 1,
    },
  },
  input: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    '.MuiInputAdornment-root + &': {
      paddingLeft: theme.spacing(1),
    },
  },
  inputMarginDense: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
  },
  inputAdornedStart: {
    paddingLeft: theme.spacing(1),
  },
  labelRoot: {
    color: `${theme.palette?.black?.['B60']} !important`,
    fontSize: '0.75rem',
    fontWeight: 600,
    '& .MuiInputLabel-asterisk': {
      color: theme.palette?.error?.main,
    },
    '& + div': {
      marginTop: theme.spacing(1),
    },
    '&.Mui-focused': {
      color: theme.palette?.black?.['B60'],
    },
  },
  labelShrink: {
    transform: `none !important`,
    '&.MuiInputLabel-outlined': {
      transform: `none`,
    },
  },
  labelFormControl: {
    position: 'static',
  },
  helperTextContained: {
    marginLeft: 0,
  },
  colorSecondary: {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette?.secondary?.main,
    },
  },
  colorError: {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette?.error?.main,
    },
  },
  colorInfo: {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette?.info?.main,
    },
  },
  colorSuccess: {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette?.success?.main,
    },
  },
  colorWarning: {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette?.warning?.main,
    },
  },
  disabled: {
    '&.MuiOutlinedInput-root': {
      backgroundColor: theme.palette?.action?.disabledBackground,
    },
  },
  endAdornment: {
    paddingRight: 0,
  },
});

const DatePicker = (props) => {
  const { classes, className, color, InputProps, icon, helperText, error, hide, ...other } = props;
  const colorClass = getClassNameByRule(classes, 'color', color);
  return (
    <ThemeProvider theme={datePickerTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          views={['year', 'month', 'date']}
          label="&nbsp;"
          InputLabelProps={{
            shrink: true,
            classes: {
              root: classes?.labelRoot,
              shrink: classes?.labelShrink,
              formControl: classes?.labelFormControl,
            },
            className: colorClass,
          }}
          InputProps={{
            classes: {
              root: classes?.root,
              input: classes?.input,
              disabled: classes?.disabled,
              inputMarginDense: classes?.inputMarginDense,
            },
            className: colorClass,
            disableUnderline: true,
            readOnly: props?.readOnly,
          }}
          className={hide ? clsx(className, classes.noDisplayPicker) : clsx(className, classes.rootDatePicker)}
          {...other}
        />
      </MuiPickersUtilsProvider>
      {helperText && <MuiFormHelperText error={error}>{helperText}</MuiFormHelperText>}
    </ThemeProvider>
  );
};
export default withStyles(styles)(DatePicker);
