import { Collapse } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

const CollapseWithLazy = ({ children, ...remain }) => {
  const [canRenderChildren, setCanRenderChildren] = useState(false);

  useEffect(() => {
    if (remain.in && canRenderChildren === false) {
      setCanRenderChildren(true);
    }
  }, [canRenderChildren, remain.in]);

  return <Collapse {...remain}>{canRenderChildren ? children : null}</Collapse>;
};

export default CollapseWithLazy;
