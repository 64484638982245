import { useTheme } from '@material-ui/core';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import TooltipWarningIcon from '@yojee/ui/common/TooltipWarningIcon';
import { areOriginsDestinationsMatchedSelector } from '@yojee/ui/new-order-booking/selectors';

import { useIsInSenderDefaultValuesPage } from '../../helpers/hooks';
import { BookingInfoSectionContext } from '../BookingInfoSections/Contexts';
import OriginDestinationMismatchMessage from '../common/OriginDestinationMismatchMessage';
import GoBackBtn from './GoBackBtn';
import NextBtnInBookingPage from './NextBtn/InBookingPage';
import SaveButton from './SaveButton';

const BookingActions = () => {
  const theme = useTheme();
  const isInSenderDefaultValuesPage = useIsInSenderDefaultValuesPage();
  const { bookingInfoSectionIndex } = useContext(BookingInfoSectionContext);
  const areOriginsDestinationsMatched = useSelector(areOriginsDestinationsMatchedSelector(bookingInfoSectionIndex));

  if (isInSenderDefaultValuesPage) {
    return null;
  }

  return (
    <div className="mt-15 flex justify-between pr-16 pl-16">
      <div className="flex justify-flex-start" />
      <div className="flex justify-flex-end">
        {!areOriginsDestinationsMatched && (
          <TooltipWarningIcon
            placement="left"
            size={3}
            style={{ marginRight: theme.spacing(1) }}
            title={<OriginDestinationMismatchMessage />}
          />
        )}
        {window.IS_EXPLORE_APP && <GoBackBtn />}
        {window.IS_BOOKING_APP && <NextBtnInBookingPage />}
        {window.IS_EXPLORE_APP && <SaveButton />}
      </div>
    </div>
  );
};

export default BookingActions;
