import './style.scss';

import { Collapse } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AutoForm } from 'uniforms-material';

import { getOrderSenderSelector, isFormModelHaveErrorSelector } from '../../../selectors';
import { ConfigContext, SchemaHelperContext } from '../../helpers/Contexts';
import { getFormKeyPath } from '../../helpers/formHelpers';
import { useIsEditOrder, useRegisterForm, useSchema } from '../../helpers/hooks';
import CustomAutoField from '../CustomFields/CustomAutoField';
import HandleSyncFormStatusForAutoForm from '../CustomFields/HandleSyncFormStatusForAutoForm';
import { useIsShowLoadingFormStatus } from '../helper';

const SenderSection = () => {
  const isEditOrder = useIsEditOrder();
  const senderData = useSelector(getOrderSenderSelector);
  const schemaHelper = useContext(SchemaHelperContext);
  const { isForceOrderFormReadOnly } = useContext(ConfigContext);
  const bridge = useSchema(schemaHelper.senderSchema, senderData);
  const [formOpened, setFormOpened] = useState(true);
  const formKeyPath = getFormKeyPath({ type: 'sender' });
  const isHaveError = useSelector((state) => isFormModelHaveErrorSelector(state, formKeyPath));
  const { setFormRef } = useRegisterForm(formKeyPath);
  const subFields = useMemo(() => bridge.getSubfields(), [bridge]);
  const isShowFormLoadingStatus = useIsShowLoadingFormStatus(subFields);

  const toggleDisplayForm = useCallback(() => {
    setFormOpened((prev) => !prev);
  }, []);

  return (
    <section
      className={`mt-15 new-booking-sender-form-section ${formOpened ? '' : 'form-closed'} ${
        isHaveError ? 'error' : ''
      }`}
    >
      <div className="h-55 flex align-center">
        <IconButton onClick={toggleDisplayForm} aria-label="settings">
          {formOpened ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
        <label className="section-label font-semibold">
          <Trans>Sender</Trans>
        </label>
      </div>

      <Collapse in={formOpened} timeout="auto">
        <div className="form-body-section">
          {isShowFormLoadingStatus ? (
            <div className="new-booking-sender-form">
              <Skeleton height={80} />
              <Skeleton height={80} />
            </div>
          ) : (
            <AutoForm
              className="new-booking-sender-form"
              autoField={CustomAutoField}
              ref={setFormRef}
              schema={bridge}
              model={senderData}
              showInlineError={true}
              disabled={isEditOrder || isForceOrderFormReadOnly}
            >
              {subFields.map((fieldName) => (
                <CustomAutoField key={fieldName} name={fieldName} />
              ))}
              <HandleSyncFormStatusForAutoForm formKeyPath={formKeyPath} />
            </AutoForm>
          )}
        </div>
      </Collapse>
    </section>
  );
};

export default React.memo(SenderSection);
