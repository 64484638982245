import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { Trans } from 'react-i18next';

import FileUploading from '../FileUploading';
import UploadZone from '../UploadZone';
import upload from './icon-upload.png';

const styledBy = (property, mapping) => (props) => {
  return mapping[props[property]] || mapping.default;
};
const styles = (theme) => ({
  zone: {
    minHeight: '92px',
    backgroundColor: styledBy('color', {
      default: 'transparent',
      primary: 'transparent',
      error: 'transparent',
      disable: theme.palette.grey[200],
    }),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  icon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
    alignSelf: 'center',
  },
  title: {
    color: theme.palette.primary.main + ' !important',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    textTransform: 'none !important',
    alignSelf: 'center',
  },
  titleStrong: {
    fontWeight: theme.typography.fontWeightBold,
    textDecoration: 'underline',
  },
  uploadContainer: {
    display: 'flex',
  },
});

const FileUploadZone = (props) => {
  const {
    classes,
    onDrop,
    helperText1,
    helperText2,
    maxSize,
    className,
    size,
    color,
    file,
    percentLoading,
    successMess,
    failedMess,
    removeFile,
    error,
    completed,
    maxTextLength,
    accept,
    ...other
  } = props;
  return (
    <UploadZone
      onDrop={onDrop}
      accept={accept}
      helperText1={helperText1}
      helperText2={helperText2}
      maxSize={maxSize}
      size={size}
      className={clsx(classes.zone, className)}
      color={color}
      error={error}
      {...other}
    >
      {file ? (
        <FileUploading
          color={color}
          size={size}
          fileName={file.name}
          fileSize={file.size}
          percent={percentLoading}
          removeFile={removeFile}
          error={error}
          completed={completed}
          successMess={successMess}
          failedMess={failedMess}
          maxTextLength={maxTextLength}
          {...other}
        />
      ) : (
        <div className={classes.uploadContainer} data-cy="upload">
          <img src={upload} alt="file-uploading-place" className={classes.icon} />
          <span className={classes.title}>
            <Trans>Drag and drop your file here or</Trans>{' '}
            <span className={clsx(classes.title, classes.titleStrong)}>
              <Trans>choose</Trans>
            </span>{' '}
            <Trans>to upload</Trans>
          </span>
        </div>
      )}
    </UploadZone>
  );
};
export default withStyles(styles)(FileUploadZone);
