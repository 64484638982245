import { Checkbox as BaseCheckbox } from '@yojee/ui/base';

import { FormFieldComponent } from '../types';

export type CheckboxFieldProps = React.ComponentProps<typeof BaseCheckbox>;

export const Checkbox: FormFieldComponent<'checkbox'> = ({
  fieldName,
  formField: { label, disabled, fieldProps },
  value,
  setValue,
}) => {
  return (
    <BaseCheckbox
      {...fieldProps}
      dataCy={fieldName}
      name={fieldName}
      label={<small>{label}</small>}
      disabled={disabled}
      onChange={(event) => setValue((event.currentTarget as HTMLInputElement).checked)}
      value={value}
      checked={!!value}
    />
  );
};
