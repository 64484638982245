import { Link } from '@material-ui/core';
import React from 'react';

import useLinkStyles from '../ActionLink/linkStyles';

const Hyperlink: React.FC<{ href: string; children: string }> = ({ href, children }) => {
  const classes = useLinkStyles();
  return (
    <Link href={href} target="_blank" rel="noreferrer" className={classes.metadataLink}>
      {children}
    </Link>
  );
};

export default React.memo(Hyperlink);
