export const listAllSenderOrganisations = (payload) => {
  return { type: 'REQUEST_ALL_LIST_SENDER_ORGANISATIONS', payload };
};
export const loadSenderOrganisation = (id) => {
  return { type: 'REQUEST_LOAD_SENDER_ORGANISATION', id };
};
export const listSenderOrganisations = (params) => {
  return { type: 'REQUEST_LIST_SENDER_ORGANISATIONS', params };
};
export const createSenderOrganisation = (
  sender,
  { uuid = undefined, onSuccess = () => {}, onError = (error) => {} }
) => {
  return { type: 'REQUEST_CREATE_SENDER_ORGANISATION', sender, uuid, onSuccess, onError };
};
export const updateSenderOrganisation = (
  sender,
  { uuid = undefined, onSuccess = () => {}, onError = (error) => {} }
) => {
  return { type: 'REQUEST_UPDATE_SENDER_ORGANISATION', sender, uuid, onSuccess, onError };
};
export const deleteSenderOrganisation = ({ sender, onSuccess }) => {
  return { type: 'REQUEST_DELETE_SENDER_ORGANISATION', payload: { sender, onSuccess } };
};
export const createSenderOrganisationUser = (sender, uuid) => {
  return { type: 'REQUEST_CREATE_SENDER_ORGANISATION_USER', sender, uuid };
};
export const updateSenderOrganisationUser = (sender, uuid) => {
  return { type: 'REQUEST_UPDATE_SENDER_ORGANISATION_USER', sender, uuid };
};
export const deleteSenderOrganisationUser = ({ sender, onSuccess }) => {
  return { type: 'REQUEST_DELETE_SENDER_ORGANISATION_USER', payload: { sender, onSuccess } };
};
export const requestSenderOrganisationBatchUpload = (payload) => {
  return { type: 'REQUEST_SENDER_ORGANISATION_BATCH_UPLOAD', payload };
};
export const downloadSenderOrganisationSample = (payload) => {
  return { type: 'DOWNLOAD_SENDER_ORGANISATION_TEMPLATE_SAMPLE', payload };
};
export const getCorporateRateCardsRequest = (params) => {
  return { type: 'GET_CORPORATE_RATE_CARDS_REQUEST', payload: params };
};
export const resetPasswordOfSenderOrganisationUser = ({ userProfileId, listOfUsers }) => {
  return { type: 'REQUEST_RESET_PASSWORD_SENDER_ORGANISATION_USER', payload: { userProfileId, listOfUsers } };
};
