import { useCallback, useEffect, useState } from 'react';

const useArray = (initial) => {
  const [value, setValue] = useState([]);

  useEffect(() => {
    if (Array.isArray(initial)) {
      setValue(initial);
    }
  }, [initial]);

  return {
    value,
    setValue,
    add: useCallback(
      (a) =>
        setValue((v) => {
          return [...v, a];
        }),
      []
    ),
    addInFront: useCallback(
      (a) =>
        setValue((v) => {
          return [a, ...v];
        }),
      []
    ),
    addAt: useCallback(
      (item, start, deleteCount = 0) =>
        setValue((v) => {
          v.splice(start, deleteCount, item);
          return [...v];
        }),
      []
    ),
    clear: useCallback(() => setValue(() => []), []),
    removeById: useCallback((id) => setValue((arr) => arr.filter((v) => v && v.id !== id)), []),
    removeIndex: useCallback((index) => setValue((arr) => arr.filter((v, i) => i !== index)), []),
    updateAtIndex: useCallback(
      (newItem, index) =>
        setValue((v) => {
          v[index] = newItem;
          return [...v];
        }),
      []
    ),
    updateAtId: useCallback(
      (newItem, itemId) =>
        setValue((items) => {
          const index = items.findIndex((v) => v.id === itemId);
          items[index] = newItem;
          return [...items];
        }),
      []
    ),
  };
};

export default useArray;
