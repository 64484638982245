import { ReasonCodeDataType, ReasonCodeType, RequestBatchUploadType } from './reasonCodeSaga';

export const listReasonCodes = () => {
  return { type: 'REQUEST_LIST_REASON_CODES' };
};

export const bulkDeleteReasonCodes = (data: { ids: number[] }) => {
  return { type: 'REQUEST_BULK_DELETE_REASON_CODES', data };
};

export const createReasonCode = (data: ReasonCodeDataType, uuid: string) => {
  return { type: 'REQUEST_CREATE_REASON_CODE', data, uuid };
};

export const editReasonCode = (data: ReasonCodeType, uuid: string) => {
  return { type: 'REQUEST_EDIT_REASON_CODES', data, uuid };
};

export const downloadReasonCodeSample = (data: { format: string }) => {
  return { type: 'DOWNLOAD_REASON_CODE_TEMPLATE_SAMPLE', data };
};

export const requestBatchUploadReasonCodes = (payload: RequestBatchUploadType) => {
  return { type: 'REQUEST_REASON_CODE_BATCH_UPLOAD', payload };
};
