import React, { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import TooltipReadOnly from '@yojee/ui/common/TooltipReadOnly';
import { ReadOnlyContext } from '@yojee/ui/ReadOnlyHelper';

import NormalStyleDialog from '../../../../../../../../../dialog/NormalStyle';
import { addSuccessMessage, deleteFormModels } from '../../../../../../../../saga/actions';
import DeleteIconBtn from '../../../../../../../common/DeleteIconBtn';
import { ConfigContext } from '../../../../../../../helpers/Contexts';
import { getFormKeyPath } from '../../../../../../../helpers/formHelpers';
import { BookingInfoSectionContext } from '../../../../../Contexts';

const DeleteItem = ({ itemIndex, totalItem, removeItem }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const readOnly = useContext(ReadOnlyContext);
  const { isForceOrderFormReadOnly } = useContext(ConfigContext);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const { bookingInfoSectionIndex } = useContext(BookingInfoSectionContext);
  const formKeyPath = getFormKeyPath({ bookingInfoSectionIndex, itemIndex });

  const syncDeletedFormModels = () => {
    dispatch(deleteFormModels(formKeyPath));
  };

  const closeConfirm = () => setIsOpenConfirm(false);
  const openConfirm = () => setIsOpenConfirm(true);
  const onConfirm = () => {
    removeItem(itemIndex);
    syncDeletedFormModels();
    dispatch(addSuccessMessage(t('Item deleted')));
  };

  const isDisabled = (itemIndex === 0 && totalItem === 1) || isForceOrderFormReadOnly;
  const itemNo = itemIndex + 1;

  return (
    <>
      <TooltipReadOnly readOnly={readOnly}>
        <DeleteIconBtn
          className="new-booking-delete-item"
          isDisabled={isDisabled}
          onClick={openConfirm}
          dataCy="delete-item"
        />
      </TooltipReadOnly>
      {isOpenConfirm && (
        <NormalStyleDialog
          open={true}
          title={<Trans i18nKey="deleteItemInOrderBookingForm" values={{ itemNo }} />}
          body={
            <>
              <Trans>Are you sure you want to delete this Item information?</Trans>
              <br />
              <span className="font-bold">
                <Trans>This cannot be undone!</Trans>
              </span>
            </>
          }
          onClose={closeConfirm}
          onCancel={closeConfirm}
          onOk={onConfirm}
          cancelText={t('Cancel')}
          okText={t('Yes, Delete')}
          dataCy="delete-item-dialog"
        />
      )}
    </>
  );
};

export default DeleteItem;
