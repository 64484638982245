import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class NotificationService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  getNotification = (type, events) => {
    events = type === 'sms' ? events.reduce((m, value) => (m = m + `&event_types[]=${value}`), '') : '';
    // explanation for this logic: https://github.com/yojee/yojee-explore/pull/6280#discussion_r1151634716
    const getNotificationType = () => {
      switch (type) {
        case 'sms':
          return `notification_types[]=sms`;
        case 'email':
          return `notification_type=email`;
        default:
          return '';
      }
    };

    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/notification_settings?${getNotificationType()}${events}&page_size=100`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  updateNotification = (notificationData) => {
    return this.authService
      .put(this.getUmbrellaApiUrl('dispatcher/notification_settings'), notificationData)
      .then((response) => {
        const result = this.extractData(response);
        return result ? result : null;
      })
      .catch((error) => this.handleError(error));
  };

  getNotificationAllSettingsTemplates = (type) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/notification_setting_templates?notification_type=${type}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  getNotificationSettingsTemplate = (notification_type, event_type) => {
    return this.authService
      .get(
        this.getUmbrellaApiUrl(
          `dispatcher/notification_setting_templates?notification_type=${notification_type}&event_type=${event_type}`
        )
      )
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  updateNotificationSettingTemplates = ({ id, template, metadata }) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/notification_setting_templates/${id}`), {
        templates: {
          en: template,
        },
        metadata,
      })
      .then((response) => {
        const result = this.extractData(response);
        return result ? result : null;
      })
      .catch((error) => this.handleError(error));
  };
}

export const notificationService = new NotificationService({ authService });
