import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { deleteOrderFieldTemplate } from '../../../../saga/actions';
import {
  getOrderFieldTemplateIdSelector,
  getOrderFieldTemplateSenderSelector,
  isInProgressDeleteOrderFieldTemplateSelector,
} from '../../../../selectors';

const useStyles = makeStyles(() => ({
  button: {
    minWidth: '96px',
    height: '30px',
    borderRadius: '4px',
    fontSize: '13px',
    fontFamily: 'Arial',
    fontWeight: 'bold',
    margin: '15px 20px 15px 10px',
  },
  progress: {
    marginRight: '10px',
  },
}));

const DeleteOrderFieldTemplateBtn = ({ onEnd } = {}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const orderFieldTemplateId = useSelector(getOrderFieldTemplateIdSelector);
  const isInProgressDeleteOrderFieldTemplate = useSelector(isInProgressDeleteOrderFieldTemplateSelector);
  const [inProgress, setInProgress] = useState(false);
  const sender = useSelector(getOrderFieldTemplateSenderSelector);

  const onClick = () => {
    setInProgress(true);
    dispatch(deleteOrderFieldTemplate(sender.id));
  };

  useEffect(() => {
    if (isInProgressDeleteOrderFieldTemplate) {
      setInProgress(true);
    } else if (inProgress) {
      setInProgress(false);
      onEnd && onEnd();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInProgressDeleteOrderFieldTemplate]);

  return orderFieldTemplateId ? (
    <Button
      onClick={onClick}
      disabled={inProgress}
      variant="contained"
      size="small"
      color="secondary"
      className={classes.button}
    >
      {inProgress && <CircularProgress className={classes.progress} size={20} />}
      <Trans>Delete</Trans>
    </Button>
  ) : null;
};

export default DeleteOrderFieldTemplateBtn;
