import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, { useCallback } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getCompanyInfoSelector } from '@yojee/ui/new-order-booking/selectors';

import { useIsCreateOrder } from '../../../../components/helpers/hooks';

const useStyles = makeStyles(() => ({
  downloadPOD: {
    color: '#1bace0',
    fontSize: '13px',
    lineHeight: '20px',
    fontWeight: 'bold',
    padding: '6px 16px',
    textTransform: 'none',
  },
}));

const OrderHeader = ({ orderInfoData, formOpened, setFormOpened }) => {
  const classes = useStyles();
  const isCreateOrder = useIsCreateOrder();
  const companyInfo = useSelector(getCompanyInfoSelector);

  const pricingUrl = companyInfo?.branding?.pricing_url;

  const toggleDisplayForm = useCallback(() => {
    setFormOpened((prev) => !prev);
  }, [setFormOpened]);

  const openPricingPage = useCallback(() => {
    window.open(pricingUrl, '_blank');
  }, [pricingUrl]);

  return (
    <div className="h-55 flex align-center justify-between">
      <div className="flex align-center">
        <IconButton onClick={toggleDisplayForm} aria-label="settings">
          {formOpened ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
        <label className="section-label font-semibold">
          <Trans>Order</Trans>
        </label>
      </div>

      <div>
        {isCreateOrder && window.IS_BOOKING_APP && pricingUrl?.trim?.() && (
          <Button onClick={openPricingPage} className={classes.downloadPOD} startIcon={<AttachMoneyIcon />}>
            <Trans>See our pricing</Trans>
          </Button>
        )}
      </div>
    </div>
  );
};

export default OrderHeader;
