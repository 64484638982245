import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getOrderBookingInfoSectionsSelector } from '@yojee/ui/new-order-booking/selectors';

import { initBookingInfoSections } from '../../helpers/formHelpers';
import BookingInfoSection from './BookingInfoSection';
import { BookingInfoSectionContext } from './Contexts';

const BookingInfoSections = () => {
  const bookingInfoSectionsData = useSelector(getOrderBookingInfoSectionsSelector);

  const bookingInfoSections = useMemo(
    () => initBookingInfoSections(bookingInfoSectionsData),
    [bookingInfoSectionsData]
  );

  return bookingInfoSections.map((group, index) => {
    const contextValue = { bookingInfoSectionIndex: index };

    return (
      <BookingInfoSectionContext.Provider key={group.uniqueID} value={contextValue}>
        <BookingInfoSection />
      </BookingInfoSectionContext.Provider>
    );
  });
};

export default React.memo(BookingInfoSections);
