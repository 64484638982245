import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import React, { useContext } from 'react';
import { Trans } from 'react-i18next';

import CollapseWithLazy from '../../../../../../common/CollapseWithLazy';
import { ConfigContext } from '../../../../helpers/Contexts';
import { useIsInSenderDefaultValuesPage, useIsOrderCompletedOrCancelledStatus } from '../../../../helpers/hooks';
import ItemDetailsHeader from './ItemDetailsHeader';
import ListItemDetailsWithVirtualize from './ListItemDetailsWithVirtualize';

const ItemDetailsSectionUI = ({
  itemDetails,
  formOpened,
  setFormOpened,
  addNew,
  removeItem,
  addNewEmptyItem,
  onChangeItemDetail,
}) => {
  const isInSenderDefaultValuesPage = useIsInSenderDefaultValuesPage();
  const { isForceOrderFormReadOnly } = useContext(ConfigContext);
  const isCompletedOrCancelled = useIsOrderCompletedOrCancelledStatus();

  return (
    <section
      className={`form-body-section new-booking-items-section ${formOpened ? '' : 'close'}`}
      data-cy="item-details-section"
    >
      <ItemDetailsHeader formOpened={formOpened} setFormOpened={setFormOpened} />

      <CollapseWithLazy in={formOpened} timeout="auto" className="items-section-collapse-wrapper">
        <ListItemDetailsWithVirtualize
          itemDetails={itemDetails}
          addNew={addNew}
          removeItem={removeItem}
          onChangeItemDetail={onChangeItemDetail}
        />

        {!isInSenderDefaultValuesPage && !isCompletedOrCancelled && (
          <Button
            disabled={isForceOrderFormReadOnly}
            className="add-new-item"
            onClick={addNewEmptyItem}
            startIcon={<AddCircleIcon />}
          >
            <Trans>Add Line</Trans>
          </Button>
        )}
      </CollapseWithLazy>
    </section>
  );
};

export default ItemDetailsSectionUI;
