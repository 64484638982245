import { isEmpty } from 'lodash-es';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useField } from 'uniforms';
import { SelectField } from 'uniforms-material';

import { getFormRefsFromPathSelector } from '@yojee/ui/new-order-booking/selectors';

import { getFormKeyPath } from '../../../helpers/formHelpers';
import { customFilterDOMProps } from '../../../helpers/SchemaHelpers';
import {
  buildDropOffOptions,
  buildPickUpTimeOptions,
  fillDropOffOptionsWhenGetTimeSlots,
  fillFromAndToTimeFromPeriod,
} from '../../../helpers/utils';
import { BookingInfoSectionContext } from '../../../OrderForm/BookingInfoSections/Contexts';

function PeriodOfTimeField(props) {
  const { onChange } = props;
  const { bookingInfoSectionIndex } = useContext(BookingInfoSectionContext);
  const [{ value: timeslots }] = useField('timeslots', {}, { absoluteName: true });
  const [{ value: dropOffSlots }] = useField('dropoff_slots', {}, { absoluteName: true });
  const [{ value: from_time }] = useField('from_time', {}, { absoluteName: true });
  const [{ value: type }] = useField('type', {}, { absoluteName: true });
  const [{ value: dropoffDate }] = useField('dropoff_date', {}, { absoluteName: true });
  const [{ value: noSlots }] = useField('no_slots', {}, { absoluteName: true });

  const [timeOptions, setTimeOptions] = useState({});

  const legIndex = props.legIndex;
  const legFormRef = useSelector((state) =>
    getFormRefsFromPathSelector(
      state,
      getFormKeyPath({
        bookingInfoSectionIndex,
        legIndex,
      }),
      null
    )
  );

  const selectProps = {
    ...props,
    label: type === 'pickup' ? 'Pickup Time' : 'Dropoff Time',
  };

  const transform = useCallback(
    (mode) => {
      return timeOptions[mode];
    },
    [timeOptions]
  );
  const allowedValues = Object.keys(timeOptions);

  const customOnChange = (event) => {
    if (!noSlots) {
      if (type === 'pickup') {
        const dropOffOptions = buildDropOffOptions(timeslots[event], dropoffDate);
        fillDropOffOptionsWhenGetTimeSlots(legFormRef, dropOffOptions, event);
      }
      fillFromAndToTimeFromPeriod(legFormRef, from_time, timeOptions[event], type);
    }
    onChange(event);
  };

  // Auto fill first slots
  useEffect(() => {
    if (!isEmpty(timeOptions)) {
      const value = Object.keys(timeOptions)?.[0];
      customOnChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeOptions]);

  useEffect(() => {
    if (!isEmpty(dropOffSlots) && type === 'dropoff') {
      setTimeOptions(dropOffSlots);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropOffSlots]);

  useEffect(() => {
    if (!isEmpty(timeslots) && from_time && moment(from_time).isValid()) {
      const pickUpTimeOptions = buildPickUpTimeOptions(timeslots);
      setTimeOptions(pickUpTimeOptions);
      if (!isEmpty(pickUpTimeOptions) && type === 'dropoff') {
        const firstPickUp = Object.keys(pickUpTimeOptions)?.[0];
        const dropOffOptions = buildDropOffOptions(timeslots[firstPickUp], from_time);
        fillDropOffOptionsWhenGetTimeSlots(legFormRef, dropOffOptions);
      }
    } else {
      setTimeOptions({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeslots, from_time, type, setTimeOptions]);

  return (
    <SelectField
      {...customFilterDOMProps(selectProps)}
      allowedValues={allowedValues}
      transform={transform}
      onChange={customOnChange}
      label={selectProps?.label}
      required={isEmpty(timeslots) ? selectProps?.required : true}
    />
  );
}

export default PeriodOfTimeField;
