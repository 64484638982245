import './style.scss';

import React, { useCallback, useEffect } from 'react';

import { DIMENSION_UNITS } from '@yojee/helpers/constants';
import { getHistoryData } from '@yojee/helpers/windowHelpers';

import WithUnitField from '../WithUnitField';

function DimensionField(props) {
  const { min, max } = props.field || {};
  const onChange = useCallback(
    (value) => {
      if (value?.toString()?.length > 6) {
        return;
      }
      props.onChange(value);
    },
    [props.onChange]
  );

  const historyData = getHistoryData();
  useEffect(() => {
    if (historyData) {
      if (props.value === null || props.value === '') {
        Object.keys(historyData).forEach((historyKey) => {
          if (props.field.key === historyKey) {
            props.onChange(historyData[historyKey], historyKey);
          }
        });
      }
    }
  }, [props]);

  return <WithUnitField {...props} onChange={onChange} min={min} max={max} units={DIMENSION_UNITS} />;
}

export default DimensionField;
