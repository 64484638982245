import { MuiThemeProvider } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useService from '@yojee/helpers/hooks/useService';
import { theme } from '@yojee/ui/base';

import AddressDialog from '../../../../../../addressDialog/index';
import { addErrorMessage, addSuccessMessage } from '../../../../../saga/actions';
import { getCompanyTimezoneSelector, getFormRefsSelector } from '../../../../../selectors';
import serviceLocator from '../../../../../serviceLocator';
import { getFormRef } from '../../../../helpers/formHelpers';

function createAddressData({ name: address1 = '', lat, lng, postalCode, state, country } = {}) {
  return {
    address1,
    tags: [],
    location: {
      lat,
      lng,
    },
    state,
    country,
    postal_code: postalCode,
  };
}

const AddToAddressBookDialog = ({
  googleAddressData,
  setIsShowAddToAddressBookDialog,
  setCanAddToAddressBook,
  formKeyPath,
}) => {
  const dispatch = useDispatch();
  const companyTimezone = useSelector(getCompanyTimezoneSelector);
  const formRefs = useSelector(getFormRefsSelector);
  const formRef = getFormRef(formRefs, formKeyPath);

  const {
    execute: createAddress,
    status: createAddressStatus,
    data: createAddressDataResult,
  } = useService(serviceLocator.addressService.createAddress, {
    lazy: true,
  });

  useEffect(() => {
    if (createAddressStatus === 'success' && formRef) {
      dispatch(addSuccessMessage('Address added to address book'));
      setCanAddToAddressBook(false);
      setIsShowAddToAddressBookDialog(false);
      const addressBookData = createAddressDataResult?.[0] || {};

      formRef.change('address_id', addressBookData.external_id || addressBookData.id);
      formRef.change('address_item_id', addressBookData.id || '');
      formRef.change('address_external_id', addressBookData.external_id || '');
    } else if (createAddressStatus === 'failed') {
      dispatch(addErrorMessage('Failed to add address to address book. Please try again'));
    }
  }, [
    createAddressDataResult,
    createAddressStatus,
    dispatch,
    formRef,
    setCanAddToAddressBook,
    setIsShowAddToAddressBookDialog,
  ]);

  return (
    <MuiThemeProvider theme={theme}>
      <AddressDialog
        timezone={companyTimezone}
        open={true}
        onClose={() => setIsShowAddToAddressBookDialog(false)}
        onSave={({ address }) => {
          const payload = {};
          if (address && Object.keys(address).length > 0) {
            Object.keys(address).forEach((key) => {
              if (typeof address[key] === 'string') {
                payload[key] = address[key].trim();
              } else {
                payload[key] = address[key];
              }
            });

            createAddress(payload);
          }
        }}
        address={createAddressData(googleAddressData || {})}
      />
    </MuiThemeProvider>
  );
};

export default AddToAddressBookDialog;
