import './style.scss';

import { Input, InputLabel, TextareaAutosize } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {},
  counter: {
    left: 'unset',
    right: -10,
  },
}));

const Textarea = ({ inputRef, ...props }) => {
  return <TextareaAutosize {...props} ref={inputRef} />;
};

const TextareaField = (props) => {
  const classes = useStyles();

  const handleInputChange = (e) => {
    const { value } = e.target;
    if (props.inputProps?.maxLength) {
      props.onChange(value.slice(0, props.inputProps.maxLength));
      return;
    }
    props.onChange(value);
  };

  const renderCounter = () => {
    if (props.value?.length > props.inputProps?.maxLength) return;

    return (
      <InputLabel className={classes.counter} shrink={true}>{`${props.value?.length || 0} / ${
        props.inputProps?.maxLength
      }`}</InputLabel>
    );
  };

  return (
    <FormControl
      margin="dense"
      fullWidth={true}
      required={props.required}
      error={!!props.error}
      disabled={props.disabled}
    >
      <InputLabel shrink={true}>{props.label}</InputLabel>
      {props.inputProps?.maxLength && renderCounter()}
      <Input
        value={props.value || ''}
        onChange={handleInputChange}
        className="ui-textarea-field"
        placeholder={props.field.sample_value}
        inputComponent={Textarea}
        inputProps={props.inputProps}
      />
      <FormHelperText>{props.error}</FormHelperText>
    </FormControl>
  );
};

export default TextareaField;
