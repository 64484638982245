import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import useService from '@yojee/helpers/hooks/useService';
import {
  getCompanyTimezoneSelector,
  getOrderFormModelsSelector,
  getTemplateDetailIdSelector,
} from '@yojee/ui/new-order-booking/selectors';

import { updateCreateOrderState } from '../../../../saga/actions';
import serviceLocator from '../../../../serviceLocator';
import { SENDER_KEY_SETTINGS } from '../constants';
import { getCreateOrderRequestPayload } from '../helpers';
import { useGetOrderSelectedSenderSetting, usePerformVariousChecksForOrder } from '../hooks';
import SaveButtonUI from './SaveButtonUI';
const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
  },
}));

const SaveNewOrderBtn = () => {
  const location = useLocation();
  const from = location?.state?.from;
  const navigate = useNavigate();
  const classes = useStyles();
  const orderFormModels = useSelector(getOrderFormModelsSelector);
  const templateId = useSelector(getTemplateDetailIdSelector);
  const companyTimezone = useSelector(getCompanyTimezoneSelector);
  const [redirectPage, setRedirectPage] = useState(null);
  const isAutoRateCalculationOnOrderCreation = useGetOrderSelectedSenderSetting(
    SENDER_KEY_SETTINGS.AUTO_RATE_CALCULATION_ON_ORDER_CREATION
  );

  const [searchParams] = useSearchParams();
  const isDuplicate = searchParams.get('duplicate');
  const originalOrderNumber = searchParams.get('original_order_number');

  const { doChecks, getConfirmDialogs } = usePerformVariousChecksForOrder();

  const {
    execute: createOrder,
    status: createOrderStatus,
    data: createOrderData,
  } = useService(serviceLocator.orderApiV4.createOrder, {
    updateStoreAction: updateCreateOrderState,
    lazy: true,
  });

  const { execute: calculateAndSaveRates, status: calculateAndSaveRatesStatus } = useService(
    serviceLocator.ratingsService.calculateAndSaveRates,
    {
      lazy: true,
    }
  );

  const isCreateOrderSuccess = createOrderStatus === 'success';
  const isCreateOrderStatusLoading = createOrderStatus === 'loading';
  const isCalculateAndSaveRateStatusLoading = calculateAndSaveRatesStatus === 'loading';
  const isCalculateChargesCompleted =
    calculateAndSaveRatesStatus === 'success' || calculateAndSaveRatesStatus === 'failed';

  const isSaveBtnLoading = isCreateOrderStatusLoading || isCalculateAndSaveRateStatusLoading;

  const newOrderNumber = createOrderData?.[0]?.number;
  const gotoPreviousPage = useCallback(() => newOrderNumber && navigate(-1), [newOrderNumber, navigate]);
  const gotoOrderDetail = useCallback(() => {
    newOrderNumber && navigate(`/booking/${newOrderNumber}?tab=booking`, { state: { from: from } });
  }, [newOrderNumber, navigate, from]);

  const createNewOrder = useCallback(() => {
    const orderData = getCreateOrderRequestPayload({ orderFormModels, templateId, companyTimezone });
    createOrder({
      data: [orderData],
      options: { skip_rate_calculation: true, ...(isDuplicate ? { original_order_number: originalOrderNumber } : {}) },
    });
  }, [companyTimezone, createOrder, orderFormModels, templateId, isDuplicate, originalOrderNumber]);

  const submit = (page) => {
    doChecks(createNewOrder);
    setRedirectPage(page);
  };

  const calculateRates = useCallback(() => calculateAndSaveRates(newOrderNumber), [newOrderNumber]);

  useEffect(() => {
    async function calculateCharge() {
      if (isCreateOrderSuccess && isAutoRateCalculationOnOrderCreation) {
        await calculateRates();
      }
    }
    calculateCharge();
  }, [isCreateOrderSuccess]);

  useEffect(() => {
    if (isAutoRateCalculationOnOrderCreation !== true || isCalculateChargesCompleted === true) {
      if (redirectPage === 'previous-page') {
        gotoPreviousPage();
      } else {
        gotoOrderDetail(newOrderNumber);
      }
    }
  }, [isCalculateChargesCompleted, newOrderNumber]);

  return (
    <div className={classes.buttons}>
      <SaveButtonUI
        onClick={() => {
          submit('order-detail');
        }}
        isLoading={isSaveBtnLoading && redirectPage === 'order-detail'}
        dataCy="save-button"
        disabled={isSaveBtnLoading}
      />
      <SaveButtonUI
        onClick={() => {
          submit('previous-page');
        }}
        isLoading={isSaveBtnLoading && redirectPage === 'previous-page'}
        dataCy="save-and-close-button"
        disabled={isSaveBtnLoading}
      >
        <Trans>Save & Close</Trans>
      </SaveButtonUI>

      {getConfirmDialogs({
        onConfirmOkMissingInfo: createNewOrder,
        onConfirmOkVolumeMismatch: createNewOrder,
      })}
    </div>
  );
};

export default SaveNewOrderBtn;
