import { Collapse, Grid, IconButton } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';
import React, { useState } from 'react';

import useStyles from './styles';

type Props = {
  header: JSX.Element | null;
  children: JSX.Element | null;
  isLoading: boolean;
  loadingRows: number;
  dataCy: string;
  className: string;
  hasError: boolean;
};

const Section: React.FC<Props> = ({
  header,
  children,
  isLoading,
  hasError = false,
  className = '',
  loadingRows = 3,
  dataCy = 'order-form-section',
}) => {
  const [isOpened, setIsOpened] = useState(true);
  const classes = useStyles({ isOpened });

  return (
    <section data-cy={dataCy} className={clsx(classes.section, className)}>
      {header && (
        <div className={clsx(classes.headerContainer, { [classes.hasError]: !isOpened && hasError })}>
          <div className={classes.header}>
            <IconButton onClick={() => setIsOpened(!isOpened)} aria-label="settings">
              {isOpened ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
            {header}
          </div>
        </div>
      )}

      {children && (
        <Collapse in={isOpened} timeout="auto">
          <div className={classes.content}>
            {isLoading ? (
              <Grid container spacing={1}>
                {Array.from({ length: loadingRows }, (_, index) => index + 1).map((row) => (
                  <Grid key={`skeleton-${row}`} item xs={12} sm={6} md={3}>
                    <Skeleton height={80} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              children
            )}
          </div>
        </Collapse>
      )}
    </section>
  );
};

export default Section;
