import { get as lodashGet, isEmpty } from 'lodash-es';
import cloneDeep from 'lodash-es/cloneDeep';
import moment from 'moment-timezone';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FORMAT_DATE_TIME_NORMAL, FORMAT_TIME_ISO_8601 } from '@yojee/helpers/constants';
import useService from '@yojee/helpers/hooks/useService';
import { isObjectEmpty } from '@yojee/helpers/StringHelper';
import { fromWeightUnitAToWeightUnitB } from '@yojee/helpers/unitConverter/weightConverter';
import { TRACKED_EVENT_NAME, TRACKED_FEATURE } from '@yojee/ui/event-tracking';
import useFeatureTracker from '@yojee/ui/event-tracking/hooks/useFeatureTracker';
import { calculateVolumeValue } from '@yojee/ui/new-order-booking/components/OrderForm/CustomFields/VolumeField/calculateVolumeValue';
import {
  buildDateTimeData,
  convertDateToUTC,
  formatTime,
} from '@yojee/ui/new-order-booking/components/OrderForm/OrderActions/utils';

import {
  addErrorMessage,
  addSuccessMessage,
  syncFormModel,
  updateFormMetaData,
  updateState,
} from '../../../saga/actions';
import {
  getFormKeysMetaDataSelector,
  getOrderTemplateIdSelector,
  getTemplateDetailIdSelector,
} from '../../../selectors';
import serviceLocator from '../../../serviceLocator';
import { VESSEL_DATETIME_KEYS } from '../../helpers/constants';
import { ConfigContext } from '../../helpers/Contexts';
import { getFormKeyPath, isNumberType } from '../../helpers/formHelpers';
import { schemaValidator } from '../../helpers/schemaValidator/index';
import { isContainerItemType } from '../BookingInfoSections/BookingInfoSection/ItemDetailsSection/ItemSection/isContainerItemType';
import { getOriginDestinationFromLegSteps, getStepProperty } from '../BookingInfoSections/BookingInfoSection/utils';

export function getCorrectUTCDateBaseOnTimezone(dateObject, timezone) {
  if (!dateObject) {
    return undefined;
  }

  // If we don't have template timezone, we use browser time
  if (!timezone) {
    const utcTime = moment(dateObject).utc();
    return utcTime.seconds(0).milliseconds(0);
  }

  const dateStr = moment(dateObject).format(FORMAT_DATE_TIME_NORMAL);
  const timeInTimezone = moment.tz(dateStr, timezone);
  return timeInTimezone.utc().seconds(0).milliseconds(0);
}

const stepModelTransform = (model = {}) => {
  const {
    timeslots,
    mapPosition,
    default_phone_country,
    address_id,
    from_time,
    to_time,
    no_slots,
    period_of_time,
    from_time_time,
    to_time_time,
    dropoff_date,
    isNewStep,
    ...remain
  } = model;

  const timezone = remain.timezone;
  const dateTimeObject = buildDateTimeData(model);

  return {
    ...remain,
    postal_code: remain.postal_code || null,
    from_time: getCorrectUTCDateBaseOnTimezone(dateTimeObject?.from_time, timezone),
    to_time: getCorrectUTCDateBaseOnTimezone(dateTimeObject?.to_time, timezone),
  };
};

const transformStepModelForEditing = (model = {}) => {
  const transformedModel = stepModelTransform(model);
  const timezone = transformedModel.timezone;

  // Keep the second and millisecond parts when editing orders.
  const dateTimeObject = buildDateTimeData(model, FORMAT_TIME_ISO_8601);
  const fromTime = convertDateToUTC(dateTimeObject?.from_time, timezone);
  const toTime = convertDateToUTC(dateTimeObject?.to_time, timezone);

  return {
    ...transformedModel,
    from_time: fromTime,
    to_time: toTime,
  };
};

const itemModelTransform = (model = {}, schemaHelper = {}) => {
  const itemSchema = schemaHelper?.itemSchema;

  const newModel = {
    ...model,
    quantity: model.quantity || 1,
  };

  if (itemSchema) {
    for (const fieldKey in newModel) {
      if (isNumberType(itemSchema[fieldKey]?.type)) {
        if (newModel[fieldKey] === '') {
          newModel[fieldKey] = null;
        } else if (typeof newModel[fieldKey] === 'string') {
          newModel[fieldKey] = Number(newModel[fieldKey]);
        }
      }
      if (fieldKey === 'cod_price' && newModel[fieldKey]) {
        newModel[fieldKey] = {
          ...newModel[fieldKey],
          amount: Number(newModel[fieldKey]?.amount || 0),
        };
      }
    }
  }

  return newModel;
};

function getOrderStepGroup({ legsFormData, orderFormData, legIndex }) {
  return legsFormData.map((legFormData, localLegIndex) => ({
    packing_mode: orderFormData.packing_mode,
    id: legFormData.find((step) => !!step?.order_step_group_id)?.order_step_group_id,
    index: legIndex + localLegIndex,
  }));
}

function getOrderItems({ itemsFormData, itemIndex }) {
  return itemsFormData.map((item) => ({
    ...item,
    index: itemIndex++,
  }));
}

function getOrderItemSteps({ orderSteps, orderItems, originalOrderItemStepIdMap, legsFormData }) {
  const { origin, destination } = getOriginDestinationFromLegSteps(legsFormData);

  const orderItemSteps = [];
  orderSteps.forEach((step, localStepIndex) => {
    orderItems.forEach((item) => {
      const originalOISId =
        originalOrderItemStepIdMap?.[
          generateLinkingKey({
            order_item_id: item.id,
            order_step_id: step.order_step_id,
            order_step_group_id: step.old_order_step_group_id || step.order_step_group_id,
            type: step.type,
          })
        ];

      orderItemSteps.push({
        order_item_index: item.index,
        order_step_group_index: step.legIndex,
        order_step_index: step.index,
        step_sequence: localStepIndex,
        step_property: getStepProperty(step, origin, destination),
        is_empty: step.is_empty,
        type: step.type,
        id: originalOISId,

        // Used for reindex when remove duplicate order step, order step group
        order_step_id: step.id,
        order_step_group_id: step.order_step_group_id,
      });
    });
  });

  return orderItemSteps;
}

function getOrderSteps({ legsFormData, stepIndex, legIndex }) {
  return (
    legsFormData?.reduce(
      (acc, legFormData, localLegIndex) =>
        acc.concat(
          legFormData.map((step) => ({
            ...step,
            index: stepIndex++,
            legIndex: legIndex + localLegIndex,
          }))
        ),
      []
    ) ?? []
  );
}

function removeDuplicateElementBaseOnId(array) {
  const checker = {};

  return (
    array?.reduce((acc, item) => {
      if (!checker[item.id]) {
        acc.push(item);
      }
      if (item.id) {
        checker[item.id] = true;
      }

      return acc;
    }, []) || []
  );
}

function getElementIdAndIndexMap(array) {
  return array.reduce((acc, element, index) => {
    if (element.id) {
      acc[element.id] = index;
    }
    return acc;
  }, {});
}

/**
 * Note: element must have a `index` property, this is old index of element
 * @param array
 * @returns {*}
 */
function getOldElementIndexAndNewElementIndexMap(array) {
  return array.reduce((acc, element, index) => {
    // element.index is old index of element
    acc[element.index] = index;
    return acc;
  }, {});
}

function getNewIndexOfElement({ idToNewIndexMap, oldIndexToNewIndexMap, id, oldIndex }) {
  let newStepIndex;

  if (id) {
    newStepIndex = idToNewIndexMap[id];
  } else {
    newStepIndex = oldIndexToNewIndexMap[oldIndex];
  }

  return newStepIndex;
}

/**
 * Why:
 * - Some orders contain steps which reuse in multiple group or in one group with different step sequence
 *  So at here we need remove duplicate steps (base on order step id) and re index order steps
 * - For case split order in multiple booking info sections, 1 group can display in multiple booking info sections
 *  So we need remove base on id of order step group
 *
 * After remove duplicate data, we need to reindex order item step (order_step_index, order_step_group_index, step_group)
 * @param orderSteps
 * @param orderItemSteps
 * @param orderStepGroups
 */
function normalizedOrderData({ orderSteps, orderItemSteps, orderStepGroups }) {
  const uniqueOrderSteps = removeDuplicateElementBaseOnId(orderSteps);
  const uniqueOrderStepGroups = removeDuplicateElementBaseOnId(orderStepGroups);

  // Map: Step id to index after removed
  const orderStepIdAndNewIndexMap = getElementIdAndIndexMap(uniqueOrderSteps);
  // Map: index before remove duplicate to index after removed duplicated
  const oldOrderStepIndexAndNewOrderStepIndexMap = getOldElementIndexAndNewElementIndexMap(uniqueOrderSteps);

  const orderStepGroupIdAndNewIndexMap = getElementIdAndIndexMap(uniqueOrderStepGroups);
  const oldOrderStepGroupIndexAndNewOrderStepIndexMap = getOldElementIndexAndNewElementIndexMap(uniqueOrderStepGroups);

  const newOrderItemSteps = orderItemSteps.map((orderItemStep) => {
    const newStepIndex = getNewIndexOfElement({
      idToNewIndexMap: orderStepIdAndNewIndexMap,
      oldIndexToNewIndexMap: oldOrderStepIndexAndNewOrderStepIndexMap,
      id: orderItemStep.order_step_id,
      oldIndex: orderItemStep.order_step_index,
    });

    const newStepGroupIndex = getNewIndexOfElement({
      idToNewIndexMap: orderStepGroupIdAndNewIndexMap,
      oldIndexToNewIndexMap: oldOrderStepGroupIndexAndNewOrderStepIndexMap,
      id: orderItemStep.order_step_group_id,
      oldIndex: orderItemStep.order_step_group_index,
    });

    return {
      ...orderItemStep,
      order_step_id: undefined,
      order_step_group_id: undefined,
      order_step_index: newStepIndex,
      order_step_group_index: newStepGroupIndex,
      step_group: newStepGroupIndex,
    };
  });

  return {
    orderItemSteps: newOrderItemSteps,
    // Remove unnecessary data
    orderStepGroups: uniqueOrderStepGroups.map(({ index, ...remain }) => remain),
    // Remove unnecessary data
    orderSteps: uniqueOrderSteps.map(({ index, legIndex, is_empty, uniqueID, ...remain }) => remain),
  };
}

const generateLinkingKey = ({ order_step_id, order_item_id, type, order_step_group_id }) =>
  `${order_step_id}-${order_item_id}-${type}-${order_step_group_id}`;

/**
 *
 * This data used for get old OrderItemStep id, when we build order payload.
 * OrderItemStep id sorted by step sequence
 * @param orderFormModels
 * @returns {*} { `${order_step_id}-${step_group}-${step_sequence}-${type}-${order_item_id}` -> id, ... }
 */
function getOriginalOrderItemStepIdMap(orderFormModels) {
  const { orderItemSteps } = orderFormModels || {};
  return (
    orderItemSteps?.reduce((acc, { id, order_step_id, order_item_id, type, order_step_group_id }) => {
      acc[
        generateLinkingKey({
          order_step_id,
          order_item_id,
          type,
          order_step_group_id,
        })
      ] = id;
      return acc;
    }, {}) ?? {}
  );
}

function getOrderRequestPayload({ templateId, orderFormModels, schemaHelper, isEditingRequest = false }) {
  const formData = getFormsData(orderFormModels, { schemaHelper, isEditingRequest });

  const originalOrderItemStepIdMap = getOriginalOrderItemStepIdMap(orderFormModels);

  const senderFormData = formData.sender;
  const orderFormData = formData.order;
  const bookingInfoSectionsData = formData.bookingInfoSections;

  let allOrderItems = [],
    allOrderSteps = [],
    // Order step group aka leg, this variable contain all legs of order
    allOrderStepGroups = [],
    allOrderItemSteps = [];

  // Index here is index of item, step, leg over all booking information section
  let itemIndex = 0,
    stepIndex = 0,
    legIndex = 0;
  bookingInfoSectionsData.forEach((bookingInfoSection) => {
    const legsFormData = bookingInfoSection.legs || [];
    const itemsFormData = bookingInfoSection.items || [];

    const orderSteps = getOrderSteps({ legsFormData, stepIndex, legIndex });
    stepIndex += orderSteps?.length ?? 0;

    const orderStepGroups = getOrderStepGroup({ legsFormData, orderFormData, legIndex });
    legIndex += legsFormData?.length ?? 0;

    const orderItems = getOrderItems({ itemsFormData, itemIndex });
    itemIndex += orderItems.length;

    const orderItemSteps = getOrderItemSteps({ orderSteps, orderItems, originalOrderItemStepIdMap, legsFormData });

    allOrderItems = allOrderItems.concat(orderItems.map(({ index, ...remain }) => remain));
    allOrderSteps = allOrderSteps.concat(orderSteps);
    allOrderStepGroups = allOrderStepGroups.concat(orderStepGroups);
    allOrderItemSteps = allOrderItemSteps.concat(orderItemSteps);
  });

  const { orderStepGroups, orderSteps, orderItemSteps } = normalizedOrderData({
    orderSteps: allOrderSteps,
    orderItemSteps: allOrderItemSteps,
    orderStepGroups: allOrderStepGroups,
  });

  return {
    order_info: {
      ...orderFormData,
      sender: senderFormData,
      template_id: templateId,
    },
    order_items: allOrderItems,
    order_item_steps: orderItemSteps,
    order_step_groups: orderStepGroups,
    order_steps: orderSteps,
    order_voyage_info: formData.order_voyage_info,
  };
}

export function getCreateOrderFieldTemplateRequestPayload({ orderFormModels, companyTimezone, schemaHelper }) {
  return getOrderRequestPayload({ orderFormModels, companyTimezone, schemaHelper });
}

export function getCreateOrderRequestPayload({ orderFormModels, templateId, companyTimezone, schemaHelper }) {
  return getOrderRequestPayload({ orderFormModels, templateId, companyTimezone, schemaHelper });
}

export function getEditOrderRequestPayload({ orderFormModels, templateId, companyTimezone, schemaHelper }) {
  return getOrderRequestPayload({ orderFormModels, templateId, companyTimezone, schemaHelper, isEditingRequest: true });
}

export function getTransformedVesselVoyageInfoData(vesselVoyageInfoData) {
  if (isObjectEmpty(vesselVoyageInfoData)) return {};

  const clonedVesselVoyageInfoData = cloneDeep(vesselVoyageInfoData);

  Object.keys(clonedVesselVoyageInfoData).forEach((vesselKey) => {
    if (VESSEL_DATETIME_KEYS.includes(vesselKey)) {
      //* Create  timeKey
      const timeKey = vesselKey.replace('date', '');

      //* Combine 'datetime' key value & 'time' key value
      const formattedDateTime = new Date(formatTime(vesselVoyageInfoData[vesselKey], vesselVoyageInfoData[timeKey]));
      clonedVesselVoyageInfoData[vesselKey] = getCorrectUTCDateBaseOnTimezone(formattedDateTime);

      //* Remove vessel 'timeKey' from 'clonedVesselVoyageInfoData'.
      delete clonedVesselVoyageInfoData[timeKey];
    }
  });

  return { order_voyage_info: clonedVesselVoyageInfoData };
}

// Same as `getTransformedVesselVoyageInfoData` except for the second and
// millisecond parts of datetime fields are kept after the conversion.
const transformVoyageInfoForEditing = (rawVoyageInfo) => {
  if (isObjectEmpty(rawVoyageInfo)) return {};

  const orderVoyageInfo = cloneDeep(rawVoyageInfo);

  Object.keys(orderVoyageInfo).forEach((vesselKey) => {
    if (VESSEL_DATETIME_KEYS.includes(vesselKey)) {
      //* Create  timeKey
      const timeKey = vesselKey.replace('date', '');

      const date = rawVoyageInfo[vesselKey];
      const time = rawVoyageInfo[timeKey];

      //* Combine 'datetime' key value & 'time' key value while keeping
      //* the second and millisecond parts.
      const dateObj = new Date(formatTime(date, time, FORMAT_TIME_ISO_8601));
      orderVoyageInfo[vesselKey] = convertDateToUTC(dateObj);

      //* Remove vessel 'timeKey'
      delete orderVoyageInfo[timeKey];
    }
  });

  return { order_voyage_info: orderVoyageInfo };
};

export const getFormsData = (
  orderFormModels,
  { allowModelTransform = true, schemaHelper, isEditingRequest = false } = {}
) => {
  const rawVoyageInfo = orderFormModels?.order_voyage_info;
  let transformedVoyageInfo;

  if (isEditingRequest) {
    transformedVoyageInfo = transformVoyageInfoForEditing(rawVoyageInfo);
  } else {
    transformedVoyageInfo = getTransformedVesselVoyageInfoData(rawVoyageInfo);
  }

  return {
    ...orderFormModels,
    bookingInfoSections:
      orderFormModels?.bookingInfoSections?.map((bookingInfoSection) => {
        return {
          legs:
            bookingInfoSection?.legs
              ?.filter((steps) => steps?.some((step) => !!step))
              ?.map((steps) =>
                steps
                  ?.filter((step) => !!step)
                  .map((step) => {
                    if (!allowModelTransform) return step;

                    if (isEditingRequest) return transformStepModelForEditing(step);

                    return stepModelTransform(step);
                  })
              ) || [],
          items: bookingInfoSection?.itemDetails
            ?.filter((itemDetail) => !!itemDetail.item)
            ?.map((itemDetail) => ({
              ...itemModelTransform(itemDetail.item, schemaHelper),
              item_container: itemDetail?.item_container,
            })),
        };
      }) ?? [],
    ...transformedVoyageInfo,
  };
};

/**
 * This function used by another functions to avoid duplicate loop over formRef
 * @param formRefs
 * @returns [formRef1, formRef2, ...]
 */
function getAllFormRefsInFlatStructure(formRefs) {
  const allFormRefs = [];

  allFormRefs.push(formRefs.sender);
  allFormRefs.push(formRefs.order);
  allFormRefs.push(formRefs.order_voyage_info);
  formRefs.bookingInfoSections?.forEach((bookingInfoSection) => {
    bookingInfoSection?.legs?.forEach((stepFormRefs) => {
      stepFormRefs?.forEach((stepFormRef) => {
        if (stepFormRef) allFormRefs.push(stepFormRef);
      });
    });
    bookingInfoSection?.itemDetails?.forEach(({ item: itemRef, item_container: containerRef } = {}) => {
      if (itemRef) allFormRefs.push(itemRef);
      if (containerRef) allFormRefs.push(containerRef);
    });
  });

  return allFormRefs;
}

/**
 * This function used by another functions to avoid duplicate loop over models
 * @returns [{ type: string, mode: object]
 */
export function getAllModelsInFlatStructure({ orderFormModels, itemTypes }) {
  if (!orderFormModels) return [];

  const allModels = [];

  allModels.push({
    type: 'order',
    model: orderFormModels.order,
    formKeyPath: getFormKeyPath({ type: 'order' }),
  });
  allModels.push({
    type: 'sender',
    model: orderFormModels.sender,
    formKeyPath: getFormKeyPath({ type: 'sender' }),
  });
  allModels.push({
    type: 'vessel',
    model: orderFormModels.order_voyage_info,
    formKeyPath: getFormKeyPath({ type: 'vessel' }),
  });
  orderFormModels.bookingInfoSections?.forEach((bookingInfoSection, bookingInfoSectionIndex) => {
    bookingInfoSection?.legs?.forEach((steps, legIndex) => {
      steps?.forEach((step, stepIndex) => {
        if (!isEmpty(step)) {
          allModels.push({
            type: 'step',
            model: step,
            bookingInfoSectionIndex,
            legIndex,
            stepIndex,
            formKeyPath: getFormKeyPath({ bookingInfoSectionIndex, legIndex, stepIndex }),
          });
        }
      });
    });
    bookingInfoSection?.itemDetails?.forEach((itemDetail, itemIndex) => {
      const { item, item_container } = itemDetail || {};
      if (!isEmpty(item)) {
        allModels.push({
          type: 'item',
          model: item,
          bookingInfoSectionIndex,
          itemIndex,
          formKeyPath: getFormKeyPath({ bookingInfoSectionIndex, itemIndex, type: 'item' }),
        });
      }
      if (!isEmpty(item_container) && isContainerItemType(item?.payload_type, itemTypes)) {
        allModels.push({
          type: 'container',
          model: item_container,
          bookingInfoSectionIndex,
          itemIndex,
          formKeyPath: getFormKeyPath({ bookingInfoSectionIndex, itemIndex, type: 'container' }),
        });
      }
    });
  });

  return allModels;
}

export const canCreateOrderWithMissingInfo = ({ schemaHelper, orderFormModels, nonOperationalZones, itemTypes }) => {
  const isAllowMissingInfo = schemaHelper?.templateSettings?.allow_missing_info;

  if (!window.IS_EXPLORE_APP || !isAllowMissingInfo) {
    return false;
  }

  const modelsValidationMetaData = [];
  const allModels = getAllModelsInFlatStructure({ orderFormModels, itemTypes });

  for (const { model, type, bookingInfoSectionIndex, legIndex, stepIndex } of allModels) {
    let schema;
    switch (type) {
      case 'order':
        schema = schemaHelper.orderSchema;
        break;
      case 'sender':
        schema = schemaHelper.senderSchema;
        break;
      case 'step':
        schema = schemaHelper.stepSchema;
        break;
      case 'item':
        schema = schemaHelper.itemSchema;
        break;
      case 'container':
        schema = schemaHelper.containerSchema;
        break;
      case 'vessel':
        schema = schemaHelper.vesselSchema;
        break;
    }
    modelsValidationMetaData.push({
      schema,
      model,
      bookingInfoSectionIndex,
      legIndex,
      stepIndex,
    });
  }

  const isNoError = ({ schema, model, bookingInfoSectionIndex, legIndex, stepIndex }) => {
    const errors = schemaValidator(schema, {
      isAllowMissingInfo,
      orderFormModels,
      nonOperationalZones,
      bookingInfoSectionIndex,
      legIndex,
      stepIndex,
    })(model);
    return isEmpty(errors);
  };

  return modelsValidationMetaData.every(isNoError);
};

function validateWeightOfOrder({ allModels, dispatch, deliveryOptions, defaultWeightUnit }) {
  const orderMaxWeight = deliveryOptions?.max_weight;
  return new Promise((resolve, reject) => {
    const itemModels = allModels.filter((model) => model?.type === 'item');
    const totalWeightOfOrder = itemModels.reduce((totalWeight, itemModel) => {
      const { weight, weight_unit, quantity } = itemModel?.model || {};
      const weightOfItem = (weight || 0) * (quantity || 1);

      return totalWeight + fromWeightUnitAToWeightUnitB(weightOfItem, weight_unit, defaultWeightUnit);
    }, 0);

    if (orderMaxWeight && totalWeightOfOrder > orderMaxWeight) {
      reject();
      dispatch(
        updateFormMetaData('root', {
          errors: {
            exceededMaxWeightError: `Order weight exceeds the maximum order weight allowed. The maximum allowed weight is ${orderMaxWeight} ${defaultWeightUnit} (current order weight is ${totalWeightOfOrder} ${defaultWeightUnit})`,
          },
        })
      );
    } else {
      resolve();
      dispatch(updateFormMetaData('root', { errors: undefined }));
    }
  });
}

function validateModelWithSchema({
  schema,
  model = {},
  dispatch,
  formKeyPath,
  orderFormModels,
  nonOperationalZones,
  bookingInfoSectionIndex,
  legIndex,
  stepIndex,
}) {
  return new Promise((resolve, reject) => {
    const errors = schemaValidator(schema, {
      orderFormModels,
      nonOperationalZones,
      bookingInfoSectionIndex,
      legIndex,
      stepIndex,
    })(model);
    if (isEmpty(errors)) {
      resolve();
      dispatch(updateFormMetaData(formKeyPath, { onceSubmitted: true, isHaveError: false, errors: undefined }));
    } else {
      dispatch(updateFormMetaData(formKeyPath, { onceSubmitted: true, isHaveError: true, errors: errors }));
      reject();
    }
  });
}

function validateAllModels({
  orderFormModels,
  dispatch,
  schemaHelper,
  nonOperationalZones,
  deliveryOptions,
  itemTypes,
}) {
  const validatePromises = [];
  let allModels = getAllModelsInFlatStructure({ orderFormModels, itemTypes });

  // We don't have sender section in sender booking page
  if (window.IS_BOOKING_APP) {
    allModels = allModels.filter((model) => model.type !== 'sender');
  }

  for (const { model, type, formKeyPath, bookingInfoSectionIndex, legIndex, stepIndex } of allModels) {
    let schema;

    if (type === 'sender') {
      schema = schemaHelper.senderSchema;
    } else if (type === 'order') {
      schema = schemaHelper.orderSchema;
    } else if (type === 'item') {
      schema = schemaHelper.itemSchema;
    } else if (type === 'container') {
      schema = schemaHelper.containerSchema;
    } else if (type === 'step') {
      schema = schemaHelper.stepSchema;
    } else if (type === 'vessel') {
      schema = schemaHelper.vesselSchema;
    }

    if (schema) {
      validatePromises.push(
        validateModelWithSchema({
          schema,
          model,
          dispatch,
          formKeyPath,
          orderFormModels,
          nonOperationalZones,
          bookingInfoSectionIndex,
          legIndex,
          stepIndex,
        })
      );
    }
  }

  if (window.IS_BOOKING_APP) {
    const defaultWeightUnit = schemaHelper?.itemSchema?.weight_unit?.default_value || 'kilogram';
    validatePromises.push(validateWeightOfOrder({ allModels, dispatch, deliveryOptions, defaultWeightUnit }));
  }

  return Promise.allSettled(validatePromises).then((values) => values.every((value) => value.status === 'fulfilled'));
}

/**
 * When submit, uniform automatically validate all rendered forms in order form.
 * @param formRefs
 * @returns {Promise<*>}
 */
function submitRenderedForms(formRefs) {
  const formSubmitPromises = [];
  const allFormRefs = getAllFormRefsInFlatStructure(formRefs);
  for (const formRef of allFormRefs) {
    formSubmitPromises.push(formRef?.submit());
  }

  return Promise.allSettled(formSubmitPromises).then((values) => values.every((value) => value.status === 'fulfilled'));
}

/**
 * Submit all forms. Also validate models in orderFormModels
 * (do this because some form may not be rendered by lazy loading, virtualized list, etc.)
 * Return true if all forms/orderFormModels pass validation else false
 * @param formRefs
 * @param orderFormModels
 * @param syncFormStatus
 * @param schemaHelper
 * @returns {Promise<null|*>}
 */
export const submitAllForms = async ({
  formRefs,
  orderFormModels,
  dispatch,
  schemaHelper,
  nonOperationalZones,
  deliveryOptions,
  itemTypes,
}) => {
  const isFormValidationPass = await submitRenderedForms(formRefs);
  const isAllModelValidationPass = await validateAllModels({
    orderFormModels,
    dispatch,
    schemaHelper,
    nonOperationalZones,
    deliveryOptions,
    itemTypes,
  });

  return isFormValidationPass && isAllModelValidationPass;
};

export const isOrderFormsChanged = (formKeysMetaData = {}) => {
  return formKeysMetaData['root']?.changed ?? false;
};

export function useIsFormChangedChecker() {
  const currentTemplateId = useSelector(getTemplateDetailIdSelector);
  const originalTemplateId = useSelector(getOrderTemplateIdSelector);
  const formKeysMetaData = useSelector(getFormKeysMetaDataSelector);

  return (formKeysMetaData?.['root']?.changed ?? false) || currentTemplateId !== originalTemplateId;
}

function getCreatedChargesReqPayload(newCharges) {
  return {
    data: {
      charges: newCharges.map((newCharge) => {
        const { chargeType, dataChanged, getChargeStatus, id, missingInfo, editableConfigs, ...restNewChargeProps } =
          newCharge;

        // Remove billing_party_id incase sell amount === null.
        if (restNewChargeProps.sell === null) restNewChargeProps.billing_party_id = null;

        return restNewChargeProps;
      }),
    },
  };
}

function getDeletedChargesReqPayload(deletedCharges) {
  return { data: { ids: deletedCharges.map((charge) => charge.id) } };
}

function getEditedChargesReqPayload(editedCharges) {
  return {
    data: {
      charges: editedCharges.map((editedCharge) => {
        const { charge_type, dataChanged, getChargeStatus, missingInfo, ...restEditedChargeProps } = editedCharge;

        // Remove billing_party_id incase sell amount === null.
        if (restEditedChargeProps.sell === null) restEditedChargeProps.billing_party_id = null;

        return restEditedChargeProps;
      }),
    },
  };
}

const RETRYABLE_ERROR_CODE = '2501';

function isRetryableError(error) {
  return error?.errors?.some((e) => e.code === RETRYABLE_ERROR_CODE);
}

/**
 * This hook use to detect and process all actions relative to charge, ex: create, update, delete
 * @param newCharges
 * @param editedCharges
 * @param deletedCharges
 * @param chargeGridData
 * @param setChargeGridData
 * @param orderNumber
 * @param invalidCharges
 * @returns {{isLoading: boolean, updateCharges: ((function(): void)|*), isFailed: unknown, isNeedUpdateCharges: unknown, isSuccess: unknown}}
 */
export function useUpdateCharges({
  newCharges,
  editedCharges,
  deletedCharges,
  chargeGridData,
  setChargeGridData,
  orderNumber,
  invalidCharges,
}) {
  const dispatch = useDispatch();
  const { sendEvent } = useFeatureTracker(TRACKED_FEATURE.RATING);

  const { usingRatingFeature } = useContext(ConfigContext);
  const isChargesUpdated = !!newCharges?.length || !!editedCharges?.length || !!deletedCharges?.length;

  // TODO: This variable may not needed anymore. Need further investigation
  const isReCalculatedCharge = useMemo(() => !!chargeGridData?.find((charge) => charge.reCalculated), [chargeGridData]);

  const isHaveChargesInvalid = !!invalidCharges?.length;
  const [actionQueue, setActionQueue] = useState([]);
  const [actionStatuses, setActionStatuses] = useState([]);
  const [actionErrors, setActionErrors] = useState([]);

  const isNeedUpdateCharges = useMemo(
    () => usingRatingFeature && (isChargesUpdated || isReCalculatedCharge),
    [isChargesUpdated, isReCalculatedCharge, usingRatingFeature]
  );

  const {
    execute: bulkCreateCharges,
    status: bulkCreateChargesStatus,
    error: bulkCreateChargesError,
    resetResultState: resetBulkCreateChargesResultState,
  } = useService(serviceLocator.ratingsService.bulkCreateCharges, {
    lazy: true,
  });

  const {
    execute: bulkDeleteCharges,
    status: bulkDeleteChargesStatus,
    error: bulkDeleteChargesError,
    resetResultState: resetBulkDeleteChargesResultState,
  } = useService(serviceLocator.ratingsService.bulkDeleteCharges, {
    lazy: true,
  });

  const {
    execute: bulkUpdateCharges,
    status: bulkUpdateChargesStatus,
    error: bulkUpdateChargesError,
    resetResultState: resetBulkUpdateChargesResultState,
  } = useService(serviceLocator.ratingsService.bulkUpdateCharges, {
    lazy: true,
  });

  const { execute: viewOrderCharges } = useService(serviceLocator.ratingsService.getCharges, {
    storeKey: 'charges',
    updateStoreAction: updateState,
    lazy: true,
  });

  const runActionsInOrder = useCallback(
    (_actionQueue) => {
      async function runInOrder() {
        for (const action of _actionQueue) {
          if (action === 'add') {
            sendEvent(TRACKED_EVENT_NAME.MANUAL_RATES_INPUT, { order_number: orderNumber });
            await bulkCreateCharges(orderNumber, getCreatedChargesReqPayload(newCharges));
          } else if (action === 'edit') {
            sendEvent(TRACKED_EVENT_NAME.RATES_UPDATE, { order_number: orderNumber });
            await bulkUpdateCharges(orderNumber, getEditedChargesReqPayload(editedCharges));
          } else if (action === 'delete') {
            sendEvent(TRACKED_EVENT_NAME.RATES_DELETE, { order_number: orderNumber });
            await bulkDeleteCharges(orderNumber, getDeletedChargesReqPayload(deletedCharges));
          }
        }
      }

      runInOrder();
    },
    [bulkCreateCharges, bulkDeleteCharges, deletedCharges, bulkUpdateCharges, editedCharges, newCharges, orderNumber]
  );

  const updateCharges = useCallback(() => {
    const newActionQueue = [];

    if (newCharges?.length) {
      newActionQueue.push('add');
    }
    if (editedCharges?.length) {
      newActionQueue.push('edit');
    }
    if (deletedCharges?.length) {
      newActionQueue.push('delete');
    }

    runActionsInOrder(newActionQueue);
    setActionQueue(newActionQueue);
  }, [deletedCharges?.length, editedCharges?.length, isReCalculatedCharge, newCharges?.length, runActionsInOrder]);

  useEffect(() => {
    const newActionStatuses = [];
    const newActionErrors = [];

    actionQueue?.forEach((action) => {
      switch (action) {
        case 'add':
          newActionStatuses.push(bulkCreateChargesStatus);
          newActionErrors.push(bulkCreateChargesError);
          break;
        case 'edit':
          newActionStatuses.push(bulkUpdateChargesStatus);
          newActionErrors.push(bulkUpdateChargesError);
          break;
        case 'delete':
          newActionStatuses.push(bulkDeleteChargesStatus);
          newActionErrors.push(bulkDeleteChargesError);
          break;
      }
    });

    const numberOfAction = actionQueue?.length ?? 0;
    if (newActionStatuses.length === numberOfAction && newActionErrors.length === numberOfAction) {
      setActionStatuses(newActionStatuses);
      setActionErrors(newActionErrors);
    }
  }, [
    bulkCreateChargesError,
    bulkCreateChargesStatus,
    bulkDeleteChargesError,
    bulkDeleteChargesStatus,
    bulkUpdateChargesError,
    bulkUpdateChargesStatus,
    actionQueue,
  ]);

  const isLoading = useMemo(() => actionStatuses.includes('loading'), [actionStatuses]);
  const isSuccess = useMemo(
    () => actionStatuses?.length && actionStatuses.every((status) => status === 'success'),
    [actionStatuses]
  );
  const isFailed = useMemo(
    () => actionStatuses?.length && actionStatuses.some((status) => status === 'failed'),
    [actionStatuses]
  );

  useEffect(() => {
    if (isSuccess) {
      dispatch(addSuccessMessage('Charges are updated'));
    } else if (isFailed) {
      const firstError = actionErrors.find((error) => !!error);
      if (!isRetryableError(firstError)) {
        dispatch(addErrorMessage(firstError?.message || 'Failed to update charges. Please try again'));
      }
    }
  }, [dispatch, actionErrors, isFailed, isSuccess]);

  useEffect(() => {
    if (isSuccess) {
      setChargeGridData([]);
      setActionErrors([]);
      setActionStatuses([]);
      setActionQueue([]);
      viewOrderCharges(orderNumber);
      resetBulkCreateChargesResultState();
      resetBulkDeleteChargesResultState();
      resetBulkUpdateChargesResultState();
    }
  }, [
    isSuccess,
    orderNumber,
    setChargeGridData,
    viewOrderCharges,
    resetBulkCreateChargesResultState,
    resetBulkDeleteChargesResultState,
    resetBulkUpdateChargesResultState,
  ]);

  return {
    updateCharges,
    isLoading,
    isSuccess,
    isFailed,
    isNeedUpdateCharges,
    isHaveChargesInvalid,
  };
}

export function resetVolumeToSystemCalculate({ dispatch, formKeysMetaData, orderFormModels, formRefs }) {
  for (const formKeyPath in formKeysMetaData) {
    const formMetaData = formKeysMetaData[formKeyPath];
    if (formMetaData.volumeNotMatchSystemCalculate) {
      const formModel = lodashGet(orderFormModels, formKeyPath, {});
      const formRef = lodashGet(formRefs, formKeyPath, {});
      const { length, length_unit, height, height_unit, width, width_unit, volume_unit } = formModel;

      const volumeValue = calculateVolumeValue({
        length,
        lengthUnit: length_unit,
        height,
        heightUnit: height_unit,
        width,
        widthUnit: width_unit,
        volumeUnit: volume_unit,
      });
      if (volumeValue) {
        if (formRef) {
          formRef.change('volume', volumeValue);
        } else {
          // In case form not render in UI (virtualize list, lazy load)
          dispatch(
            syncFormModel(formKeyPath, {
              ...formModel,
              volume: volumeValue,
            })
          );
        }
        dispatch(
          updateFormMetaData(formKeyPath, {
            volumeNotMatchSystemCalculate: false,
          })
        );
      }
    }
  }
}
