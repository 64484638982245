import React, { ReactElement, ReactNode } from 'react';
import { Trans } from 'react-i18next';

import { isValidEmail, isValidPassword } from '@yojee/auth/utils/AuthUtils';

import { phoneValidation } from '../validators';

export const formRules = {
  custom: ({
    isValid,
    message,
  }: {
    isValid: (value: unknown, formData: unknown) => boolean;
    message: string | ReactElement;
  }) => ({
    isValid,
    message,
  }),
  email: () => ({
    isValid: (value: string) => !value || isValidEmail(value),
    message: <Trans>Invalid Email</Trans>,
  }),
  password: () => ({
    isValid: (value: string) => isValidPassword(value),
    message: <Trans>Password should contain at least 8 characters</Trans>,
  }),
  required: () => ({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    isValid: (value: any) => !([null, undefined, ''].includes(value) || (Array.isArray(value) && value.length === 0)),
    message: <Trans>This field is required</Trans>,
    required: true,
  }),
  maxLength: (length: number) => ({
    isValid: (value: string) => value.length <= length,
    message: <Trans>This field length should not exceed {{ length }} characters</Trans>,
  }),
  minLength: (length: number) => ({
    isValid: (value: string) => value.length >= length,
    message: <Trans>This field length must be from {{ length }} characters</Trans>,
  }),
  phoneNumber: ({ isRequired = true } = {}) => ({
    isValid: (value: string) => {
      if (isRequired) {
        return phoneValidation(value);
      } else {
        return !value || /^\+\d{2,3}$/.test(value) || phoneValidation(value);
      }
    },
    message: <Trans>Invalid Phone Number</Trans>,
  }),
  positiveNumber: () => ({
    isValid: (value: string) => Number(value.toString()) >= 0,
    message: <Trans>This field cannot be a negative number</Trans>,
  }),
  integer: () => ({
    isValid: (value: string) => Number.isInteger(Number(value.toString())),
    message: <Trans>This field must be an integer number</Trans>,
  }),
  nonEmptyArray: (
    { message } = {
      message: <Trans>This field should not be empty</Trans>,
    }
  ) => ({
    isValid: (value: Array<unknown>) => Array.isArray(value) && value.length > 0,
    message: message,
  }),
};

export type FormRule = {
  isValid: (...value: any[]) => boolean;
  message: string | ReactNode;
  required?: boolean;
};
