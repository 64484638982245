export const requestGetUsers = (payload) => {
  return { type: 'REQUEST_GET_USERS', payload };
};

export const requestUpdateUser = (payload) => {
  return { type: 'REQUEST_UPDATE_USER', payload };
};

export const requestCreateUser = (payload) => {
  return { type: 'REQUEST_CREATE_USER', payload };
};

export const requestBatchUploadUser = (uploadData) => {
  return { type: 'USERS_BATCH_UPLOAD', uploadData };
};

export const downloadUserSample = (format) => {
  return { type: 'DOWNLOAD_USER_TEMPLATE_SAMPLE', format };
};
