import { t } from 'i18next';

import { fromUnitAToUnitB } from '@yojee/helpers/unitConverter/base';

const isValueLessThanMin = ({ value, min, currentUnit, defaultUnit }) => {
  if (typeof min === 'number') {
    const minValue = fromUnitAToUnitB({ value: min, unitA: defaultUnit, unitB: currentUnit });
    return Number(value) < minValue;
  }

  return false;
};

const isValueGreaterThanMax = ({ value, max, currentUnit, defaultUnit }) => {
  if (typeof max === 'number') {
    const maxValue = fromUnitAToUnitB({ value: max, unitA: defaultUnit, unitB: currentUnit });
    return Number(value) > maxValue;
  }

  return false;
};

export function checkUnitValue({ value, min, max, currentUnit, defaultUnit }) {
  if (value === null || value === undefined || value === '') return '';

  if (isValueLessThanMin({ value, min, currentUnit, defaultUnit })) {
    return t('Minimum value is {{min}} {{defaultUnit}}', { min, defaultUnit });
  }
  if (isValueGreaterThanMax({ value, max, currentUnit, defaultUnit })) {
    return t('Maximum value is {{max}} {{defaultUnit}}', { max, defaultUnit });
  }

  return '';
}

export function validateUnitValues({ schema, model, errors }) {
  Object.keys(schema).forEach((fieldName) => {
    const fieldMetadata = schema[fieldName];
    const { min, max, type } = fieldMetadata;

    if (type === 'unit_number' && !errors[fieldName]) {
      const unitFieldName = `${fieldName}_unit`;
      const { default_value: defaultUnit } = schema[unitFieldName];
      const currentUnit = model[unitFieldName];
      const value = model[fieldName];

      const error = checkUnitValue({ value, currentUnit, min, max, defaultUnit });
      if (error) {
        errors[fieldName] = error;
      }
    }
  });

  return errors;
}
