import { call, put, takeLatest } from 'redux-saga/effects';

import { companyService } from '../../services/companyService';

export default function* sagas() {
  yield takeLatest('REQUEST_PARTNERSHIP_INFO', getPartnerShipInfo);
  yield takeLatest('REQUEST_UPDATE_COMPANY_SETTINGS', updateCompanySettings);
  yield takeLatest(
    'REQUEST_UPDATE_TASK_EXCEPTION_OTHER_REASON_COMPANY_SETTINGS',
    updateTaskExceptionOtherReasonCompanySettings
  );
}

function* updateCompanySettings(action) {
  try {
    yield put({ type: 'SET_COMPANY_SETTINGS_LOADING' });
    const resultUpdated = yield call(companyService.updateCompanySettings, action.payload);
    yield put({ type: 'UPDATE_COMPANY_SETTINGS_SUCCESS', result: resultUpdated });
    yield put({ type: 'UPDATE_COMPANY', data: resultUpdated.data });
  } catch (error) {
    yield put({ type: 'UPDATE_COMPANY_SETTINGS_FAILED', error });
  }
}

function* updateTaskExceptionOtherReasonCompanySettings(action) {
  try {
    yield put({ type: 'SET_COMPANY_SETTINGS_LOADING' });
    const resultUpdated = yield call(companyService.updateTaskExceptionOtherReasonCompanySettings, action.payload);
    yield put({ type: 'UPDATE_COMPANY_SETTINGS_SUCCESS', result: resultUpdated });
    yield put({ type: 'UPDATE_COMPANY', data: resultUpdated.data });
  } catch (error) {
    yield put({ type: 'UPDATE_COMPANY_SETTINGS_FAILED', error });
  }
}

function* getPartnerShipInfo() {
  try {
    const partnerShipInfo = yield call(companyService.getPartnershipInfo);
    yield put({ type: 'GET_PARTNERSHIP_INFO_SUCCESS', partnerShipInfo });
  } catch (error) {
    yield put({ type: 'GET_PARTNER_SHIP_INFO_FAILED', error });
  }
}
