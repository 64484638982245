import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@mui/material';

interface Props {
  isOpened: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  section: {
    margin: theme.spacing(1, 0),
    borderRadius: theme.spacing(0.625),
    border: '1px solid #e5e5e5',
    backgroundColor: theme.palette.common.white,
    paddingBottom: (props: Props) => (props.isOpened ? theme.spacing(2) : 0),
    boxShadow: '0 5px 15px 0 rgb(124 133 152 / 15%)',
  },
  hasError: {
    // TODO: firstly moving 'theme' json to the new one, then using `theme.palette.error.main`.
    backgroundColor: '#dd5147',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: theme.spacing(6.875),
    borderBottom: '1px solid #e5e5e5',
    borderRadius: 5,
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    padding: theme.spacing(0, 2),
  },
}));

export default useStyles;
