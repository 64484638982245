import './style.scss';

import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect } from 'react';

import { FORMAT_DATE_TIME_FOR_NEW_BOOKING } from '@yojee/helpers/constants';

import { customFilterDOMProps } from '../../../helpers/SchemaHelpers';

function DateTimeField(props) {
  useEffect(() => {
    const { category, key } = props?.field || {};
    const ignoredFields = ['from_time', 'to_time', 'from_time_time', 'to_time_time'];

    if (category === 'task' && ignoredFields.includes(key)) {
      props.setClasses('field-hidden');
    }
  }, []);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDateTimePicker
        {...customFilterDOMProps(props)}
        margin="dense"
        onChange={(newValue) => props.onChange(newValue)}
        value={props.value || null}
        name=""
        label={props.label}
        format={FORMAT_DATE_TIME_FOR_NEW_BOOKING}
        placeholder={FORMAT_DATE_TIME_FOR_NEW_BOOKING.toLowerCase()}
        error={!!props.error}
        helperText={props.error}
        required={props.required}
        disabled={props.disabled}
        className="ui-datetime-field"
        DialogProps={{
          'data-cy': 'date-time-field-dialog',
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

export default DateTimeField;
