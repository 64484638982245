import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { Trans } from 'react-i18next';

import DeleteOrderFieldTemplateBtn from '@yojee/ui/new-order-booking/components/OrderForm/OrderActions/DeleteOrderFieldTemplateBtn';
import SaveOrderFieldTemplateBtn from '@yojee/ui/new-order-booking/components/OrderForm/OrderActions/SaveBtn/SaveOrderFieldTemplateBtn';
import SenderDefaultOrderValues from '@yojee/ui/new-order-booking/components/SenderDefaultOrderValues';

const styles = () => ({
  closeButton: {
    padding: 1,
    backgroundColor: 'black',
    color: 'white',
    border: 'solid 2.1px white',
    position: 'absolute',
    top: -12,
    right: -12,
    '&:hover': {
      backgroundColor: 'black',
    },
  },
  titlePopup: {
    textTransform: 'uppercase',
    fontFamily: 'Arial',
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#2a3236',
  },
  actions: {
    justifyContent: 'space-between',
  },
});

const OrderFieldTemplateDialog = ({ classes, open, onClose, sender }) => {
  return (
    <Dialog onBackdropClick={onClose} onClose={onClose} open={open} fullWidth={true} maxWidth="md">
      <DialogTitle onClose={onClose}>
        <div className={classes.titlePopup}>
          <Trans>Pre-filled Order Form</Trans>
        </div>
        <IconButton aria-label="Close" size="small" className={classes.closeButton} onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent className="base-dialog-content">
        <SenderDefaultOrderValues sender={sender} />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <div>
          <DeleteOrderFieldTemplateBtn onEnd={onClose} />
        </div>
        <SaveOrderFieldTemplateBtn onEnd={onClose} />
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(OrderFieldTemplateDialog);
