import './styles.scss';

import _ from 'lodash-es';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { calculateBounds } from '@yojee/helpers/ZoomHelper';
import { GoogleMap } from '@yojee/ui/map/components/google-map';

import { BaseMarkerCanvasComponent } from '../../../../map/index';
import { getOrderFormModelsSelector, getTaskTypesSelector } from '../../../selectors';
import { ConfigContext } from '../../helpers/Contexts';
import { getFormsData } from '../OrderActions/helpers';
import { createStepAddressMarker } from './helpers';

const emptyFunc = () => {};

const Map = () => {
  const mapWrapperRef = useRef();
  const [google, setGoogle] = useState(null);
  const [map, setMap] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [zoom, setZoom] = useState();
  const [center, setCenter] = useState();
  const { isShowMap } = useContext(ConfigContext);
  const taskTypes = useSelector(getTaskTypesSelector);
  const orderFormModels = useSelector(getOrderFormModelsSelector);

  const getNewMarker = useCallback(() => {
    const { bookingInfoSections = [] } = getFormsData(orderFormModels, { allowModelTransform: false });

    const allSteps = bookingInfoSections
      .reduce((acc, bookingInfoSection) => acc.concat(bookingInfoSection.legs), [])
      .reduce((acc, steps) => acc.concat(steps), []);

    return _.uniqBy(allSteps, ({ mapPosition }) => `${mapPosition?.lat}_${mapPosition?.lng}`)
      .filter((step) => !!step.mapPosition)
      .map((step) => ({
        ...step.mapPosition,
        div: createStepAddressMarker({ step, taskTypes }),
      }));
  }, [orderFormModels, taskTypes]);

  useEffect(() => {
    const intervalID = setInterval(() => {
      setMarkers((prev) => {
        const newMarkers = getNewMarker();

        if (prev.length !== newMarkers.length) {
          return newMarkers;
        }

        // Check if no new markers then don't change state to avoid re-render
        const latLongSet = new Set(prev.map((marker) => `${marker.lat}_${marker.lng}`));
        const isChanged = !!newMarkers?.find((marker) => !latLongSet.has(`${marker.lat}_${marker.lng}`));
        if (isChanged) {
          return newMarkers;
        }

        return prev;
      });
    }, 1000);

    return () => {
      if (intervalID) {
        clearInterval(intervalID);
      }
    };
  }, [getNewMarker]);

  useEffect(() => {
    const positionInfo = mapWrapperRef.current.getBoundingClientRect();
    const size = { width: positionInfo.width, height: positionInfo.height };
    const bounds = calculateBounds({ locations: markers, size });

    if (bounds && bounds.center) {
      setCenter(bounds.center);
      setZoom(bounds.zoom);
    }
  }, [mapWrapperRef, markers]);

  const handleGoogleMapApi = useCallback((googleObj) => {
    setMap(googleObj.map);
    setGoogle(googleObj);
  }, []);

  return (
    <div ref={mapWrapperRef} className={`new-order-form-map ${isShowMap ? '' : 'hidden'}`}>
      <GoogleMap onMapChange={emptyFunc} handleGoogleMapApi={handleGoogleMapApi} center={center} zoom={zoom} />
      <BaseMarkerCanvasComponent google={google} map={map} markers={markers} />
    </div>
  );
};

export default React.memo(Map);
