import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: ({ arrowPosition }) => {
    let flexDirection;
    switch (arrowPosition) {
      case 'bottom':
        flexDirection = 'column-reverse';
        break;
      case 'left':
        flexDirection = 'row';
        break;
      case 'right':
        flexDirection = 'row-reverse';
        break;
      case 'top':
      default:
        flexDirection = 'column';
    }
    return {
      display: 'flex',
      flexDirection,
    };
  },
  contentContainer: {
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(1),
    color: 'white',
    borderRadius: '5px',
    fontWeight: '700',
    display: 'block',
    fontSize: theme.spacing(1.5),
  },
  arrow: ({ arrowPosition }) => {
    const border = {
      borderTop: '5px solid transparent',
      borderLeft: '5px solid transparent',
      borderRight: '5px solid transparent',
      borderBottom: '5px solid transparent',
      display: 'flex',
      alignSelf: 'center',
      justifyItems: 'center',
    };
    switch (arrowPosition) {
      case 'bottom':
        border.borderTop = '5px solid ' + theme.palette.primary.dark;
        break;
      case 'left':
        border.borderRight = '5px solid ' + theme.palette.primary.dark;
        break;
      case 'right':
        border.borderLeft = '5px solid ' + theme.palette.primary.dark;
        break;
      case 'top':
      default:
        border.borderBottom = '5px solid ' + theme.palette.primary.dark;
    }
    return {
      width: 0,
      height: 0,
      ...border,
    };
  },
}));

const TooltipBackground = ({ title, arrowPosition, className, customClassArrow, customClassContent, ...restProps }) => {
  const classes = useStyles({
    arrowPosition,
  });
  return (
    <div className={clsx(classes.root, className)} {...restProps}>
      <div className={clsx(classes.arrow, customClassArrow)} />
      <div className={clsx(classes.contentContainer, customClassContent)}>{title}</div>
    </div>
  );
};

TooltipBackground.defaultProps = {
  title: 'Tooltip Background',
  arrowPosition: 'top',
};

export default TooltipBackground;
