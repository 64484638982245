import './style.scss';

import IconButton from '@material-ui/core/IconButton';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, { useCallback } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getCompletedQuantityData } from '@yojee/helpers/TasksHelper';

import { isItemDetailModelsHaveErrorSelector } from '../../../../../../../selectors';
import { useGetTasksOfItem, useTaskStatisticsOfItem } from '../../../../helpers';
import ItemActions from './ItemActions';

const ItemHeader = ({ item, itemIndex, totalItem, removeItem, formKeyPath, addNewItem, formOpened, setFormOpened }) => {
  const isHaveError = useSelector((state) => isItemDetailModelsHaveErrorSelector(state, formKeyPath));
  const { numberCompletedTasks, totalTasks, isItemCompleted } = useTaskStatisticsOfItem(item?.id);
  const tasks = useGetTasksOfItem(item?.id);
  const partnerOrderNumber = tasks?.[0]?.transfer_info_metadata?.order?.number;
  const partnerItemTrackingNumber = tasks?.[0]?.transfer_info_metadata?.order_item?.tracking_number;

  const toggleDisplayForm = useCallback(() => {
    setFormOpened((v) => !v);
  }, [setFormOpened]);

  const isItemCanceled = item?.status === 'cancelled';

  return (
    <div
      className={`new-booking-item-header-section ${formOpened ? 'form-opened' : 'form-closed'} ${
        isHaveError ? 'error' : ''
      }`}
    >
      <div className="new-booking-item-header-toggle-btn-wrapper">
        <IconButton onClick={toggleDisplayForm} aria-label="settings">
          {formOpened ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </div>

      <div className="new-booking-item-header-main-content">
        <div className="new-booking-item-header-section-content-highlight-info">
          {isItemCompleted && tasks && tasks.length > 1 ? (
            <>
              {[tasks[0], tasks[tasks.length - 1]].map((task, index) => {
                const { completedQuantity, quantity, compareStatus } = getCompletedQuantityData(task);
                return quantity ? (
                  <div className="new-booking-item-completed-group" key={index}>
                    <div className="new-booking-item-status new-booking-item-completed">
                      {(index === 0 && 'first pick up') || (index === 1 && 'last drop off')}
                    </div>
                    <div className="new-booking-item-status new-booking-item-quantity">
                      (<Trans>Items</Trans>:{' '}
                      {completedQuantity && <span className={compareStatus}>{completedQuantity}/</span>}
                      {quantity})
                    </div>
                  </div>
                ) : null;
              })}
            </>
          ) : isItemCanceled ? (
            <div className="new-booking-item-status new-booking-item-cancelled">Cancelled</div>
          ) : (
            totalTasks > 0 && (
              <div className="new-booking-item-status new-booking-item-statistics">
                {numberCompletedTasks}/{totalTasks} Completed
              </div>
            )
          )}
          {item?.id ? (
            <div className="booking-item-tracking-number">
              <Trans>Item Tracking Number</Trans>: {item.tracking_number}
            </div>
          ) : (
            <div className="booking-item-index">Item {itemIndex + 1}</div>
          )}
        </div>

        <ItemActions
          item={item}
          itemIndex={itemIndex}
          totalItem={totalItem}
          formKeyPath={formKeyPath}
          removeItem={removeItem}
          addNewItem={addNewItem}
        />
      </div>

      {item?.id && (
        <div className="booking-item-tracking-numbers">
          <div className="booking-global-item-tracking-number">
            <Trans>Global Item TN</Trans>: {item.global_tracking_number}
          </div>
          {partnerOrderNumber && (
            <div className="booking-partner-order-number">
              <Trans>Partner Order TN</Trans>: {partnerOrderNumber}
            </div>
          )}
          {partnerItemTrackingNumber && (
            <div className="booking-partner-item-tracking-number">
              <Trans>Partner Item TN</Trans>: {partnerItemTrackingNumber}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ItemHeader;
