import { Link } from '@material-ui/core';
import React from 'react';

import useLinkStyles from './linkStyles';

const ActionLink: React.FC<{ children: string; onClick: () => void }> = ({ children, onClick }) => {
  const classes = useLinkStyles();
  return (
    <Link onClick={onClick} className={classes.metadataLink}>
      {children}
    </Link>
  );
};

export default React.memo(ActionLink);
