import React, { useContext } from 'react';

import TooltipReadOnly from '@yojee/ui/common/TooltipReadOnly';
import { ReadOnlyContext } from '@yojee/ui/ReadOnlyHelper';

import ClearStep from './ClearStep';

const StepActions = ({ formKeyPath }) => {
  const readOnly = useContext(ReadOnlyContext);

  return (
    <div className="step-actions">
      <TooltipReadOnly readOnly={readOnly}>
        <ClearStep formKeyPath={formKeyPath} readOnly={readOnly} />
      </TooltipReadOnly>
    </div>
  );
};

export default StepActions;
