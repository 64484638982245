import { Input, InputLabel } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import React from 'react';

const TextField = (props) => {
  const handleInputChange = (e) => {
    const { value } = e.target;

    if (props.inputProps?.maxLength) {
      props.onChange(value.slice(0, props.inputProps.maxLength));
      return;
    }

    props.onChange(value);
  };

  return (
    <FormControl
      margin="dense"
      fullWidth={true}
      required={props.required}
      error={!!props.error}
      disabled={props.disabled}
    >
      <InputLabel shrink={true}>{props.label}</InputLabel>
      <Input
        value={props.value || ''}
        onChange={handleInputChange}
        className="ui-textarea-field"
        placeholder={props.field.sample_value}
        inputProps={props.inputProps}
      />
      {props.error && <FormHelperText>{props.error}</FormHelperText>}
    </FormControl>
  );
};

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  inputProps: PropTypes.shape({
    maxLength: PropTypes.number,
    // Add other inputProps as needed
  }),
  field: PropTypes.shape({
    sample_value: PropTypes.string,
    // Add other field properties as needed
  }),
};

export default TextField;
