import { FormGroup } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import { t } from 'i18next';
import React from 'react';

export type Option = {
  key: string;
  label: string;
  checked: boolean;
  disabled?: boolean;
};

type Props = {
  leftColumnOptions: Option[];
  rightColumnOptions: Option[];
  title?: string | null;
  onChange?: (newValue: Option[]) => void;
  hint?: string | null;
  groupDisabled?: boolean;
  checkboxClassName?: string;
};

const useStyles = makeStyles({
  hintText: {
    color: '#ffaf21',
    fontSize: '14px',
    marginLeft: 3,
  },
});

export default function CheckboxGroup({
  leftColumnOptions,
  rightColumnOptions,
  title,
  onChange,
  hint,
  groupDisabled,
  checkboxClassName,
}: Props) {
  const allOptions = [...leftColumnOptions, ...rightColumnOptions];
  const classes = useStyles();

  const handleChangeOption = (key: string, checked: boolean) => {
    const newValue = allOptions.map((option) => {
      return option.key === key ? { ...option, checked: checked } : option;
    });
    onChange?.(newValue);
  };

  const handleChangeGroupOption = (checked: boolean) => {
    const newValue = allOptions.map((val) => {
      return { ...val, checked: checked };
    });
    onChange?.(newValue);
  };

  const isAllChecked = () => {
    return allOptions.every(({ checked }) => checked === true);
  };

  const isAnyUnchecked = () => {
    return allOptions.some(({ checked }) => checked === false) && allOptions.some(({ checked }) => checked === true);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <FormControlLabel
          label={title}
          control={
            <Checkbox
              checked={isAllChecked()}
              indeterminate={isAnyUnchecked()}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleChangeGroupOption(event.target.checked);
              }}
              color="primary"
              disabled={groupDisabled}
              className={checkboxClassName}
            />
          }
        />
        {hint && (
          <Box sx={{ ml: 3 }}>
            <div className={classes.hintText}>{hint}</div>
          </Box>
        )}
      </Grid>

      <Grid item xs={6}>
        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
          {leftColumnOptions.map(({ key, label, checked, disabled }) => (
            <FormGroup>
              <FormControlLabel
                disabled={disabled || groupDisabled}
                control={<Checkbox key={key} checked={checked} color="primary" className={checkboxClassName} />}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeOption(key, event.target.checked);
                }}
                label={t(label)}
              />
            </FormGroup>
          ))}
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
          {rightColumnOptions.map(({ key, label, checked, disabled }) => (
            <FormGroup>
              <FormControlLabel
                disabled={disabled || groupDisabled}
                control={<Checkbox key={key} checked={checked} color="primary" className={checkboxClassName} />}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleChangeOption(key, event.target.checked);
                }}
                label={t(label)}
              />
            </FormGroup>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
}
