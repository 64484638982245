import configureMeasurements, { length as lengthMeasurement } from 'convert-units';

import { DIMENSION_UNIT_NOTIONS, fromUnitAToUnitB } from '@yojee/helpers/unitConverter/base';

const converter = configureMeasurements({
  length: lengthMeasurement,
});

export function getShortFormOfDimensionUnit(unit) {
  return DIMENSION_UNIT_NOTIONS[unit] || unit;
}

/**
 *
 * @param value
 * @param unit current support: meter, foot
 * @returns {number}
 */
export function toCentimeterUnit(value, unit) {
  return fromDimensionUnitAToDimensionUnitB(value, unit, 'cm');
}

export function fromDimensionUnitAToDimensionUnitB(value, unitA, unitB) {
  return fromUnitAToUnitB({
    converter,
    value,
    unitA,
    unitB,
    getShortFormOfUnit: getShortFormOfDimensionUnit,
  });
}
