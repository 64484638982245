import { DOMAttributes } from 'react';

import { Translate } from '@yojee/types';

export type AddressCompetionRadiusUnit = 'meter' | 'kilometer';

export type CompletionTimeBufferUnit = 'hour' | 'minute';

export const DEFAULT_ACR: { value: number; unit: AddressCompetionRadiusUnit } = {
  value: 500,
  unit: 'meter',
};

export const DEFAULT_CTB: { value: number; unit: CompletionTimeBufferUnit } = {
  value: 30,
  unit: 'minute',
};

export const getACRUnitOptions = (t: Translate) => [
  {
    value: 'meter',
    label: t('Meters'),
  },
  {
    value: 'kilometer',
    label: t('Kilometers'),
  },
];

export const getCTBUnitOptions = (t: Translate) => [
  {
    value: 'hour',
    label: t('Hours'),
  },
  {
    value: 'minute',
    label: t('Minutes'),
  },
];

export const preventDecimalInput: DOMAttributes<HTMLInputElement>['onKeyDown'] = (event) =>
  [',', '.', 'e'].includes(event.key) && event.preventDefault();
