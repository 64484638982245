import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { PopoverProps } from '@mui/material';
import React, { useState } from 'react';

import TooltipReadOnly from '@yojee/ui/common/TooltipReadOnly';

import useStyles from './styles';

type Props = {
  readOnly?: boolean;
  dataCy?: string;
  customPopoverProps?: PopoverProps;
  children?: React.ReactNode | null;
  className?: string;
};

const defaultPopoverProps: PopoverProps = {
  open: true,
  anchorOrigin: {
    vertical: 'center',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
};

const ActionsPopover: React.FC<Props> = ({
  dataCy = 'actions-popover',
  readOnly = false,
  children = null,
  customPopoverProps = {},
  className = 'actions',
}) => {
  const classes = useStyles();
  const [isPopoverDisplayed, setIsPopoverDisplayed] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const onDisplayPopover = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setIsPopoverDisplayed(true);
    setAnchorEl(e.currentTarget);
  };

  const onClosePopover = () => setIsPopoverDisplayed(false);

  return (
    <div className={className}>
      <TooltipReadOnly readOnly={readOnly}>
        <IconButton className={classes.moreHorizIcon} onClick={onDisplayPopover} data-cy={dataCy} disabled={readOnly}>
          <MoreHorizIcon />
        </IconButton>
      </TooltipReadOnly>
      {isPopoverDisplayed && (
        <Popover onClose={onClosePopover} anchorEl={anchorEl} {...defaultPopoverProps} {...customPopoverProps}>
          <div className={classes.children}>{children}</div>
        </Popover>
      )}
    </div>
  );
};

export default ActionsPopover;
