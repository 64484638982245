import { en } from 'chrono-node';
import moment, { Moment, MomentInput } from 'moment';

import { DATE_FORMAT, DEFAULT_DATE_VALUES } from '../constant';
import { DateType } from '../types';

// https://stackoverflow.com/a/643827/21617288
export const isDateType = (maybeDate: MomentInput) => Object.prototype.toString.call(maybeDate) === '[object Date]';

export const isValidDate = (maybeDate: MomentInput) => moment(maybeDate).isValid();

export const formatDate = (date: MomentInput) => (isValidDate(date) ? moment(date).format(DATE_FORMAT) : '');

const toValidMoment = (date: MomentInput) => (isValidDate(date) ? moment(date) : null);

const generateDate = () => moment().set(DEFAULT_DATE_VALUES);

const getNewMoment = (date: DateType, input: string): Moment | null => {
  input = input.toLowerCase();
  switch (input) {
    case 't':
      return generateDate();
    case 'y':
      return generateDate().add(-1, 'd');
    case '+':
      return toValidMoment(date)?.add(1, 'd') ?? generateDate().add(1, 'd');
    case '-':
      return toValidMoment(date)?.add(-1, 'd') ?? generateDate().add(-1, 'd');
    default:
      return toValidMoment(en.GB.parseDate(input));
  }
};

export const parseInput = ({
  input,
  refDate,
  otherDate,
}: {
  input: string;
  refDate: DateType;
  otherDate: DateType;
}): [DateType, DateType] => {
  if (input === '') {
    return [null, otherDate];
  }
  if (input === '=') {
    if (otherDate != null) return [new Date(otherDate), otherDate];
    if (refDate != null) return [refDate, new Date(refDate)];
    return [null, null];
  }

  return [getNewMoment(refDate, input)?.toDate() ?? null, otherDate];
};
