import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback } from 'react';

import { getItemPODUrl } from '@yojee/helpers/orders-helper';

import icon from './icons-custom-photo-dl.png';

const useStyles = makeStyles(() => ({
  downloadPhoto: {
    height: '40px',
    marginRight: '10px',
    cursor: 'pointer',
  },
}));

const DownloadPhoto = ({ item }) => {
  const classes = useStyles();
  const isItemHasProofsFile = item?.has_proofs_file;

  const downloadPhotosFile = useCallback(() => {
    const url = getItemPODUrl(item?.tracking_number, 'zip');
    window.open(url, '_blank');
  }, [item?.tracking_number]);

  return (
    item?.status === 'completed' &&
    isItemHasProofsFile && (
      <img onClick={downloadPhotosFile} className={classes.downloadPhoto} src={icon} alt="Download item" />
    )
  );
};

export default DownloadPhoto;
