import './style.scss';

import { TextField, Tooltip } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { geocodeGooglePlaceId } from '@yojee/helpers/google';

import { AddressType } from '../../../../saga/helpers';
import { getFormRefsSelector } from '../../../../selectors';
import { getFormRef } from '../../../helpers/formHelpers';
import { useIsCreateOrder } from '../../../helpers/hooks';
import { customFilterDOMProps } from '../../../helpers/SchemaHelpers';
import AddToAddressBookDialog from './AddToAddressBookDialog';
import { fillDataFromAddressBook, fillDataFromGoogleAddress } from './helpers';

const useStyles = makeStyles(() => ({
  popper: {
    maxHeight: '400px',
    overflowY: 'auto',
  },
}));

function BaseAddressSelectField({ formKeyPath, options, setInputValue, ...props }) {
  const classes = useStyles();
  const formRefs = useSelector(getFormRefsSelector);
  const formRef = getFormRef(formRefs, formKeyPath);
  const [canAddToAddressBook, setCanAddToAddressBook] = useState(false);
  const [currentGoogleAddressData, setCurrentGoogleAddressData] = useState(null);
  const [isShowAddToAddressBookDialog, setIsShowAddToAddressBookDialog] = useState(false);
  const isCreateOrder = useIsCreateOrder();

  const getOptionLabel = (option) => {
    return option?.name ?? option;
  };

  const onChange = async (event, newValue) => {
    props.onChange(newValue?.name || '');

    if (newValue) {
      if (newValue.type === AddressType.google) {
        const googleAddressData = await geocodeGooglePlaceId(newValue.id);

        setCurrentGoogleAddressData({ ...googleAddressData, name: newValue.name });
        fillDataFromGoogleAddress({ googleAddressData, formRef, isCreateOrder });
        setCanAddToAddressBook(true);
      } else if (newValue.type === AddressType.book) {
        fillDataFromAddressBook({ addressBookData: newValue.value, formRef, isCreateOrder });
        setCanAddToAddressBook(false);
      }
    } else {
      formRef?.change('mapPosition', null);
      setCanAddToAddressBook(false);
    }
  };

  return (
    <>
      <Autocomplete
        filterOptions={(x) => x}
        freeSolo
        autoComplete
        disabled={props.disabled}
        getOptionLabel={getOptionLabel}
        options={options}
        groupBy={(option) => option.type}
        value={props.value || ''}
        onChange={onChange}
        onInputChange={(event, newInputValue) => {
          // Check event here to ignore in case first time render with default value
          // => no need to call api get prediction
          if (event) {
            setInputValue(newInputValue);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            {...customFilterDOMProps(props)}
            error={!!props.error}
            helperText={props.error}
            margin="dense"
            label={props.label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps?.endAdornment}
                  {canAddToAddressBook && window.IS_EXPLORE_APP && (
                    <InputAdornment
                      onClick={() => setIsShowAddToAddressBookDialog(true)}
                      className="custom-field-address-adornment"
                      position="end"
                    >
                      <Tooltip title={<Trans>Click here to add address to address book</Trans>}>
                        <AddCircleIcon />
                      </Tooltip>
                    </InputAdornment>
                  )}
                </>
              ),
            }}
            inputProps={{
              ...params.inputProps,
              ...props.inputProps,
              autoComplete: 'new-password',
              'data-cy': 'step-address',
            }}
          />
        )}
        renderOption={(option) => {
          const optionLabel = option.type === AddressType.book ? option.optionLabel : option.description;
          return (
            <Tooltip title={optionLabel}>
              <Typography className="custom-field-address-select-option-item" noWrap>
                {optionLabel}
              </Typography>
            </Tooltip>
          );
        }}
        ListboxProps={{
          className: 'custom-field-address-select-listbox',
          'data-cy': 'step-address-menu',
        }}
        classes={{
          popper: classes.popper,
        }}
      />

      {isShowAddToAddressBookDialog && (
        <AddToAddressBookDialog
          googleAddressData={currentGoogleAddressData}
          setIsShowAddToAddressBookDialog={setIsShowAddToAddressBookDialog}
          setCanAddToAddressBook={setCanAddToAddressBook}
          formKeyPath={formKeyPath}
        />
      )}
    </>
  );
}

export default BaseAddressSelectField;
