import { isEmpty } from 'lodash-es';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useField } from 'uniforms';

import {
  getCompanyTimezoneSelector,
  getFormRefsFromPathSelector,
  getSelectedServiceTypeSelector,
} from '@yojee/ui/new-order-booking/selectors';

import serviceLocator from '../../../../serviceLocator';
import { getFormKeyPath } from '../../../helpers/formHelpers';
import { useTimeSlots } from '../../../helpers/hooks';
import {
  fillDateTimeWhenGetTimeSlots,
  getDropoffDates,
  getPickUpTime,
  getValueFromSlots,
} from '../../../helpers/utils';
import { BookingInfoSectionContext } from '../../BookingInfoSections/Contexts';
import BaseFromDateField from './BaseFromDateField';

const newLabelIfHavingSlots = {
  pickup: 'Pickup Date',
  dropoff: 'Dropoff Date',
};

function BookingPageFromDateField(props) {
  const [{ value: timeslots }] = useField('timeslots', {}, { absoluteName: true });
  const [{ value: type }] = useField('type', {}, { absoluteName: true });
  const [{ value: noSlots }] = useField('no_slots', {}, { absoluteName: true });
  const [{ value: pickupTime }] = useField('pickup_time', {}, { absoluteName: true });

  const [additionalError, setAdditionalError] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [disabled, setDisabled] = useState(props?.disabled);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [currentServiceType, setCurrentServiceType] = useState(null);

  const timezone = useSelector(getCompanyTimezoneSelector);
  const { bookingInfoSectionIndex } = useContext(BookingInfoSectionContext);

  const { disableNonOperatingDays, generateSlotsPayload, serviceRequiredTimeslot } = useTimeSlots();
  const serviceType = useSelector(getSelectedServiceTypeSelector);
  const serviceTypeKey = serviceType?.key;

  const legIndex = props.legIndex;
  const legFormRef = useSelector((state) =>
    getFormRefsFromPathSelector(
      state,
      getFormKeyPath({
        bookingInfoSectionIndex,
        legIndex,
      }),
      null
    )
  );

  const openClosePicker = useCallback(
    (status) => {
      if (disabled) {
        setIsOpen(false);
      } else {
        setIsOpen(status);
      }
    },
    [disabled]
  );

  const extraProps = React.useMemo(() => {
    const propsObject = {
      open: !disabled ? isOpen : false,
      onClick: () => openClosePicker(true),
      onClose: () => openClosePicker(false),
      readOnly: true,
      disablePast: true,
    };
    if (minDate) {
      propsObject.minDate = minDate;
    }

    if (maxDate) {
      propsObject.maxDate = maxDate;
    }

    return propsObject;
  }, [disabled, isOpen, openClosePicker, minDate, maxDate]);

  const customOnChange = (event) => {
    const pickUpFromDateTime = getPickUpTime(event);
    const getLegTimeSlots = async () => {
      try {
        if (serviceTypeKey) {
          const payLoad = generateSlotsPayload(pickUpFromDateTime, serviceTypeKey, timezone);
          const legTimeSlots = await serviceLocator.serviceTypesApi.getSlots(payLoad);
          const dateTimeObject = getValueFromSlots(legTimeSlots);

          const options = {
            serviceRequiredTimeslot,
            from_time: pickUpFromDateTime,
          };

          fillDateTimeWhenGetTimeSlots(legFormRef, dateTimeObject, options, legTimeSlots);
        }
      } catch (error) {
        fillDateTimeWhenGetTimeSlots(legFormRef, [], { serviceRequiredTimeslot, from_time: pickUpFromDateTime });
      }
    };
    if (serviceRequiredTimeslot && type === 'pickup') {
      getLegTimeSlots();
    }

    props.onChange(event);
  };

  useEffect(() => {
    if (currentServiceType?.key !== serviceTypeKey) {
      const dateObject = !isEmpty(timeslots) ? getValueFromSlots(timeslots) : null;
      setMinDate(dateObject?.[type]?.from || new Date());
      setCurrentServiceType(serviceType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeslots, type]);

  useEffect(() => {
    if (type === 'dropoff' && (!isEmpty(timeslots) || noSlots)) {
      setDisabled(true);
    } else {
      setDisabled(props?.disabled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeslots, noSlots, props?.disabled]);

  useEffect(() => {
    if (noSlots && type === 'pickup') {
      setAdditionalError('No slots found. Select another date.');
    } else {
      setAdditionalError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noSlots]);

  useEffect(() => {
    if (!isEmpty(timeslots) && type === 'dropoff') {
      const dropOffDate = getDropoffDates(timeslots, pickupTime);
      if (dropOffDate?.length > 1) {
        setMinDate(moment(dropOffDate[0]));
        setMaxDate(moment(dropOffDate[1]));
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeslots, type]);

  return (
    <BaseFromDateField
      {...props}
      {...extraProps}
      shouldDisableDate={disableNonOperatingDays}
      label={newLabelIfHavingSlots[type]}
      disabled={disabled}
      onChange={customOnChange}
      error={props.error || additionalError}
    />
  );
}

export default BookingPageFromDateField;
