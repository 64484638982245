import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Dialog from '@yojee/ui/base/corners/Dialog';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    margin: theme.spacing(3, 0),
  },
}));

const EditInvoicedOrderConfirmDialog: React.FC = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      maxWidth="xs"
      open={true}
      title={t('Changes might impact invoices')}
      primaryBtnText={t('Ok')}
      dividers="paper"
      dataCy={{ save: 'ok-button' }}
      {...props}
    >
      <div className={classes.content}>
        {t(
          'An invoice for this order already exists, your recent changes might influence the invoice details. Changes to your invoice can be made on the next page.'
        )}
      </div>
    </Dialog>
  );
};

export default EditInvoicedOrderConfirmDialog;
