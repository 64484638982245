import './style.scss';

import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getCompanyCurrencySelector } from '@yojee/ui/new-order-booking/selectors';

import { customFilterDOMProps } from '../../../helpers/SchemaHelpers';

function CODPriceField(props) {
  const defaultCurrency = useSelector(getCompanyCurrencySelector);
  const { onChange } = props;

  const customOnChange = useCallback(
    (e) => {
      onChange({ amount: e.target.value, currency: defaultCurrency });
    },
    [defaultCurrency, onChange]
  );

  const InputProps = useMemo(
    () => ({
      endAdornment: (
        <InputAdornment
          className={`cod-price-custom-field-adornment ${props.disabled ? 'disabled' : ''}`}
          position="end"
        >
          {defaultCurrency}
        </InputAdornment>
      ),
    }),
    [defaultCurrency, props.disabled]
  );

  return (
    <TextField
      {...customFilterDOMProps(props)}
      type="number"
      error={!!props.error}
      helperText={props.error}
      disabled={props.disabled}
      margin="dense"
      label={props.label}
      value={props.value?.amount}
      onChange={customOnChange}
      placeholder={props.field.sample_value}
      name=""
      InputProps={InputProps}
    />
  );
}

export default CODPriceField;
