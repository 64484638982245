import { styled } from '@mui/material/styles';
import { t } from 'i18next';
import React from 'react';

import { ORDER_BOOKING_TAB } from '@yojee/helpers/constants';
import { getConsignmentNoteReportUrl, getOrderLabelUrl, getOrderPODUrl } from '@yojee/helpers/orders-helper';
import { useTab } from '@yojee/ui/new-order-booking/components/helpers/hooks';

import ActionLink from '../ActionLink';
import HyperLink from '../HyperLink';

const StyledSpan = styled('span')({
  fontSize: '1rem',
  color: '#d3d3d3',
});

const DocSeparator = () => {
  return <StyledSpan> | </StyledSpan>;
};

const DocLinks: React.FC<{ orderNumber: string }> = ({ orderNumber }) => {
  const { changeTab } = useTab();

  const labelUrl = getOrderLabelUrl(orderNumber, 'pdf');
  const podUrl = getOrderPODUrl(orderNumber, 'pdf');
  const conNoteUrl = getConsignmentNoteReportUrl(orderNumber, 'pdf');

  const onDocPageHyperlinkClick = () => {
    changeTab(ORDER_BOOKING_TAB.documents.value);
  };

  return (
    <div>
      <HyperLink href={labelUrl}>{t('LABEL')}</HyperLink>
      <DocSeparator />
      <HyperLink href={podUrl}>{t('POD')}</HyperLink>
      <DocSeparator />
      <HyperLink href={conNoteUrl}>{t('CONSIGNMENT NOTE')}</HyperLink>
      <DocSeparator />
      <ActionLink onClick={onDocPageHyperlinkClick}>{t('OTHER')}</ActionLink>
    </div>
  );
};

export default React.memo(DocLinks);
