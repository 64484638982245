import React from 'react';
import { useTranslation } from 'react-i18next';

import NormalStyleDialog from '../../../../../../dialog/NormalStyle';

const MissingInfoConfirmDialog = ({ setIsShowConfirmMissingInfo, onOk, bodyText }) => {
  const { t } = useTranslation();

  const defaultBodyText = t('Order will be created with Missing info status, Do you want to proceed?');

  const onClose = () => {
    setIsShowConfirmMissingInfo(false);
  };

  const _onOK = () => {
    onOk();
    onClose();
  };

  return (
    <NormalStyleDialog
      open={true}
      title={t('Some required information is missing')}
      body={bodyText || defaultBodyText}
      onClose={onClose}
      onCancel={onClose}
      onOk={_onOK}
      cancelText={t('No')}
      okText={t('Yes')}
    />
  );
};
export default MissingInfoConfirmDialog;
