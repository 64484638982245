import { sendGA4EventSync } from '@yojee/helpers/GAHelper';

import { TRACKED_EVENT_NAME, TRACKED_FEATURE } from './eventTrackingNames';

type FeatureCategory = keyof typeof TRACKED_FEATURE;
type EventTrackingName = keyof typeof TRACKED_EVENT_NAME;

export const sendEvent = (
  feature: FeatureCategory,
  eventName: EventTrackingName,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraParams?: Record<string, unknown> & { value: any }
) => {
  sendGA4EventSync({ feature: TRACKED_FEATURE[feature], event: TRACKED_EVENT_NAME[eventName], ...extraParams });
};
