import React, { useEffect, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SelectField } from 'uniforms-material';

import { getHistoryData } from '@yojee/helpers/windowHelpers';

import { getItemTypesSelector } from '../../../../selectors';
import { useAddValueAsOptionIfValueMissingInOptionsForSelect } from '../../../helpers/formHelpers';
import { customFilterDOMProps } from '../../../helpers/SchemaHelpers';

function ItemTypeSelectField({ InputLabelProps, ...props }) {
  const itemTypes = useSelector(getItemTypesSelector);

  const allowedValues = useMemo(() => itemTypes?.map((v) => v.name) || [], [itemTypes]);

  const { newAllowValues } = useAddValueAsOptionIfValueMissingInOptionsForSelect({
    value: props.value,
    label: props.value,
    allowedValues,
  });

  const historyData = getHistoryData();
  useEffect(() => {
    if (historyData) {
      props.onChange(historyData['payload_type'], 'payload_type');
    }
  }, [props.value]);

  return (
    <SelectField
      {...customFilterDOMProps(props)}
      value={props.value || ''}
      placeholder={
        <em>
          <Trans>Please choose</Trans>
        </em>
      }
      allowedValues={newAllowValues}
      name=""
      data-cy="item-payload-type-select"
      MenuProps={{
        'data-cy': 'item-payload-type-select-menu',
      }}
    />
  );
}

export default ItemTypeSelectField;
