import { call, put, select, takeLatest } from 'redux-saga/effects';

import { notificationService } from '../../services/notificationService';

export default function* sagas() {
  yield takeLatest('REQUEST_GET_NOTIFICATION_SMS', getNotificationSms);
  yield takeLatest('REQUEST_GET_NOTIFICATION_EMAIL', getNotificationEmail);
  yield takeLatest('REQUEST_UPDATE_NOTIFICATION', updateNotification);

  yield takeLatest('REQUEST_UPDATE_SMS_TEMPLATE', updateSMSTemplate);
  yield takeLatest('REQUEST_UPDATE_EMAIL_TEMPLATE', updateEmailTemplate);

  yield takeLatest('REQUEST_SAVE_TEMPLATE', updateNotificationSettingTemplates);

  yield takeLatest('REQUEST_GET_TEMPLATE', getDetailTemplate);
}

function* getNotificationSms({ payload }) {
  try {
    const notification = yield call(notificationService.getNotification, payload.type, payload.events);
    const list = yield call(transformData, notification);
    yield put({ type: 'REQUEST_GET_SMS_NOTIFICATION_SUCCESS', result: list });
  } catch (error) {
    yield put({ type: 'REQUEST_NOTIFICATION_FAIL', error });
  }
}

function* getNotificationEmail({ payload }) {
  try {
    const notification = yield call(notificationService.getNotification, payload.type);
    const list = yield call(transformData, notification);
    yield put({ type: 'REQUEST_GET_EMAIL_NOTIFICATION_SUCCESS', result: list });
  } catch (error) {
    yield put({ type: 'REQUEST_NOTIFICATION_FAIL', error });
  }
}

function buildNotificationRequestData(payload) {
  const requestData = {
    sender: {},
    pickup_person: {},
    dropoff_person: {},
  };

  payload.list.forEach((noti) => {
    if (noti.enable_pickup_person !== undefined) {
      requestData.pickup_person[noti.event_type] =
        payload.type === 'sms' ? { sms: noti.enable_pickup_person } : { email: noti.enable_pickup_person };
    }
    if (noti.enable_sender !== undefined) {
      requestData.sender[noti.event_type] =
        payload.type === 'sms' ? { sms: noti.enable_sender } : { email: noti.enable_sender };
    }
    if (noti.enable_dropoff_person !== undefined) {
      requestData.dropoff_person[noti.event_type] =
        payload.type === 'sms' ? { sms: noti.enable_dropoff_person } : { email: noti.enable_dropoff_person };
    }
  });

  return requestData;
}

function* updateNotification({ payload }) {
  try {
    const requestData = buildNotificationRequestData(payload);

    const resultUpdateNotification = yield call(notificationService.updateNotification, requestData);
    resultUpdateNotification.data = yield call(transformData, resultUpdateNotification.data);
    if (payload.type === 'sms') {
      yield put({ type: 'REQUEST_UPDATE_SMS_NOTIFICATION_SUCCESS', result: resultUpdateNotification });
    } else {
      yield put({ type: 'REQUEST_UPDATE_EMAIL_NOTIFICATION_SUCCESS', result: resultUpdateNotification });
    }
  } catch (error) {
    yield put({ type: 'REQUEST_NOTIFICATION_FAIL', message: error.message });
  }
}

function* updateSMSTemplate({ payload }) {
  try {
    const smsTemplates = yield select((state) => state.notifications.template);
    if (payload.template !== undefined) {
      smsTemplates.templates.en = payload.template;
    }
    yield put({ type: 'UPDATE_TEMPLATE', result: smsTemplates });
  } catch (error) {
    yield put({ type: 'REQUEST_NOTIFICATION_FAIL', message: error.message });
  }
}

function* updateEmailTemplate({ payload }) {
  try {
    const email = yield select((state) => state.notifications.template);
    if (payload.template !== undefined) {
      email.templates.en = payload.template;
    }
    if (payload.subject !== undefined) {
      email.metadata.subject = { en: payload.subject };
    }
    yield put({ type: 'UPDATE_TEMPLATE', result: email });
  } catch (error) {
    yield put({ type: 'REQUEST_NOTIFICATION_FAIL', message: error.message });
  }
}

function* updateNotificationSettingTemplates({ payload }) {
  try {
    const result = yield call(notificationService.updateNotificationSettingTemplates, {
      id: payload.id,
      template: payload.template,
      metadata: payload.metadata,
    });
    yield put({ type: 'REQUEST_SAVE_TEMPLATE_SUCCESS', message: result.message });
  } catch (error) {
    yield put({ type: 'REQUEST_NOTIFICATION_FAIL' });
  }
}

function* getDetailTemplate({ payload }) {
  try {
    const result = yield call(
      notificationService.getNotificationSettingsTemplate,
      payload.notification_type,
      payload.event_type
    );
    if (result && result.length) {
      yield put({ type: 'REQUEST_GET_TEMPLATE_SUCCESS', result: result[0] });
    }
  } catch (error) {
    yield put({ type: 'REQUEST_GET_TEMPLATE_FAIL' });
  }
}

function transformData(notification) {
  return notification
    .reduce((m, noti) => {
      const index = m.findIndex((e) => e.event_type === noti.event_type);
      if (index > -1) {
        if (noti.user_type === 'pickup_person') {
          m[index].enable_pickup_person = noti.is_selected;
        }
        if (noti.user_type === 'dropoff_person') {
          m[index].enable_dropoff_person = noti.is_selected;
        }
        if (noti.user_type === 'sender') {
          m[index].enable_sender = noti.is_selected;
        }
      } else {
        if (noti.user_type === 'pickup_person') {
          noti.enable_pickup_person = noti.is_selected;
        }
        if (noti.user_type === 'dropoff_person') {
          noti.enable_dropoff_person = noti.is_selected;
        }
        if (noti.user_type === 'sender') {
          noti.enable_sender = noti.is_selected;
        }
        m.push(noti);
      }
      return m;
    }, [])
    .sort((a, b) => {
      if (a.event_type < b.event_type) {
        return -1;
      }
      if (a.event_type > b.event_type) {
        return 1;
      }
      return 0;
    });
}
