import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Dialog from '@yojee/ui/base/corners/Dialog';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    margin: theme.spacing(3, 0),
  },
}));

const ReCalculateOrderConfirmDialog: React.FC = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      maxWidth="xs"
      open={true}
      title={t('Order information has changed')}
      primaryBtnText={t('Yes, Recalculate')}
      secondaryBtnText={t('No need')}
      dividers="paper"
      dataCy={{ save: 'yes-recalculate-button', close: 'no-need-button' }}
      {...props}
    >
      <div className={classes.content}>
        {t('Do you want to recalculate price?')}
        <br />
        {t('You can recalculate later!')}
      </div>
    </Dialog>
  );
};

export default ReCalculateOrderConfirmDialog;
