import { get as lodashGet } from 'lodash-es';
import React from 'react';
import { Trans } from 'react-i18next';

/**
 * We have main 5 types of formKeyPath here
 * sender -> sender section
 * order -> order detail section
 * bookingInfoSections.0.legs.0.0 -> step section
 * bookingInfoSections.0.itemDetails.0.item -> item section
 * bookingInfoSections.0.itemDetails.0.container -> container section
 *
 * And other types like:
 * root
 *
 * @param formKeyPath
 * @param formKeysMetaData
 * @param orderFormModels
 * @param taskTypes
 * @returns {{fieldErrors: unknown[] | any[], title: string, order: number}}
 */
export function getError({ formKeyPath, formKeysMetaData, orderFormModels, taskTypes }) {
  let title = '',
    order = 0;
  const numberOfBookingInformationSection = orderFormModels.bookingInfoSections.length || 0;
  let bookingInfoSectionNumber = formKeyPath.split('bookingInfoSections.')?.[1]?.split('.')?.[0];
  bookingInfoSectionNumber = parseInt(bookingInfoSectionNumber || 0);

  if (formKeyPath === 'root') {
    title = '';
    order = -1;
  } else if (formKeyPath === 'sender') {
    title = <Trans>Sender</Trans>;
    order = 0;
  } else if (formKeyPath === 'order') {
    title = <Trans>Order Details</Trans>;
    order = 1;
  } else if (formKeyPath.includes('.legs.')) {
    const legKey = formKeyPath.split('.legs.')[1];
    let [legNumber] = legKey.split('.');
    legNumber = parseInt(legNumber) + 1;

    const taskTypeOfStep = lodashGet(orderFormModels, formKeyPath)?.type;
    const taskTypeName = getTaskTypeName(taskTypeOfStep, taskTypes);

    title = (
      <>
        <Trans>Booking Information</Trans>
        {numberOfBookingInformationSection > 1 ? ` ${bookingInfoSectionNumber}` : ''}, <Trans>Leg</Trans>
        {', '}
        {legNumber}
        {', '}
        {taskTypeName}
      </>
    );
    order = 2;
  } else if (formKeyPath.includes('.itemDetails')) {
    const itemDetailKey = formKeyPath.split('.itemDetails.')[1];
    let [itemNumber] = itemDetailKey.split('.');
    itemNumber = parseInt(itemNumber) + 1;

    const itemTrackingNumber = lodashGet(orderFormModels, formKeyPath)?.tracking_number;
    const itemIndicate = itemTrackingNumber || itemNumber;

    if (formKeyPath.includes('.item_container')) {
      title = (
        <>
          <Trans>Booking Information</Trans>
          {numberOfBookingInformationSection > 1 ? ` ${bookingInfoSectionNumber}` : ''}
          {', '}
          <Trans>Item Details, Item</Trans> {itemIndicate}, <Trans>Container Information</Trans>
        </>
      );
      order = 4;
    } else if (formKeyPath.includes('.item')) {
      title = (
        <>
          <Trans>Booking Information</Trans>
          {numberOfBookingInformationSection > 1 ? ` ${bookingInfoSectionNumber}` : ''}
          {', '}
          <Trans>Item Details, Item</Trans> {itemIndicate}
        </>
      );
      order = 3;
    }
  } else if (formKeyPath === 'order_voyage_info') {
    title = <Trans>Order Voyage Info</Trans>;
    order = 4;
  }

  return {
    title,
    fieldErrors: Object.values(formKeysMetaData[formKeyPath]?.errors),
    order,
  };
}

function getTaskTypeName(value, taskTypes) {
  return taskTypes?.find((taskType) => taskType.slug === value)?.display_name;
}
