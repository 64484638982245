import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { StepProperty } from '@yojee/types';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  fill: {
    padding: '2px 7px 2px 7px',
    borderRadius: '4px',
    backgroundColor: theme.palette.primary.tint,
  },
  originDestinationLabel: {
    fontSize: '11px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
}));

const OriginDestinationTag = ({ stepProperty }: { stepProperty: Exclude<null, StepProperty> }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const labels = {
    origin: t('Origin'),
    destination: t('Destination'),
  };

  return (
    <div className={classes.container}>
      <div className={classes.fill}>
        <label className={classes.originDestinationLabel}>{labels[stepProperty]}</label>
      </div>
    </div>
  );
};

export default OriginDestinationTag;
