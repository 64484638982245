import { Trans } from 'react-i18next';

import { isValidEmail } from '@yojee/auth/utils/AuthUtils';
import { validateUnitValues } from '@yojee/ui/new-order-booking/components/helpers/schemaValidator/unitValidator';

import { VESSEL_DATETIME_KEYS } from '../constants';
import { validateAddressLine1 } from './addressValidator';
import { validateFromToTime } from './fromToTimeValidator';

export function validateVesselDateTime({ schema, model, fieldName, value, errors }) {
  if (!VESSEL_DATETIME_KEYS.includes(fieldName)) return;

  // Reassigning these variables is simply to make things clearer.
  const [dateKey, dateFieldVal] = [fieldName, value];

  // Get time key && time field value.
  const timeKey = dateKey.replace('date', '');
  const timeFieldVal = model?.[timeKey];

  if ((dateFieldVal && timeFieldVal) || (!dateFieldVal && !timeFieldVal)) return;

  if (!dateFieldVal) {
    errors[dateKey] = (
      <>
        {schema[dateKey]?.name} <Trans>is required!</Trans>
      </>
    );

    return;
  }

  errors[timeKey] = (
    <>
      {schema[timeKey]?.name} <Trans>is required!</Trans>
    </>
  );
}

export function getGeneralErrors({ schema, model, isAllowMissingInfo }) {
  const errors = {};

  Object.keys(schema).forEach((fieldName) => {
    const fieldMetadata = schema[fieldName];
    let { required, system_enforced_required, name, type, category } = fieldMetadata;
    const value = model[fieldName];

    required = isAllowMissingInfo ? system_enforced_required : system_enforced_required || required;

    if (required && !value && value !== 0) {
      errors[fieldName] = (
        <>
          {name} <Trans>is required!</Trans>
        </>
      );
    } else if (type === 'positive_number' && value && value <= 0) {
      errors[fieldName] = (
        <>
          {name} <Trans>is invalid</Trans>
        </>
      );
    } else if (type === 'email' && value && !isValidEmail(value)) {
      errors[fieldName] = (
        <>
          {name} <Trans>Invalid email!</Trans>
        </>
      );
    } else if (type === 'datetime' && category === 'vessel') {
      validateVesselDateTime({ schema, model, fieldName, value, errors });
    }
  });

  return errors;
}

function mergeErrors(baseErrors, newErrors) {
  const allErrors = { ...baseErrors };
  Object.keys(newErrors).forEach((fieldName) => {
    allErrors[fieldName] = newErrors[fieldName];
  });

  return allErrors;
}

export const schemaValidator = (schema, options = {}) => {
  return (model) => {
    const { orderFormModels, nonOperationalZones, legIndex, stepIndex, bookingInfoSectionIndex, isAllowMissingInfo } =
      options;

    let errors = getGeneralErrors({ schema, model, isAllowMissingInfo });

    // Below rules only apply for step forms because all step forms schema have address field
    if (schema.address) {
      errors = mergeErrors(
        errors,
        validateFromToTime({
          model,
          errors,
          orderFormModels,
          legIndex,
          stepIndex,
          bookingInfoSectionIndex,
        })
      );
      errors = mergeErrors(errors, validateAddressLine1({ model, errors, nonOperationalZones }));
    }

    errors = mergeErrors(errors, validateUnitValues({ schema, model, errors }));

    if (window.IS_BOOKING_APP) {
      // In booking page, we don't show above fields
      ['from_time_time', 'to_time', 'to_time_time'].forEach((timeField) => {
        if (errors[timeField]) {
          delete errors[timeField];
        }
      });
    }

    if (Object.keys(errors).length) {
      return errors;
    }

    return undefined;
  };
};
