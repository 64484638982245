import cloneDeep from 'lodash-es/cloneDeep';
import React, { useMemo, useState } from 'react';
import { Trans } from 'react-i18next';

import { SubTaskRule, SubTaskRuleAction, SubTaskRuleEvent } from '@yojee/types';
import ActionsTable from '@yojee/ui/base/rooms/ActionsTable';

import SignatureEditDialog from './SignatureEditDialog';

const allowedActionEventMap = {
  [SubTaskRuleAction.UploadSignature]: [
    SubTaskRuleEvent.PickupCompleted,
    SubTaskRuleEvent.PickupFailed,
    SubTaskRuleEvent.DropoffCompleted,
    SubTaskRuleEvent.DropoffFailed,
  ],
  [SubTaskRuleAction.UploadPhoto]: [
    SubTaskRuleEvent.PickupCompleted,
    SubTaskRuleEvent.PickupFailed,
    SubTaskRuleEvent.DropoffCompleted,
    SubTaskRuleEvent.DropoffFailed,
  ],
  [SubTaskRuleAction.SubmitOTP]: [SubTaskRuleEvent.PickupCompleted, SubTaskRuleEvent.DropoffCompleted],
};

type RuleIdentifier = Pick<SubTaskRule, 'event' | 'action'>;

const getAllAllowedRuleIdentifiersForAction = (action: SubTaskRuleAction): RuleIdentifier[] => {
  const allowedEvents = allowedActionEventMap[action];
  return allowedEvents.map((event) => ({ event, action }));
};

const isRuleAllowed = (rule: RuleIdentifier) => allowedActionEventMap[rule.action].includes(rule.event);

const actionColumns = [
  { action: SubTaskRuleAction.UploadSignature, label: <Trans>Upload Signature</Trans> },
  { action: SubTaskRuleAction.UploadPhoto, label: <Trans>Upload Photo</Trans> },
  { action: SubTaskRuleAction.SubmitOTP, label: <Trans>Submit OTP</Trans> },
];

const actionHeaderTooltipMap: { [key in SubTaskRuleAction]?: object } = {
  [SubTaskRuleAction.SubmitOTP]: <Trans>When enabled, this will be an optional action</Trans>,
};

const eventRows = [
  { event: SubTaskRuleEvent.PickupCompleted, label: <Trans>Pick up Completed</Trans> },
  { event: SubTaskRuleEvent.PickupFailed, label: <Trans>Pick up Failed</Trans> },
  { event: SubTaskRuleEvent.DropoffCompleted, label: <Trans>Drop off Completed</Trans> },
  { event: SubTaskRuleEvent.DropoffFailed, label: <Trans>Drop off Failed</Trans> },
];

type HeaderConfig = {
  label: object;
  onChange: (data?: HeaderCheckData) => void;
  data: HeaderCheckData;
  checked: boolean;
  disabled?: boolean;
  headerTooltipConfigs: { title?: object };
};

type HeaderCheckData = {
  checked?: boolean;
  action: SubTaskRuleAction;
};

type BodyConfig = {
  label: object;
  checkboxConfigs: Array<CheckboxConfig>;
};

type CheckboxConfig = {
  checked: boolean;
  data: BodyCheckData;
  onChange: (data?: BodyCheckData) => void;
  secondaryBtnShow: boolean;
  secondaryBtn: (() => void) | null;
  secondaryTooltipConfigs?: { title: object } | null;
  dataCy: string;
  disabled: boolean;
};

type BodyCheckData = RuleIdentifier & { checked?: boolean };

const genRuleKey = (rule: RuleIdentifier) => rule.event + '-' + rule.action;

type RuleMap = Record<string, SubTaskRule>;

const genRuleMap = (subTaskRules: SubTaskRule[]): RuleMap => {
  const map: RuleMap = {};
  subTaskRules.forEach((rule) => {
    map[genRuleKey(rule)] = rule;
  });
  return map;
};

const hasSameIdentifier = (rule1: RuleIdentifier, rule2: RuleIdentifier) => {
  return genRuleKey(rule1) === genRuleKey(rule2);
};

const getCheckedRule = (checkedRuleMap: RuleMap, ruleIdentifier: RuleIdentifier): SubTaskRule | null => {
  const ruleKey = genRuleKey(ruleIdentifier);
  return checkedRuleMap[ruleKey] ? checkedRuleMap[ruleKey] : null;
};

const hasCheckedRule = (checkedRuleMap: RuleMap, ruleIdentifier: RuleIdentifier) => {
  return !!getCheckedRule(checkedRuleMap, ruleIdentifier);
};

const hasCheckedAllAllowedEventRowsForAction = (checkedRuleMap: RuleMap, action: SubTaskRuleAction) => {
  const allowedEvents = allowedActionEventMap[action];

  return allowedEvents.every((event) => {
    const ruleIdentifier = { event, action };
    return hasCheckedRule(checkedRuleMap, ruleIdentifier);
  });
};

const genNewRule = (ruleIdentifier: RuleIdentifier): SubTaskRule => ({
  event: ruleIdentifier.event,
  action: ruleIdentifier.action,
  meta: {},
  optional: ruleIdentifier.action === SubTaskRuleAction.SubmitOTP,
});

type SubTaskRuleTableProps = {
  loading?: boolean;
  readOnly?: boolean;
  subTaskRules: SubTaskRule[];
  onChange?: (newSubTaskRules: SubTaskRule[]) => void;
  onAddRules?: (newRules: SubTaskRule[]) => void;
  onUpdateRule?: (updatedRule: SubTaskRule) => void;
  onDeleteRules?: (deletedRules: SubTaskRule[]) => void;
};

const SubTaskRuleTable: React.FC<SubTaskRuleTableProps> = ({
  loading,
  readOnly,
  subTaskRules,
  onChange,
  onAddRules,
  onUpdateRule,
  onDeleteRules,
}) => {
  const [ruleToEditSigMsg, setRuleToEditSigMsg] = useState<SubTaskRule | null>(null);

  const checkedRuleMap = useMemo(() => genRuleMap(subTaskRules), [subTaskRules]);

  const checkAllRules = (ruleIdentifiers: RuleIdentifier[]) => {
    const rulesToCheck = ruleIdentifiers
      .filter((ruleIdentifier) => !hasCheckedRule(checkedRuleMap, ruleIdentifier))
      .map(genNewRule);

    onAddRules?.(rulesToCheck);
    onChange?.([...subTaskRules, ...rulesToCheck]);
  };

  const uncheckAllRules = (ruleIdentifiers: RuleIdentifier[]) => {
    const rulesToUncheck = ruleIdentifiers
      .filter((ruleIdentifier) => hasCheckedRule(checkedRuleMap, ruleIdentifier))
      .map((ruleIdentifier) => checkedRuleMap[genRuleKey(ruleIdentifier)]);

    const ruleKeySetToUncheck = new Set(rulesToUncheck.map((rule) => genRuleKey(rule)));
    const remainingRules = subTaskRules.filter((rule) => !ruleKeySetToUncheck.has(genRuleKey(rule)));

    onDeleteRules?.(rulesToUncheck);
    onChange?.(remainingRules);
  };

  const onSelectAllCheckboxChange = ({ action, checked }: HeaderCheckData) => {
    const ruleIdentifiersForAction = getAllAllowedRuleIdentifiersForAction(action);
    if (checked) {
      checkAllRules(ruleIdentifiersForAction);
    } else {
      uncheckAllRules(ruleIdentifiersForAction);
    }
  };

  const onRuleCheckboxChange = ({ event, action, checked }: BodyCheckData) => {
    const ruleIdentifier = { event, action };
    const foundCheckedRule = getCheckedRule(checkedRuleMap, ruleIdentifier);

    if (checked && !foundCheckedRule) {
      const newRule = genNewRule(ruleIdentifier);
      const newRules = [...subTaskRules, newRule];
      onAddRules?.([newRule]);
      onChange?.(newRules);
    }

    if (!checked && foundCheckedRule) {
      const newRules = subTaskRules.filter((rule) => !hasSameIdentifier(rule, ruleIdentifier));
      onDeleteRules?.([foundCheckedRule]);
      onChange?.(newRules);
    }
  };

  const onSigMsgEditButtonClick = (ruleIdentifier: RuleIdentifier) => {
    const foundCheckedRule = getCheckedRule(checkedRuleMap, ruleIdentifier);
    const rule = foundCheckedRule ? cloneDeep(foundCheckedRule) : genNewRule(ruleIdentifier);
    setRuleToEditSigMsg(rule);
  };

  const onSigMsgEditChange = (newSigMsg: string) => {
    if (!ruleToEditSigMsg) return;
    const newRule = cloneDeep(ruleToEditSigMsg);
    newRule.meta = { signature_message: newSigMsg };
    setRuleToEditSigMsg(newRule);
  };

  const onSigMsgSave = (editedRule: SubTaskRule | null) => {
    if (!editedRule) return;
    const isNewRule = !hasCheckedRule(checkedRuleMap, editedRule);

    if (isNewRule) {
      const newRules = [...subTaskRules, editedRule];
      onAddRules?.([editedRule]);
      onChange?.(newRules);
    } else {
      const newRules = subTaskRules.filter((rule) => !hasSameIdentifier(rule, editedRule));
      newRules.push(editedRule);
      onUpdateRule?.(editedRule);
      onChange?.(newRules);
    }

    setRuleToEditSigMsg(null);
  };

  const headerConfigs: Array<HeaderConfig> = actionColumns.map(({ action, label }) => {
    const isChecked = hasCheckedAllAllowedEventRowsForAction(checkedRuleMap, action);
    return {
      label,
      data: { action },
      checked: isChecked,
      onChange: () => onSelectAllCheckboxChange({ action, checked: !isChecked }),
      headerTooltipConfigs: {
        title: actionHeaderTooltipMap[action],
      },
      disabled: readOnly,
    };
  });

  const bodyConfigs: Array<BodyConfig> = eventRows.map(({ event, label }) => ({
    label,
    checkboxConfigs: actionColumns.map(({ action }) => {
      const ruleIdentifier = { event, action };

      const isChecked = hasCheckedRule(checkedRuleMap, ruleIdentifier);
      const isDisabled = readOnly || !isRuleAllowed(ruleIdentifier);
      const canEditSignatureMsg = !readOnly && action === SubTaskRuleAction.UploadSignature;

      return {
        checked: isChecked,
        disabled: isDisabled,
        data: ruleIdentifier,
        onChange: () => onRuleCheckboxChange({ ...ruleIdentifier, checked: !isChecked }),
        secondaryBtnShow: canEditSignatureMsg,
        secondaryBtn: canEditSignatureMsg ? () => onSigMsgEditButtonClick(ruleIdentifier) : null,
        secondaryTooltipConfigs: canEditSignatureMsg ? { title: <Trans>Edit signature message</Trans> } : null,
        dataCy: `checkbox-${event}-${action}`,
      };
    }),
  }));

  const tableConfigs = {
    headerConfigs,
    bodyConfigs,
  };

  return (
    <>
      <ActionsTable tableLoading={loading} tableConfigs={tableConfigs} />

      <SignatureEditDialog
        open={!!ruleToEditSigMsg}
        onClose={() => setRuleToEditSigMsg(null)}
        onSave={() => onSigMsgSave(ruleToEditSigMsg)}
        value={ruleToEditSigMsg?.meta?.signature_message || ''}
        onChange={(newSigMsg: string) => onSigMsgEditChange(newSigMsg)}
      />
    </>
  );
};

export default React.memo(SubTaskRuleTable);
