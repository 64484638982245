import { t } from 'i18next';
import cloneDeep from 'lodash-es/cloneDeep';
import React from 'react';
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';

import { PACKING_MODE_OPTIONS } from '@yojee/helpers/constants';
import { volumetricUnicode } from '@yojee/helpers/rating/constant';
import { capitalize, join, pipe, split } from '@yojee/helpers/rating/utilities';

import { Input, TimePicker } from '../base';
import ConjsButtons from './ConjsButtons';
import MaterialButton from './MaterialButton';
import MaterialFieldSelect from './MaterialFieldSelect';
import Operator from './Operator';
import QbSelectWidget from './QbSelectWidget';

export const REVERSED_CONJ = {
  AND: 'OR',
  OR: 'AND',
};

const widgetsCustomProps = {
  boolean: {
    classes: {
      root: 'condition-switch',
    },
  },
  text: {
    'data-cy': 'text-input',
  },
  time: {
    'data-cy': 'time-input',
  },
  select: {
    'data-cy': 'selected-item',
    MenuProps: {
      MenuListProps: {
        'data-cy': 'list-item',
      },
    },
  },
};

const customWidgets = ['boolean', 'select', 'multiselect', 'proximity', 'number', 'text', 'time'].reduce(
  (widgets, widgetType) => ({
    ...widgets,
    [widgetType]: {
      ...MaterialConfig.widgets[widgetType],
      customProps: widgetsCustomProps[widgetType] || {},
      factory: (props) => {
        switch (widgetType) {
          case 'select':
            return <QbSelectWidget qbProps={props} label={null} />;
          case 'multiselect':
            return <QbSelectWidget qbProps={props} isMulti showSum label={null} />;
          case 'number':
            return (
              <Input
                {...props}
                value={props.value || ''}
                onChange={(e) => {
                  props?.setValue(e.target.value);
                }}
                label={null}
                InputProps={{
                  endAdornment: props.customProps.si_unit,
                }}
                inputProps={{
                  min: 0,
                }}
                dataCy="condition-number-input"
              />
            );
          case 'text':
            return (
              <Input
                {...props}
                value={props.value || ''}
                onChange={(e) => {
                  props?.setValue(e.target.value);
                }}
                label={null}
                dataCy="condition-text-input"
              />
            );
          case 'time':
            return (
              <TimePicker
                {...props}
                value={props.value || ''}
                onChange={(e) => {
                  props.setValue(e.target.value);
                }}
                label={null}
                dataCy="condition-time-input"
              />
            );
        }

        return MaterialConfig.widgets[widgetType].factory(props);
      },
    },
  }),
  {}
);

const getDefaultConfig = (fields, addRuleLabel = 'Add Rule') => {
  return {
    ...MaterialConfig,
    fields,
    // Just want to remove the label of proximity operator
    operators: {
      ...MaterialConfig.operators,
      proximity: {
        ...MaterialConfig.operators.proximity,
        options: {
          ...MaterialConfig.operators.proximity.options,
          optionLabel: null,
          optionTextBefore: null,
        },
      },
    },
    widgets: {
      ...MaterialConfig.widgets,
      ...customWidgets,
    },
    settings: {
      ...MaterialConfig.settings,
      // https://github.com/ukrbublik/react-awesome-query-builder/issues/340
      // TODO: Remove this when fixed in core
      renderField: (props) => <MaterialFieldSelect {...props} />,
      renderButton: (props) => <MaterialButton {...props} />,
      renderOperator: (props) => <Operator {...props} />,
      renderConjs: (props) => <ConjsButtons {...props} />,
      showNot: false,
      groupActionsPosition: 'topRight',
      maxNesting: 2,
      addRuleLabel,
      canLeaveEmptyGroup: true,
    },
  };
};

const getFields = (dimensions, prefix = '', customData = {}) => {
  return Object.keys(dimensions).reduce((acc, key) => {
    const prefixedKey = [prefix, key].filter((f) => !!f).join('_');
    const value = dimensions[key];
    const dataType = value['data_type'];
    const dimensionOptions = dimensions[key]?.options ? dimensions[key].options : {};

    const defaultField = {
      label: value.alternative_label || pipe(prefixedKey, split, capitalize, join),
      valueSources: ['value'],
      type: 'text',
      ...dimensionOptions,
    };

    if (
      ['commodity_type', 'container_type', 'packing_mode', 'load_type', 'service_type_name', 'item_types'].includes(key)
    ) {
      const values = customData?.[key] || (listValues[key] ?? []);
      return {
        ...acc,
        [prefixedKey]: {
          ...defaultField,
          type: 'select',
          fieldSettings: {
            listValues: [...values],
            allowCustomValues: true,
          },
        },
      };
    }

    if (dataType === 'boolean') {
      return { ...acc, [prefixedKey]: { ...defaultField, type: 'boolean', operators: ['equal'] } };
    }

    if (dataType === 'naive_datetime_usec') {
      return { ...acc, [prefixedKey]: { ...defaultField, type: 'time' } };
    }

    if (['origin', 'destination'].includes(key)) {
      return {
        ...acc,
        [prefixedKey]: {
          ...defaultField,
          type: '!struct',
          subfields: { ...getFields(addressFields, key) },
        },
      };
    }

    if (['decimal', 'integer'].includes(dataType)) {
      return {
        ...acc,
        [prefixedKey]: {
          ...defaultField,
          type: 'number',
          widgets: {
            number: {
              widgetProps: {
                customProps: {
                  si_unit: value['si_unit'],
                },
                valuePlaceholder: 'Add number',
              },
            },
          },
          operators: ['equal', 'not_equal', 'less', 'less_or_equal', 'greater', 'greater_or_equal'],
        },
      };
    }

    return { ...acc, [prefixedKey]: defaultField };
  }, {});
};

const listValues = {
  commodity_type: [{ value: 'commodity_type', title: 'Commodity Type' }],
  container_type: [{ value: 'container_type', title: 'Container Type' }],
  load_type: [
    { value: 'ftl', title: 'FTL' },
    { value: 'ltl', title: 'LTL' },
  ],
  service_type: [
    { value: 'same_day', title: 'Same Day' },
    { value: 'next_day', title: 'Next Day' },
  ],
  item_type: [
    { value: 'container', title: 'Container' },
    { value: 'pallet', title: 'Pallet' },
  ],
  packing_mode: Object.keys(PACKING_MODE_OPTIONS).map((k) => ({ value: k, title: PACKING_MODE_OPTIONS[k] })),
};

const addressFields = {
  from_time: {
    base: 'time',
    category: 'leg',
    data_type: 'naive_datetime_usec',
    options: {
      excludeOperators: ['proximity', 'starts_with', 'ends_with'],
    },
  },
  to_time: {
    base: 'time',
    category: 'leg',
    data_type: 'naive_datetime_usec',
    options: {
      excludeOperators: ['proximity', 'starts_with', 'ends_with'],
    },
  },
  postal_code: {
    base: 'postal_code',
    category: 'leg',
    data_type: 'string',
    options: {
      excludeOperators: ['proximity', 'starts_with', 'ends_with'],
    },
  },
  city: {
    base: 'city',
    category: 'leg',
    data_type: 'string',
    options: {
      excludeOperators: ['proximity', 'starts_with', 'ends_with'],
    },
  },
  state: {
    base: 'state',
    category: 'leg',
    data_type: 'string',
    options: {
      excludeOperators: ['proximity', 'starts_with', 'ends_with'],
    },
  },
  country_code: {
    base: 'country',
    category: 'leg',
    data_type: 'string',
    options: {
      excludeOperators: ['proximity', 'starts_with', 'ends_with'],
    },
  },
};

const dimensions = {
  distance: {
    base: 'distance',
    category: 'leg',
    data_type: 'decimal',
    si_unit: 'm',
  },
  duration: {
    base: 'duration',
    category: 'leg',
    data_type: 'decimal',
    si_unit: 's',
  },
  packing_mode: {
    base: 'service_type',
    category: 'order',
    data_type: 'string',
  },
  item_types: {
    base: 'item_types',
    category: 'order',
    data_type: 'string',
    alternative_label: t('Item Type'),
  },
  max_height: {
    base: 'length',
    category: 'item',
    data_type: 'decimal',
    si_unit: 'm',
  },
  max_length: {
    base: 'length',
    category: 'item',
    data_type: 'decimal',
    si_unit: 'm',
  },
  max_width: {
    base: 'length',
    category: 'item',
    data_type: 'decimal',
    si_unit: 'm',
  },
  origin: {
    data_type: 'struct',
    category: 'leg',
    base: 'address',
  },
  destination: {
    data_type: 'struct',
    category: 'leg',
    base: 'address',
  },
  quantity: {
    base: 'quantity',
    category: 'item',
    data_type: 'integer',
  },
  service_type_name: {
    base: 'service_type_name',
    category: 'order',
    data_type: 'string',
    alternative_label: t('Service Type'),
  },
  volume: {
    base: 'volume',
    category: 'item',
    data_type: 'decimal',
    si_unit: `m${volumetricUnicode}`,
  },
  volumetric_weight: {
    base: 'volume',
    category: 'item',
    data_type: 'decimal',
    si_unit: `m${volumetricUnicode}`,
  },
  weight: {
    base: 'weight',
    category: 'item',
    data_type: 'decimal',
    si_unit: 'kg',
  },
};

const matchingParamsDimensions = {
  service_type_name: {
    base: 'service_type',
    category: 'order',
    data_type: 'string',
    alternative_label: t('Service Type'),
  },
  packing_mode: {
    base: 'service_type',
    category: 'order',
    data_type: 'string',
  },
};

const CUSTOM_SELECTED_OPERATORS = {
  select_equals: 'has_this',
  select_not_equals: 'not_has_this',
  select_any_in: 'has',
  select_not_any_in: 'not_has',
};

function defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
  } else {
    obj[key] = value;
  }
  return obj;
}

function getCustomConfig(defaultConfig) {
  const config = cloneDeep(defaultConfig);

  // custom operators config
  const customOperatorConfig = Object.keys(CUSTOM_SELECTED_OPERATORS).reduce((ops, op) => {
    if (ops[op]) {
      ops[op].jsonLogic = (field, _, val) => {
        if (field.var === 'item_types') return defineProperty({}, CUSTOM_SELECTED_OPERATORS[op], [field, val]);

        if (op === 'select_not_any_in') return { '!': defineProperty({}, 'in', [field, val]) };

        return defineProperty({}, defaultConfig.operators[op].jsonLogic, [field, val]);
      };
    }

    return ops;
  }, config.operators);

  // update custom operators config to default config
  config.operators = customOperatorConfig;

  return config;
}

function formatJsonLogic(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (!Object.values(CUSTOM_SELECTED_OPERATORS).includes(key) && typeof obj[key] === 'object') {
        formatJsonLogic(obj[key]);
      }

      switch (key) {
        case CUSTOM_SELECTED_OPERATORS.select_equals:
          obj['=='] = obj[key];
          break;

        case CUSTOM_SELECTED_OPERATORS.select_not_equals:
          obj['!='] = obj[key];
          break;

        case CUSTOM_SELECTED_OPERATORS.select_any_in:
          obj['in'] = obj[key];
          break;

        case CUSTOM_SELECTED_OPERATORS.select_not_any_in:
          obj['!'] = { in: obj[key] };
          break;
      }

      if (Object.values(CUSTOM_SELECTED_OPERATORS).includes(key)) {
        delete obj[key];
      }
    }
  }

  return obj;
}

export {
  CUSTOM_SELECTED_OPERATORS,
  dimensions,
  formatJsonLogic,
  getCustomConfig,
  getDefaultConfig,
  getFields,
  matchingParamsDimensions,
};
