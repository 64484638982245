import './style.scss';

import { Collapse } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { debounce as lodashDebounce } from 'lodash-es';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AutoForm } from 'uniforms-material';

import { submitAllForms } from '@yojee/ui/new-order-booking/components/OrderForm/OrderActions/helpers';

import {
  getCompanyDeliverOptionsSelector,
  getFormRefsSelector,
  getItemTypesSelector,
  getNonOperationalZonesSelector,
  getOrderFormModelsSelector,
  getOrderInfoSelector,
  isFormModelHaveErrorSelector,
} from '../../../selectors';
import { SchemaHelperContext } from '../../helpers/Contexts';
import { getFormKeyPath } from '../../helpers/formHelpers';
import { useIsReadOnlyOrderSection, useRegisterForm, useSchema } from '../../helpers/hooks';
import CustomAutoField from '../CustomFields/CustomAutoField';
import HandleSyncFormStatusForAutoForm from '../CustomFields/HandleSyncFormStatusForAutoForm';
import { useIsShowLoadingFormStatus } from '../helper';
import OrderHeader from './OrderHeader';

const OrderSection = () => {
  const dispatch = useDispatch();
  const orderInfoData = useSelector(getOrderInfoSelector);
  const isReadOnlyOrderSection = useIsReadOnlyOrderSection();
  const schemaHelper = useContext(SchemaHelperContext);
  const [formOpened, setFormOpened] = useState(true);
  const formKeyPath = getFormKeyPath({ type: 'order' });
  const isHaveError = useSelector((state) => isFormModelHaveErrorSelector(state, formKeyPath));
  const bridge = useSchema(schemaHelper.orderSchema, orderInfoData);
  const { setFormRef } = useRegisterForm(formKeyPath);
  const subFields = useMemo(() => bridge.getSubfields(), [bridge]);
  const isShowFormLoadingStatus = useIsShowLoadingFormStatus(subFields);
  const formRefs = useSelector(getFormRefsSelector);
  const orderFormModels = useSelector(getOrderFormModelsSelector);
  const itemTypes = useSelector(getItemTypesSelector);
  const nonOperationalZones = useSelector(getNonOperationalZonesSelector);
  const deliveryOptions = useSelector(getCompanyDeliverOptionsSelector);

  const highlightMissingFields = useCallback(() => {
    const isMissingInfoOrder = !!Object.keys(orderInfoData?.['missing_info'] || {}).length;
    if (isMissingInfoOrder) {
      submitAllForms({
        formRefs,
        orderFormModels,
        dispatch,
        schemaHelper,
        nonOperationalZones,
        deliveryOptions,
        itemTypes,
      });
    }
  }, [
    orderInfoData,
    formRefs,
    orderFormModels,
    dispatch,
    schemaHelper,
    nonOperationalZones,
    deliveryOptions,
    itemTypes,
  ]);

  const highlightMissingFieldsDebounceFunc = useMemo(
    () => lodashDebounce(highlightMissingFields, 3000),
    [highlightMissingFields]
  );

  useEffect(() => {
    highlightMissingFieldsDebounceFunc();

    return () => {
      highlightMissingFieldsDebounceFunc.cancel();
    };
  }, [highlightMissingFieldsDebounceFunc]);

  return (
    <section
      className={`mt-15 new-booking-order-form-section ${formOpened ? '' : 'form-closed'} ${
        isHaveError ? 'error' : ''
      }`}
      data-cy="booking-form-order-section"
    >
      <OrderHeader orderInfoData={orderInfoData} formOpened={formOpened} setFormOpened={setFormOpened} />

      <Collapse in={formOpened} timeout="auto">
        <div className="form-body-section ">
          {isShowFormLoadingStatus ? (
            <div className="new-booking-order-form">
              <Skeleton height={80} />
              <Skeleton height={80} />
              <Skeleton height={80} />
            </div>
          ) : (
            <AutoForm
              className="new-booking-order-form"
              autoField={CustomAutoField}
              ref={setFormRef}
              schema={bridge}
              model={orderInfoData}
              showInlineError={true}
              disabled={isReadOnlyOrderSection}
            >
              {subFields.map((fieldName) => (
                <CustomAutoField key={fieldName} name={fieldName} />
              ))}
              <HandleSyncFormStatusForAutoForm formKeyPath={formKeyPath} />
            </AutoForm>
          )}
        </div>
      </Collapse>
    </section>
  );
};

export default React.memo(OrderSection);
