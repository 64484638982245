import { makeStyles } from '@material-ui/core/styles';
import React, { useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import InformationStyleDialog from '../../../../../../dialog/InformationStyle';
import {
  getFormKeysMetaDataSelector,
  getOrderFormModelsSelector,
  getTaskTypesSelector,
} from '../../../../../selectors';
import { getError } from './helpers';

const useStyles = makeStyles({
  errorContainer: {
    textAlign: 'left',
    color: '#dd5147',
  },
  errorHeader: {
    fontWeight: 'bold',
  },
});

const ErrorsDialog = ({ setIsShowErrorsDialog }) => {
  const classes = useStyles();
  const formKeysMetaData = useSelector(getFormKeysMetaDataSelector);
  const orderFormModels = useSelector(getOrderFormModelsSelector);
  const taskTypes = useSelector(getTaskTypesSelector);

  const body = useMemo(() => {
    const formKeyPathsHaveError = Object.keys(formKeysMetaData)
      .filter((formKeyPath) => !!formKeysMetaData[formKeyPath]?.errors)
      .sort();

    if (formKeyPathsHaveError?.length) {
      const errors = formKeyPathsHaveError
        .map((formKeyPath) => getError({ formKeyPath, formKeysMetaData, orderFormModels, taskTypes }))
        .sort((error1, error2) => (error1.order > error2.order ? 1 : -1));

      return (
        <ul className={classes.errorContainer}>
          {errors.map((error, index) => {
            if (error.title) {
              return (
                <React.Fragment key={index}>
                  <li key={`title-${index}`} className={classes.errorHeader} data-cy="error-section">
                    {error.title}
                  </li>
                  <ul key={`children-${index}`} data-cy="error-list-fields">
                    {error.fieldErrors.map((fieldError, fieldErrorIndex) => (
                      <li key={fieldErrorIndex} data-cy="error-field">
                        {fieldError}
                      </li>
                    ))}
                  </ul>
                </React.Fragment>
              );
            } else {
              return error.fieldErrors.map((fieldError, fieldErrorIndex) => (
                <li key={`root-error-${fieldErrorIndex}`}>{fieldError}</li>
              ));
            }
          })}
        </ul>
      );
    }

    return null;
  }, [classes.errorContainer, classes.errorHeader, formKeysMetaData, orderFormModels, taskTypes]);

  const onClose = () => {
    setIsShowErrorsDialog(false);
  };

  return (
    <InformationStyleDialog
      open={true}
      title={<Trans>Please resolve errors</Trans>}
      body={body}
      onClose={onClose}
      onOk={onClose}
      dataCy="booking-validation-dailog"
    />
  );
};
export default ErrorsDialog;
