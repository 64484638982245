import { useSelector } from 'react-redux';

import { CompanyData } from './getCompanyDefaultData';

export const useCompanyCountry = () => {
  const country = useSelector(
    (state: { company?: { companyData: CompanyData } }) => state.company?.companyData.country
  );

  return country;
};
