import useMediaQuery from '@material-ui/core/useMediaQuery';
import _ from 'lodash-es';
import moment from 'moment';
import { DateUtils } from 'react-day-picker';

import { FORMAT_DATE_FOR_MOMENT } from '@yojee/helpers/constants';

export const isString = (target) => {
  return target && typeof target === 'string';
};

export const isEmpty = (target, noWhiteSpace = false, noZero = false) => {
  return (
    isNull(target) ||
    target === '' ||
    (noWhiteSpace ? isString(target) && target.trim().length === 0 : false) ||
    (noZero ? target === 0 : false)
  );
};

export const isNotEmpty = (target, noWhiteSpace = false, noZero = false) => {
  return !isEmpty(target, noWhiteSpace, noZero);
};

export const isNotNull = (target) => {
  return !isNull(target);
};

export const isNumber = (target) => {
  return isNotEmpty(target, true) && !isNaN(target);
};

export const parseNumber = (target) => {
  return isNumber(target) ? Number(target) : 0;
};

export const isNull = (target) => {
  return target === undefined || target === null;
};

export const parseMinutes = (minutes) => {
  if (isNull(minutes)) {
    return '';
  }
  minutes = parseNumber(minutes);
  return minutes < 10 ? `0${minutes}` : minutes;
};

export const parseHours = (hour) => {
  if (isNull(hour)) {
    return '';
  }
  hour = parseNumber(hour);
  return hour < 10 ? `0${hour}` : hour;
};

export const getValueFromSlots = (timeslots) => {
  timeslots = _.omitBy(timeslots, _.isEmpty);
  const _slotGroup = timeslots?.[Object.keys(timeslots)?.[0]];
  if (_slotGroup && _slotGroup.length > 0) {
    const _slotData = _slotGroup[0];
    const { pickUpFrom, pickUpTo, dropOffFrom, dropOffTo } = _slotData;
    const _pickUpFromDateObj = moment(pickUpFrom);
    const _pickUpToDateObj = moment(pickUpTo);
    const _dropOffFromDateObj = moment(dropOffFrom);
    const _dropOffToDateObj = moment(dropOffTo);
    return {
      pickup: {
        from: _pickUpFromDateObj,
        to: _pickUpToDateObj,
      },
      dropoff: {
        from: _dropOffFromDateObj,
        to: _dropOffToDateObj,
      },
    };
  }
  return {
    pickup: null,
    dropoff: null,
  };
};

export const buildDateTimeValue = (type, dateTimeObject, legTimeSlots) => {
  return {
    from_time: dateTimeObject?.[type]?.from || null,
    from_time_time: dateTimeObject?.[type]?.from || null,
    to_time: dateTimeObject?.[type]?.to || null,
    to_time_time: dateTimeObject?.[type]?.to || null,
    timeslots: !isEmpty(legTimeSlots) ? legTimeSlots : null,
  };
};

export const fillDateTimeWhenGetTimeSlots = (legFormRef, dateTimeObject, options, legTimeSlots = null) => {
  if (legFormRef) {
    legFormRef?.forEach((stepForm) => {
      const type = stepForm?.props?.model?.type;
      const data = buildDateTimeValue(type, dateTimeObject, legTimeSlots);

      if (options?.serviceRequiredTimeslot && _.isEmpty(legTimeSlots)) {
        stepForm.change('no_slots', true);
        if (type === 'pickup') {
          data.from_time = options?.from_time || new Date();
        }
      } else {
        if (type === 'pickup') {
          stepForm.change('dropoff_date', data?.from_time);
        }
        stepForm.change('no_slots', false);
      }

      Object.keys(data).forEach((key) => {
        stepForm?.change(key, data[key]);
      });
    });
  }
};

export const buildPickUpTimeOptions = (timeslots) => {
  const options = {};
  Object.keys(timeslots).forEach((key) => {
    const _slotGroup = timeslots[key];
    if (_slotGroup && _slotGroup.length > 0) {
      const _slotData = _slotGroup[0];
      const { pickUpFrom, pickUpTo } = _slotData;
      const _pickUpFromDateObj = new Date(pickUpFrom);
      const _pickUpToDateObj = new Date(pickUpTo);
      const _pickUpFromMinutes = parseMinutes(_pickUpFromDateObj.getMinutes());
      const _pickUpToMinutes = parseMinutes(_pickUpToDateObj.getMinutes());
      const _pickUpFromHour = parseHours(_pickUpFromDateObj.getHours());
      const _pickUpToHour = parseHours(_pickUpToDateObj.getHours());
      options[key] = `${_pickUpFromHour}:${_pickUpFromMinutes} - ${_pickUpToHour}:${_pickUpToMinutes}`;
    }
    return null;
  });
  return options;
};

export const buildDropOffOptions = (timeslots, dropOffDate) => {
  const options = {};
  timeslots.map((slot) => {
    const { dropOffFrom, dropOffTo } = slot;
    const _dropOffFromDateObj = new Date(dropOffFrom);
    const _dropOffToDateObj = new Date(dropOffTo);
    if (DateUtils.isSameDay(_dropOffFromDateObj, new Date(dropOffDate))) {
      const _dropOffFromMinutes = parseMinutes(_dropOffFromDateObj.getMinutes());
      const _dropOffToMinutes = parseMinutes(_dropOffToDateObj.getMinutes());
      const _dropOffFromHour = parseHours(_dropOffFromDateObj.getHours());
      const _dropOffToHour = parseHours(_dropOffToDateObj.getHours());
      options[
        `${_dropOffFromDateObj.getUTCHours()}:${_dropOffFromMinutes}-${_dropOffToDateObj.getUTCHours()}:${_dropOffToMinutes}`
      ] = `${_dropOffFromHour}:${_dropOffFromMinutes} - ${_dropOffToHour}:${_dropOffToMinutes}`;
    }
    return null;
  });
  return options;
};

export const fillDropOffOptionsWhenGetTimeSlots = (legFormRef, dropOffSlots, pickUpTime = null) => {
  if (legFormRef) {
    legFormRef?.forEach((stepForm) => {
      const type = stepForm?.props?.model?.type;

      if (pickUpTime) {
        stepForm.change('pickup_time', pickUpTime);
      }

      if (type === 'dropoff') {
        stepForm.change('dropoff_slots', dropOffSlots);
      }
    });
  }
};

export function buildTimeFromPeriod(date, periods) {
  const fromAndToTime = periods.split('-');
  const isTomorrow = parseInt(fromAndToTime?.[0]) > parseInt(fromAndToTime?.[1]);

  const from_time = new Date(moment(date).format(FORMAT_DATE_FOR_MOMENT) + ' ' + fromAndToTime?.[0]);
  const to_time = new Date(moment(date).format(FORMAT_DATE_FOR_MOMENT) + ' ' + fromAndToTime?.[1]);

  return { from_time, to_time, isTomorrow };
}

export function fillFromAndToTimeFromPeriod(legFormRef, date, periods, type) {
  if (legFormRef) {
    const datetimeObject = buildTimeFromPeriod(date, periods);
    legFormRef?.forEach((stepForm) => {
      const stepType = stepForm?.props?.model?.type;
      if (stepType === type) {
        stepForm.change('from_time_time', moment(datetimeObject?.from_time).seconds(0).milliseconds(0));
        stepForm.change('to_time_time', moment(datetimeObject?.to_time).seconds(0).milliseconds(0));
        const newDate = datetimeObject?.isTomorrow
          ? moment(date)
              .set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              })
              .add(1, 'days')
          : moment(date);
        stepForm.change('to_time', newDate);
      }
    });
  }
}

export const getDropoffDates = (slots, pickUpTime) => {
  let _enabledDays = [];
  if (slots && pickUpTime && slots[pickUpTime]) {
    _enabledDays = slots[pickUpTime].reduce((arr, currentSlot) => {
      if (currentSlot.dropOffFrom) {
        const _dropOffFromMoment = moment(currentSlot.dropOffFrom);
        if (arr.length === 0 || !arr.find((day) => day.isSame(_dropOffFromMoment, 'day'))) {
          arr.push(_dropOffFromMoment);
        }
      }
      return arr;
    }, []);
  }
  return _enabledDays;
};

export const getPickUpTime = (fromDate) => {
  const _today = new Date();
  if (fromDate?.toDateString() === _today.toDateString()) {
    return fromDate;
  }
  return moment(fromDate).startOf('day');
};

export function useIsCanShowMap() {
  return useMediaQuery('(min-width:1280px)');
}

export const isReadOnlyOrderSection = ({ isForceOrderFormReadOnly, globalReadOnly, isEditOrder, orderInfoData }) => {
  return isForceOrderFormReadOnly || globalReadOnly || (isEditOrder && orderInfoData?.editable === false);
};

export const BOOKING_TEMPLATE_CONSUMER_MAP = {
  bookingForm: 'booking_form',
  batchUpload: 'batch_upload',
};

export const isVesselCategoryField = (field) => field.category === 'vessel';
