import './styles/style.scss';

import { Collapse } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AutoForm } from 'uniforms-material';

import { deleteContainerData } from '@yojee/ui/new-order-booking/saga/actions';
import { getItemTypesSelector } from '@yojee/ui/new-order-booking/selectors';

import { SchemaHelperContext } from '../../../../../helpers/Contexts';
import { getFormKeyPath } from '../../../../../helpers/formHelpers';
import { useIsReadOnlyItem, useRegisterForm, useSchema } from '../../../../../helpers/hooks';
import CustomAutoField from '../../../../CustomFields/CustomAutoField';
import HandleAutoFormInVirtualizationList from '../../../../CustomFields/HandleAutoFormInVirtualizationList';
import HandleSyncFormStatusForAutoForm from '../../../../CustomFields/HandleSyncFormStatusForAutoForm';
import { useIsShowLoadingFormStatus } from '../../../../helper';
import { BookingInfoSectionContext } from '../../../Contexts';
import ContainerSection from '../ContainerSection';
import { isContainerItemType } from './isContainerItemType';
import ItemHeader from './ItemHeader';

const ItemSection = ({
  itemDetail: { item_container: container, item: actualItem },
  itemIndex,
  totalItem,
  removeItem,
  addNewItem,
  onChangeItemDetail,
}) => {
  const itemTypes = useSelector(getItemTypesSelector);
  const dispatch = useDispatch();
  const [formOpened, setFormOpened] = useState(true);
  const [isShowContainerForm, setIsShowContainerForm] = useState(false);
  const { bookingInfoSectionIndex } = useContext(BookingInfoSectionContext);
  const formKeyPath = getFormKeyPath({ bookingInfoSectionIndex, itemIndex, type: 'item' });
  const schemaHelper = useContext(SchemaHelperContext);
  const bridge = useSchema(schemaHelper.itemSchema, actualItem);
  const itemFormModel = useMemo(() => ({ ...bridge.getDefaultModel(), ...actualItem }), [bridge, actualItem]);
  const isReadOnlyItem = useIsReadOnlyItem();
  const { setFormRef, formRef } = useRegisterForm(formKeyPath);
  const subFields = useMemo(
    () =>
      bridge.getSubfields({
        exceptFields: [
          'width_unit',
          'length_unit',
          'weight_unit',
          'height_unit',
          'volume_unit',
          'volumetric_weight_unit',
        ],
      }),
    [bridge]
  );
  const isShowFormLoadingStatus = useIsShowLoadingFormStatus(subFields);
  const isHaveContainerField = Object.keys(schemaHelper.originalContainerSchema).length > 0;

  const hasValueForContainerField = () => {
    const f = Object.keys(schemaHelper.containerSchema);
    return f.some((x) => {
      return container && container[x] != null;
    });
  };

  const resetContainerData = () => {
    const containerFormKeyPath = getFormKeyPath({ bookingInfoSectionIndex, itemIndex, type: 'container' });
    dispatch(deleteContainerData(containerFormKeyPath));
  };

  const onChangeForm = (model) => {
    const payload = { modelData: { ...model }, index: itemIndex };

    // This component is being used as the children component of Virtuoso component, when scrolling, the Virtuoso component will
    // force to re-render the children components, then all the data input from user will be clear.
    // This handler is to get the latest data from Form and update the data to the item in order to keep the data which is not yet
    // saving
    onChangeItemDetail(payload);

    const isShownContainerSection = isContainerItemType(model.payload_type, itemTypes) && isHaveContainerField;
    setIsShowContainerForm(isShownContainerSection);

    if (!isShownContainerSection) {
      resetContainerData();
    }
  };

  useEffect(() => {
    setIsShowContainerForm(
      (isContainerItemType(itemFormModel?.payload_type, itemTypes) && isHaveContainerField) ||
        hasValueForContainerField()
    );
  }, [itemFormModel]);

  return (
    <div className="item-container-wrapper">
      <section className={`form-body-section item-section ${formOpened ? '' : 'close'}`} data-cy="item-section">
        <ItemHeader
          item={actualItem}
          itemIndex={itemIndex}
          totalItem={totalItem}
          formKeyPath={formKeyPath}
          removeItem={removeItem}
          addNewItem={addNewItem}
          formOpened={formOpened}
          setFormOpened={setFormOpened}
        />

        <Collapse in={formOpened} timeout="auto" className="item-form-collapse-wrapper">
          <AutoForm
            className="item-form"
            autoField={CustomAutoField}
            model={itemFormModel}
            ref={setFormRef}
            schema={bridge}
            onChangeModel={onChangeForm}
            showInlineError={true}
            disabled={isReadOnlyItem}
          >
            {isShowFormLoadingStatus ? (
              Array(12)
                .fill(1)
                .map((_item, index) => <Skeleton key={index} height={100} />)
            ) : (
              <>
                {subFields.map((fieldName) => (
                  <CustomAutoField itemIndex={itemIndex} key={fieldName} name={fieldName} />
                ))}
                <HandleSyncFormStatusForAutoForm formKeyPath={formKeyPath} />
                <HandleAutoFormInVirtualizationList formRef={formRef} formKeyPath={formKeyPath} />
              </>
            )}
          </AutoForm>

          {isShowContainerForm && (
            <ContainerSection itemIndex={itemIndex} container={container} isReadOnlyItem={isReadOnlyItem} />
          )}
        </Collapse>
      </section>
    </div>
  );
};

export default React.memo(ItemSection);
