import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Dialog from '@yojee/ui/base/corners/Dialog';
import TextArea from '@yojee/ui/base/corners/TextArea';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    marginTop: theme.spacing(2.5),
  },
}));

const SignatureEditDialog = ({ open, onClose, onSave, value, onChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [error, setError] = useState('');

  const handleSave = () => {
    if (!!value === false) {
      setError(t('Your signature message is empty!'));
      return;
    }

    onSave();
  };

  const handleOnChange = (e) => {
    onChange(e.target.value);
    setError('');
  };

  const handleOnClose = () => {
    setError('');
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleOnClose()}
      onSave={() => handleSave()}
      maxWidth="xs"
      title={t('Edit Signature Message')}
    >
      <div className={classes.dialogContent}>
        <TextArea
          fullWidth
          minRows={20}
          maxRows={5}
          value={value}
          onChange={(e) => handleOnChange(e)}
          showWordCount={true}
          maxLength={300}
          error={!!error}
          helperText={error}
        />
      </div>
    </Dialog>
  );
};

export default SignatureEditDialog;
