import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import useService from '@yojee/helpers/hooks/useService';
import { updateState } from '@yojee/ui/new-order-booking/saga/actions';
import serviceLocator from '@yojee/ui/new-order-booking/serviceLocator';

import {
  getChargesDataSelector,
  getCompanyDeliverOptionsSelector,
  getFormKeysMetaDataSelector,
  getFormRefsSelector,
  getItemTypesSelector,
  getNonOperationalZonesSelector,
  getOrderFormModelsSelector,
  getOrderSenderId,
  getOrganisationsSelector,
  isServiceTypeChangedSelector,
} from '../../../selectors';
import { SchemaHelperContext } from '../../helpers/Contexts';
import { useIsEditOrder, useIsOrderAlreadyPaid, useIsUsingProformaInvoiceFeature } from '../../helpers/hooks';
import { getItemsHaveVolumeMismatchSystemCalculate } from '../../helpers/schemaValidator/volumeValidator';
import ChangeServiceTypeConfirmDialog from './dialogs/ChangeServiceTypeConfirmDialog';
import ErrorsDialog from './dialogs/ErrorsDialog';
import MismatchVolumeConfirmDialog from './dialogs/MismatchVolumeConfirmDialog';
import MissingInfoConfirmDialog from './dialogs/MissingInfoConfirmDialog';
import { canCreateOrderWithMissingInfo, resetVolumeToSystemCalculate, submitAllForms } from './helpers';

/**
 * Checks:
 * 1. Is order pass validation
 * 2. Is order allow missing information. If allow then show confirmation
 * 3. Is order have mismatch volume calculation. If have then show confirmation
 * @returns {{doChecks: ((function(*): Promise<void>)|*), getChecksConfirmDialogs: (function({onConfirmOkMissingInfo: *, onConfirmOkVolumeMismatch: *}))}}
 */
export function usePerformVariousChecksForOrder() {
  const dispatch = useDispatch();
  const isEditOrder = useIsEditOrder();
  const formRefs = useSelector(getFormRefsSelector);
  const orderFormModels = useSelector(getOrderFormModelsSelector);
  const nonOperationalZones = useSelector(getNonOperationalZonesSelector);
  const deliveryOptions = useSelector(getCompanyDeliverOptionsSelector);
  const formKeysMetaData = useSelector(getFormKeysMetaDataSelector);
  const schemaHelper = useContext(SchemaHelperContext);
  const itemTypes = useSelector(getItemTypesSelector);
  const isServiceTypeChanged = useSelector(isServiceTypeChangedSelector);

  const [isShowConfirmVolumeMismatch, setIsShowConfirmVolumeMismatch] = useState(false);
  const [itemsHaveMismatchVolume, setItemsHaveMismatchVolume] = useState([]);
  const [isShowConfirmMissingInfo, setIsShowConfirmMissingInfo] = useState(false);
  const [isShowErrorsDialog, setIsShowErrorsDialog] = useState(false);
  const [serviceTypeChangedAlertDialog, setServiceTypeChangedAlertDialog] = useState({
    open: false,
    onConfirm: null,
  });

  const performACheckVolumeMismatch = useCallback(() => {
    const _itemsHaveMismatchVolume = getItemsHaveVolumeMismatchSystemCalculate(formKeysMetaData);
    if (_itemsHaveMismatchVolume.length) {
      setIsShowConfirmVolumeMismatch(true);
      setItemsHaveMismatchVolume(_itemsHaveMismatchVolume);

      return true;
    }

    return false;
  }, [formKeysMetaData]);

  const doChecks = useCallback(
    async (callbackAction) => {
      const isAllPass = await submitAllForms({
        formRefs,
        orderFormModels,
        dispatch,
        schemaHelper,
        nonOperationalZones,
        deliveryOptions,
        itemTypes,
      });

      const allowsMissingInfo = canCreateOrderWithMissingInfo({
        schemaHelper,
        orderFormModels,
        nonOperationalZones,
        itemTypes,
      });

      if (isAllPass && !isServiceTypeChanged) {
        const isHaveMismatchVolume = performACheckVolumeMismatch();
        if (!isHaveMismatchVolume) {
          callbackAction();
        }
      } else if (isAllPass && isServiceTypeChanged) {
        setServiceTypeChangedAlertDialog((prev) => ({
          onConfirm: null,
          open: true,
        }));
      } else if (allowsMissingInfo && isServiceTypeChanged) {
        setServiceTypeChangedAlertDialog({
          open: true,
          onConfirm: () => {
            // Close the service type changed alert dialog
            setServiceTypeChangedAlertDialog((prev) => ({
              onConfirm: prev.onConfirm,
              open: false,
            }));

            // Then open the missing infor one.
            setIsShowConfirmMissingInfo(true);
          },
        });
      } else if (allowsMissingInfo) {
        setIsShowConfirmMissingInfo(true);
      } else {
        setIsShowErrorsDialog(true);
      }
    },
    [
      dispatch,
      formRefs,
      nonOperationalZones,
      orderFormModels,
      performACheckVolumeMismatch,
      schemaHelper,
      isServiceTypeChanged,
      deliveryOptions,
      itemTypes,
      // allowsMissingInfo,
    ]
  );

  const resetVolume = useCallback(() => {
    resetVolumeToSystemCalculate({ dispatch, formKeysMetaData, orderFormModels, formRefs });
  }, [dispatch, formKeysMetaData, formRefs, orderFormModels]);

  const getConfirmDialogs = useCallback(
    ({ onConfirmOkMissingInfo, onConfirmOkVolumeMismatch, onConfirmToChangeServiceType }) => {
      const _onConfirmOkMissingInfo = () => {
        const isHaveMismatch = performACheckVolumeMismatch();
        if (!isHaveMismatch) {
          onConfirmOkMissingInfo();
        }
      };

      return (
        <>
          {isShowConfirmMissingInfo && (
            <MissingInfoConfirmDialog
              setIsShowConfirmMissingInfo={setIsShowConfirmMissingInfo}
              onOk={_onConfirmOkMissingInfo}
              bodyText={
                isEditOrder ? 'Order will be updated with Missing info status, Do you want to proceed?' : undefined
              }
            />
          )}

          {isShowConfirmVolumeMismatch && (
            <MismatchVolumeConfirmDialog
              setIsShowConfirmVolumeMismatch={setIsShowConfirmVolumeMismatch}
              onOk={onConfirmOkVolumeMismatch}
              onCancel={resetVolume}
              itemTrackingNumbers={itemsHaveMismatchVolume}
            />
          )}

          {serviceTypeChangedAlertDialog.open && isEditOrder && (
            <ChangeServiceTypeConfirmDialog
              onClose={() => {
                setServiceTypeChangedAlertDialog((prev) => ({
                  onConfirm: prev.onConfirm,
                  open: false,
                }));
              }}
              onConfirm={() => {
                if (serviceTypeChangedAlertDialog.onConfirm) {
                  serviceTypeChangedAlertDialog.onConfirm();
                } else {
                  onConfirmToChangeServiceType();
                }
              }}
            />
          )}

          {isShowErrorsDialog && <ErrorsDialog setIsShowErrorsDialog={setIsShowErrorsDialog} />}
        </>
      );
    },
    [
      isEditOrder,
      isShowConfirmMissingInfo,
      isShowConfirmVolumeMismatch,
      isShowErrorsDialog,
      serviceTypeChangedAlertDialog,
      itemsHaveMismatchVolume,
      performACheckVolumeMismatch,
      resetVolume,
    ]
  );

  return { doChecks, getConfirmDialogs };
}

export function useIsPreviewBtnShowed(isInvoiceGenerated) {
  const isUsingProformaInvoiceFeature = useIsUsingProformaInvoiceFeature();
  const isOrderAlreadyPaid = useIsOrderAlreadyPaid();

  return useMemo(
    () => isUsingProformaInvoiceFeature && (isInvoiceGenerated || isOrderAlreadyPaid),
    [isUsingProformaInvoiceFeature, isInvoiceGenerated, isOrderAlreadyPaid]
  );
}

export function useIsOrderInvoiceExisted() {
  const chargesData = useSelector(getChargesDataSelector);
  return useMemo(() => chargesData?.some((charge) => charge.invoice_number), [chargesData]);
}

export function useRedoInvoices({ setLoading, callBackFuncs }) {
  const chargesData = useSelector(getChargesDataSelector);
  const { orderNumber } = useParams();
  const { execute: redoInvoice } = useService(serviceLocator.invoiceApi.invalidateInvoice, { lazy: true });
  const { execute: viewOrderCharges } = useService(serviceLocator.ratingsService.viewOrderCharges, {
    storeKey: 'charges',
    updateStoreAction: updateState,
    lazy: true,
  });

  return useMemo(() => {
    const redoInvoiceHandler = async () => {
      try {
        setLoading(true);
        const reqPayload = { data: { reason: 'Change Booking Information' } };
        const invoiceIds = [
          ...new Set(
            chargesData?.reduce((acc, charge) => (charge['invoice_id'] ? [...acc, charge['invoice_id']] : acc), [])
          ),
        ];
        await Promise.all(invoiceIds.map((invoiceId) => redoInvoice(invoiceId, reqPayload)));
        await viewOrderCharges(orderNumber);
        setLoading(false);
        callBackFuncs.forEach((func) => func?.());
      } catch (error) {
        console.error(error);
      }
    };
    return { redoInvoices: redoInvoiceHandler };
  }, [chargesData]);
}

export function useGetOrderSelectedSenderSetting(keySetting) {
  const organisations = useSelector(getOrganisationsSelector);
  const selectedSenderId = useSelector(getOrderSenderId);

  if (!organisations || !selectedSenderId) return null;

  const organisation = organisations.find(({ sender }) => sender.id === selectedSenderId);

  if (!organisation) return null;

  return organisation.sender.settings?.[keySetting] ?? null;
}
