import { SubTaskRule } from '@yojee/types';
import BaseSubTaskRuleTable from '@yojee/ui/base/rooms/SubTaskRuleTable/SubTaskRuleTable';

import { FormFieldComponent } from '../types';

export type SubTaskRulesFieldProps = React.ComponentProps<typeof BaseSubTaskRuleTable>;

export const SubTaskRuleTable: FormFieldComponent<'subTaskRules'> = ({
  formField: {
    fieldProps: { loading, readOnly },
  },
  value,
  setValue,
}) => {
  return (
    <BaseSubTaskRuleTable
      loading={loading}
      readOnly={readOnly}
      subTaskRules={value as unknown as SubTaskRule[]}
      onChange={setValue}
    />
  );
};
