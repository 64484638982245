import MuiFormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import Dropzone from 'react-dropzone';
import { Trans } from 'react-i18next';

import { formatSize } from '@yojee/helpers/StringHelper';

const styledBy = (property, mapping) => (props) => {
  return mapping[props[property]] || mapping.default;
};
const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: styledBy('size', {
        default: '100%',
        small: theme.spacing(40),
        medium: theme.spacing(40),
        large: theme.spacing(40),
      }),
      '&:hover': {
        cursor: 'pointer',
      },
    },
    dropZone: {
      width: styledBy('size', {
        default: '100%',
        small: theme.spacing(40),
        medium: theme.spacing(40),
        large: theme.spacing(40),
      }),
      padding: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxSizing: 'border-box',
      backgroundImage: (props) =>
        `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23${styledBy(
          'color',
          {
            default: theme.palette.primary.main,
            primary: theme.palette.primary.main,
            success: theme.palette.success.main,
            error: theme.palette.error.main,
            disable: theme.palette.grey[400],
          }
        )(props).substr(
          1
        )}' stroke-width='2px' stroke-dasharray='6 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
      backgroundColor: styledBy('color', {
        default: '',
        primary: theme.palette.primary.tint,
        error: theme.palette.error.tint,
        disable: theme.palette.grey[200],
      }),
      color: styledBy('color', {
        default: theme.palette.primary.main,
        primary: theme.palette.primary.main,
        success: theme.palette.success.main,
        error: theme.palette.error.main,
        disable: theme.palette.grey[400],
      }),
    },
    label: {
      width: '100%',
      minHeight: theme.spacing(1),
      wordWrap: 'break-word',
      display: 'block',
      color: theme.palette?.black?.['B60'],
      fontSize: '0.75rem',
      fontWeight: 600,
    },
    helpBox: {
      display: 'flex',
      justifyContent: 'space-between',
      wordWrap: 'break-word',
      marginTop: '8px',
      fontSize: '0.75rem',
    },
    helperText1: {
      color: theme.palette?.black?.B60,
      width: '50%',
    },
    helperText2: {
      color: theme.palette?.black?.B60,
      width: '50%',
      textAlign: 'right',
    },
    helperText: {
      fontWeight: '600',
    },
  };
});

const UploadZone = ({ children, className, helperText, errorFile, rootClassName, ...args }) => {
  const classes = useStyles(args);
  return (
    <div className={clsx(classes.root, rootClassName)}>
      <Dropzone {...args}>
        {({ getRootProps, getInputProps, fileRejections }) => {
          const rejections = [];
          if (fileRejections.length > 0) {
            fileRejections.forEach((fileRejection) => {
              fileRejection.errors.forEach((r) => rejections.push(r.code));
            });
          }

          return (
            <section>
              <div {...getRootProps()} className={clsx(classes.dropZone, className)}>
                <input data-cy={args?.dataCy} {...getInputProps()} />
                {children}
              </div>
              {rejections.length > 0 &&
                rejections
                  .filter((r) => r === 'file-too-large')
                  .map((rej) => {
                    return (
                      !args?.disabledMaxSize && (
                        <MuiFormHelperText error={true}>
                          <Trans i18nKey="fileIsLargerThanMaxSize" values={{ maxSize: formatSize(args.maxSize) }} />
                        </MuiFormHelperText>
                      )
                    );
                  })}
            </section>
          );
        }}
      </Dropzone>
      <div className={classes.helpBox} style={{ display: args?.helperText1 || args?.helperText2 ? 'flex' : 'none' }}>
        <span className={classes.helperText1}>{args?.helperText1 ? args.helperText1 : '\u00A0'}</span>
        <span className={classes.helperText2}>{args?.helperText2 ? args.helperText2 : '\u00A0'}</span>
      </div>
      {helperText && (
        <MuiFormHelperText error={errorFile} className={classes.helperText}>
          {helperText}
        </MuiFormHelperText>
      )}
    </div>
  );
};

export default UploadZone;
