import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FEATURES_MAP } from '@yojee/helpers/SettingResolver';
import useIsFeatureEnabled from '@yojee/ui/feature-management/hooks/useIsFeatureEnabled';

import {
  IconAccount,
  IconContact,
  IconCustomers,
  IconDocuments,
  IconDriver,
  IconFinance,
  IconFleet,
  IconMembers,
  IconOrders,
  IconPlanning,
} from '../icons';

export default function useTopMenuItems() {
  const slug = useSelector((state) => state.company?.companyData?.slug);

  const isEnterpriseUserManagementEnabled = useIsFeatureEnabled(FEATURES_MAP.ENTERPRISE_USER_MANAGEMENT);
  const isVehiclesEnabled = useIsFeatureEnabled(FEATURES_MAP.VEHICLES);
  const isUsingManifest = useIsFeatureEnabled(FEATURES_MAP.USING_MANIFEST);
  const isAddressBookEnabled = useIsFeatureEnabled(FEATURES_MAP.ADDRESS_BOOK);
  const isItemTypesManagementEnabled = useIsFeatureEnabled(FEATURES_MAP.ITEM_TYPES_MANAGEMENT_VIEW);
  const isServiceTypesManagementEnabled = useIsFeatureEnabled(FEATURES_MAP.SERVICE_TYPES_MANAGEMENT);
  const isServiceTimesManagementEnabled = useIsFeatureEnabled(FEATURES_MAP.SERVICE_TIMES_MANAGEMENT);
  const isOperationsEnabled = useIsFeatureEnabled(FEATURES_MAP.OPERATIONS);
  const isSavedFiltersEnabled = useIsFeatureEnabled(FEATURES_MAP.SAVED_FILTERS);
  const isDispatchRulesEnabled = useIsFeatureEnabled(FEATURES_MAP.DISPATCH_RULES);
  const isWhitelabelEnabled = useIsFeatureEnabled(FEATURES_MAP.WHITELABEL);
  const isUsingConnote = useIsFeatureEnabled(FEATURES_MAP.USING_CONNOTE);
  const isRatingManagementEnabled = useIsFeatureEnabled(FEATURES_MAP.SHOW_RATING_MANAGEMENT);
  const isVasManagementEnabled = useIsFeatureEnabled(FEATURES_MAP.VAS_MANAGEMENT);
  const isUsingCustomFields = useIsFeatureEnabled(FEATURES_MAP.USING_CUSTOM_FIELDS);
  const isUsingDocumentPortalFeature = useIsFeatureEnabled(FEATURES_MAP.DOCUMENT_PORTAL);
  const isUsingIntegrationFeature = useIsFeatureEnabled(FEATURES_MAP.INTEGRATIONS_MANAGEMENT);
  const isNotificationEnabled = useIsFeatureEnabled(FEATURES_MAP.ALLOW_NOTIFICATIONS);

  return [
    {
      key: 'parent-account',
      icon: <IconAccount />,
      title: <Trans>Account</Trans>,
      shouldShow: true,
      children: [
        {
          key: 'company-settings',
          title: <Trans>Company Settings</Trans>,
          shouldShow: true,
          isDefault: true,
        },
        {
          key: 'branding',
          title: <Trans>Branding</Trans>,
          shouldShow: true,
        },
        {
          key: 'tags',
          title: <Trans>Tags</Trans>,
          shouldShow: true,
        },
        {
          key: 'integrations',
          title: <Trans>Integrations</Trans>,
          shouldShow: isUsingIntegrationFeature,
        },
      ],
    },
    {
      key: 'parent-enterprise-user-management',
      icon: <IconMembers />,
      shouldShow: true,
      title: <Trans>Members</Trans>,
      children: [
        {
          key: 'roles',
          title: <Trans>Roles</Trans>,
          shouldShow: isEnterpriseUserManagementEnabled,
        },
        {
          key: 'user-management',
          title: <Trans>Users</Trans>,
          shouldShow: isEnterpriseUserManagementEnabled,
        },
        {
          key: 'dispatcher-management',
          title: <Trans>User Management</Trans>,
          shouldShow: !isEnterpriseUserManagementEnabled,
        },
      ],
    },
    {
      key: 'parent-fleet',
      icon: <IconFleet />,
      title: <Trans>Fleet</Trans>,
      shouldShow: true,
      children: [
        {
          key: 'fleetmanagement',
          title: <Trans>Fleet Management</Trans>,
          shouldShow: isVehiclesEnabled,
        },
        {
          key: 'vehicle-types',
          title: <Trans>Vehicle Types</Trans>,
          shouldShow: true,
        },
        {
          key: 'container-types',
          title: <Trans>Container Type</Trans>,
          shouldShow: false,
        },
      ],
    },
    {
      key: 'parent-drivers',
      icon: <IconDriver />,
      title: <Trans>Drivers</Trans>,
      shouldShow: true,
      children: [
        {
          key: 'driver-management',
          title: <Trans>All Drivers</Trans>,
          shouldShow: true,
        },
        {
          key: 'teams',
          title: <Trans>Teams</Trans>,
          shouldShow: true,
        },
        {
          key: 'driver-workflow',
          title: <Trans>Driver Workflow</Trans>,
          shouldShow: true,
        },
        {
          key: 'manifest',
          title: <Trans>Manifest</Trans>,
          shouldShow: isUsingManifest,
        },
      ],
    },
    {
      key: 'addresses',
      icon: <IconContact />,
      title: <Trans>Addresses</Trans>,
      shouldShow: isAddressBookEnabled,
    },
    {
      key: 'parent-customers',
      icon: <IconCustomers />,
      title: <Trans>Customers</Trans>,
      shouldShow: true,
      children: [
        {
          key: 'parent-senders',
          title: <Trans>Senders</Trans>,
          shouldShow: true,
          children: [
            {
              key: 'senders/individual',
              title: <Trans>Individual</Trans>,
              shouldShow: true,
            },
            {
              key: 'senders/corporate',
              title: <Trans>Corporate</Trans>,
              shouldShow: true,
            },
          ],
        },
        {
          key: 'notifications',
          title: <Trans>Notifications</Trans>,
          shouldShow: isNotificationEnabled,
        },
      ],
    },
    {
      key: 'parent-orders',
      icon: <IconOrders />,
      title: <Trans>Orders</Trans>,
      shouldShow: true,
      children: [
        {
          key: 'booking-template',
          title: <Trans>Order Templates</Trans>,
          shouldShow: true,
        },
        {
          key: 'item-types',
          title: <Trans>Item Types</Trans>,
          shouldShow: !!isItemTypesManagementEnabled,
        },
        {
          key: 'service-types',
          title: <Trans>Service Types</Trans>,
          shouldShow: isServiceTypesManagementEnabled,
        },
        {
          key: 'custom-fields',
          title: <Trans>Custom Fields</Trans>,
          shouldShow: isUsingCustomFields,
        },
        {
          key: 'reason-codes',
          title: <Trans>Reason Codes</Trans>,
          shouldShow: true,
        },
      ],
    },
    {
      key: 'parent-planning',
      icon: <IconPlanning />,
      title: <Trans>Planning</Trans>,
      shouldShow: true,
      children: [
        {
          key: 'service-times',
          title: <Trans>Service Times</Trans>,
          shouldShow: !!isServiceTimesManagementEnabled,
        },
        {
          key: 'operations',
          title: <Trans>Operations</Trans>,
          shouldShow: isOperationsEnabled,
        },
        {
          key: 'saved-filters',
          title: <Trans>Saved Filters</Trans>,
          shouldShow: isSavedFiltersEnabled,
        },
        {
          key: 'dispatch-rules',
          title: <Trans>Dispatch Rules</Trans>,
          shouldShow: isDispatchRulesEnabled,
        },
      ],
    },
    {
      key: 'parent-documentation',
      icon: <IconDocuments />,
      title: <Trans>Documentation</Trans>,
      shouldShow: true,
      children: [
        {
          key: 'pods',
          title: <Trans>POD</Trans>,
          shouldShow: isWhitelabelEnabled,
        },
        {
          key: 'labels',
          title: <Trans>Labels</Trans>,
          shouldShow: isWhitelabelEnabled && !['yojee', 'sila'].includes(slug),
        },
        {
          key: 'connote-template',
          title: <Trans>Connote Template</Trans>,
          shouldShow: isUsingConnote,
        },
        {
          key: 'manifest-template',
          title: <Trans>Manifest Template</Trans>,
          shouldShow: isUsingManifest,
        },
        {
          key: 'document-codes',
          title: <Trans>Document Codes</Trans>,
          shouldShow: isUsingDocumentPortalFeature,
        },
      ],
    },
    {
      key: 'finance',
      icon: <IconFinance />,
      title: <Trans>Finance</Trans>,
      shouldShow: true,
      children: [
        {
          key: 'rates',
          title: <Trans>Rate Cards</Trans>,
          shouldShow: isRatingManagementEnabled,
        },
        {
          key: 'rate-charge-codes',
          title: <Trans>Charge Codes</Trans>,
          shouldShow: isRatingManagementEnabled,
        },
        {
          key: 'vas',
          title: <Trans>VAS</Trans>,
          shouldShow: isVasManagementEnabled,
        },
      ],
    },
  ];
}
