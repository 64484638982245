import { Grid } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans } from 'react-i18next';

import FileUploadZone from '../../corners/FileUploadZone';
import ManageButtonGroup from '../../corners/ManageButtonGroup';
import ReactSelect from '../../furnitures/Select';
import { Paragraph } from '../../furnitures/Typography';

const useStyles = makeStyles((theme) => ({
  additionalInfoLabel: {
    display: 'inline',
  },
  downloadBtns: {
    marginLeft: theme.spacing(1),
  },
}));

const BatchUpload = ({
  onDrop,
  accept,
  batchUploadState,
  percentLoading,
  helperText,
  successMess,
  failedMess,
  errorFile,
  disabled,
  supportedExtensions,
  maxFileSizeText,
  onClickCSV,
  onClickXLSX,
  selectOption,
  uploadedFile,
  maxSize,
  removeFile,
  errorZone,
  dataCy = {
    selectOption: 'select-option',
    select: 'select',
    batchUpload: 'batch-upload-file',
    excel: 'excel',
    csv: 'csv',
  },
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <ReactSelect
          label={<Trans>What do you want to create</Trans>}
          value={selectOption?.value}
          options={selectOption?.options}
          onChange={selectOption?.onChange}
          optionDataCy={dataCy?.selectOption}
          dataCy={dataCy?.select}
        />
      </Grid>
      <Grid item xs={12}>
        <FileUploadZone
          onDrop={onDrop}
          accept={accept}
          helperText1={<Trans>File supported: {{ extensions: supportedExtensions.join(', ') }}</Trans>}
          helperText2={<Trans>Maximum size: {{ maxFileSize: maxFileSizeText }}MB</Trans>}
          maxSize={maxSize}
          color={
            batchUploadState === 'failed'
              ? 'error'
              : batchUploadState === 'completed' && percentLoading === 0
              ? 'success'
              : 'default'
          }
          completed={batchUploadState === 'completed'}
          successMess={successMess}
          failedMess={failedMess}
          disabled={disabled}
          file={uploadedFile}
          percent={percentLoading}
          removeFile={removeFile}
          size="default"
          error={errorZone}
          helperText={helperText}
          errorFile={errorFile}
          dataCy={dataCy?.batchUpload}
        />
      </Grid>
      <Grid item xs={12}>
        <Paragraph className={classes.additionalInfoLabel}>
          <Trans>Download Template</Trans>:
        </Paragraph>
        <span className={classes.downloadBtns}>
          <ManageButtonGroup
            btnGroup={[
              {
                label: 'Excel',
                onClick: onClickXLSX,
                size: 'small',
                'data-cy': dataCy?.excel,
              },
              {
                label: 'CSV',
                onClick: onClickCSV,
                size: 'small',
                'data-cy': dataCy?.csv,
              },
            ]}
          />
        </span>
      </Grid>
    </Grid>
  );
};

BatchUpload.defaultProps = {
  supportedExtensions: ['XLSX', 'CSV'],
  maxFileSizeText: '5',
  percentLoading: 0,
  disabled: false,
  onClickCSV: () => {},
  onClickXLSX: () => {},
  accept:
    '.csv, application/vnd.ms-excel, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xlsx',
  selectOption: {
    value: { value: 'user_a', label: 'User A' },
    options: [
      { value: 'user_a', label: 'User A' },
      { value: 'user_b', label: 'User B' },
    ],
    onChange: () => {},
  },
  maxSize: 5242880,
};

BatchUpload.propTypes = {
  onDrop: PropTypes.func.isRequired,
  batchUploadState: PropTypes.any,
  percentLoading: PropTypes.number,
  helperText: PropTypes.string,
  successMess: PropTypes.string,
  failedMess: PropTypes.string,
  errorFile: PropTypes.any,
  disabled: PropTypes.bool,
  onClickCSV: PropTypes.func,
  onClickXLSX: PropTypes.func,
  supportedExtensions: PropTypes.array,
  maxFileSizeText: PropTypes.string,
  uploadedFile: PropTypes.any,
};

export default BatchUpload;
