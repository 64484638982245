export const ADD_LEG_ACTION_TYPE = {
  ADD_IN_FRONT: 'ADD_IN_FRONT',
  SPLIT: 'SPLIT',
  ADD_AT_THE_END: 'ADD_AT_THE_END',
  DUPLICATE: 'DUPLICATE',
};

export const TASK = {
  PICKUP: 'pickup',
  DROPOFF: 'dropoff',
};
