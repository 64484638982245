import { Link } from '@material-ui/core';
import { useState } from 'react';
import { Trans } from 'react-i18next';

import { useCompanyCountry } from '@yojee/helpers/common-reducers';
import AddressLocator from '@yojee/ui/addressDialog/AddressLocator';
import { Input as TextField, PlaceAutoComplete } from '@yojee/ui/base';

import { FormFieldComponent } from '../types';
import { AddressData, getFullAddress } from './utils/getFullAddress';

export type AddressInputProps = Omit<React.ComponentProps<typeof TextField>, 'label' | 'required'> & {
  onAddressChange: (addressData: AddressData) => void;
  defaultAddress?: AddressData;
};

const emptyAddress: AddressData = {
  address1: '',
  country: '',
  state: '',
  city: '',
  center: {
    lat: 1.352083,
    lng: 103.819836,
  },
  postalCode: '',
};

export const AddressInput: FormFieldComponent<'addressInput'> = ({
  fieldName,
  formField: {
    label,
    disabled,
    fieldProps: { onAddressChange, defaultAddress = emptyAddress },
  },
  value: inputValue,
  setValue: setInputValue,
  required,
  errorMessage,
}) => {
  const [isShownAddressMap, setIsShownAddressMap] = useState(false);
  const defauntMapCountry = useCompanyCountry();
  const [address, _setAddress] = useState<AddressData>(defaultAddress);

  const setAddress = (addressData: AddressData) => {
    _setAddress(addressData);
    onAddressChange(addressData);
  };

  return (
    <>
      <PlaceAutoComplete
        name={fieldName}
        maxLength={undefined}
        value={inputValue}
        onChange={setInputValue}
        onSelect={async (addressLine1: string) => {
          setInputValue(addressLine1);
          const address = (await getFullAddress(addressLine1)) || emptyAddress;
          setAddress(address);
        }}
        label={label}
        disabled={disabled}
        extraLabel={
          <Link
            data-cy="address-map"
            underline="none"
            style={{ cursor: 'pointer' }}
            onClick={() => setIsShownAddressMap(true)}
          >
            <Trans>Latitude/Longitude</Trans>
          </Link>
        }
        error={!!errorMessage}
        helperText={errorMessage}
        required={required}
        fullWidth
      />
      {isShownAddressMap && (
        <AddressLocator
          operationCountry={defauntMapCountry}
          lat={address.center?.lat}
          lng={address.center?.lng}
          address={address.address1}
          onCancel={() => setIsShownAddressMap(false)}
          onSaveChanges={(data: {
            address1: string;
            addressItemData: unknown | null;
            country: string;
            lat: number;
            lng: number;
            postalCode: string;
            state: string;
          }) => {
            const { lat, lng, address1, country, postalCode, state } = data;
            setAddress({
              center: {
                lat,
                lng,
              },
              address1,
              country,
              postalCode,
              state,
              city: '',
            });
            setIsShownAddressMap(false);
          }}
        />
      )}
    </>
  );
};
