export const requestGetSchedulesList = (payload) => {
  return { type: 'REQUEST_SCHEDULES_LIST', payload };
};

//CREATE SCHEDULE
export const requestAddSchedule = (payload) => {
  return { type: 'REQUEST_ADD_SCHEDULE', payload };
};

export const requestGetSchedule = (payload) => {
  return { type: 'REQUEST_GET_SCHEDULE', payload };
};

export const requestDeleteSchedule = (payload) => {
  return { type: 'REQUEST_DELETE_SCHEDULE', payload };
};

export const requestUpdateSchedule = (payload) => {
  return { type: 'REQUEST_UPDATE_SCHEDULE', payload };
};

export const requestScheduleBatchUpload = (payload) => {
  return { type: 'REQUEST_SCHEDULE_BATCH_UPLOAD', payload };
};

export const downloadScheduleSample = (payload) => {
  return { type: 'DOWNLOAD_SCHEDULE_TEMPLATE_SAMPLE', payload };
};

// CLEAR SCHEDULES
export const clearSchedule = () => {
  return { type: 'CLEAR_SCHEDULES' };
};
