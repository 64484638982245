import { useEffect } from 'react';

import MarkersCanvas from './BaseMarkerCanvas';

const BaseMarkerCanvasComponent = ({ google, map, markers }) => {
  useEffect(() => {
    let markersCanvas;
    if (map && google) {
      markersCanvas = new MarkersCanvas({ map, google, markers });
    }

    return () => {
      if (markersCanvas) {
        markersCanvas.onRemove();
      }
    };
  }, [map, google, markers]);

  return null;
};

export default BaseMarkerCanvasComponent;
