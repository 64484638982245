import React from 'react';

import AddressPicker from '@yojee/ui/address-picker/addressPicker';

const AddressLocator = ({ address, lat, lng, operationCountry, onCancel, onSaveChanges }) => {
  return (
    <div className="address-locator-container">
      <AddressPicker
        addressData={{ address, lat, lng }}
        operationCountry={operationCountry}
        onCancel={onCancel}
        onLocationSave={onSaveChanges}
        isShownUpdateAddressFromSender={false}
        isShownAddToAddressBook={false}
      />
    </div>
  );
};

export default AddressLocator;
