/* eslint-disable @typescript-eslint/no-explicit-any */
import { MutableRefObject, ReactElement, useImperativeHandle, useRef } from 'react';

import { FormRule, useFormValidator } from '@yojee/helpers/FormValidator';
import { ValuesOf } from '@yojee/types';

import { FieldComponentMap, FieldPropsMap } from './controls';
import { mapObject } from './helpers/map-object';
import { scrollToErrorField } from './helpers/scroll-to-error-field';
import { FormField, FormSchema } from './types';

type OverrideFormField = { disabled?: boolean; fieldProps?: ValuesOf<FieldPropsMap> };

export type FormNode = {
  render: (params?: OverrideFormField) => ReactElement;
};

export type QuickFormRef<TData> = {
  setFieldValue: (data: Partial<TData>, shouldTriggerChange?: boolean) => void;
};

type QuickFormProps<TSchema extends FormSchema, TData extends Record<keyof TSchema, any>> = {
  refForm?: MutableRefObject<QuickFormRef<TData> | null>;
  formSchema: TSchema;
  className?: string;
  defaultData: TData;
  onSubmit: (data: TData) => void;
  onChange?: (data: TData) => void;
  onFieldChange?: (data: TData, field: keyof TData) => void;
  render: ({ fields, formData }: { fields: Record<keyof TSchema, FormNode>; formData: TData }) => ReactElement;
};

export const QuickForm = <TSchema extends FormSchema, TData extends Record<keyof TSchema, any>>({
  formSchema,
  render,
  defaultData,
  onSubmit,
  onChange,
  refForm,
  onFieldChange,
  className,
}: QuickFormProps<TSchema, TData>) => {
  const root = useRef<HTMLFormElement>(null);
  const formRules = mapObject(formSchema, (_, field) => field.validator ?? []) as Record<keyof TData, FormRule[]>;
  const { setFieldValue, validate, formErrors, formData } = useFormValidator({
    defaultData,
    rules: formRules,
    onChange,
    onFieldChange,
  });

  const getFormControls = () =>
    mapObject(formSchema, (fieldName, formField): FormNode => {
      const FieldComponent = FieldComponentMap[formField.fieldType as keyof typeof FieldComponentMap];
      const required = !!formField.validator?.some((rule) => rule.required);
      const setValue = (value: any) => setFieldValue({ [fieldName]: value } as any);
      const defaultWrapper: FormField<any>['wrapper'] = ({ children }) => children;
      const wrapper = formField.wrapper ?? defaultWrapper;

      return {
        render: (override) =>
          wrapper({
            children: (
              <div data-cy={`field-${fieldName.toString()}`}>
                <FieldComponent
                  fieldName={fieldName as string}
                  required={required}
                  formField={{
                    ...formField,
                    disabled: override?.disabled ?? formField.disabled,
                    fieldProps: {
                      ...formField.fieldProps,
                      ...override?.fieldProps,
                    },
                  }}
                  value={formData[fieldName]}
                  setValue={setValue}
                  errorMessage={formErrors[fieldName] || null}
                />
              </div>
            ),
          }),
      };
    });

  const fields = getFormControls();

  useImperativeHandle(refForm, () => ({
    setFieldValue,
  }));

  return (
    <form
      className={className}
      // disbale default validation by browser
      noValidate
      ref={root}
      onSubmit={(event) => {
        event.preventDefault();
        const { isValid } = validate(formData);

        if (isValid) {
          onSubmit(formData);
        } else {
          setTimeout(() => scrollToErrorField(root.current), 100);
        }
      }}
    >
      {render({ fields, formData })}
    </form>
  );
};
