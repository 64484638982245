export const listTags = (params) => {
  return { type: 'REQUEST_LIST_TAGS', params };
};
export const createTag = (tag) => {
  return { type: 'REQUEST_CREATE_TAG', tag };
};
export const updateTag = (tag) => {
  return { type: 'REQUEST_UPDATE_TAG', tag };
};
export const deleteTag = (tag) => {
  return { type: 'REQUEST_DELETE_TAG', tag };
};
// BATCH UPLOAD
export const requestTagBatchUpload = (uploadData) => {
  return { type: 'TAG_BATCH_UPLOAD', uploadData };
};
export const downloadTagSample = (format) => {
  return { type: 'DOWNLOAD_TAGS_TEMPLATE_SAMPLE', format };
};
