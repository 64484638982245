import './style.scss';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';

import { FORMAT_DATE_FOR_NEW_BOOKING } from '@yojee/helpers/constants';

import { customFilterDOMProps } from '../../../helpers/SchemaHelpers';
import DatePicker from './../../../../../date-time-picker/DatePicker';

function BaseFromDateField(props) {
  const onChange = (event) => {
    props.onChange(event);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        {...customFilterDOMProps(props)}
        shouldDisableDate={props.disableNonOperatingDays}
        margin="dense"
        {...props.extraProps}
        onChange={onChange}
        value={props.value || null}
        name=""
        label={props.label}
        format={FORMAT_DATE_FOR_NEW_BOOKING}
        placeholder={FORMAT_DATE_FOR_NEW_BOOKING.toLowerCase()}
        error={!!props.error}
        helperText={props.error}
        required={props.required}
        disabled={props.disabled}
        data-cy="step-from-date"
        DialogProps={{
          'data-cy': 'step-from-date-dialog',
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

export default BaseFromDateField;
