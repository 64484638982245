export function isValidEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// Validate val in a range (inclusive)
export const isValidRangeNumber = (val, { min = 0, max = Number.POSITIVE_INFINITY } = {}) => {
  return val >= min && val <= max;
};

export function isObjectEmpty(object) {
  return Object.entries(object).length === 0 && object.constructor === Object;
}

export function isArrayEmpty(array) {
  return Array.isArray(array) && array.length === 0;
}

export function isValidUrl(url = '') {
  return /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g.test(url);
}

export function validateField(validator, value) {
  let error;
  if (validator === 'required' && !`${value ?? ''}`.trim()) {
    error = 'Field is required';
  } else if (validator === 'array' && isArrayEmpty(value)) {
    error = 'Field is required';
  } else if (validator === 'email' && !isValidEmail(value)) {
    error = 'Email is invalid';
  } else if (validator === 'phone' && !phoneValidation(value)) {
    error = 'Phone is invalid';
  }

  return error;
}

export function isEmpty(target, noWhiteSpace = false, noZero = false) {
  return (
    isNull(target) ||
    target === '' ||
    (noWhiteSpace ? isString(target) && target.trim().length === 0 : false) ||
    (noZero ? target === 0 : false) ||
    target === {}
  );
}

export function isString(target) {
  return target && typeof target === 'string';
}

export function isNull(target) {
  return target === undefined || target === null;
}

export const isNumber = (value) => {
  return /^[0-9]+$/.test(value);
};

export const phoneValidation = (inputPhoneNumber) => {
  const phoneNumber = inputPhoneNumber.split(/[\s\-()]/).join('');
  const isSingaporeNumber = /^\+?65/;

  if (isSingaporeNumber.test(phoneNumber)) {
    return /^\+?65[0-9]{8}$/.test(phoneNumber);
  }

  return /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im.test(phoneNumber);
};
