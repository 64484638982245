import './style.scss';

import { styled } from '@mui/material/styles';
import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { reset } from '../../saga/actions';
import { ConfigContext } from '../helpers/Contexts';
import WithSchemaHelper from '../helpers/hoc/WithSchemaHelper';
import { useIsEditOrder, useIsInSenderDefaultValuesPage } from '../helpers/hooks';
import BookingInfoSections from './BookingInfoSections';
import { useCommonAPIData, useDisableScrollOnNumberField, useDisplayMessage } from './helper';
import Map from './Map';
import BookingActions from './OrderActions/BookingActions';
import OrderMetadata from './OrderMetadata/OrderMetadata';
import OrderSection from './OrderSection';
import OrderTemplateSelect from './OrderTemplateSelect';
import SenderSection from './SenderSection';

const Hr = styled('hr')({
  height: '1px',
  border: 'none',
  color: '#d8d8d8',
  backgroundColor: '#d8d8d8',
  margin: '0px',
});

const OrderForm = () => {
  const isEditOrder = useIsEditOrder();
  const dispatch = useDispatch();
  const isInSenderDefaultValuesPage = useIsInSenderDefaultValuesPage();
  const { isShowMap, isHideSenderSection, bookingInfoSectionsReactComponentKey } = useContext(ConfigContext);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  useDisableScrollOnNumberField();
  useCommonAPIData();
  useDisplayMessage();

  return (
    <WithSchemaHelper>
      <div className={`new-booking ${isShowMap ? 'show-map' : ''}`}>
        <div className="main-form" data-cy="booking-form">
          {isEditOrder && (
            <>
              <OrderMetadata />
              <Hr />
            </>
          )}
          <OrderTemplateSelect />
          <Hr />
          <div className="sender-and-order-section">
            {!isInSenderDefaultValuesPage && !isHideSenderSection && <SenderSection />}
            <OrderSection />
          </div>
          <BookingInfoSections key={bookingInfoSectionsReactComponentKey} />
          <BookingActions />
        </div>
        {isShowMap && (
          <div className="map-section" data-cy="booking-map">
            <Map />
          </div>
        )}
      </div>
    </WithSchemaHelper>
  );
};

export default React.memo(OrderForm);
