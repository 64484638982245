import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  getCompanyTimezoneSelector,
  getFormRefsFromPathSelector,
  getSelectedServiceTypeSelector,
} from '@yojee/ui/new-order-booking/selectors';

import serviceLocator from '../../../../../../serviceLocator';
import { getFormKeyPath } from '../../../../../helpers/formHelpers';
import { useTimeSlots } from '../../../../../helpers/hooks';
import { fillDateTimeWhenGetTimeSlots, getValueFromSlots } from '../../../../../helpers/utils';
import { BookingInfoSectionContext } from '../../../Contexts';
import LegSection from '../LegSection';

const BookingPageLegSection = (props) => {
  const { bookingInfoSectionIndex } = useContext(BookingInfoSectionContext);
  const timezone = useSelector(getCompanyTimezoneSelector);

  const serviceType = useSelector(getSelectedServiceTypeSelector);
  const serviceTypeKey = serviceType?.key;
  const { generateSlotsPayload, serviceRequiredTimeslot } = useTimeSlots();

  const legIndex = props.legIndex;
  const legFormRef = useSelector((state) =>
    getFormRefsFromPathSelector(
      state,
      getFormKeyPath({
        bookingInfoSectionIndex,
        legIndex,
      }),
      null
    )
  );

  useEffect(() => {
    const getLegTimeSlots = async () => {
      try {
        if (serviceTypeKey) {
          const payLoad = generateSlotsPayload(moment(), serviceTypeKey, timezone);
          const legTimeSlots = await serviceLocator.serviceTypesApi.getSlots(payLoad);
          const dateTimeObject = getValueFromSlots(legTimeSlots);

          fillDateTimeWhenGetTimeSlots(legFormRef, dateTimeObject, { serviceRequiredTimeslot }, legTimeSlots);
        }
      } catch (error) {
        fillDateTimeWhenGetTimeSlots(legFormRef, [], { serviceRequiredTimeslot });
      }
    };
    if (serviceRequiredTimeslot) {
      getLegTimeSlots();
    } else {
      fillDateTimeWhenGetTimeSlots(legFormRef, [], { serviceRequiredTimeslot });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceTypeKey, serviceRequiredTimeslot, legFormRef, generateSlotsPayload]);

  return <LegSection {...props} />;
};

export default BookingPageLegSection;
