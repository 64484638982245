import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Translation } from 'react-i18next';
import ReactPlaceAutoComplete from 'react-places-autocomplete';

import Input from '../Input';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  suggestionContainer: {
    position: 'absolute',
    zIndex: 10000,
    background: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    fontSize: '14px',
    padding: `${theme.spacing(1)}px 0px`,
    borderRadius: theme.spacing(1),
    boxShadow: `0 ${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0 ${theme.palette?.black?.B32}`,
  },
  suggestionItem: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    '&:hover': {
      background: theme.palette.primary.tint,
      cursor: 'pointer',
    },
  },
  mainText: {
    fontWeight: '700',
  },
}));

const PlaceAutoComplete = ({
  value,
  onChange,
  onSelect,
  label,
  extraLabel,
  error,
  helperText,
  required,
  maxLength,
  ...other
}) => {
  const classes = useStyles();
  return (
    <ReactPlaceAutoComplete value={value} onChange={onChange} onSelect={onSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps }) => {
        return (
          <div className={classes.root}>
            <Translation>
              {(t) => (
                <Input
                  fullWidth
                  inputProps={{ maxLength: maxLength }}
                  {...getInputProps({
                    placeholder: t('Search Location'),
                    className: 'field',
                  })}
                  label={label}
                  extraLabel={extraLabel}
                  error={error}
                  helperText={helperText}
                  required={required}
                  {...other}
                />
              )}
            </Translation>
            {suggestions.length > 0 && (
              <div className={'autocomplete-suggestions-container ' + classes.suggestionContainer}>
                {suggestions.map((suggestion, index) => {
                  return (
                    <>
                      <div
                        key={'auto-complete-suggestion-item-' + index + '-' + Date.now()}
                        {...getSuggestionItemProps(suggestion, {
                          className: suggestion.active
                            ? 'auto-complete-suggestion-active ' + classes.suggestionItem
                            : 'auto-complete-suggestion ' + classes.suggestionItem,
                        })}
                      >
                        <span>
                          {suggestion.formattedSuggestion.mainText}, {suggestion.formattedSuggestion.secondaryText}
                        </span>
                      </div>
                      {index !== suggestions.length - 1 && <Divider />}
                    </>
                  );
                })}
              </div>
            )}
          </div>
        );
      }}
    </ReactPlaceAutoComplete>
  );
};

PlaceAutoComplete.defaultProps = {
  maxLength: 255,
};

PlaceAutoComplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default PlaceAutoComplete;
