export const getListExceptions = (payload) => {
  return { type: 'GET_LIST_EXCEPTIONS', payload };
};

export const resetTaskException = () => {
  return { type: 'RESET_TASK_EXCEPTION' };
};

export const openAddExceptionDialog = (payload) => {
  return { type: 'OPEN_ADD_EXCEPTION_DIALOG', exception: payload && payload.exception };
};

export const openConfirmDeleteTaskException = (exception) => {
  return { type: 'OPEN_CONFIRM_DELETE_TASK_EXCEPTION', exception };
};

export const closeConfirmDeleteTaskException = () => {
  return { type: 'CLOSE_CONFIRM_DELETE_TASK_EXCEPTION' };
};

export const closeAddExceptionDialog = () => {
  return { type: 'CLOSE_ADD_EXCEPTION_DIALOG' };
};

export const updateTaskException = (exception) => {
  return { type: 'UPDATE_TASK_EXCEPTION', exception };
};

export const createTaskException = (exception) => {
  return { type: 'CREATE_TASK_EXCEPTION', exception };
};

export const deleteTaskException = (exception) => {
  return { type: 'DELETE_TASK_EXCEPTION', exception };
};

// BATCH UPLOAD
export const requestTaskExceptionBatchUpload = (uploadData) => {
  return { type: 'TASK_EXCEPTION_BATCH_UPLOAD', uploadData };
};
export const downloadTaskExceptionSample = (format) => {
  return { type: 'DOWNLOAD_TASK_EXCEPTION_TEMPLATE_SAMPLE', format };
};

export const clearTaskExceptionMessage = () => {
  return { type: 'CLEAR_MESSAGE' };
};

export const setCreateUpdateStatus = (payload) => {
  return { type: 'SET_CREATE_UPDATE_STATUS', payload };
};
