import React from 'react';

import { useIsEditOrder } from '@yojee/ui/new-order-booking/components/helpers/hooks';

import SaveNewOrderBtn from './CreateOrder';
import SaveEditedOrderBtn from './EditOrder';

const SaveButton: React.FC = () => {
  const isEditOrder = useIsEditOrder();

  if (isEditOrder) return <SaveEditedOrderBtn />;

  return <SaveNewOrderBtn />;
};

export default SaveButton;
