import './styles/style.scss';

import React, { useContext, useMemo, useState } from 'react';

import { SchemaHelperContext } from '../../../../../../helpers/Contexts';
import { getFormKeyPath } from '../../../../../../helpers/formHelpers';
import { useIsReadOnlyLegSection, useRegisterForm, useSchema } from '../../../../../../helpers/hooks';
import { useIsShowLoadingFormStatus } from '../../../../../helper';
import { BookingInfoSectionContext } from '../../../../Contexts';
import { useTasksStatisticsOfStep } from '../../../../helpers';
import StepSectionUI from './StepSectionUI';

const StepSection = ({ legIndex, stepIndex, stepProperty, step }) => {
  const [formOpened, setFormOpened] = useState(true);
  const isReadOnlyLegSection = useIsReadOnlyLegSection();
  const { bookingInfoSectionIndex } = useContext(BookingInfoSectionContext);
  const formKeyPath = getFormKeyPath({ bookingInfoSectionIndex, legIndex, stepIndex });
  const schemaHelper = useContext(SchemaHelperContext);
  const taskStatisticsOfStep = useTasksStatisticsOfStep(formKeyPath);

  const defaultModel = useMemo(
    () => ({
      ...step,
      address_id: step?.address_external_id || step?.address_item_id,
    }),
    [step]
  );

  const schemaOptions = useMemo(
    () => ({
      legIndex,
      stepIndex,
      bookingInfoSectionIndex,
    }),
    [bookingInfoSectionIndex, legIndex, stepIndex]
  );
  const bridge = useSchema(schemaHelper.stepSchema, step, schemaOptions);
  const { setFormRef, formRef } = useRegisterForm(formKeyPath);
  const subFields = useMemo(() => bridge.getSubfields({ exceptFields: ['type'] }), [bridge]);
  const isShowFormLoadingStatus = useIsShowLoadingFormStatus(subFields);

  const onStepFieldChange = (fieldName) => {
    if (fieldName !== 'id' && fieldName !== 'is_empty') {
      // Delete the id if step data changed
      formRef?.change('id', undefined);
    }
  };

  return (
    <StepSectionUI
      bridge={bridge}
      defaultModel={defaultModel}
      onStepFieldChange={onStepFieldChange}
      isReadOnlyLegSection={isReadOnlyLegSection}
      setFormRef={setFormRef}
      stepIndex={stepIndex}
      legIndex={legIndex}
      formOpened={formOpened}
      setFormOpened={setFormOpened}
      formKeyPath={formKeyPath}
      isShowFormLoadingStatus={isShowFormLoadingStatus}
      subFields={subFields}
      taskStatisticsOfStep={taskStatisticsOfStep}
      bookingInfoSectionIndex={bookingInfoSectionIndex}
      stepProperty={stepProperty}
      onFormChange={(newStepData) => {
        Object.assign(step, {
          is_empty: newStepData.is_empty,
        });
      }}
    />
  );
};

export default StepSection;
