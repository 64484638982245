import React, { useContext } from 'react';

/** @type {{bookingInfoSectionIndex: number}} */
const defaultBooingInfoSectionContextValue = {
  bookingInfoSectionIndex: 0,
};

export const BookingInfoSectionContext = React.createContext(defaultBooingInfoSectionContextValue);

export const useBookingInfoSectionIndex = () => {
  const { bookingInfoSectionIndex } = useContext(BookingInfoSectionContext);
  return bookingInfoSectionIndex;
};
