import { FormHelperText, InputLabel } from '@material-ui/core';
import moment from 'moment';
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import DateRangePicker from './Date';
import TimeRangePicker from './Time';
import { normalizeTime } from './Time/utils';
import { DateChangeCallback, DateType, TimeChangeCallback, TimeType } from './types';

export type DateTimeRangePickerProps = {
  start: DateType;
  end: DateType;
  onStartChange: DateChangeCallback;
  onEndChange: DateChangeCallback;
  startTime: TimeType;
  endTime: TimeType;
  onStartTimeChange: TimeChangeCallback;
  onEndTimeChange: TimeChangeCallback;
  label?: Partial<{ date: string; time: string }>;
  errors?: Array<string | ReactElement>;
  className?: string | undefined;
  disabled?: boolean;
};

export type DateTimeRangeHandle = {
  clear: () => void;
};

const DateTimeRangePicker: ForwardRefRenderFunction<DateTimeRangeHandle, DateTimeRangePickerProps> = (
  {
    start,
    end,
    onStartChange,
    onEndChange,
    startTime,
    endTime,
    onStartTimeChange,
    onEndTimeChange,
    label,
    errors,
    className,
    disabled,
  },
  ref
) => {
  const [flashError, setFlashError] = useState(false);
  const dateRangeRef = useRef<DateTimeRangeHandle>(null);
  const timeRangeRef = useRef<DateTimeRangeHandle>(null);

  useEffect(() => {
    if (start == null || end == null || startTime == null || endTime == null) return;
    if (!moment(start).isSame(end, 'day')) return;

    if (normalizeTime(endTime).isBefore(normalizeTime(startTime))) {
      onEndChange(moment(start).add(1, 'day').toDate());
      setFlashError(true);
      setTimeout(() => setFlashError(false), 500);
    }
  }, [end, endTime, onEndChange, start, startTime]);

  useImperativeHandle(
    ref,
    () => ({
      clear() {
        dateRangeRef.current?.clear();
        timeRangeRef.current?.clear();
      },
    }),
    []
  );

  return (
    <div className={className} style={{ marginTop: 10 }}>
      <div style={{ flexDirection: 'row', display: 'flex' }}>
        <div style={{ flex: 2 }}>
          {label?.date && (
            <InputLabel shrink required>
              {label?.date}
            </InputLabel>
          )}
          <DateRangePicker
            ref={dateRangeRef}
            start={start}
            end={end}
            onStartChange={onStartChange}
            onEndChange={onEndChange}
            disabled={disabled}
            flashError={flashError}
          />
        </div>
        <div style={{ flex: 1 }}>
          {label?.time && (
            <InputLabel shrink required>
              {label?.time}
            </InputLabel>
          )}
          <TimeRangePicker
            ref={timeRangeRef}
            start={startTime}
            end={endTime}
            onStartChange={onStartTimeChange}
            onEndChange={onEndTimeChange}
            disabled={disabled}
          />
        </div>
      </div>
      {errors != null && errors.length > 0 && (
        <FormHelperText error>
          <ul style={{ marginTop: 0, marginBottom: 0, paddingLeft: 16, paddingRight: 16 }}>
            {errors.map((error) => (
              <li>{error}</li>
            ))}
          </ul>
        </FormHelperText>
      )}
    </div>
  );
};

export default forwardRef(DateTimeRangePicker);
