import './style.scss';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useField } from 'uniforms';
import { TextField } from 'uniforms-material';

import usePreviousValue from '@yojee/helpers/hooks/usePreviousValue';
import { getHistoryData } from '@yojee/helpers/windowHelpers';
import { checkUnitValue } from '@yojee/ui/new-order-booking/components/helpers/schemaValidator/unitValidator';

import { customFilterDOMProps } from '../../../helpers/SchemaHelpers';
import UnitSelectField from './UnitSelectField';

function WithUnitField(props) {
  const [additionalError, setAdditionalError] = useState('');

  const unitFieldKey = `${props.field.key}_unit`;
  const [{ value: currentUnit, field: unitFieldSchema }] = useField(unitFieldKey, {}, { absoluteName: true });
  const { units, min, max, onChange: onChangeProp } = props;
  const defaultUnit = unitFieldSchema.default_value;
  const previousUnit = usePreviousValue(currentUnit);
  const isDisplayUnit = props.isDisplayUnit ?? true;

  const onChange = useCallback(
    (value) => {
      setAdditionalError(checkUnitValue({ value, min, max, currentUnit, defaultUnit }));
      onChangeProp(value < 0 ? 1 : value);
    },
    [onChangeProp, currentUnit, min, max, defaultUnit, setAdditionalError]
  );

  // When unit change, update validation error message
  useEffect(() => {
    if (previousUnit && currentUnit !== previousUnit) {
      setAdditionalError(checkUnitValue({ value: props.value, min, max, currentUnit, defaultUnit }));
    }
  }, [currentUnit, previousUnit]);

  const inputProps = useMemo(
    () => ({
      autoComplete: 'off',
    }),
    []
  );

  const historyData = getHistoryData();
  useEffect(() => {
    if (historyData) {
      if (props.value === null || props.value === '') {
        props.onChange(historyData['weight'], 'weight');
      }
    }
  }, [props]);

  return (
    <div className={isDisplayUnit ? 'with-unit-field-wrapper' : ''}>
      <TextField
        {...customFilterDOMProps(props)}
        onChange={onChange}
        value={props.value || ''}
        error={!!props.error || !!additionalError}
        helperText={props.error || additionalError}
        placeholder={props.field.sample_value}
        name=""
        inputProps={inputProps}
      />
      {isDisplayUnit && <UnitSelectField name={unitFieldKey} units={units} disabled={props.disabled} />}
    </div>
  );
}

export default WithUnitField;
