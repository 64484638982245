import './style.scss';

import { CircularProgress, TextField, Tooltip } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useForm } from 'uniforms';

import { getMapOfArray } from '@yojee/helpers/ArrayHelper';
import useDebounce from '@yojee/helpers/hooks/useDebounce';
import useService from '@yojee/helpers/hooks/useService';

import { updateState } from '../../../../saga/actions';
import { getOrderSenderSelector, getOrganisationsSelector } from '../../../../selectors';
import serviceLocator from '../../../../serviceLocator';
import { DUMMY_CORP_ID, useGenerateOptionsForAutoComplete } from '../../../helpers/formHelpers';
import { customFilterDOMProps } from '../../../helpers/SchemaHelpers';

function CorporateSelectField(props) {
  const { t } = useTranslation();
  const { value, onChange, disabled, label, isEditOrder } = props;
  const [inputValue, setInputValue] = useState();
  const organisations = useSelector(getOrganisationsSelector);
  const senderData = useSelector(getOrderSenderSelector);
  const formData = useForm();
  const primarySenderId = formData?.model?.id;

  const debouncedInputValue = useDebounce(inputValue, 300);

  const { status: getOrganisationsStatus, execute: getOrganisations } = useService(
    serviceLocator.senderApi.getOrganisations,
    {
      storeKey: 'organisations',
      updateStoreAction: updateState,
      autoCancelRequest: true,
      lazy: true,
    }
  );
  const loading = getOrganisationsStatus === 'loading';

  // Update primary sender id when change corporate
  useEffect(() => {
    const defaultSender = organisations?.find((org) => org.id === value)?.sender;
    if (defaultSender) {
      onChange(defaultSender.id, 'id');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // Preselect in case of edit order (primarySenderId is exists)
  useEffect(() => {
    if (primarySenderId && organisations) {
      const selectedOrg = organisations?.find((org) => org?.sender?.id === primarySenderId);
      if (selectedOrg?.id) {
        onChange(selectedOrg.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisations, primarySenderId]);

  // Set dummy corporate if no corporate in edit order to avoid validation error
  useEffect(() => {
    if (isEditOrder && !value) {
      onChange(DUMMY_CORP_ID);
    }
  }, [isEditOrder, onChange, value]);

  useEffect(() => {
    getOrganisations({
      pageSize: 10,
      q: debouncedInputValue,
    });
  }, [debouncedInputValue, getOrganisations, onChange]);

  const customOnChange = (event, newValue) => {
    onChange(newValue?.id || '');
  };

  const { options } = useGenerateOptionsForAutoComplete({
    value,
    name: senderData?.organisation_name,
    options: organisations,
  });

  const idAndNameMap = useMemo(() => getMapOfArray(options, 'id', 'name'), [options]);

  const getOptionLabel = (option) => {
    return option?.name || idAndNameMap[option] || '';
  };

  const onInputChange = (event, inputValue, reason) => {
    if (['input', 'clear'].includes(reason)) {
      if (!inputValue) {
        onChange('', 'corporate');
        onChange('', 'id');
      }
      setInputValue(inputValue);
    }
  };

  return (
    <div className="custom-field-corporate-wrapper">
      <Autocomplete
        className="custom-field-corporate-autocomplete"
        onInputChange={onInputChange}
        options={options}
        getOptionLabel={getOptionLabel}
        value={value || ''}
        disabled={disabled}
        onChange={customOnChange}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            {...customFilterDOMProps(props)}
            error={!!props.error}
            helperText={props.error}
            type="text"
            margin="dense"
            label={label}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment className="custom-field-corporate-adornment" position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <React.Fragment>
                  {loading && <CircularProgress color="inherit" size={20} />}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
              'data-cy': 'corporate-input',
            }}
            placeholder={t('Select Corporate or type to search')}
          />
        )}
        renderOption={(option) => (
          <Tooltip title={option?.name}>
            <Typography className="custom-field-corporate-select-option-item" noWrap>
              {option?.name}
            </Typography>
          </Tooltip>
        )}
        ListboxProps={{
          'data-cy': 'corporate-input-menu',
        }}
      />
      <div className="custom-field-corporate-placeholder-section" />
    </div>
  );
}

export default CorporateSelectField;
