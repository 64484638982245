import './style.scss';

import { makeStyles } from '@material-ui/core';

import {
  useIsInSenderDefaultValuesPage,
  useIsOrderCompletedOrCancelledStatus,
} from '../../../../../../../helpers/hooks';
import ClearItem from './ClearItem';
import DeleteItem from './DeleteItem';
import DownloadPhoto from './DownloadPhoto';
import DownloadPod from './DownloadPOD';
import DuplicateItem from './DuplicateItem';

const useStyles = makeStyles((theme) => ({
  divider: {
    display: 'inline-block',
    width: 1,
    height: 18,
    backgroundColor: '#979797',
    margin: '-4px 0px',
  },
}));

const ItemActions = ({ item, formKeyPath, itemIndex, totalItem, removeItem, addNewItem }) => {
  const styles = useStyles();
  const isInSenderDefaultValuesPage = useIsInSenderDefaultValuesPage();
  const isCompletedOrCancelled = useIsOrderCompletedOrCancelledStatus();

  return (
    <div className="booking-item-actions">
      {!isCompletedOrCancelled && (
        <div className="actionInlineBlock">
          <DuplicateItem formKeyPath={formKeyPath} addNewItem={addNewItem} labelName="Duplicate" />
          <span className={styles.divider} />
          <ClearItem formKeyPath={formKeyPath} />
          {totalItem > 1 && (!item || !item.id) && <span className={styles.divider} />}
          {totalItem > 1 && (!item || !item.id) && (
            <DeleteItem itemIndex={itemIndex} totalItem={totalItem} removeItem={removeItem} />
          )}
        </div>
      )}

      {item?.id && !isInSenderDefaultValuesPage && (
        <div className="actions">
          <DownloadPod item={item} />
          <DownloadPhoto item={item} />
        </div>
      )}
    </div>
  );
};

export default ItemActions;
