import _ from 'lodash-es';
import moment from 'moment-timezone';

import { FORMAT_DATE_TIME_NORMAL } from '@yojee/helpers/constants';
import { buildDateTimeData } from '@yojee/ui/new-order-booking/components/OrderForm/OrderActions/utils';

import { getFormKeyPath } from '../formHelpers';

export function addtTzWithoutSec(date, timezone) {
  const newDate = moment(date).seconds(0).milliseconds(0);
  return timezone ? moment(newDate.tz(timezone).format(FORMAT_DATE_TIME_NORMAL)) : newDate;
}

export function checkSlots({ noSlots, taskType }) {
  if (noSlots && taskType === 'pickup') {
    return timeSlotsNotFoundError();
  }

  return null;
}

function fromTimeMustLessThanOrEqualToTime({ fromTime, toTime }) {
  if (fromTime.isAfter(toTime)) {
    return {
      from_time: 'From time must less than or equal to time',
    };
  }

  return null;
}

function toTimeMustGreaterThanOrEqualFromTimeInPrevStep({
  toTime,
  orderFormModels,
  legIndex,
  stepIndex,
  bookingInfoSectionIndex,
}) {
  const isHavePrevStep = stepIndex > 0;
  if (!isHavePrevStep) {
    return null;
  }

  const prevStepFormModel = _.get(
    orderFormModels,
    getFormKeyPath({
      bookingInfoSectionIndex,
      legIndex: legIndex,
      stepIndex: stepIndex - 1,
    })
  );
  const dateTimeObject = buildDateTimeData(prevStepFormModel);

  const fromTimeInPrevStep = addtTzWithoutSec(dateTimeObject?.from_time, prevStepFormModel?.timezone);

  if (fromTimeInPrevStep && toTime.isBefore(fromTimeInPrevStep)) {
    return {
      to_time: 'To time must greater than or equal From time in the previous step',
    };
  }

  return null;
}

const timeSlotsNotFoundError = () => {
  return {
    from_time: 'No slots found. Select another date.',
  };
};

export function validateFromToTime({ model, errors, orderFormModels, legIndex, stepIndex, bookingInfoSectionIndex }) {
  if (errors['from_time'] || errors['to_time'] || errors['from_time_time'] || errors['to_time_time']) {
    return {};
  }

  if (!model?.from_time || !model?.to_time || !model?.from_time_time || !model?.to_time_time) {
    return {};
  }

  const dateTimeObject = buildDateTimeData(model);

  const timezone = model?.timezone;
  const fromTime = addtTzWithoutSec(dateTimeObject?.from_time, timezone);
  const toTime = addtTzWithoutSec(dateTimeObject?.to_time, timezone);
  const taskType = model?.type;

  if (window.IS_BOOKING_APP) {
    return checkSlots({ noSlots: model?.no_slots, taskType }) || {};
  }

  return (
    fromTimeMustLessThanOrEqualToTime({ fromTime, toTime }) ||
    toTimeMustGreaterThanOrEqualFromTimeInPrevStep({
      toTime,
      orderFormModels,
      legIndex,
      stepIndex,
      bookingInfoSectionIndex,
    }) ||
    {}
  );
}
