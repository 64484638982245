import Button from '@material-ui/core/Button';
import { omit } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getFormRefsFromPathSelector } from '@yojee/ui/new-order-booking/selectors';

import { addSuccessMessage } from '../../../../../../../../saga/actions';
import { ADD_LEG_ACTION_TYPE } from '../../../../../constants';

const DuplicateLeg = ({ formKeyPath, addNewLeg, closePopover }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const stepFormRefs = useSelector((state) => getFormRefsFromPathSelector(state, formKeyPath, []));

  const duplicate = () => {
    const legFormData = [];
    const [currentLegIndex] = formKeyPath.split('.').reverse();

    stepFormRefs
      .filter((formRef) => !!formRef)
      .forEach((stepFormRef) => {
        const stepFormData = omit(stepFormRef.getModel(), [
          'order_step_group_id',
          'order_step_id',
          'order_item_id',
          'id',
          'display_time_conflict_warning',
        ]);

        legFormData.push({ ...stepFormData, isNewStep: true });
      });

    addNewLeg(legFormData, ADD_LEG_ACTION_TYPE.DUPLICATE, { start: parseFloat(currentLegIndex) + 1 });

    dispatch(addSuccessMessage(t('A new leg is duplicated')));
    closePopover();
  };

  return (
    <div>
      <Button onClick={duplicate} data-cy="duplicate-leg-button">
        {t('Duplicate')}
      </Button>
    </div>
  );
};

export default DuplicateLeg;
