import './style.scss';

import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, { useCallback, useContext } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import EmptyLegLabel from '@yojee/ui/common/EmptyLegLabel';
import { isLegSectionHaveErrorSelector } from '@yojee/ui/new-order-booking/selectors';

import { BookingInfoSectionContext } from '../../../../Contexts';
import LegActions from './LegActions';
import { useIsEmptyLeg } from './LegActions/useIsEmptyLeg';

const useStyles = makeStyles(() => ({
  emptyLegContainer: {
    marginLeft: '10px',
  },
}));

const LegHeader = ({ legIndex, totalLeg, formOpened, removeLeg, addNewLeg, setFormOpened, formKeyPath }) => {
  const classes = useStyles();
  const { bookingInfoSectionIndex } = useContext(BookingInfoSectionContext);
  const isHaveError = useSelector((state) => isLegSectionHaveErrorSelector(state, bookingInfoSectionIndex, legIndex));
  const isEmptyLeg = useIsEmptyLeg(formKeyPath);

  const toggleDisplayForm = useCallback(() => {
    setFormOpened((v) => !v);
  }, [setFormOpened]);

  return (
    <div
      className={`flex align-center justify-between content-between new-booking-leg-header ${
        formOpened ? '' : 'form-closed'
      } ${isHaveError ? 'error' : ''}`}
    >
      <div className="flex align-center">
        <IconButton onClick={toggleDisplayForm} aria-label="settings">
          {formOpened ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
        <label className="section-label font-semibold uppercase">
          <Trans>LEG</Trans> {legIndex + 1}
        </label>
        {isEmptyLeg && <EmptyLegLabel className={classes.emptyLegContainer} />}
      </div>

      <LegActions
        legIndex={legIndex}
        totalLeg={totalLeg}
        removeLeg={removeLeg}
        addNewLeg={addNewLeg}
        formKeyPath={formKeyPath}
      />
    </div>
  );
};

export default LegHeader;
