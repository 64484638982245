import Button from '@material-ui/core/Button';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getFormRefsFromPathSelector } from '@yojee/ui/new-order-booking/selectors';

import { addSuccessMessage } from '../../../../../../../../saga/actions';
import { ConfigContext } from '../../../../../../../helpers/Contexts';
import { getFormKeyPathOfContainerFromItemKeyPath } from '../../../../../../../helpers/formHelpers';

const DuplicateItem = ({ formKeyPath, addNewItem, closePopover, labelName }) => {
  const label = labelName || 'Duplicate item';
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isForceOrderFormReadOnly } = useContext(ConfigContext);
  const itemFormRef = useSelector((state) => getFormRefsFromPathSelector(state, formKeyPath, null));
  const containerFormKeyPath = getFormKeyPathOfContainerFromItemKeyPath(formKeyPath);
  const containerFormRef = useSelector((state) => getFormRefsFromPathSelector(state, containerFormKeyPath, null));

  const duplicate = () => {
    const { id, ...itemFormData } = itemFormRef.getModel();
    const { id: _id, ...containerFormData } = containerFormRef?.getModel() ?? {};
    itemFormData.status = 'created';
    const itemDetailData = {
      item: itemFormData,
      item_container: containerFormData,
    };

    addNewItem(itemDetailData);

    dispatch(addSuccessMessage(t('Item duplicated')));
  };

  return (
    <Button
      disabled={isForceOrderFormReadOnly}
      onClick={duplicate}
      data-cy="duplicate-item-info"
      className="duplicate-btn"
    >
      <Trans>{label}</Trans>
    </Button>
  );
};

export default DuplicateItem;
