import './style.scss';

import Popover from '@material-ui/core/Popover';
import React from 'react';

import AddLegAtTheEnd from './AddLegAtTheEnd';
import AddLegInFront from './AddLegInFront';
import DuplicateLeg from './DuplicateLeg';
import SplitCurrentLeg from './SplitCurrentLeg';

const defaultPopoverProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
};

// TODO: Check the `origin` and `destination` markers when one of these actions is invoked?
const MoreActionsPopover = ({ open, closePopover, anchorEl, addNewLeg, formKeyPath }) => {
  return (
    <Popover {...defaultPopoverProps} open={open} onClose={closePopover} anchorEl={anchorEl}>
      <div className="new-booking-leg-more-actions">
        <AddLegInFront addNewLeg={addNewLeg} closePopover={closePopover} />
        <SplitCurrentLeg addNewLeg={addNewLeg} closePopover={closePopover} formKeyPath={formKeyPath} />
        <AddLegAtTheEnd addNewLeg={addNewLeg} closePopover={closePopover} />
        <DuplicateLeg addNewLeg={addNewLeg} closePopover={closePopover} formKeyPath={formKeyPath} />
      </div>
    </Popover>
  );
};
export default MoreActionsPopover;
