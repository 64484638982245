import { isPointInsideNonOperationalZone } from '@yojee/helpers/map-helper';

export function validateAddressLine1({ model, errors, nonOperationalZones }) {
  if (errors['address']) {
    return {};
  }

  if (!model?.location?.lat || !model?.location?.lng) {
    return {
      address: 'Invalid address. Please re-select from the dropdown',
    };
  }

  if (nonOperationalZones?.length) {
    for (let i = 0; i < nonOperationalZones.length; i++) {
      const nonOperatingZone = nonOperationalZones[i]?.[0] ?? [];
      if (isPointInsideNonOperationalZone(model?.location, nonOperatingZone)) {
        return {
          address: 'Our service does not operate at this location',
        };
      }
    }
  }

  return {};
}
