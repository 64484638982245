import { authService } from '@yojee/api/authService';
import { BaseService } from '@yojee/api/baseService';

export class SenderService extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  listSenders = (params) => {
    return this.authService
      .get(this.getUmbrellaApiUrl('dispatcher/senders'), params)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  getSenders = (params) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/senders/${params.id}`))
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleError(error));
  };

  createSender = (sender) => {
    return this.authService
      .post(this.getUmbrellaApiUrl('dispatcher/senders'), sender)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  updateSender = (sender) => {
    return this.authService
      .put(this.getUmbrellaApiUrl(`dispatcher/senders/${sender.id}`), sender)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  deleteSender = (id) => {
    return this.authService
      .delete(this.getUmbrellaApiUrl(`dispatcher/senders/${id}`))
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleError(error));
  };

  batchUpload = ({ file, format, type }) => {
    const uploadJson = new FormData();
    uploadJson.set('file', file);
    uploadJson.set('format', format);
    uploadJson.set('type', type);

    return this.authService
      .post(this.getUmbrellaApiUrl(`dispatcher/senders/batches`), uploadJson, { 'Content-Type': 'multipart/form-data' })
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  checkBatchStatus = ({ batchId }) => {
    return this.authService
      .get(this.getUmbrellaApiUrl(`dispatcher/senders/batches/${batchId}/status`))
      .then((response) => this.extractData(response))
      .catch((error) => this.handleError(error));
  };

  downloadTemplateSample = ({ format, type }) => {
    return this.getUmbrellaApiUrl(`public/senders/download_sample?format=${format}`);
  };
}

export const senderService = new SenderService({ authService });
