import React from 'react';
import { Trans } from 'react-i18next';

const OriginDestinationMismatchMessage = () => (
  <Trans>
    Origin & Destination location do not match Upstream Partner's Order.
    <br />
    Please refer to audit log for more information and consider re-planning this order.
  </Trans>
);

export default OriginDestinationMismatchMessage;
