import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useForm } from 'uniforms';

import { updateFormMetaData } from '../../../../saga/actions';

export const useUpdateVolumeWhenDimensionFieldsChange = (formKeyPath, onChange, volumeFromSystemCalculate) => {
  const dispatch = useDispatch();
  const { changedMap } = useForm();

  useEffect(() => {
    if (
      changedMap.length ||
      changedMap.length_unit ||
      changedMap.height ||
      changedMap.height_unit ||
      changedMap.width ||
      changedMap.width_unit ||
      changedMap.volume_unit
    ) {
      onChange(volumeFromSystemCalculate || 0);
      dispatch(
        updateFormMetaData(formKeyPath, {
          volumeNotMatchSystemCalculate: false,
        })
      );
    }
  }, [
    changedMap.height,
    changedMap.height_unit,
    changedMap.length,
    changedMap.length_unit,
    changedMap.volume_unit,
    changedMap.width,
    changedMap.width_unit,
    dispatch,
    formKeyPath,
    onChange,
    volumeFromSystemCalculate,
  ]);
};

export const useDetectVolumeValueNotMatchValueFromSystemCalculated = ({
  currentVolumeValue,
  formKeyPath,
  setAdditionalError,
  itemIndex,
  volumeFromSystemCalculate,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { changedMap, model } = useForm();

  // Detect volume not match with system calculate
  useEffect(() => {
    if (
      changedMap.volume &&
      volumeFromSystemCalculate &&
      Number(currentVolumeValue).toFixed(2) !== Number(volumeFromSystemCalculate).toFixed(2)
    ) {
      setAdditionalError(t('Volume does not match'));
      dispatch(
        updateFormMetaData(formKeyPath, {
          itemTrackingNumber: model.tracking_number ?? itemIndex + 1,
          volumeNotMatchSystemCalculate: true,
        })
      );
    } else {
      setAdditionalError('');
      dispatch(
        updateFormMetaData(formKeyPath, {
          volumeNotMatchSystemCalculate: false,
        })
      );
    }
  }, [
    changedMap.volume,
    currentVolumeValue,
    dispatch,
    formKeyPath,
    itemIndex,
    model.tracking_number,
    setAdditionalError,
    volumeFromSystemCalculate,
  ]);
};
