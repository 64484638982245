import { makeStyles } from '@material-ui/core/styles';
import { Trans } from 'react-i18next';

import TooltipWarningIcon from '../TooltipWarningIcon';

const useStyles = makeStyles(() => ({
  container: {
    padding: '16px 0px 8px 0px',
  },
  popper: {
    width: 'calc(100% - 40px)',
  },
  tooltip: {
    borderRadius: '8px',
    padding: '8px',
  },
}));

const TimeConflictWithPrevWarningIcon = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <TooltipWarningIcon
        title={<Trans i18nKey="timeConflictWithPreviousTaskWarningMessage" />}
        PopperProps={{ disablePortal: true }}
        classes={{ popper: classes.popper, tooltip: classes.tooltip }}
        placement="right"
        size={3}
      />
    </div>
  );
};

export default TimeConflictWithPrevWarningIcon;
