import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { getValue } from '../../utils/accessHelper';

const useStyles = makeStyles((theme) => ({
  list: {
    display: 'block',
    marginBottom: theme.spacing(0.5),
  },
}));

const BatchUploadErrorsNew = ({ errors }) => {
  const classes = useStyles();

  if (errors) {
    let errorMessages = [];
    if (errors.errors) {
      errorMessages = [].concat(
        ...errors.errors.map((e) => {
          if (e.errors) {
            return [].concat(
              ...e.errors.map((lineError) => {
                const field = Object.keys(lineError)[0];
                const fieldErrors = lineError[field];
                const fieldErrorsKey = Object.keys(fieldErrors)[0];
                const fieldError = fieldErrors[fieldErrorsKey];
                return fieldError.map((errorText) => ({
                  key: `${e.line}_${fieldErrorsKey}`,
                  message: `Line ${e.line} - Field: ${field} - ${errorText}`,
                }));
              })
            );
          } else if (e.error) {
            return [].concat(
              ...Object.values(e.error).map((lineErrors, c) => {
                const fieldErrorsKey = Object.keys(e.error)[e.error];
                return lineErrors.map((errorText) => ({
                  key: `${e.line}_${fieldErrorsKey}`,
                  message: `Line ${e.line} - ${errorText}`,
                }));
              })
            );
          }

          return [].concat(...Object.keys(e).map((key) => ({ key, message: e[key] })));
        })
      );
    } else if (Array.isArray(errors)) {
      errorMessages = [].concat(
        ...errors.map((error) => Object.keys(error).map((key) => ({ key, message: getValue(error, `${key}.0`) })))
      );
    }
    return errorMessages.map((error) => (
      <span className={classes.list} key={error.key}>
        {error.message}
      </span>
    ));
  }

  return null;
};

export default BatchUploadErrorsNew;
