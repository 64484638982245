import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { DefaultTheme, makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React from 'react';

import warningIcon from './icons-edit-warning.svg';

interface BaseTheme extends DefaultTheme {
  spacing: (arg: number, arg1?: number) => string;
}

const useStyles = (size: number) =>
  makeStyles((theme: BaseTheme) => ({
    root: {
      width: theme.spacing(size),
    },
    warningTooltip: {
      backgroundColor: '#ffbf4d',
      color: '#040921',
      textTransform: 'unset',
      fontWeight: 'normal',
      fontSize: '12px',
      maxWidth: 'none',
    },
    arrow: {
      color: '#ffbf4d',
    },
  }));

const TooltipWarningIcon = ({
  style,
  title,
  placement = 'top-start',
  size = 2,
  PopperProps = {},
  classes = {},
}: Pick<TooltipProps, 'style' | 'title' | 'placement' | 'PopperProps' | 'classes'> & { size?: number }) => {
  const internalClasses = useStyles(size)();
  const { tooltip, arrow, ...restExternalClasses } = classes;

  return (
    <Tooltip
      className={internalClasses.root}
      classes={{
        tooltip: clsx(internalClasses.warningTooltip, tooltip),
        arrow: clsx(internalClasses.arrow, arrow),
        ...restExternalClasses,
      }}
      style={style}
      title={title}
      placement={placement}
      PopperProps={PopperProps}
      arrow
    >
      <img src={warningIcon} alt="warning icon" />
    </Tooltip>
  );
};

export default TooltipWarningIcon;
