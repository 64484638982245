import './style.scss';

import MaterialUiPhoneNumber from 'material-ui-phone-number';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useField } from 'uniforms';

import { countries } from '@yojee/helpers/countryAndTimezoneHelper';

import { getCompanyInfoSelector } from '../../../../selectors';
import { customFilterDOMProps } from '../../../helpers/SchemaHelpers';

const PhoneField = (props) => {
  const { onChange } = props;
  const companyInfo = useSelector(getCompanyInfoSelector);
  const [{ value: defaultPhoneCountry }] = useField('default_phone_country', {}, { absoluteName: true });

  const companyCountryCode = useMemo(() => {
    const country = companyInfo?.country;
    if (country) {
      return (countries?.find((c) => c.name === country || c.alt_name === country)?.country_code ?? '').toLowerCase();
    }

    return '';
  }, [companyInfo]);

  return (
    <MaterialUiPhoneNumber
      {...customFilterDOMProps(props)}
      fullWidth
      className="ui-phone-number-field"
      margin="dense"
      label={props.label}
      defaultCountry={defaultPhoneCountry || companyCountryCode}
      countryCodeEditable={true}
      enableLongNumbers={true}
      value={props.value || ''}
      onChange={(value) => onChange(value)}
      required={props.required}
      error={!!props.error}
      helperText={props.error}
      disabled={props.disabled}
    />
  );
};

export default PhoneField;
