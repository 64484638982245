export const requestGetListVehicles = (payload) => {
  return { type: 'REQUEST_GET_VEHICLES', payload };
};

export const requestGetVehicle = (payload) => {
  return { type: 'REQUEST_GET_VEHICLE', payload };
};

export const resetVehicle = () => {
  return { type: 'RESET_VEHICLE' };
};

export const requestCreateVehicle = (payload) => {
  return { type: 'REQUEST_CREATE_VEHICLE', payload };
};

export const requestDeleteVehicle = (payload) => {
  return { type: 'REQUEST_DELETE_VEHICLE', payload };
};

export const requestUpdateVehicle = (payload) => {
  return { type: 'REQUEST_UPDATE_VEHICLE', payload };
};

export const requestSearchVehicleTags = (query) => {
  return { type: 'REQUEST_SEARCH_VEHICLE_TAGS', query };
};

export const requestGetListVehicleTypes = (payload) => {
  return { type: 'REQUEST_GET_VEHICLE_TYPES', payload };
};

export const requestGetVehicleTypesMaster = () => {
  return { type: 'REQUEST_GET_VEHICLE_TYPES_MASTER' };
};

export const requestCreateVehicleType = (payload) => {
  return { type: 'REQUEST_CREATE_VEHICLE_TYPE', payload };
};

export const requestUpdateVehicleType = (payload) => {
  return { type: 'REQUEST_UPDATE_VEHICLE_TYPE', payload };
};

export const requestDeleteVehicleTypes = (payload) => {
  return { type: 'REQUEST_DELETE_VEHICLE_TYPES', payload };
};

export const requestVehicleBatchUpload = (uploadData) => {
  return { type: 'REQUEST_VEHICLE_BATCH_UPLOAD', uploadData };
};

export const downloadVehicleSample = (format) => {
  return { type: 'DOWNLOAD_VEHICLE_TEMPLATE_SAMPLE', format };
};
