import { FormFieldComponent } from '../types';
import { AddressInput, AddressInputProps } from './AddressInput';
import { Checkbox, CheckboxFieldProps } from './Checkbox';
import { Input, InputFieldProps } from './Input';
import { MultiSelect, MultiSelectProps } from './MultiSelect';
import { PhoneInput, PhoneInputProps } from './PhoneInput';
import { SingleSelect, SingleSelectProps } from './SingleSelect';
import { SubTaskRulesFieldProps, SubTaskRuleTable } from './SubTaskRules';
import { Switch, SwitchFieldProps } from './Switch';
import { TransferList, TransferListProps } from './TransferList';

export type FieldType =
  | 'input'
  | 'singleSelect'
  | 'multiSelect'
  | 'phone'
  | 'switch'
  | 'checkbox'
  | 'addressInput'
  | 'subTaskRules'
  | 'transferList';

export type FieldPropsMap = {
  input: InputFieldProps;
  switch: SwitchFieldProps;
  checkbox: CheckboxFieldProps;
  multiSelect: MultiSelectProps;
  phone: PhoneInputProps;
  singleSelect: SingleSelectProps;
  addressInput: AddressInputProps;
  subTaskRules: SubTaskRulesFieldProps;
  transferList: TransferListProps;
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const FieldComponentMap: Record<FieldType, FormFieldComponent<any>> = {
  input: Input,
  switch: Switch,
  checkbox: Checkbox,
  phone: PhoneInput,
  multiSelect: MultiSelect,
  singleSelect: SingleSelect,
  addressInput: AddressInput,
  subTaskRules: SubTaskRuleTable,
  transferList: TransferList,
};
