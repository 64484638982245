import './style.scss';

import { TextField, Tooltip } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useField } from 'uniforms';

import { getMapOfArray } from '@yojee/helpers/ArrayHelper';
import useService from '@yojee/helpers/hooks/useService';
import { useIsCreateOrder } from '@yojee/ui/new-order-booking/components/helpers/hooks';

import { getOrderSenderSelector } from '../../../../selectors';
import serviceLocator from '../../../../serviceLocator';
import { DUMMY_CORP_ID, useGenerateOptionsForAutoComplete } from '../../../helpers/formHelpers';
import { customFilterDOMProps } from '../../../helpers/SchemaHelpers';

function CorporateUserSelectField(props) {
  const { t } = useTranslation();
  const { value, label, onChange } = props;
  const isCreateOrder = useIsCreateOrder();
  const senderData = useSelector(getOrderSenderSelector);
  const [{ value: corporateId }] = useField('corporate', {}, { absoluteName: true });
  const disabled = !corporateId || props.disabled;

  const { execute: getSenderByOrganizationId, data: senders } = useService(
    serviceLocator.senderApi.getSenderByOrganizationId,
    {
      lazy: true,
    }
  );

  useEffect(() => {
    if (corporateId && corporateId !== DUMMY_CORP_ID) {
      getSenderByOrganizationId({ organisationId: corporateId, page_size: 10000 });
    }
  }, [corporateId, getSenderByOrganizationId]);

  const senderLists = useMemo(() => {
    if (corporateId && senders) {
      return senders.map((sender) => ({ ...sender, id: sender.user_profile_id }));
    }
    return [];
  }, [senders, corporateId]);

  const customOnChange = (event, newValue) => {
    onChange(newValue?.id || '');
  };

  const { options } = useGenerateOptionsForAutoComplete({
    value,
    name: senderData?.name,
    options: senderLists,
  });

  // Autofill first sender of corporate
  useEffect(() => {
    if (options?.length > 0 && isCreateOrder) {
      onChange(options[0]?.id);
    }
  }, [options, isCreateOrder, onChange]);

  const idAndNameMap = useMemo(() => getMapOfArray(options, 'id', 'name'), [options]);

  const getOptionLabel = (option) => {
    return option?.name || idAndNameMap[option] || '';
  };

  return (
    <div className="custom-field-corporate-user-wrapper">
      <Autocomplete
        className="custom-field-corporate-user-autocomplete"
        options={options}
        getOptionLabel={getOptionLabel}
        value={value || ''}
        disabled={disabled}
        onChange={customOnChange}
        renderInput={(params) => (
          <TextField
            {...params}
            {...customFilterDOMProps(props)}
            error={!!props.error}
            helperText={props.error}
            margin="dense"
            type="text"
            label={label}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment className="custom-field-corporate-user-adornment" position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
              'data-cy': 'corporate-user-input',
            }}
            placeholder={t('Select Corporate User or type to search')}
          />
        )}
        renderOption={(option) => (
          <Tooltip title={option?.name}>
            <Typography className="custom-field-corporate-user-select-option-item" noWrap>
              {option?.name}
            </Typography>
          </Tooltip>
        )}
        ListboxProps={{
          'data-cy': 'corporate-user-input-menu',
        }}
      />
      <div className="custom-field-corporate-placeholder-section" />
    </div>
  );
}

export default CorporateUserSelectField;
