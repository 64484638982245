export const listSenders = (params) => {
  return { type: 'REQUEST_LIST_SENDERS', params };
};
export const getSenders = (params) => {
  return { type: 'REQUEST_GET_SENDERS', params };
};
export const createSender = (sender) => {
  return { type: 'REQUEST_CREATE_SENDER', sender };
};
export const updateSender = (sender) => {
  return { type: 'REQUEST_UPDATE_SENDER', sender };
};
export const deleteSender = (sender) => {
  return { type: 'REQUEST_DELETE_SENDER', sender };
};
export const requestSenderBatchUpload = (payload) => {
  return { type: 'REQUEST_SENDER_BATCH_UPLOAD', payload };
};
export const downloadSenderSample = (payload) => {
  return { type: 'DOWNLOAD_SENDER_TEMPLATE_SAMPLE', payload };
};
