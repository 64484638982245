import React from 'react';
import { Virtuoso } from 'react-virtuoso';

import { useIsEditOrder } from '../../../../../helpers/hooks';
import ItemSection from '../ItemSection';

const virtuosoStyle = {
  height: '600px',
};

const getItemSection = ({ index, addNew, removeItem, itemDetail, totalItem, onChangeItemDetail }) => {
  return (
    <ItemSection
      key={itemDetail?.uniqueID}
      itemDetail={itemDetail}
      itemIndex={index}
      totalItem={totalItem}
      removeItem={removeItem}
      addNewItem={addNew}
      onChangeItemDetail={onChangeItemDetail}
    />
  );
};

/**
 * Important: only apply virtualization when have many items in edit order
 */
const ListItemDetailsWithVirtualize = ({ itemDetails, addNew, removeItem, onChangeItemDetail }) => {
  const isEditOrder = useIsEditOrder();
  const totalItem = itemDetails.length;

  if (isEditOrder && itemDetails.length > 5) {
    return (
      <Virtuoso
        style={virtuosoStyle}
        data={itemDetails}
        itemContent={(index, itemDetail) =>
          getItemSection({ index, addNew, removeItem, itemDetail, totalItem, onChangeItemDetail })
        }
        followOutput
        initialTopMostItemIndex={totalItem - 1}
      />
    );
  }

  return itemDetails.map((itemDetail, index) =>
    getItemSection({ index, addNew, removeItem, itemDetail, totalItem, onChangeItemDetail })
  );
};

export default React.memo(ListItemDetailsWithVirtualize);
