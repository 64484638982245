import './style.scss';

import IconButton from '@material-ui/core/IconButton';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, { useCallback, useContext } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { isItemDetailSectionHaveErrorSelector } from '@yojee/ui/new-order-booking/selectors';

import { useIsEditOrder } from '../../../../../helpers/hooks';
import { BookingInfoSectionContext } from '../../../Contexts';
import { useNumberItemsOfBookingInfoSection, useNumberLinesOfBookingInfoSection } from '../../../helpers';

const ItemDetailsHeader = ({ formOpened, setFormOpened }) => {
  const { bookingInfoSectionIndex } = useContext(BookingInfoSectionContext);
  const isEditOrder = useIsEditOrder();
  const isHaveError = useSelector((state) => isItemDetailSectionHaveErrorSelector(state, bookingInfoSectionIndex));
  const numberItems = useNumberItemsOfBookingInfoSection();
  const numberLines = useNumberLinesOfBookingInfoSection();

  const toggleDisplayForm = useCallback(() => {
    setFormOpened((v) => !v);
  }, [setFormOpened]);

  return (
    <div
      className={`flex align-center new-booking-item-details-header ${formOpened ? '' : 'form-closed'} ${
        isHaveError ? 'error' : ''
      }`}
    >
      <IconButton onClick={toggleDisplayForm} aria-label="settings">
        {formOpened ? <ExpandLess /> : <ExpandMore />}
      </IconButton>
      <label className="section-label font-semibold">
        {numberLines} <Trans>Line(s)</Trans>{' '}
        {isEditOrder && numberItems > 0 && (
          <span>
            (<Trans>Total</Trans>: {numberItems} <Trans>Item(s)</Trans>)
          </span>
        )}
      </label>
    </div>
  );
};

export default ItemDetailsHeader;
