import BaseSwitch from '@yojee/ui/base/furnitures/Switch';

import { FormFieldComponent } from '../types';

export type SwitchFieldProps = React.ComponentProps<typeof BaseSwitch>;

export const Switch: FormFieldComponent<'switch'> = ({
  fieldName,
  formField: { label, disabled, fieldProps },
  value,
  setValue,
}) => {
  return (
    <BaseSwitch
      {...fieldProps}
      label={label}
      disabled={disabled}
      checked={!!value}
      onChange={() => setValue(!value)}
      switchProps={fieldName}
    />
  );
};
