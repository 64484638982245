import React from 'react';

import Tooltip from '@yojee/ui/base/furnitures/Tooltip';

const TooltipWrapper = ({ title, children, ...restProps }) => {
  if (title) {
    return (
      <Tooltip title={title} enterDelay={500} {...restProps}>
        {children}
      </Tooltip>
    );
  }

  return children;
};

export default TooltipWrapper;
