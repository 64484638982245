import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getOrderFormModelsSelector, getTemplateDetailSelector } from '@yojee/ui/new-order-booking/selectors';

import { SchemaHelperContext } from '../Contexts';
import { useIsCreateOrder, useIsEditOrder } from '../hooks';
import { SchemaHelper } from '../SchemaHelpers';

const defaultSchemaHelper = new SchemaHelper();

const WithSchemaHelper = ({ children }) => {
  const isEditOrder = useIsEditOrder();
  const isCreateOrder = useIsCreateOrder();
  const templateDetail = useSelector(getTemplateDetailSelector);
  const orderFormModels = useSelector(getOrderFormModelsSelector);

  const schemaHelper = useMemo(() => {
    if (templateDetail?.fields_schema && ((isEditOrder && orderFormModels) || isCreateOrder)) {
      return new SchemaHelper({
        templateSchema: templateDetail.fields_schema,
        templateSettings: templateDetail.settings,
        orderFormModels,
        isEditOrder,
      });
    }

    return defaultSchemaHelper;
  }, [isEditOrder, orderFormModels, templateDetail, isCreateOrder]);

  return <SchemaHelperContext.Provider value={schemaHelper}>{children}</SchemaHelperContext.Provider>;
};

export default WithSchemaHelper;
