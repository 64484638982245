import React, { useContext, useState } from 'react';

import { ORDER_BOOKING_TAB } from '@yojee/helpers/constants';

import { ConfigContext } from '../../../../helpers/Contexts';
import { usePerformVariousChecksForOrder } from '../../hooks';
import NextBtnUI from '../NextBtnUI';

const NextBtnInBookingPage = () => {
  const { changeTab } = useContext(ConfigContext);
  const [isDisableBtn, setIsDisableBtn] = useState(false);
  const { doChecks, getConfirmDialogs } = usePerformVariousChecksForOrder();

  const gotoSummaryTab = () => {
    changeTab(ORDER_BOOKING_TAB.booking_summary.value);
  };

  const goNext = async () => {
    setIsDisableBtn(true);
    await doChecks(gotoSummaryTab);
    setIsDisableBtn(false);
  };

  return (
    <>
      <NextBtnUI goNext={goNext} isDisabled={isDisableBtn} />

      {getConfirmDialogs({
        onConfirmOkMissingInfo: gotoSummaryTab,
        onConfirmOkVolumeMismatch: gotoSummaryTab,
      })}
    </>
  );
};

export default NextBtnInBookingPage;
