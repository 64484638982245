import { call, put, select, takeLatest } from 'redux-saga/effects';

import { addressService } from '@yojee/api/addressService';
import { updateBulkSelection } from '@yojee/ui/BulkUpdate/redux/bulkActions';

export default function* sagas() {
  yield takeLatest('REQUEST_GET_ADDRESSES', getAddresses);
  yield takeLatest('REQUEST_CREATE_ADDRESS', createAddress);
  yield takeLatest('REQUEST_UPDATE_ADDRESS', updateAddress);
  yield takeLatest('REQUEST_DELETE_ADDRESS', deleteAddress);
  yield takeLatest('REQUEST_UPLOAD_ADDRESSES', uploadAddresses);
  yield takeLatest('BULK_DELETE_ADDRESS_REQUEST', bulkDeleteAddressSaga);
  yield takeLatest('DOWNLOAD_ADDRESS_TEMPLATE_SAMPLE', downloadAddressTemplateSample);
}

export const getFilter = (state) => {
  return state.address.filter;
};

function* getAddresses(action) {
  try {
    yield put({ type: 'START_LOADING', loadingAction: 'getAddresses' });
    const response = yield call(addressService.getAddresses, action.params);
    yield put({ type: 'GET_ADDRESSES_SUCCESSFUL', addressData: response, params: action.params });
  } catch (error) {
    yield put({ type: 'GET_ADDRESSES_FAILED', error });
  }
}

function* createAddress(action) {
  yield put({ type: 'START_LOADING', loadingAction: 'createAddress' });
  const { address, isLoadAddresses } = action;
  yield put({ type: 'CLEAR_NEW_ADDRESS_ID' });
  yield put({ type: 'CLEAR_ADDRESS_MESSAGE' });
  try {
    const addressData = yield call(addressService.createAddress, address);
    yield put({ type: 'CREATE_ADDRESS_SUCCESSFUL', addressData });

    if (isLoadAddresses) {
      yield put({ type: 'REQUEST_GET_ADDRESSES', params: yield select(getFilter) });
    }
  } catch (error) {
    yield put({ type: 'CREATE_ADDRESS_FAILED', error });
  }
}
function* updateAddress({ payload }) {
  yield put({ type: 'START_LOADING', loadingAction: 'updateAddress' });
  try {
    const addressData = yield call(addressService.updateAddress, payload);
    yield put({ type: 'UPDATE_ADDRESS_SUCCESSFUL', addressData });
    yield put({ type: 'REQUEST_GET_ADDRESSES', params: yield select(getFilter) });
  } catch (error) {
    yield put({ type: 'UPDATE_ADDRESS_FAILED', error });
  }
}
function* deleteAddress(action) {
  yield put({ type: 'START_LOADING', loadingAction: 'deleteAddress' });
  try {
    const addressData = yield call(addressService.deleteAddress, action.address.id);
    yield put({ type: 'DELETE_ADDRESS_SUCCESSFUL', addressData });
    yield put({ type: 'REQUEST_GET_ADDRESSES', params: yield select(getFilter) });
  } catch (error) {
    yield put({ type: 'DELETE_ADDRESS_FAILED', error });
  }
}

function* bulkDeleteAddressSaga(action) {
  try {
    const data = action.payload;
    yield call(addressService.deleteBulkAddress, data.ids);
    yield put({ type: 'DELETE_ADDRESS_SUCCESSFUL' });
    yield put(updateBulkSelection([]));
    yield put({ type: 'REQUEST_GET_ADDRESSES', params: yield select(getFilter) });
  } catch (error) {
    yield put({ type: 'BULK_DELETE_ADDRESS_FAIL' });
  }
}

function* uploadAddresses(action) {
  yield put({ type: 'ADDRESS_BATCH_UPLOAD_STARTED', loadingAction: 'uploadAddress' });
  try {
    const data = yield call(addressService.uploadAddresses, action.payload.file, action.payload.sender_id);
    if (data) {
      yield put({ type: 'ADDRESS_BATCH_UPLOAD_SUCCEEDED', total: data.length });
    }
  } catch (e) {
    yield put({
      type: 'ADDRESS_BATCH_UPLOAD_FAILED',
      errors: {
        errors: e.message
          ? Array.isArray(e.message)
            ? e.message
            : [{ serverError: e.message }]
          : [{ serverError: `Please populate all mandatory fields` }],
      },
    });
  }
}

function downloadAddressTemplateSample({ format, type }) {
  window.open(addressService.downloadTemplateSample({ format, type }), '_self');
}
