import { ThemeProvider } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Checkbox, theme } from '@yojee/ui/base';
import Text from '@yojee/ui/base/furnitures/Typography/Text';
import { sendEvent } from '@yojee/ui/event-tracking';
import { updateField } from '@yojee/ui/new-order-booking/components/helpers/hooks';
import { getFormRefsFromPathSelector } from '@yojee/ui/new-order-booking/selectors';

import { useIsEmptyLeg } from './useIsEmptyLeg';

interface EmptyLegCheckboxProps {
  legFormKeyPath: string;
}

const useStyles = makeStyles((theme) => ({
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginRight: 0,
  },
}));

export function EmptyLegCheckbox({ legFormKeyPath }: EmptyLegCheckboxProps) {
  const styles = useStyles();
  const { t } = useTranslation();
  const legRef = useSelector((state) => getFormRefsFromPathSelector(state, legFormKeyPath));
  const isEmptyLeg = useIsEmptyLeg(legFormKeyPath);

  const markEmptyLeg = (isEmpty: boolean) => {
    sendEvent('PLANNING', isEmpty ? 'MARK_LEG_AS_EMPTY' : 'MARK_LEG_AS_NON_EMPTY');

    const [pickupRef, dropoffRef] = legRef;

    updateField(pickupRef, {
      is_empty: isEmpty,
    });

    updateField(dropoffRef, {
      is_empty: isEmpty,
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Checkbox
        className={styles.checkboxContainer}
        size="small"
        label={
          <Text color="inherit" variant="body2">
            {t('EMPTY (MT)')}
          </Text>
        }
        checked={isEmptyLeg}
        onChange={() => markEmptyLeg(!isEmptyLeg)}
        dataCy="set-as-empty-leg"
      />
    </ThemeProvider>
  );
}
