import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import { validateFormData } from './validateForm';

export const useFormValidator = <T>({
  defaultData,
  rules,
  onChange,
  onFieldChange,
}: {
  defaultData: T;
  rules: Record<keyof T, unknown[]>;
  onFieldChange?: (data: T, field: keyof T) => void;
  onChange?: (data: T) => void;
}) => {
  const [formErrors, setFormErrors] = useState<Partial<Record<keyof T, string>>>({});
  const [formData, setFormData] = useState<T>(defaultData);

  const validate = (formData: T) => {
    const newErrors = validateFormData(formData, rules) as typeof formErrors;

    setFormErrors(newErrors);

    return {
      isValid: isEmpty(newErrors),
      newErrors,
    };
  };

  const resetErrors = (changedData: Partial<T>) => {
    const newErrors = Object.keys(changedData).reduce(
      (acc, key) => ({
        ...acc,
        [key]: null,
      }),
      formErrors
    );

    setFormErrors(newErrors);
  };

  const setFieldValue = (data: Partial<typeof formData>, shouldTriggerChange = true) => {
    const newData = {
      ...formData,
      ...data,
    };
    setFormData(newData);
    resetErrors(data);
    if (shouldTriggerChange) {
      setTimeout(() => onFieldChange?.(newData, Object.keys(data)[0] as keyof T));
    }
  };

  useEffect(() => {
    if (formData === defaultData) {
      return;
    }

    onChange?.(formData);
  }, [formData, onChange, defaultData]);

  return {
    validate,
    formErrors,
    formData,
    setFieldValue,
  };
};
