import { css, FormHelperText, keyframes, styled } from '@mui/material';
import { ComponentProps, forwardRef, ForwardRefRenderFunction } from 'react';

import { COMPONENT_HEIGHT } from '../constant';

export const BaseInput = styled('input')<{ error: string | null }>(({ error }) => ({
  height: COMPONENT_HEIGHT,
  width: '100%',
  border: 0,
  outline: 'none',
  paddingTop: 8,
  paddingBottom: 8,
  backgroundColor: 'transparent',
  color: '#1bace0',
  fontWeight: 'bold',
  '&::placeholder': {
    color: '#dedede',
    fontSize: 12,
  },
  '&:hover': {
    backgroundColor: '#e8f7fc',
    borderRadius: 4,
    transition: `background-color 0.2s ease`,
  },
  '&:focus': {
    backgroundColor: '#e8f7fc',
    borderRadius: 4,
    transition: `background-color 0.3s ease`,
  },
  '&:disabled': {
    color: '#757575',
    backgroundColor: 'transparent',
  },
  ...(error && {
    borderRadius: 4,
    border: '1px solid #dc3545',
  }),
}));

const flashErrorKeyframes = keyframes`
  0%,
  100% {
    background-color: transparent;
  }
  50% {
    background-color: #dc3545;
  }
`;

const AnimatedInput = styled(BaseInput)<{ flashError?: boolean }>`
  animation: ${(props) =>
    props.flashError
      ? css`
          ${flashErrorKeyframes} 0.5s
        `
      : ''};
`;

const StyledInput: ForwardRefRenderFunction<HTMLInputElement, ComponentProps<typeof AnimatedInput>> = (props, ref) => {
  return (
    <div>
      <AnimatedInput {...props} ref={ref} />
      {props.error && <FormHelperText error>{props.error}</FormHelperText>}
    </div>
  );
};

export default forwardRef(StyledInput);
