import { t } from 'i18next';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useField } from 'uniforms';

import DateTimeRangePicker from '@yojee/ui/common/DateTimeRangePicker';
import { isDateType, isValidDate } from '@yojee/ui/common/DateTimeRangePicker/Date/utils';

import { clearDateTimeInputEnd } from '../../../../saga/actions';

// fromDate and toDate can be:
// - null, Date: Create Order
// - string: first load of Edit Order
// - empty string: Clear/Clear All leg info
const sanitizeDate = (date) => {
  if (isValidDate(date)) {
    if (isDateType(date)) return date;
    return moment(date).toDate();
  }
  return null;
};

function DateTimeRangeField(props) {
  const dispatch = useDispatch();
  const dateTimeRangeRef = useRef(null);
  const formKeyPath = props.formKeyPath;

  const shouldClearDateTimeInputForFormKeyPath = useSelector((state) => {
    return state?.orderBooking?.shouldClearDateTimeInputForFormKeyPath;
  });

  const { value: fromDate, onChange: onFromDateChange, className, disabled } = props;
  const [{ value: toDate, onChange: onToDateChange }] = useField('to_time', {}, { absoluteName: true });
  const [{ value: fromTime, onChange: onFromTimeChange }] = useField('from_time_time', {}, { absoluteName: true });
  const [{ value: toTime, onChange: onToTimeChange }] = useField('to_time_time', {}, { absoluteName: true });

  useEffect(() => {
    if (shouldClearDateTimeInputForFormKeyPath[formKeyPath]) {
      dateTimeRangeRef?.current.clear();
      dispatch(clearDateTimeInputEnd(formKeyPath));
    }
  }, [shouldClearDateTimeInputForFormKeyPath[formKeyPath]]);

  return (
    <DateTimeRangePicker
      ref={dateTimeRangeRef}
      start={sanitizeDate(fromDate)}
      onStartChange={onFromDateChange}
      end={sanitizeDate(toDate)}
      onEndChange={onToDateChange}
      startTime={fromTime}
      onStartTimeChange={onFromTimeChange}
      endTime={toTime}
      onEndTimeChange={onToTimeChange}
      label={{ date: t('Dates'), time: t('Times') }}
      className={className}
      disabled={disabled}
    />
  );
}

export default DateTimeRangeField;
