import React from 'react';

import { isVesselCategoryField } from '../../../helpers/utils';
import BaseFromDateField from './BaseFromDateField';
import BookingPageFromDateField from './BookingPageFromDateField';
import ExploreFromDateField from './ExploreFromDateField';

function FromDateField(props) {
  if (isVesselCategoryField(props.field)) return <BaseFromDateField {...props} />;

  return window.IS_BOOKING_APP ? <BookingPageFromDateField {...props} /> : <ExploreFromDateField {...props} />;
}

export default FromDateField;
