import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import clsx from 'clsx';
import React from 'react';

const styledBy = (property, mapping) => (props) => {
  return mapping[props[property]] || mapping.default;
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      flexGrow: 1,
    },
    child: {
      width: styledBy('size', {
        default: '100%',
        small: theme.spacing(25),
        medium: theme.spacing(30),
        large: theme.spacing(35),
      }),
      maxHeight: '100%',
      borderRadius: theme.spacing(1),
      backgroundImage: (props) => {
        const colorBackground = styledBy('color', {
          default: theme.palette.black.B4,
          primary: theme.palette.primary.tint,
          success: theme.palette.success.tint,
          error: theme.palette.error.tint,
        })(props);
        return `linear-gradient(to top, ${theme.palette.primary.main} 4px ,transparent 0),linear-gradient(to top, ${colorBackground} , ${colorBackground} 0)`;
      },
      backgroundRepeat: 'no-repeat',
      backgroundSize: ({ percent }) => (percent ? percent + '%,100%' : '0%,100%'),
      color: theme.palette.common.black,
      padding: '10px 14px',
    },
    childInfo: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    childText: {
      color: theme.palette.common.black,
      fontWeight: theme.typography.fontWeightBold,
      marginRight: theme.spacing(1),
    },
    childLabel: {
      color: theme.palette.common.black,
      opacity: 0.6,
      fontWeight: 'normal',
    },
    childError: {
      '& ul': {
        margin: 0,
        padding: `0 ${theme.spacing(2.5)}px`,
      },
    },
    closeIcon: {
      alignSelf: 'flex-start',
      '&:hover': {
        cursor: 'pointer',
        color: theme.palette?.black?.B60,
      },
    },
    successIcon: {
      color: theme.palette.success.main,
    },
    icon: {
      marginRight: theme.spacing(1),
      alignSelf: 'flex-start',
    },
    fontWeightNormal: {
      fontWeight: 'normal',
    },
    fileName: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
      wordBreak: 'break-all',
    },
    failedMess: {
      display: 'block',
      marginBottom: theme.spacing(1),
    },
  };
});

const defaultLength = 10;
const trimFileName = (fileName, maxTextLength) => {
  if (!fileName || !maxTextLength) return fileName;
  if (fileName.length > maxTextLength)
    return (
      fileName.substring(0, Math.min(maxTextLength, defaultLength)) +
      '..' +
      fileName.substring(fileName.length - maxTextLength + defaultLength, fileName.length)
    );
  return fileName;
};

const ErrorState = ({ classes, error, failedMess }) => (
  <div className={clsx(classes.childError, classes.fontWeightNormal)}>
    <span className={classes.failedMess}>{failedMess}</span>
    <hr />
    {error}
  </div>
);

const FileName = ({ classes, fileName, maxTextLength, fileSize }) => (
  <div className={classes.fileName}>
    <span className={classes.childText}>{fileName ? trimFileName(fileName, maxTextLength) : ''}</span>
    <span className={classes.childLabel}>
      {fileSize
        ? (fileSize / 1048576).toFixed(1) > 0
          ? `(${(fileSize / 1048576).toFixed(1)}MB)`
          : `(${(fileSize / 1024).toFixed(1)}KB)`
        : ''}
    </span>
  </div>
);

const CompleteState = ({ classes, successMess }) => (
  <span className={clsx(classes.fontWeightNormal)}>{successMess}</span>
);

const FileState = ({ args }) => {
  const classes = useStyles(args);

  if (args.error) {
    return <ErrorState classes={classes} error={args.error} failedMess={args.failedMess} />;
  }

  if (args.completed) {
    return <CompleteState classes={classes} successMess={args.successMess} />;
  }

  return null;
};

const FileUploading = ({ children, className, ...args }) => {
  const classes = useStyles(args);
  return (
    <div className={classes.child}>
      <div className={classes.childInfo}>
        {args.error ? <ErrorOutlineIcon className={clsx(classes.icon)} color="error" /> : null}
        {args.completed ? <CheckIcon className={clsx(classes.icon, classes.successIcon)} /> : null}
        <div>
          <div>
            <FileName
              classes={classes}
              fileName={args.fileName}
              maxTextLength={args.maxTextLength}
              fileSize={args.fileSize}
            />
          </div>
          <div>
            <FileState args={args} />
          </div>
        </div>
        <div className={classes.root} />
        {args.removeFile ? (
          <CloseIcon
            color={args.disabled ? 'disabled' : 'inherit'}
            className={classes.closeIcon}
            onClick={args.removeFile}
          />
        ) : null}
      </div>
    </div>
  );
};

export default FileUploading;
