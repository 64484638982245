import { makeStyles } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React, { useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '@yojee/ui/base/furnitures/Button';
import TooltipReadOnly from '@yojee/ui/common/TooltipReadOnly';
import { getIsPreviewTransferredOrder } from '@yojee/ui/new-order-booking/selectors';
import { ReadOnlyContext } from '@yojee/ui/ReadOnlyHelper';

import {
  useIsAllowDispatcherAllMoreLegs,
  useIsInSenderDefaultValuesPage,
  useIsReadOnlyLegSection,
} from '../../../../../../../helpers/hooks';
import { useIsOrderHaveSomeTasksCanceled } from '../../../../../helpers';
import ClearAll from './ClearAll';
import DeleteLeg from './DeleteLeg';
import { EmptyLegCheckbox } from './EmptyLegCheckbox';
import MoreActionsPopover from './MoreActionsPopover';

const useStyles = makeStyles((theme) => ({
  divider: {
    display: 'inline-block',
    width: 1,
    height: 18,
    backgroundColor: '#979797',
    margin: '0 4px',
    '&:first-child': {
      display: 'none',
    },
  },
}));

const LegActions = ({ legIndex, totalLeg, removeLeg, addNewLeg, formKeyPath }) => {
  const styles = useStyles();
  const readOnly = useContext(ReadOnlyContext);
  const [showPopover, setShowPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const isInSenderDefaultValuesPage = useIsInSenderDefaultValuesPage();
  const isAllowDispatcherAllMoreLegs = useIsAllowDispatcherAllMoreLegs();
  const isPreviewMode = useSelector(getIsPreviewTransferredOrder);
  const isCanceledOrder = useIsOrderHaveSomeTasksCanceled();
  const isReadOnlyLegSection = useIsReadOnlyLegSection();

  const onAddLegs = (e) => {
    setShowPopover(true);
    setAnchorEl(e.currentTarget);
  };

  return (
    <div className="leg-actions">
      {!window.IS_BOOKING_APP && !isPreviewMode && !isCanceledOrder && !isReadOnlyLegSection && (
        <EmptyLegCheckbox legIndex={legIndex} legFormKeyPath={formKeyPath} />
      )}

      {/* Add legs Button */}
      {!isInSenderDefaultValuesPage && isAllowDispatcherAllMoreLegs && (
        <>
          <span className={styles.divider} />
          <TooltipReadOnly readOnly={readOnly}>
            <Button
              size="small"
              onClick={onAddLegs}
              data-cy="see-more-leg-button"
              disabled={readOnly}
              endIcon={<ArrowDropDownIcon />}
            >
              <Trans>Add legs</Trans>
            </Button>
          </TooltipReadOnly>
          {showPopover && (
            <MoreActionsPopover
              addNewLeg={addNewLeg}
              anchorEl={anchorEl}
              open={showPopover}
              closePopover={() => setShowPopover(false)}
              formKeyPath={formKeyPath}
            />
          )}
        </>
      )}

      <span className={styles.divider} />
      {/* Clear All Button */}
      <ClearAll formKeyPath={formKeyPath} />
      {/* Delete Leg Button */}
      {!isInSenderDefaultValuesPage && (
        <DeleteLeg
          legIndex={legIndex}
          totalLeg={totalLeg}
          removeLeg={removeLeg}
          formKeyPath={formKeyPath}
          divider={<span className={styles.divider} />}
        />
      )}
    </div>
  );
};

export default LegActions;
