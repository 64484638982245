import React from 'react';

import BookingPageLegSection from './BookingPageLegSection';
import LegSection from './LegSection';

const LegsSectionUI = ({ legs, removeIndex, addNewLeg }) => {
  const LegSectionComponent = window.IS_BOOKING_APP ? BookingPageLegSection : LegSection;

  return (
    <section className="new-booking-legs-section">
      <div className="legs">
        {legs?.map((leg, index) => {
          return (
            <LegSectionComponent
              key={leg.uniqueID}
              legIndex={index}
              totalLeg={legs?.length}
              steps={leg.steps}
              removeLeg={removeIndex}
              addNewLeg={addNewLeg}
            />
          );
        })}
      </div>
    </section>
  );
};

export default LegsSectionUI;
