import { makeStyles } from '@material-ui/styles';
import React, { useRef, useState } from 'react';
import { Trans } from 'react-i18next';

import { Dialog, Tab } from '../base/index';
import { AddressForm } from './AddressForm';

const useStyles = makeStyles((theme) => ({
  tabPanel: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}));

const AddressDialog = ({
  address,
  onSave,
  onClose,
  open,
  timezone,
  onDialogClose,
  uploadItemType,
  senderId,
  BatchUploadContent,
  isLoading,
  showBatchUpload = true,
  readOnly = false,
}) => {
  const contentRef = useRef();
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  const [dataCy, setDataCy] = useState('address-save-button');
  const batchComponentRef = useRef();
  const isEditMode = address?.id;

  const handleSave = () => {
    batchComponentRef.current?.saveRef();
    contentRef.current?.querySelector('form button[type="submit"]')?.click();
  };

  const handleChange = (event, newValue) => {
    setTab(newValue);
    if (newValue === 0) setDataCy('address-save-button');
    else setDataCy('batch-upload');
  };

  function close() {
    onDialogClose?.(true);
    onClose();
  }

  const renderForm = () => (
    <AddressForm
      addressItem={address}
      timezone={timezone}
      onSave={(address, isTouched) => {
        onSave({ address, updateRelatedOrderSteps: isTouched });
      }}
    />
  );

  return (
    <Dialog
      onClose={close}
      open={open}
      fullWidth={true}
      onSave={handleSave}
      maxWidth="md"
      dividers="paper"
      haveTopDivider={!isEditMode && showBatchUpload ? true : false}
      isLoading={isLoading}
      primaryBtnDisabled={readOnly}
      title={
        <>
          <div>{isEditMode ? <Trans>Edit Address</Trans> : <Trans>Add Address</Trans>}</div>
          {!isEditMode && showBatchUpload && (
            <div>
              <Tab.Tabs noBorder={true} value={tab} onChange={handleChange}>
                <Tab.Tab label={<Trans>Create New</Trans>} />
                {BatchUploadContent && <Tab.Tab label={<Trans>Batch Upload</Trans>} />}
              </Tab.Tabs>
            </div>
          )}
        </>
      }
      dataCy={dataCy}
    >
      <div ref={contentRef} className={classes.tabPanel}>
        {!isEditMode && showBatchUpload ? (
          <>
            <Tab.TabPanel value={tab} index={0}>
              {renderForm()}
            </Tab.TabPanel>
            {BatchUploadContent && (
              <Tab.TabPanel value={tab} index={1}>
                <BatchUploadContent
                  ref={batchComponentRef}
                  uploadItemType={uploadItemType}
                  senderId={senderId}
                  onSuccess={close}
                />
              </Tab.TabPanel>
            )}
          </>
        ) : (
          renderForm()
        )}
      </div>
    </Dialog>
  );
};

export default AddressDialog;
