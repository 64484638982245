import { Grid } from '@material-ui/core';
import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
// Libs
import { AutoForm } from 'uniforms-material';

// Selectors
import { getVesselVoyageInfoSelector, isVesselSectionHaveErrorSelector } from '../../../selectors';
// Base UI
import Section from '../../common/Section';
import { SchemaHelperContext } from '../../helpers/Contexts';
// Helpers
import { getFormKeyPath } from '../../helpers/formHelpers';
import { useRegisterForm, useSchema } from '../../helpers/hooks';
// Custom Field helpers
import CustomAutoField from '../CustomFields/CustomAutoField';
import HandleSyncFormStatusForAutoForm from '../CustomFields/HandleSyncFormStatusForAutoForm';
import { useIsShowLoadingFormStatus } from '../helper';
import Header from './Header';
import useStyles from './styles';

//* Ticket: https://yojeee.atlassian.net/browse/MT-341
const VesselSection = () => {
  const classes = useStyles();

  const formKeyPath = getFormKeyPath({ type: 'vessel' });
  const { setFormRef } = useRegisterForm(formKeyPath);

  const vesselVoyageInfo = useSelector(getVesselVoyageInfoSelector);
  const vesselHasError = useSelector(isVesselSectionHaveErrorSelector);
  const schemaHelper = useContext(SchemaHelperContext);
  const bridge = useSchema(schemaHelper.vesselSchema, vesselVoyageInfo);
  const subFields = useMemo(() => bridge.getSubfields(), [bridge]);

  const isFormLoading = useIsShowLoadingFormStatus(subFields);

  //* AC2: In case no Vessel information fields are enabled on the template, do not display the Vessel schedules section in its entirety.
  if (subFields.length <= 0) return null;

  //* AC1: In case any Vessel schedules fields are enabled on the template, display the Vessel information section on the order page, below the task details.
  return (
    <Section
      loadingRows={subFields.length}
      header={<Header />}
      isLoading={isFormLoading}
      dataCy="order-form-vessel-section"
      hasError={vesselHasError}
    >
      <AutoForm
        autoField={CustomAutoField}
        ref={setFormRef}
        schema={bridge}
        model={vesselVoyageInfo}
        showInlineError={true}
      >
        <Grid container className={classes.vesselContainer}>
          {subFields.map((fieldName) => (
            <Grid key={fieldName} item xs={12} sm={6} md={3} className={classes.vesselItem}>
              <CustomAutoField key={fieldName} name={fieldName} />
            </Grid>
          ))}
        </Grid>
        <HandleSyncFormStatusForAutoForm formKeyPath={formKeyPath} />
      </AutoForm>
    </Section>
  );
};

export default VesselSection;
