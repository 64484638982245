import './style.scss';

import React from 'react';

import { WEIGHT_UNITS } from '@yojee/helpers/constants';

import WithUnitField from '../WithUnitField';

function WeightField(props) {
  const { min, max } = props.field || {};

  const onChange = React.useCallback(
    (value) => {
      if (value?.toString()?.length > 6) {
        return;
      }
      props.onChange(value);
    },
    [props.onChange]
  );

  return <WithUnitField {...props} onChange={onChange} min={min} max={max} units={WEIGHT_UNITS} />;
}

export default WeightField;
