import React, { useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SelectField } from 'uniforms-material';

import { getHistoryData } from '@yojee/helpers/windowHelpers';
import { getServiceTypesSelector, isServiceTypeEditableSelector } from '@yojee/ui/new-order-booking/selectors';

import { selectServiceType } from '../../../../saga/actions';
import { useAddValueAsOptionIfValueMissingInOptionsForSelect } from '../../../helpers/formHelpers';
import { customFilterDOMProps } from '../../../helpers/SchemaHelpers';

function ServiceTypeSelectField({ InputLabelProps, ...props }) {
  const dispatch = useDispatch();
  const [manualAddServiceTypes, setManualAddServiceTypes] = useState([]);

  const serviceTypeData = useSelector(getServiceTypesSelector);
  const isServiceTypeEditable = useSelector(isServiceTypeEditableSelector);

  const allowedValues = useMemo(
    () => (serviceTypeData?.data?.service_types ?? []).map((v) => v.name).concat(manualAddServiceTypes),
    [serviceTypeData, manualAddServiceTypes]
  );

  const serviceTypes = serviceTypeData?.data?.service_types;
  const selectedServiceType = useMemo(() => {
    return serviceTypes?.find((serviceType) => serviceType?.name === props.value);
  }, [props.value, serviceTypes]);

  useEffect(() => {
    dispatch(selectServiceType(selectedServiceType));
  }, [selectedServiceType, dispatch]);

  // In some old orders, the service type can have value is `multi`
  useEffect(() => {
    const value = props.value;
    if (value === 'multi' && !allowedValues.includes('multi')) {
      setManualAddServiceTypes((prev) => prev.concat(['multi']));
    }
  }, [props, allowedValues]);

  const historyData = getHistoryData();
  useEffect(() => {
    if (historyData) {
      if (props.value === null) {
        props.onChange(historyData['service_type_name'], 'service_type_name');
      }
    }
  }, [props, historyData]);

  const { newAllowValues } = useAddValueAsOptionIfValueMissingInOptionsForSelect({
    value: props.value,
    label: props.value,
    allowedValues,
  });

  return (
    <SelectField
      {...customFilterDOMProps(props)}
      value={props.value || ''}
      placeholder={
        <Trans>
          <em>Please choose</em>
        </Trans>
      }
      allowedValues={newAllowValues}
      disabled={!isServiceTypeEditable}
      name=""
      data-cy="order-service-type-select"
      MenuProps={{
        'data-cy': 'order-service-type-select-menu',
      }}
    />
  );
}

export default ServiceTypeSelectField;
