import './style.scss';

import { Collapse } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import { getOriginDestinationSelector } from '@yojee/ui/new-order-booking/selectors';

import { getFormKeyPath } from '../../../../../helpers/formHelpers';
import { BookingInfoSectionContext } from '../../../Contexts';
import { getStepProperty } from '../../utils';
import LegHeader from './LegHeader';
import StepSection from './StepSection';

const LegSection = ({ legIndex, totalLeg, steps, removeLeg, addNewLeg }) => {
  const [formOpened, setFormOpened] = useState(true);
  const { bookingInfoSectionIndex } = useContext(BookingInfoSectionContext);
  const formKeyPath = getFormKeyPath({ bookingInfoSectionIndex, legIndex });
  const { origin, destination } = useSelector((state) => getOriginDestinationSelector(state, bookingInfoSectionIndex));

  return (
    <section className={`form-body-section new-booking-leg-section ${formOpened ? '' : 'close'}`} data-cy="leg-section">
      <LegHeader
        legIndex={legIndex}
        totalLeg={totalLeg}
        formOpened={formOpened}
        addNewLeg={addNewLeg}
        removeLeg={removeLeg}
        setFormOpened={setFormOpened}
        formKeyPath={formKeyPath}
      />

      <Collapse in={formOpened} timeout="auto" className="steps-collapse-wrapper">
        <div className={`steps ${steps?.length !== 2 ? 'step-vertical' : ''}`}>
          {steps?.map((step, index) => {
            return (
              <StepSection
                key={step.uniqueID}
                legIndex={legIndex}
                stepIndex={index}
                step={step}
                totalLeg={totalLeg}
                stepProperty={getStepProperty(step, origin, destination)}
              />
            );
          })}
        </div>
      </Collapse>
    </section>
  );
};

export default LegSection;
