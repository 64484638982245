export const getAddresses = (params) => {
  return { type: 'REQUEST_GET_ADDRESSES', params };
};
export const createAddress = ({ address, isLoadAddresses }) => {
  return { type: 'REQUEST_CREATE_ADDRESS', address, isLoadAddresses };
};
export const updateAddress = (payload) => {
  return { type: 'REQUEST_UPDATE_ADDRESS', payload };
};
export const deleteAddress = (address) => {
  return { type: 'REQUEST_DELETE_ADDRESS', address };
};
export const uploadAddresses = (payload) => {
  return { type: 'REQUEST_UPLOAD_ADDRESSES', payload };
};
export const clearNewAddressID = () => {
  return { type: 'CLEAR_NEW_ADDRESS_ID' };
};
export const downloadAddressSample = (format) => {
  return { type: 'DOWNLOAD_ADDRESS_TEMPLATE_SAMPLE', format };
};
