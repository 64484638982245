import { TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Autocomplete } from '@material-ui/lab';
import momentTZ from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { customFilterDOMProps } from '@yojee/ui/new-order-booking/components/helpers/SchemaHelpers';

import { getCompanyTimezoneSelector } from '../../../../selectors';
import { useIsCreateOrder } from '../../../helpers/hooks';
import ListBoxComponent from '../helpers/ListBoxComponent';

const allowedValues = momentTZ.tz.names();
const currentTimeZone = momentTZ.tz.guess();

function TimeZoneSelectField(props) {
  const companyTimezone = useSelector(getCompanyTimezoneSelector);
  const [isSetInitialValue, setIsSetInitialValue] = useState(false);
  const isCreateOrder = useIsCreateOrder();

  useEffect(() => {
    // Initial value of timezone in create order page
    if (isCreateOrder && !props.value && companyTimezone && !isSetInitialValue) {
      const timezone = companyTimezone || currentTimeZone;
      props.onChange(timezone);
      setIsSetInitialValue(true);
    }
  }, [companyTimezone, setIsSetInitialValue, props, isSetInitialValue, isCreateOrder]);

  return (
    <Autocomplete
      autoComplete
      disabled={props.disabled}
      getOptionSelected={(option, value) => option === value}
      value={props.value || null}
      disableListWrap
      onChange={(_, newValue) => props.onChange(newValue)}
      ListboxComponent={ListBoxComponent}
      options={allowedValues}
      renderInput={(params) => (
        <TextField
          {...params}
          {...customFilterDOMProps(props)}
          error={!!props.error}
          helperText={props.error}
          margin="dense"
          label={props.label}
          InputLabelProps={{ shrink: true }}
        />
      )}
      renderOption={(option) => <Typography noWrap>{option}</Typography>}
    />
  );
}

export default TimeZoneSelectField;
