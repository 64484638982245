import AddressIDField from './AddressIDField';
import AddressSelectField from './AddressSelectField';
import CODPriceField from './CODPriceField';
import CorporateSelectField from './CorporateSelectField';
import CorporateUserSelectField from './CorporateUserSelectField';
import DateTimeField from './DatetimeField';
import DateTimeRangeField from './DateTimeRangeField/index';
import DimensionField from './DimensionField';
import EmptyLegHiddenField from './EmptyLegHiddenField';
import FromDateField from './FromDateField';
import FromTimeField from './FromTimeField';
import GoogleAddressSelectField from './GoogleAddressSelectField';
import ISOTypeSelectField from './ISOTypeSelectField';
import ItemTypeSelectField from './ItemTypeSelectField';
import PackingModeSelectField from './PackingModeSelectField';
import PeriodOfTimeField from './PeriodOfTimeField';
import PhoneField from './PhoneField';
import ServiceTypeSelectField from './ServiceTypeSelectField';
import TaskTypeSelectField from './TaskTypeSelectField';
import TextareaField from './TextareaField';
import TextField from './TextField';
import TimeConflictWithPrevField from './TimeConflictWithPrevField';
import TimeZoneSelectField from './TimeZoneSelectField';
import VesselToDateField from './VesselToDateField';
import VesselToTimeField from './VesselToTimeField';
import VolumeField from './VolumeField';
import WeightField from './WeightField';

export default function getCustomField(props = {}) {
  const {
    name,
    field: { category, type },
  } = props;

  switch (category) {
    case 'sender':
      switch (name) {
        case 'corporate':
          return CorporateSelectField;
        case 'user_profile_id':
          return CorporateUserSelectField;
      }
      break;
    case 'order':
      switch (name) {
        case 'service_type_name':
          return ServiceTypeSelectField;
        case 'packing_mode':
          return PackingModeSelectField;
        case 'external_id':
          return TextField;
      }
      break;
    case 'task':
      switch (name) {
        case 'type':
          return TaskTypeSelectField;
        case 'address':
          if (window.IS_BOOKING_APP) {
            return GoogleAddressSelectField;
          }
          return AddressSelectField;
        case 'address2':
        case 'country':
        case 'postal_code':
        case 'state':
        case 'city':
        case 'contact_name':
        case 'contact_email':
        case 'contact_company':
          return TextField;
        case 'timezone':
          return TimeZoneSelectField;
        case 'from_time':
          if (window.IS_BOOKING_APP) {
            return FromDateField;
          }
          return DateTimeRangeField;
        case 'period_of_time':
          return PeriodOfTimeField;
        case 'address_id':
          return AddressIDField;
        case 'is_empty':
          return EmptyLegHiddenField;
        case 'display_time_conflict_warning':
          return TimeConflictWithPrevField;
      }
      break;
    case 'item':
      switch (name) {
        case 'payload_type':
          return ItemTypeSelectField;
        case 'weight':
        case 'volumetric_weight':
          return WeightField;
        case 'volume':
          return VolumeField;
        case 'length':
          return DimensionField;
        case 'width':
          return DimensionField;
        case 'height':
          return DimensionField;
        case 'description':
          return TextareaField;
        case 'info':
          return TextareaField;
        case 'cod_price':
          return CODPriceField;
        case 'external_customer_id':
        case 'external_customer_id2':
        case 'external_customer_id3':
          return TextField;
      }
      break;
    case 'container':
      switch (name) {
        case 'container_no':
        case 'seal_no':
        case 'slot_reference':
        case 'tare_weight':
          return TextField;
        case 'description':
          return TextareaField;
        case 'iso_type':
          return ISOTypeSelectField;
      }
      break;
    case 'vessel':
      switch (name) {
        case 'vessel_name':
        case 'vessel_imo':
        case 'voyage_number':
        case 'vessel_carrier':
        case 'departure_port_code':
        case 'departure_port_name':
        case 'arrival_port_code':
        case 'arrival_port_name':
          return TextField;
        // From Date
        case 'receiving_start_datetime':
        case 'departure_datetime':
        case 'fcl_available_datetime':
          return FromDateField;
        // From Time
        case 'receiving_start_time':
        case 'departure_time':
        case 'fcl_available_time':
          return FromTimeField;
        // To Date
        case 'receiving_end_datetime':
        case 'arrival_datetime':
        case 'fcl_storage_datetime':
          return VesselToDateField;
        // To Time
        case 'receiving_end_time':
        case 'arrival_time':
        case 'fcl_storage_time':
          return VesselToTimeField;
      }
      break;
  }

  switch (type) {
    case 'phone':
      return PhoneField;
    case 'datetime':
      return DateTimeField;

    default:
      return undefined;
  }
}
