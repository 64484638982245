import React, { useContext } from 'react';
import { Trans } from 'react-i18next';

import { ReadOnlyContext } from '@yojee/ui/ReadOnlyHelper';

import ActionsPopover from '../../../common/ActionsPopover';
import ClearAll from '../Actions/ClearAll';
import useStyles from './styles';

const Header: React.FC = () => {
  const classes = useStyles();
  const readOnly = useContext(ReadOnlyContext);

  return (
    <div className={classes.header}>
      <label className="section-label font-semibold">
        <Trans>Vessel Information</Trans>
      </label>

      <ActionsPopover readOnly={readOnly} dataCy="vessel-actions-popover">
        <ClearAll />
      </ActionsPopover>
    </div>
  );
};

export default Header;
