import './style.scss';

import { Tooltip } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { isFormModelHaveErrorSelector } from '@yojee/ui/new-order-booking/selectors';

import { useIsEditOrder } from '../../../../../../../helpers/hooks';
import CustomAutoField from '../../../../../../CustomFields/CustomAutoField';
import { useIsShowLoadingFormStatus } from '../../../../../../helper';
import StepActions from './StepActions';

const StepHeader = ({ bridge, formOpened, formKeyPath, setFormOpened, taskStatisticsOfStep }) => {
  const isEditOrder = useIsEditOrder();
  const isHaveError = useSelector((state) => isFormModelHaveErrorSelector(state, formKeyPath));
  const subFields = useMemo(() => bridge?.getSubfields(), [bridge]);
  const isShowFormLoadingStatus = useIsShowLoadingFormStatus(subFields);

  const { numberCompletedTasks, totalTasks, numberCancelledTasks } = taskStatisticsOfStep;
  const completedStatistics =
    numberCompletedTasks === totalTasks ? totalTasks.toString() : `${numberCompletedTasks}/${totalTasks}`;

  return (
    <div className={`new-booking-step-form-header ${formOpened ? '' : 'closed'} ${isHaveError ? 'error' : ''}`}>
      <div className="flex align-center">
        <IconButton onClick={() => setFormOpened((v) => !v)} aria-label="settings">
          {formOpened ? <ExpandLess /> : <ExpandMore />}
        </IconButton>

        {isShowFormLoadingStatus ? (
          <Skeleton height={40} width={90} />
        ) : (
          <CustomAutoField name="type" readOnly={!formOpened} disabled={isEditOrder} />
        )}
        {isEditOrder && totalTasks > 0 && (
          <Tooltip title={`${completedStatistics} item${totalTasks > 1 ? 's' : ''} completed`}>
            <Chip
              className={`new-booking-item-status-of-step-chip ${
                numberCompletedTasks === totalTasks ? 'new-booking-step-success-completed' : ''
              }`}
              label={completedStatistics}
            />
          </Tooltip>
        )}
        {isEditOrder && numberCancelledTasks > 0 && (
          <Tooltip title={`${numberCancelledTasks} item${numberCancelledTasks > 1 ? 's' : ''} cancelled`}>
            <Chip
              className="new-booking-item-status-of-step-chip new-booking-step-cancelled"
              label={numberCancelledTasks}
            />
          </Tooltip>
        )}
      </div>
      <div>
        <StepActions formKeyPath={formKeyPath} />
      </div>
    </div>
  );
};

export default StepHeader;
