import { MenuItem, MenuItemProps } from '@material-ui/core';
import { styled } from '@mui/material';

export const StyledInputsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

export const OPTION_HEIGHT = 32;
export const StyledMenuItem = styled(MenuItem)<MenuItemProps>(({ selected }) => ({
  fontSize: 13,
  height: OPTION_HEIGHT,
  '&:hover': {
    backgroundColor: '#1bace0 !important',
    color: 'white',
    fontWeight: 'bold',
  },
  ...(selected && {
    backgroundColor: '#e8f7fc !important',
    color: '#1bace0',
    fontWeight: 'bold',
  }),
}));
