import { useSelector } from 'react-redux';

import { getFormRefsFromPathSelector } from '@yojee/ui/new-order-booking/selectors';
import { Leg } from '@yojee/ui/new-order-booking/types';

export const isEmptyLeg = (leg: Leg) => leg.every((step) => step?.is_empty === true);

export const useIsEmptyLeg = (legFormKeyPath: string) => {
  return useSelector((state) => {
    const [pickUpRef, dropOffRef] = getFormRefsFromPathSelector(state, legFormKeyPath, []);

    if (pickUpRef && dropOffRef) {
      const leg = [pickUpRef.getModel(), dropOffRef.getModel()];
      return isEmptyLeg(leg);
    }

    return false;
  });
};
