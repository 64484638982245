import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DEBOUNCE_TIME_GOOGLE_API } from '@yojee/helpers/constants';
import useDebounce from '@yojee/helpers/hooks/useDebounce';

import { searchAddress } from '../../../../saga/actions';
import BaseAddressSelectField from '../BaseAddressSelectField';

function GoogleAddressSelectField(props) {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const debouncedInputValue = useDebounce(inputValue, DEBOUNCE_TIME_GOOGLE_API);
  const addressBooksItems = useSelector((state) => state.orderBooking.addressSearch.addressBookItems);
  const googleAddressItems = useSelector((state) => state.orderBooking.addressSearch.googleAddressItems);
  const operationCountry = useSelector((state) => state.orderBooking.companyInfo?.data?.country);
  const auth = useSelector((state) => state.login);

  useEffect(() => {
    if (debouncedInputValue) {
      dispatch(
        searchAddress({
          searchKey: debouncedInputValue,
          includeAddressBook: !!auth.accountInfo.accessToken,
          operationCountry,
          allowToUsePlaceSearchApi: false,
        })
      );
    }
  }, [debouncedInputValue, operationCountry, dispatch, auth.accountInfo.accessToken]);

  return (
    <BaseAddressSelectField
      {...props}
      options={[...addressBooksItems, ...googleAddressItems]}
      setInputValue={setInputValue}
    />
  );
}

export default GoogleAddressSelectField;
