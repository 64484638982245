import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { connectField } from 'uniforms';

import { getFormKeysMetaDataSelector } from '@yojee/ui/new-order-booking/selectors';

const HandleAutoFormInVirtualizationList = ({ formRef, formKeyPath }) => {
  const formKeysMetaData = useSelector(getFormKeysMetaDataSelector);

  // This useEffect support case after a form submit, user scroll down or up to view another form
  // and submitted form is deleted by virtual list.
  // So we need to auto re-submit form when it re-render again
  useEffect(() => {
    if (formKeysMetaData[formKeyPath]?.onceSubmitted && formRef && !formRef.state?.submitted) {
      formRef.submit();
    }
  }, [formKeyPath, formKeysMetaData, formRef]);

  return null;
};

export default connectField(HandleAutoFormInVirtualizationList);
