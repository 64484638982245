import { Option } from '@yojee/types';
import { ReactSelect } from '@yojee/ui/base';

import { FormFieldComponent } from '../types';

export type SingleSelectProps = React.ComponentProps<typeof ReactSelect> & {
  getOptions: () => Option[];
};

export const SingleSelect: FormFieldComponent<'singleSelect'> = ({
  fieldName,
  formField: {
    label,
    disabled,
    fieldProps: { options, getOptions, ...restProps },
  },
  required,
  value,
  setValue,
  errorMessage,
}) => {
  return (
    <ReactSelect
      {...restProps}
      labelHeight="auto"
      isRawValue
      dataCy={fieldName}
      label={label}
      required={required}
      disabled={disabled}
      value={value}
      isMulti={false}
      error={!!errorMessage}
      helperText={errorMessage}
      options={getOptions ? getOptions() : options}
      onChange={({ value }: { value: unknown }) => setValue(value)}
    />
  );
};
