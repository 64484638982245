import { useMemo } from 'react';
import { useField } from 'uniforms';

import { toCentimeterUnit } from '@yojee/helpers/unitConverter/dimensionConverter';
import { fromCubicCentimeterTo } from '@yojee/helpers/unitConverter/volumeConverter';

export const calculateVolumeValue = ({ length, lengthUnit, height, heightUnit, width, widthUnit, volumeUnit }) => {
  if (length && lengthUnit && height && heightUnit && width && widthUnit && volumeUnit) {
    const volumeValueInCubicCm =
      toCentimeterUnit(length, lengthUnit) * toCentimeterUnit(height, heightUnit) * toCentimeterUnit(width, widthUnit);
    return fromCubicCentimeterTo(volumeValueInCubicCm, volumeUnit)?.toFixed(2);
  }

  return 0;
};

export const useVolumeValueFromSystemCalculated = () => {
  const [{ value: length }] = useField('length', {}, { absoluteName: true });
  const [{ value: lengthUnit }] = useField('length_unit', {}, { absoluteName: true });
  const [{ value: height }] = useField('height', {}, { absoluteName: true });
  const [{ value: heightUnit }] = useField('height_unit', {}, { absoluteName: true });
  const [{ value: width }] = useField('width', {}, { absoluteName: true });
  const [{ value: widthUnit }] = useField('width_unit', {}, { absoluteName: true });
  const [{ value: volumeUnit }] = useField('volume_unit', {}, { absoluteName: true });

  return useMemo(
    () =>
      calculateVolumeValue({
        length,
        lengthUnit,
        height,
        heightUnit,
        width,
        widthUnit,
        volumeUnit,
      }),
    [height, heightUnit, length, lengthUnit, volumeUnit, width, widthUnit]
  );
};
