/**
 * This is for the new UI components (Poey) 2021
 *
 * When updating logics in old component e.g: BatchUploadDialog.js
 * You should include those logics in this component also
 *
 */
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import BatchUpload from '@yojee/ui/base/rooms/BatchUpload';
import { BATCH_UPLOAD_ITEM_TYPES } from '@yojee/ui/onboarding/helpers/batchUploadHelper';
import { UIUserManagementActions } from '@yojee/ui/onboarding/sagas/userManagement/actions';

import { downloadAddressSample, uploadAddresses } from '../../sagas/addresses/addressActions';
import {
  downloadChargeCodeSample,
  requestChargeCodeBatchUpload,
} from '../../sagas/rate_charge_types/rateChargeTypresAction.js';
import { downloadReasonCodeSample, requestBatchUploadReasonCodes } from '../../sagas/reason_codes/reasonCodesActions';
import { downloadScheduleSample, requestScheduleBatchUpload } from '../../sagas/schedule/scheduleActions';
import {
  downloadSenderOrganisationSample,
  requestSenderOrganisationBatchUpload,
} from '../../sagas/sender_organisations/senderOrganisationActions.js';
import { downloadSenderSample, requestSenderBatchUpload } from '../../sagas/senders/senderActions';
import { downloadTagSample, requestTagBatchUpload } from '../../sagas/tags/tagActions';
import {
  downloadTaskExceptionSample,
  requestTaskExceptionBatchUpload,
} from '../../sagas/taskExceptions/taskExceptionActions';
import { downloadUserSample, requestBatchUploadUser } from '../../sagas/userManagement/userAction';
import { downloadVehicleSample, requestVehicleBatchUpload } from '../../sagas/vehicles/vehicleActions';
import { downloadWorkerSample, refreshBatchUpload, requestWorkerBatchUpload } from '../../sagas/worker/workerActions';
import BatchUploadErrors from './BatchUploadErrorsNew';

const getSelectLabelMap = (uploadItem) => {
  const labelMap = {
    [BATCH_UPLOAD_ITEM_TYPES.VEHICLE]: {
      value: BATCH_UPLOAD_ITEM_TYPES.VEHICLE,
      text: <Trans>Fleet</Trans>,
      label: <Trans>Fleet</Trans>,
    },
    [BATCH_UPLOAD_ITEM_TYPES.VEHICLE_SCHEDULE]: {
      value: BATCH_UPLOAD_ITEM_TYPES.VEHICLE_SCHEDULE,
      text: <Trans>Fleet Schedule</Trans>,
      label: <Trans>Fleet Schedule</Trans>,
    },
    [BATCH_UPLOAD_ITEM_TYPES.WORKER]: {
      value: BATCH_UPLOAD_ITEM_TYPES.WORKER,
      text: <Trans>Driver</Trans>,
      label: <Trans>Driver</Trans>,
    },
    [BATCH_UPLOAD_ITEM_TYPES.WORKER_SCHEDULE]: {
      value: BATCH_UPLOAD_ITEM_TYPES.WORKER_SCHEDULE,
      text: <Trans>Driver Schedule</Trans>,
      label: <Trans>Driver Schedule</Trans>,
    },
    [BATCH_UPLOAD_ITEM_TYPES.DISPATCHER]: {
      value: BATCH_UPLOAD_ITEM_TYPES.DISPATCHER,
      text: <Trans>Users</Trans>,
      label: <Trans>Users</Trans>,
    },
    [BATCH_UPLOAD_ITEM_TYPES.ADDRESS]: {
      value: BATCH_UPLOAD_ITEM_TYPES.ADDRESS,
      text: <Trans>Address</Trans>,
      label: <Trans>Address</Trans>,
    },
    [BATCH_UPLOAD_ITEM_TYPES.TAGS]: {
      value: BATCH_UPLOAD_ITEM_TYPES.TAGS,
      text: <Trans>Tag</Trans>,
      label: <Trans>Tag</Trans>,
    },
    [BATCH_UPLOAD_ITEM_TYPES.CHARGE_CODE]: {
      value: BATCH_UPLOAD_ITEM_TYPES.CHARGE_CODE,
      text: <Trans>Charge Code</Trans>,
      label: <Trans>Charge Code</Trans>,
    },
    [BATCH_UPLOAD_ITEM_TYPES.EXCEPTIONS]: {
      value: BATCH_UPLOAD_ITEM_TYPES.EXCEPTIONS,
      text: <Trans>Report Reasons</Trans>,
      label: <Trans>Report Reasons</Trans>,
    },
    [BATCH_UPLOAD_ITEM_TYPES.SENDER_ORGANISATION]: {
      value: BATCH_UPLOAD_ITEM_TYPES.SENDER_ORGANISATION,
      text: <Trans>Corporate</Trans>,
      label: <Trans>Corporate</Trans>,
    },
    [BATCH_UPLOAD_ITEM_TYPES.SENDER_ORGANISATION_USER]: {
      value: BATCH_UPLOAD_ITEM_TYPES.SENDER_ORGANISATION_USER,
      text: <Trans>Corporate User</Trans>,
      label: <Trans>Corporate User</Trans>,
    },
    [BATCH_UPLOAD_ITEM_TYPES.SENDER]: {
      value: BATCH_UPLOAD_ITEM_TYPES.SENDER,
      text: <Trans>Sender</Trans>,
      label: <Trans>Sender</Trans>,
    },
    [BATCH_UPLOAD_ITEM_TYPES.ENTERPRISE]: {
      value: BATCH_UPLOAD_ITEM_TYPES.SENDER,
      text: <Trans>Enterprise</Trans>,
      label: <Trans>Enterprise</Trans>,
    },
    [BATCH_UPLOAD_ITEM_TYPES.REASON_CODE]: {
      value: BATCH_UPLOAD_ITEM_TYPES.REASON_CODE,
      text: <Trans>Reason Code</Trans>,
      label: <Trans>Reason Code</Trans>,
    },
    // schedules
    default: { value: '', text: <Trans>Schedules</Trans>, label: <Trans>Schedules</Trans> },
  };

  return labelMap[uploadItem] || labelMap.default;
};
const ENUM = {
  BATCH_UPLOAD: 'BATCH_UPLOAD',
};
const BatchUploadContent = ({ uploadItemType, senderId, onSuccess, dataCy }, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [uploadedFile, setUploadedFile] = useState(undefined);
  const [uploadItem, setUploadItem] = useState(uploadItemType);
  const [percentLoading, setPercentLoading] = useState(0);
  const [selectOption, setSelectOption] = useState({
    value: '',
    text: '',
    label: '',
  });
  const [error, setError] = useState({
    [ENUM.BATCH_UPLOAD]: '',
  });
  const uploadProgressTimer = useRef(null);

  const setErrorHelper = (key, error) => {
    setError((prev) => ({
      ...prev,
      [key]: error,
    }));
  };

  const resetProgressBar = () => {
    clearInterval(uploadProgressTimer.current);
    setPercentLoading(0);
  };

  const isValid = () => {
    // case is uploading
    if (percentLoading !== 0) return false;
    if (!uploadedFile) {
      setErrorHelper(ENUM.BATCH_UPLOAD, t('Please upload 1 file'));
      return false;
    }
    if (batchUploadState === 'failed' || batchUploadState === 'completed') {
      setErrorHelper(ENUM.BATCH_UPLOAD, t('Please upload new file'));
      return false;
    }
    return true;
  };

  const getReducerName = (uploadItem) => {
    switch (uploadItem) {
      case BATCH_UPLOAD_ITEM_TYPES.VEHICLE:
        return 'vehicles';
      case BATCH_UPLOAD_ITEM_TYPES.WORKER:
        return 'worker';
      case BATCH_UPLOAD_ITEM_TYPES.DISPATCHER:
        return 'users';
      case BATCH_UPLOAD_ITEM_TYPES.ADDRESS:
        return 'address';
      case BATCH_UPLOAD_ITEM_TYPES.TAGS:
        return 'tag';
      case BATCH_UPLOAD_ITEM_TYPES.EXCEPTIONS:
        return 'exceptions';
      case BATCH_UPLOAD_ITEM_TYPES.SENDER:
        return 'sender';
      case BATCH_UPLOAD_ITEM_TYPES.SENDER_ORGANISATION:
      case BATCH_UPLOAD_ITEM_TYPES.SENDER_ORGANISATION_USER:
        return 'senderOrganisation';
      case BATCH_UPLOAD_ITEM_TYPES.CHARGE_CODE:
        return 'chargeCode';
      case BATCH_UPLOAD_ITEM_TYPES.ENTERPRISE:
        return 'enterprise';
      case BATCH_UPLOAD_ITEM_TYPES.REASON_CODE:
        return 'reasonCode';
      default:
        return 'schedules';
    }
  };

  const { batchUploadState, errors, total } = useSelector((state) => {
    const reducerName = getReducerName(uploadItem);
    const batchUpload = (state[reducerName] && state[reducerName].batchUpload) || {};
    return {
      batchUploadState: batchUpload.state || null,
      errors: batchUpload.errors || {},
      total: batchUpload.total || 0,
    };
  });

  useEffect(() => {
    return () => {
      setUploadedFile(undefined);
      dispatch(refreshBatchUpload());
    };
  }, []);

  useEffect(() => {
    if (batchUploadState === 'failed' || batchUploadState === 'completed') {
      clearInterval(uploadProgressTimer.current);
      setPercentLoading(100);
      setTimeout(() => {
        resetProgressBar();
      }, 200);
    }

    if (batchUploadState === 'completed') {
      onSuccess?.();
    }
  }, [batchUploadState]);

  useImperativeHandle(
    ref,
    () => ({
      saveRef() {
        if (!isValid()) return false;
        if (uploadedFile && Object.keys(errors).length === 0) {
          onUpload();
          startUploadProgress();
        }
      },
    }),
    [uploadedFile, errors, isValid]
  );

  useEffect(() => {
    setSelectOption(getSelectLabelMap(uploadItemType));
  }, [uploadItemType]);

  const resetDialog = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setErrorHelper(ENUM.BATCH_UPLOAD);
    setUploadedFile(undefined);
    dispatch(refreshBatchUpload());
  };

  // using sine graph to smoother loading progress
  const getCircleY = (angle, radius) => Math.sin(angle * (Math.PI / 180)) * radius;

  const startUploadProgress = () => {
    resetProgressBar();

    const CIRCLE_RADIUS = 100;
    const START_DEGREE = 0;
    const END_DEGREES = 75;
    const STEP_DEGREE = 0.1;
    const SLOWER_STEP_DEGREE = 0.05;
    const BREAK_POINT = 40;
    const TIME_INTERVAL = 10; // 10ms
    let degree = START_DEGREE;

    uploadProgressTimer.current = setInterval(() => {
      if (degree >= START_DEGREE && degree <= END_DEGREES) {
        if (degree < BREAK_POINT) degree = degree + STEP_DEGREE;
        else degree = degree + SLOWER_STEP_DEGREE;
        setPercentLoading(getCircleY(degree, CIRCLE_RADIUS));
      }
    }, TIME_INTERVAL);
  };

  const onDrop = (acceptedFiles) => {
    setErrorHelper(ENUM.BATCH_UPLOAD);
    setUploadedFile(acceptedFiles[0]);
    resetProgressBar();
  };

  const uploadItems = [];
  if ([BATCH_UPLOAD_ITEM_TYPES.WORKER, BATCH_UPLOAD_ITEM_TYPES.WORKER_SCHEDULE].includes(uploadItemType)) {
    uploadItems.push({ value: BATCH_UPLOAD_ITEM_TYPES.WORKER, text: t('Driver') });
    uploadItems.push({ value: BATCH_UPLOAD_ITEM_TYPES.WORKER_SCHEDULE, text: t('Driver Schedule') });
  } else if ([BATCH_UPLOAD_ITEM_TYPES.DISPATCHER].includes(uploadItemType)) {
    uploadItems.push({ value: BATCH_UPLOAD_ITEM_TYPES.DISPATCHER, text: t('Dispatcher') });
  } else if ([BATCH_UPLOAD_ITEM_TYPES.ADDRESS].includes(uploadItemType)) {
    uploadItems.push({ value: BATCH_UPLOAD_ITEM_TYPES.ADDRESS, text: t('Address') });
  } else if ([BATCH_UPLOAD_ITEM_TYPES.EXCEPTIONS].includes(uploadItemType)) {
    uploadItems.push({ value: BATCH_UPLOAD_ITEM_TYPES.EXCEPTIONS, text: t('Exceptions') });
  } else if ([BATCH_UPLOAD_ITEM_TYPES.TAGS].includes(uploadItemType)) {
    uploadItems.push({ value: BATCH_UPLOAD_ITEM_TYPES.TAGS, text: t('Tags') });
  } else if ([BATCH_UPLOAD_ITEM_TYPES.SENDER].includes(uploadItemType)) {
    uploadItems.push({ value: BATCH_UPLOAD_ITEM_TYPES.SENDER, text: t('Individual sender') });
  } else if ([BATCH_UPLOAD_ITEM_TYPES.SENDER_ORGANISATION].includes(uploadItemType)) {
    uploadItems.push({ value: BATCH_UPLOAD_ITEM_TYPES.SENDER_ORGANISATION, text: t('Corporate') });
  } else if ([BATCH_UPLOAD_ITEM_TYPES.SENDER_ORGANISATION_USER].includes(uploadItemType)) {
    uploadItems.push({ value: BATCH_UPLOAD_ITEM_TYPES.SENDER_ORGANISATION_USER, text: t('Corporate User') });
  } else if ([BATCH_UPLOAD_ITEM_TYPES.CHARGE_CODE].includes(uploadItemType)) {
    uploadItems.push({ value: BATCH_UPLOAD_ITEM_TYPES.CHARGE_CODE, text: t('Charge Code') });
  } else if ([BATCH_UPLOAD_ITEM_TYPES.ENTERPRISE].includes(uploadItemType)) {
    uploadItems.push({ value: BATCH_UPLOAD_ITEM_TYPES.ENTERPRISE, text: t('Enterprise') });
  } else if ([BATCH_UPLOAD_ITEM_TYPES.REASON_CODE].includes(uploadItemType)) {
    uploadItems.push({ value: BATCH_UPLOAD_ITEM_TYPES.REASON_CODE, text: t('Reason Code') });
  } else {
    uploadItems.push({ value: BATCH_UPLOAD_ITEM_TYPES.VEHICLE, text: t('Vehicle') });
    uploadItems.push({ value: BATCH_UPLOAD_ITEM_TYPES.VEHICLE_SCHEDULE, text: t('Fleet Schedule') });
  }

  const _downloadTemplateSample = (format) => {
    if (uploadItem === BATCH_UPLOAD_ITEM_TYPES.WORKER) {
      dispatch(downloadWorkerSample(format));
    } else if (uploadItem === BATCH_UPLOAD_ITEM_TYPES.VEHICLE) {
      dispatch(downloadVehicleSample(format));
    } else if (uploadItem === BATCH_UPLOAD_ITEM_TYPES.DISPATCHER) {
      dispatch(downloadUserSample(format));
    } else if (uploadItem === BATCH_UPLOAD_ITEM_TYPES.ADDRESS) {
      dispatch(downloadAddressSample(format));
    } else if (uploadItem === BATCH_UPLOAD_ITEM_TYPES.TAGS) {
      dispatch(downloadTagSample(format));
    } else if (uploadItem === BATCH_UPLOAD_ITEM_TYPES.SENDER) {
      dispatch(downloadSenderSample({ format, uploadItemType }));
    } else if (
      uploadItem === BATCH_UPLOAD_ITEM_TYPES.SENDER_ORGANISATION ||
      uploadItem === BATCH_UPLOAD_ITEM_TYPES.SENDER_ORGANISATION_USER
    ) {
      dispatch(downloadSenderOrganisationSample({ format, type: uploadItem }));
    } else if (uploadItem === BATCH_UPLOAD_ITEM_TYPES.EXCEPTIONS) {
      dispatch(downloadTaskExceptionSample(format));
    } else if (uploadItem === BATCH_UPLOAD_ITEM_TYPES.CHARGE_CODE) {
      dispatch(downloadChargeCodeSample(format));
    } else if (uploadItem === BATCH_UPLOAD_ITEM_TYPES.ENTERPRISE) {
      dispatch(UIUserManagementActions.downloadUserSample({ format }));
    } else if (uploadItem === BATCH_UPLOAD_ITEM_TYPES.REASON_CODE) {
      dispatch(downloadReasonCodeSample({ format }));
    } else {
      dispatch(downloadScheduleSample({ format, type: uploadItem }));
    }
  };

  const createOptions = uploadItems.map((item) => ({
    value: item.value,
    label: item.text,
    text: item.text,
  }));

  const onUpload = () => {
    const params = {
      file: uploadedFile,
      format: uploadedFile.type === 'text/csv' ? 'CSV' : 'XLSX',
    };
    if (uploadItem === BATCH_UPLOAD_ITEM_TYPES.WORKER) {
      dispatch(requestWorkerBatchUpload(params));
    } else if (uploadItem === BATCH_UPLOAD_ITEM_TYPES.VEHICLE) {
      dispatch(requestVehicleBatchUpload(params));
    } else if (uploadItem === BATCH_UPLOAD_ITEM_TYPES.DISPATCHER) {
      dispatch(requestBatchUploadUser(params));
    } else if (uploadItem === BATCH_UPLOAD_ITEM_TYPES.ADDRESS) {
      params.sender_id = senderId;
      dispatch(uploadAddresses(params));
    } else if (uploadItem === BATCH_UPLOAD_ITEM_TYPES.EXCEPTIONS) {
      dispatch(requestTaskExceptionBatchUpload(params));
    } else if (uploadItem === BATCH_UPLOAD_ITEM_TYPES.SENDER) {
      dispatch(requestSenderBatchUpload(params));
    } else if (
      uploadItem === BATCH_UPLOAD_ITEM_TYPES.SENDER_ORGANISATION ||
      uploadItem === BATCH_UPLOAD_ITEM_TYPES.SENDER_ORGANISATION_USER
    ) {
      dispatch(
        requestSenderOrganisationBatchUpload({
          ...params,
          type: uploadItem,
        })
      );
    } else if (uploadItem === BATCH_UPLOAD_ITEM_TYPES.TAGS) {
      dispatch(requestTagBatchUpload(params));
    } else if (uploadItem === BATCH_UPLOAD_ITEM_TYPES.CHARGE_CODE) {
      dispatch(requestChargeCodeBatchUpload(params));
    } else if (uploadItem === BATCH_UPLOAD_ITEM_TYPES.ENTERPRISE) {
      dispatch(UIUserManagementActions.requestBatchUploadEnterpriseUser(params));
    } else if (uploadItem === BATCH_UPLOAD_ITEM_TYPES.REASON_CODE) {
      dispatch(requestBatchUploadReasonCodes(params));
    } else {
      dispatch(
        requestScheduleBatchUpload({
          ...params,
          type: uploadItem,
        })
      );
    }
  };

  return (
    <BatchUpload
      onDrop={onDrop}
      batchUploadState={batchUploadState}
      percentLoading={percentLoading}
      uploadedFile={uploadedFile}
      helperText={error[ENUM.BATCH_UPLOAD]}
      successMess={`Upload successful! ${total} record(s) processed`}
      failedMess={`Upload failed! ${errors?.errors?.length} record(s) failed`}
      errorFile={error[ENUM.BATCH_UPLOAD]?.length > 0}
      removeFile={resetDialog}
      errorZone={batchUploadState === 'failed' && errors?.errors ? <BatchUploadErrors errors={errors} /> : null}
      disabled={uploadedFile && (batchUploadState === 'failed' || batchUploadState === 'completed')}
      onClickCSV={() => _downloadTemplateSample('csv')}
      onClickXLSX={() => _downloadTemplateSample('xlsx')}
      selectOption={{
        value: selectOption,
        options: createOptions,
        onChange: (select) => {
          setUploadItem(select.value);
          setSelectOption({
            value: select.value,
            text: select.text,
            label: select.text,
          });
        },
      }}
      dataCy={dataCy}
    />
  );
};

BatchUploadContent.propTypes = {
  classes: PropTypes.object.isRequired,
  uploadItemType: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
};

export default forwardRef(BatchUploadContent);
