import { get as lodashGet } from 'lodash-es';
import { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import useInterval from '@yojee/helpers/hooks/useInterval';
import { isCancelledTask, isCompletedTask } from '@yojee/helpers/TasksHelper';
import { getItemStepUniqueKey } from '@yojee/ui/new-order-booking/components/OrderForm/OrderActions/utils';
import {
  getAllTasksOfOrder,
  getItemIdTasksMap,
  getItemStepKeyTaskMap,
  getOrderFormModelsSelector,
} from '@yojee/ui/new-order-booking/selectors';

import { getFormKeyPath, getFormKeyPathItemDetailsFromStepFormKeyPath } from '../../helpers/formHelpers';
import { BookingInfoSectionContext } from './Contexts';

export function useGetTasksOfItem(itemId) {
  const itemIdTasksMap = useSelector(getItemIdTasksMap);

  return useMemo(() => itemIdTasksMap?.[itemId] || [], [itemIdTasksMap, itemId]);
}

export function useTaskStatisticsOfItem(itemId) {
  const itemIdTasksMap = useSelector(getItemIdTasksMap);

  return useMemo(() => {
    const tasksOfItem = itemIdTasksMap?.[itemId] || [];
    const numberCompletedTasks = tasksOfItem.filter(isCompletedTask).length;
    const totalTasks = tasksOfItem.length;

    return {
      numberCompletedTasks,
      totalTasks,
      isItemCompleted: numberCompletedTasks === totalTasks && totalTasks > 0,
    };
  }, [itemId, itemIdTasksMap]);
}

function getTasksOfStep({ itemDetails, itemStepKeyTaskMap, stepFormModel }) {
  const taskOfSteps = [];

  itemDetails.forEach((itemDetail) => {
    const itemId = itemDetail?.item?.id;
    const itemStepKey = getItemStepUniqueKey({ ...stepFormModel, order_item_id: itemId });
    const task = itemStepKeyTaskMap?.[itemStepKey];

    if (task) {
      taskOfSteps.push(task);
    }
  });

  return taskOfSteps;
}

function getTaskStatisticsOfStep({ itemDetails, itemStepKeyTaskMap, stepFormModel }) {
  const taskOfSteps = getTasksOfStep({ itemDetails, itemStepKeyTaskMap, stepFormModel });

  const numberCompletedTasks = taskOfSteps.filter(isCompletedTask).length;
  const numberCancelledTasks = taskOfSteps.filter(isCancelledTask).length;
  const totalTasks = taskOfSteps.length;

  return {
    numberCompletedTasks,
    numberCancelledTasks,
    totalTasks,
  };
}

export function useTasksStatisticsOfStep(stepFormKeyPath) {
  const itemDetailsFormKeyPath = getFormKeyPathItemDetailsFromStepFormKeyPath(stepFormKeyPath);
  const orderFormModels = useSelector(getOrderFormModelsSelector);
  const stepFormModel = lodashGet(orderFormModels, stepFormKeyPath, {});
  const itemDetails = lodashGet(orderFormModels, itemDetailsFormKeyPath, []);
  const itemStepKeyTaskMap = useSelector(getItemStepKeyTaskMap);

  return useMemo(
    () =>
      getTaskStatisticsOfStep({
        itemDetails,
        itemStepKeyTaskMap,
        stepFormModel,
      }),
    [itemDetails, itemStepKeyTaskMap, stepFormModel]
  );
}

export function useIsOrderHaveSomeTasksCanceled() {
  const allTasks = useSelector(getAllTasksOfOrder);

  return !!allTasks?.some((task) => isCancelledTask(task));
}

export function useNumberItemsOfBookingInfoSection() {
  const { bookingInfoSectionIndex } = useContext(BookingInfoSectionContext);
  const orderFormModels = useSelector(getOrderFormModelsSelector);
  const formKeyPath = getFormKeyPath({ bookingInfoSectionIndex });
  const bookingInfoSection = lodashGet(orderFormModels, formKeyPath, {});
  const [totalItems, setTotalItems] = useState(0);

  useInterval(() => {
    const numberItems =
      bookingInfoSection.itemDetails?.reduce((acc, itemDetail) => {
        return acc + (itemDetail.item?.quantity || 1);
      }, 0) || 0;
    numberItems && setTotalItems(numberItems);
  }, 1000);

  return totalItems;
}

export function useNumberLinesOfBookingInfoSection() {
  const { bookingInfoSectionIndex } = useContext(BookingInfoSectionContext);
  const orderFormModels = useSelector(getOrderFormModelsSelector);
  const formKeyPath = getFormKeyPath({ bookingInfoSectionIndex });
  const bookingInfoSection = lodashGet(orderFormModels, formKeyPath, {});
  const [totalLines, setTotalLines] = useState(0);

  useInterval(() => {
    const numberLines = bookingInfoSection.itemDetails?.filter((itemDetail) => !!itemDetail.item)?.length;
    numberLines && setTotalLines(numberLines);
  }, 1000);

  return totalLines;
}
