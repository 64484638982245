import countryCodes from '@yojee/helpers/countryCodes';

import { useCompanyCountry } from './useCompanyCountry';

export const useCompanyDefaultPhoneCountry = () => {
  const country = useCompanyCountry() || '';
  const countryCode = countryCodes[country] || 'sg';

  return countryCode;
};
