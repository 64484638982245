import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import NormalStyleDialog from '../../../../../../dialog/NormalStyle';

const MismatchVolumeConfirmDialog = ({ setIsShowConfirmVolumeMismatch, onOk, onCancel, itemTrackingNumbers }) => {
  const { t } = useTranslation();

  const bodyText = (
    <p>
      <Trans
        i18nKey="volumeDontMatchDimensions"
        count={itemTrackingNumbers.length}
        values={{ itemTrackingNumbersStr: itemTrackingNumbers.join(', ') }}
        components={[<b />]}
      />
    </p>
  );

  const onClose = () => {
    setIsShowConfirmVolumeMismatch(false);
  };

  const _onCancel = () => {
    onCancel();
    onClose();
  };

  const _onOk = () => {
    onOk();
    onClose();
  };

  return (
    <NormalStyleDialog
      open={true}
      title={t('Volume does not match')}
      body={bodyText}
      onClose={onClose}
      onCancel={_onCancel}
      onOk={_onOk}
      cancelText={t('No, use system calculated volume')}
      okText={t('Yes')}
    />
  );
};

export default MismatchVolumeConfirmDialog;
