import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import copy from 'copy-to-clipboard';
import React from 'react';

const useStyles = makeStyles({
  orderIdOuter: {
    display: 'inline-block',
    margin: '0px 10px 0px 0px',
    padding: '2px',
    alignItems: 'center',
  },
  orderIdInner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  orderIdText: {
    fontWeight: 'bold',
    fontSize: '1.5em',
    padding: '10px 0px',
  },
});

const OrderID: React.FC<{ children: string }> = ({ children: id }) => {
  const classes = useStyles();
  const onCopyButtonClick = () => copy(id);
  return (
    <div className={classes.orderIdOuter}>
      <div className={classes.orderIdInner}>
        <span className={classes.orderIdText}>{id}</span>
        <IconButton onClick={onCopyButtonClick}>
          <FileCopyIcon fontSize="small" htmlColor="#1babe0" />
        </IconButton>
      </div>
    </div>
  );
};

export default React.memo(OrderID);
