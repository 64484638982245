// Tracked Events
export const TRACKED_FEATURE = {
  RATING: 'rating',
  INVOICING: 'invoicing',
  PLANNING: 'planning',
  PARTNER: 'partner',
  CHAT: 'chat',
  BOOKING: 'booking',
} as const;

// Ref: https://yojeee.atlassian.net/wiki/spaces/~812198437/pages/1796341779/Google+Analytics+Event+Names#Current-tracked-events
export const TRACKED_EVENT_NAME = {
  // Charges Page
  AUTO_RATE_CALC: 'rating__auto_rate__calc',
  RATES_UPDATE: 'rating__rates__update',
  MANUAL_RATES_INPUT: 'rating__manual_rates__input',
  RATES_DELETE: 'rating__rates__delete',
  INVOICING_CREATE: 'rating__invoicing_create',
  INVOICING_REDO: 'rating__invoicing_redo',

  // Periodic Job Page
  PERIODIC_JOB_CALC_BULK: 'rating__periodic_job__calc_bulk',
  PERIODIC_JOB_INVOICE: 'rating__periodic_job_invoice',
  PERIODIC_JOB_INVOICE_REDO: 'rating__periodic_job_invoice_redo',

  // Explore page
  PRESENTATION_MODE_OPEN: 'planning__presentation_mode_open',
  ADVANCED_FILTER_OPEN: 'planning__advanced_filter_open',
  QUICK_ASSIGN: 'planning__quick_assign',
  MANUAL_PLAN: 'planning__manual_plan',
  OPTIMISE: 'planning__optimise',
  APPLY_FILTER: 'planning__apply_filter',
  SAVE_FILTER: 'planning__save_filter',
  LOAD_FILTER: 'planning__load_filter',
  TIMEFRAME_QUICK_FILTER: 'planning__timeframe_quick_filter',
  QUICK_LOAD_FILTER: 'planning__quick_load_filter',
  SELECT_PICKUP_SINGLE_FILTER: 'planning__filter_tasktype_pickup_single',
  SELECT_PICKUP_PLUS_PAIR_FILTER: 'planning__filter_tasktype_pickup_plus_pair',
  SELECT_DROPOFF_SINGLE_FILTER: 'planning__filter_tasktype_dropoff_single',
  SELECT_DROPOFF_PLUS_PAIR_FILTER: 'planning__filter_tasktype_dropoff_plus_pair',
  REJECTED_TRANSFER: 'planning__reject_transfer',

  // Order Booking Form
  MARK_LEG_AS_EMPTY: 'planning__mark_leg_empty',
  MARK_LEG_AS_NON_EMPTY: 'planning__mark_leg_not_empty',
  SPLIT_LEG: 'planning__split_leg',
  ADD_LEG_AT_THE_END: 'planning__add_leg_at_the_end',
  ADD_LEG_IN_FRONT: 'planning__add_leg_in_front',

  // Booking
  BOOKING_CHANGE_SERVICE_TYPE: 'booking__change_service_type',
  BOOKING_DUPLICATE_ORDER: 'booking__duplicate_order',

  // Chat
  CHAT_OPEN: 'chat__open',
  CHAT_SEND_MESSAGE: 'chat__send_message',
  CHAT_GO_FULLSCREEN: 'chat__go_fullscreen',
  CHAT_LIST_ACTIVE_TASKS: 'chat__list_active_tasks',
  CHAT_LIST_INACTIVE_TASKS: 'chat__list_inactive_tasks',

  SERVICE_TYPE_SET_UP_DURING_TRANSFER: 'partner__service_type_set_up_during_transfer',
} as const;
