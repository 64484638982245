import ToolTip from '@material-ui/core/Tooltip';
import React, { useContext } from 'react';

import { TOOLTIP_DISABLED } from '@yojee/helpers/constants';
import { ReadOnlyContext } from '@yojee/ui/ReadOnlyHelper';

const TooltipReadOnly = ({ children, ...restProps }) => {
  const readOnlyInContext = useContext(ReadOnlyContext);
  const readOnly = restProps.readOnly ?? readOnlyInContext;

  if (!readOnly) return children;

  return (
    <ToolTip {...restProps} title={TOOLTIP_DISABLED}>
      <span>{React.cloneElement(children, { disabled: true })}</span>
    </ToolTip>
  );
};

TooltipReadOnly.defaultProps = {
  title: TOOLTIP_DISABLED,
};

export default TooltipReadOnly;
