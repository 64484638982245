import React, { ChangeEventHandler } from 'react';

type Props = {
  value?: boolean;
  onChange: (value: boolean) => void;
  label?: string;
};

export const EmptyLegHiddenField = ({ value, onChange }: Props) => {
  const onCheckboxChange: ChangeEventHandler<HTMLInputElement> = ({ currentTarget: { checked } }) => {
    onChange(checked);
  };

  return (
    <input
      data-testid="is-empty-step"
      type="checkbox"
      readOnly
      style={{
        display: 'none',
      }}
      checked={value || false}
      onChange={onCheckboxChange}
    />
  );
};
