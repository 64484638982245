import { CircularProgress } from '@material-ui/core';
import { useEffect, useState } from 'react';

import { TransferList as TransferListComponent } from '@yojee/ui/base';

import { FormFieldComponent } from '../types';

type Option = {
  label: string;
  value: number;
};

export type TransferListProps = React.ComponentProps<typeof TransferList> & {
  loadOptions: () => Promise<Option[]>;
};

export const TransferList: FormFieldComponent<'transferList'> = ({
  formField: {
    fieldProps: { loadOptions, ...restProps },
  },
  value,
  setValue,
  errorMessage,
}) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const selectedValues = (value as unknown as number[]) || [];

  const selectedOptions = options.filter((option) => selectedValues.includes(option.value));
  const notSelectedOptions = options.filter((option) => !selectedValues.includes(option.value));

  useEffect(() => {
    setIsLoading(true);
    loadOptions()
      .then((data: Option[]) => {
        setOptions(data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const setRightItems = (options: Option[]) => {
    const newValues = options.map((option) => option.value);
    setValue(newValues);
  };

  return isLoading ? (
    <div style={{ height: '350px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress size="24px" />
    </div>
  ) : (
    <TransferListComponent
      {...restProps}
      leftItems={notSelectedOptions}
      rightItems={selectedOptions}
      setRightItems={setRightItems}
      rightError={Boolean(errorMessage)}
      rightHelperText={errorMessage}
    />
  );
};
