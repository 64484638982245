import './addressPicker.scss';

import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import GoogleMapReact, { fitBounds } from 'google-map-react';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Trans, Translation } from 'react-i18next';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { useDispatch, useSelector } from 'react-redux';

import { saveToAddressBook as saveToAddressBookAction } from '@yojee/ui/address-picker/saga/actions';

import image from './address-locator-marker.svg';

const bootstrapURLKeys = {
  key: process.env.REACT_APP_SCOPED_GOOGLE_API_KEY,
  libraries: ['drawing', 'places'].join(','),
};

const AddressPicker = ({
  onLocationSave,
  onCancel,
  saveToAddressBook = false,
  addressFix = false,
  isShownAddToAddressBook = true,
  isShownUpdateAddressFromSender = true,
  addressItemData,
  addressData,
  operationCountry,
}) => {
  const dispatch = useDispatch();
  const [data, updateData] = useReducer((state, newState) => ({ ...state, ...newState }), {
    center: [1.352083, 103.819836],
    zoom: 12,
    lat: '',
    lng: '',
    map: null,
    address: '',
    state: null,
    country: null,
    postalCode: null,
    updateAddress: true,
    language: 'en',
    token: null,
    originAddressInfo: null,
    showInvalidLocationMessage: false,
  });

  const [map, setMap] = useState(null);
  const [saveToAddressBookOption, setSaveToAddressBookOption] = useState(false);

  const containerRef = useRef();

  const status = useSelector((state) => state.addressPicker.status);
  const savedItemData = useSelector((state) => state.addressPicker.data);

  useEffect(() => {
    setSaveToAddressBookOption(saveToAddressBook);
  }, [saveToAddressBook]);

  useEffect(() => {
    if (status === 'success') {
      postLocatorSave();
    }
  }, [status]);

  const handleGoogleMapApi = (googleObj) => {
    // eslint-disable-next-line no-undef
    google = googleObj;
    setMap(googleObj.map);
  };

  useEffect(() => {
    if (map) {
      updateData(addressData);
      if (addressData?.address) {
        updateData({ originAddressInfo: addressData });
        handleSelect(addressData.address);
      }

      const isCoordinatesSet = addressData?.lat && addressData?.lng;
      if (addressData?.address && !isCoordinatesSet) {
        updateData({ address: addressData.address });
      } else if (operationCountry && !isCoordinatesSet) {
        setCenterToCounty(operationCountry);
      } else if (isCoordinatesSet) {
        if (isLocationValid(data)) {
          geocodeLatLng(addressData);
          updateData({ center: [addressData?.lat, addressData?.lng] });
        } else {
          updateData({ showInvalidLocationMessage: true });
        }
      }
    }
  }, [map]);

  const geocodeLatLng = ({ lat, lng }) => {
    const geocoder = new window.google.maps.Geocoder();
    const OK = window.google.maps.GeocoderStatus.OK;
    geocoder.geocode({ latLng: { lat, lng } }, (results, geocodeStatus) => {
      if (geocodeStatus !== OK) {
        console.error(geocodeStatus);
      }

      const result = {
        state: '',
        country: '',
        postalCode: '',
      };

      if (results && results.length > 0) {
        if (results[0].address_components) {
          results[0].address_components.forEach((component) => {
            if (component.types.includes('administrative_area_level_1')) {
              result.state = component.long_name;
            } else if (component.types.includes('country')) {
              result.country = component.long_name;
            } else if (component.types.includes('postal_code')) {
              result.postalCode = component.long_name;
            }
            result.address = results[0].formatted_address;
          });
        }
      }

      updateData({ ...result, lat, lng });
    });
  };

  const onClickHandler = (event) => {
    updateData({ lat: event.lat, lng: event.lng });
    geocodeLatLng(event);
  };

  const handleChange = (address) => {
    updateData({ address });
  };

  const handleSelect = (address) => {
    if (address === null) {
      return;
    }

    geocodeByAddress(address)
      .then((results) => {
        if (results.length > 0) {
          return getLatLng(results[0]).then((latLng) => {
            const result = {
              lat: latLng.lat,
              lng: latLng.lng,
              center: latLng,
              address: address,
              state: '',
              country: '',
              postalCode: '',
            };
            if (results[0].address_components) {
              const componentPropertyMap = {
                administrative_area_level_1: 'state',
                country: 'country',
                postal_code: 'postalCode',
              };
              results[0].address_components.forEach((component) => {
                Object.keys(componentPropertyMap).forEach((key) => {
                  if (component.types.includes(key)) {
                    result[componentPropertyMap[key]] = component.long_name;
                  }
                });
              });
            }
            return updateData(result);
          });
        }
      })
      .catch((error) => console.error('Error', error));
  };

  const setCenterToCounty = (country) => {
    geocodeByAddress(country)
      .then((results) => {
        if (results.length > 0) {
          return getLatLng(results[0]).then((latLng) => {
            const bounds = JSON.parse(JSON.stringify(results[0].geometry.bounds));
            const { center, zoom } = fitBounds(
              {
                ne: { lat: bounds.north, lng: bounds.east },
                sw: { lat: bounds.south, lng: bounds.west },
              },
              {
                height: containerRef.clientHeight,
                width: containerRef.clientWidth,
              }
            );
            const result = {
              lat: latLng.lat,
              lng: latLng.lng,
              center: center,
              zoom: zoom,
            };
            return updateData(result);
          });
        }
      })
      .catch((error) => console.error('Error', error));
  };

  const renderPlacesAutocomplete = () => {
    return (
      <PlacesAutocomplete value={data.address} onChange={handleChange} onSelect={handleSelect}>
        {({ getInputProps, suggestions, getSuggestionItemProps }) => {
          return (
            <div className="search-bar-container">
              <div className="search-input-container form-group-picker">
                <label>
                  <Trans>Address</Trans> *
                </label>
                <div className="form-control">
                  <Translation>
                    {(t) => (
                      <input
                        {...getInputProps({
                          placeholder: t('Search Location'),
                          className: 'field',
                        })}
                      />
                    )}
                  </Translation>
                </div>
              </div>
              {suggestions.length > 0 && (
                <div className="autocomplete-container">
                  {suggestions.map((suggestion) => {
                    return (
                      <div
                        key={suggestion.placeId}
                        {...getSuggestionItemProps(suggestion, {
                          className: `suggestion-item${suggestion.active ? ' active' : ''}`,
                        })}
                      >
                        <strong>{suggestion.formattedSuggestion.mainText}</strong>{' '}
                        <small>{suggestion.formattedSuggestion.secondaryText}</small>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        }}
      </PlacesAutocomplete>
    );
  };

  const onCancelClick = () => {
    onCancel();
  };

  const saveAddressBookItem = () => {
    const address1Value = data?.originAddressInfo?.address ?? data.address;
    const updateAddress = {
      address1: address1Value,
      tags: ['pickup', 'dropoff'],
      location: { lat: data.lat, lng: data.lng },
      external_id: null,
    };
    if (data.address2) {
      updateAddress['address2'] = data.address2;
    }
    if (data.postalCode) {
      updateAddress['postal_code'] = data.postalCode;
    }
    if (data.state) {
      updateAddress['state'] = data?.originAddressInfo?.state ?? data.state;
    }
    if (data.country) {
      updateAddress['country'] = data?.originAddressInfo?.country ?? data.country;
    }
    const contact = data.contact;
    const contactAddressProperties = ['name', 'phone', 'email', 'company'];
    if (contact) {
      contactAddressProperties.forEach((property) => {
        if (contact[property]) {
          updateAddress['contact_' + property] = contact[property];
        }
      });
      if (typeof contact === 'string') {
        updateAddress['contact_name'] = contact;
      }
    }

    dispatch(saveToAddressBookAction(updateAddress));
  };

  const onSaveClick = () => {
    if (saveToAddressBookOption) {
      saveAddressBookItem();
    } else {
      postLocatorSave();
    }
  };

  const postLocatorSave = () => {
    onLocationSave({
      lat: data.lat,
      lng: data.lng,
      address1: data.updateAddress ? data.address : null,
      country: data.country,
      postalCode: data.postalCode,
      state: data.state,
      addressItemData: savedItemData,
    });
  };

  const isLocationValid = ({ lat, lng }) => {
    let res = true;
    if (lat < -90 || lat > 90 || lng < -180 || lng > 180) {
      res = false;
    }

    return res;
  };

  const getRangeValue = (value, min, max) => {
    if (value < min) {
      return min;
    }
    if (value > max) {
      return max;
    }

    return value;
  };

  const handleLocationChange = (type) => (event) => {
    let value = parseFloat(event.target.value);
    if (type === 'lat' && !isNaN(value)) {
      value = getRangeValue(value, -90.0, 90.0);
    }
    if (type === 'lng' && !isNaN(value)) {
      value = getRangeValue(value, -180.0, 180.0);
    }

    updateData({ [type]: value });
  };

  const handleCheckboxChange = () => {
    updateData({ updateAddress: !data.updateAddress });
  };

  const showProgress = status === 'processing';
  return (
    <Grid container>
      <div className="fullscreen-map-view embed-address-picker" ref={containerRef}>
        <div className="form-container">
          <h2 className="title">
            <Trans>Update Location</Trans>
          </h2>
          <p className="sub-title">
            <Trans>Move the map pin to the precise location</Trans>
          </p>
          {addressFix && data.contact && (
            <div className="form-group-picker">
              <label>
                <Trans>Contact name</Trans> *
              </label>
              <div className="form-control">
                <input disabled className="field" value={data.contact} />
              </div>
            </div>
          )}
          {map && renderPlacesAutocomplete()}
          <div className="form-group-picker">
            <label>
              <Trans>Latitude</Trans> *
            </label>
            <div className="form-control">
              <input
                type="number"
                min="-90"
                max="90"
                className="field"
                value={data?.lat ?? ''}
                onChange={handleLocationChange('lat')}
              />
            </div>
          </div>
          <div className="form-group-picker">
            <label>
              <Trans>Longitude</Trans> *
            </label>
            <div className="form-control">
              <input
                type="number"
                min="-180"
                max="180"
                className="field"
                value={data.lng ?? ''}
                onChange={handleLocationChange('lng')}
              />
            </div>
          </div>
          {!addressFix && (
            <div className="form-group-picker">
              {isShownUpdateAddressFromSender && (
                <label className="checkbox-label">
                  <input type="checkbox" checked={data.updateAddress} onChange={handleCheckboxChange} />{' '}
                  <Trans>Update address entered by sender</Trans>
                </label>
              )}
              {isShownAddToAddressBook && (
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={saveToAddressBookOption}
                    onChange={() => {
                      setSaveToAddressBookOption(!saveToAddressBookOption);
                    }}
                  />
                  <span>
                    <Trans>Save to Address Book (address information entered by sender)</Trans>
                  </span>
                </label>
              )}
            </div>
          )}
          <div className="btn-container-picker">
            <button className="btn btn-default" onClick={onCancelClick}>
              <Trans>Cancel</Trans>
            </button>
            <button
              className="btn btn-primary"
              disabled={!data.lng || !data.lat || !data.address}
              onClick={onSaveClick}
            >
              <Trans>Update</Trans>
            </button>
          </div>
        </div>
        <GoogleMapReact
          center={data.center}
          zoom={data.zoom}
          draggableCursor=""
          onClick={onClickHandler}
          bootstrapURLKeys={bootstrapURLKeys}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={handleGoogleMapApi}
        >
          <img className="marker" alt="location" src={image} lat={data.lat} lng={data.lng} />
        </GoogleMapReact>
      </div>
      {showProgress && (
        <Dialog open={showProgress}>
          <CircularProgress disableShrink />
        </Dialog>
      )}
      {data.showInvalidLocationMessage && (
        <Dialog open={data.showInvalidLocationMessage}>
          <DialogContent>
            <div>
              <Trans i18nKey="wrongLocationMessage" />
            </div>
          </DialogContent>
          <DialogActions>
            <button className="btn btn-cancel" onClick={() => updateData({ showInvalidLocationMessage: false })}>
              <Trans>Ok</Trans>
            </button>
          </DialogActions>
        </Dialog>
      )}
    </Grid>
  );
};

export default AddressPicker;
