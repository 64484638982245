import { Option } from '@yojee/types';
import { ReactSelect } from '@yojee/ui/base';

import { FormFieldComponent } from '../types';

export type MultiSelectProps = React.ComponentProps<typeof ReactSelect> & {
  getOptions: () => Option[];
};

export const MultiSelect: FormFieldComponent<'multiSelect'> = ({
  fieldName,
  formField: {
    label,
    disabled,
    fieldProps: { options, getOptions, ...restProps },
  },
  required,
  value,
  setValue,
  errorMessage,
}) => {
  const isRawValue = restProps.isRawValue ?? true;

  return (
    <ReactSelect
      {...restProps}
      isRawValue={isRawValue}
      labelHeight="auto"
      isMulti
      dataCy={fieldName}
      label={label}
      required={required}
      disabled={disabled}
      value={value}
      onChange={(newValues: Option[] | null) => {
        const options = newValues?.map((e) => (isRawValue ? e.value : e)) ?? [];
        setValue(options);
      }}
      error={!!errorMessage}
      helperText={errorMessage}
      options={getOptions ? getOptions() : options}
    />
  );
};
