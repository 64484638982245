import Button from '@material-ui/core/Button';
import React from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { addSuccessMessage } from '../../../../saga/actions';
import { getFormKeyPath } from '../../../helpers/formHelpers';
import { useClearForm } from '../../../helpers/hooks';

interface KeyPathProps {
  bookingInfoSectionIndex: number | string;
  legIndex: number | string;
  stepIndex: number | string;
  itemIndex: number | string;
  type: string | undefined;
}

const ClearAll: React.FC = () => {
  const dispatch = useDispatch();
  const keyPath = getFormKeyPath({ type: 'vessel' } as KeyPathProps);
  const clearAll = useClearForm(keyPath);

  return (
    <Button
      onClick={() => {
        clearAll();
        dispatch(addSuccessMessage(<Trans>All vessel information cleared</Trans>));
      }}
      data-cy="clear-all-vessel-info-button"
    >
      <Trans>Clear All</Trans>
    </Button>
  );
};

export default ClearAll;
