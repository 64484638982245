import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { createOrderFieldTemplate, updateOrderFieldTemplate } from '../../../../saga/actions';
import {
  getCompanyTimezoneSelector,
  getOrderFieldTemplateIdSelector,
  getOrderFieldTemplateSenderSelector,
  getOrderFormModelsSelector,
  getTemplateDetailIdSelector,
  isInProgressCreateOrderFieldTemplateSelector,
  isInProgressUpdateOrderFieldTemplateSelector,
} from '../../../../selectors';
import { SchemaHelperContext } from '../../../helpers/Contexts';
import { getCreateOrderFieldTemplateRequestPayload } from '../helpers';

const useStyles = makeStyles(() => ({
  button: {
    minWidth: '96px',
    height: '30px',
    borderRadius: '4px',
    fontSize: '13px',
    fontFamily: 'Arial',
    fontWeight: 'bold',
    margin: '15px 20px 15px 10px',
  },
  progress: {
    marginRight: '10px',
  },
}));

const SaveOrderFieldTemplateBtn = ({ onEnd } = {}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [inProgress, setInProgress] = useState(false);
  const companyTimezone = useSelector(getCompanyTimezoneSelector);
  const templateId = useSelector(getTemplateDetailIdSelector);
  const orderFieldTemplateId = useSelector(getOrderFieldTemplateIdSelector);
  const isInProgressUpdateOrderFieldTemplate = useSelector(isInProgressUpdateOrderFieldTemplateSelector);
  const isInProgressCreateOrderFieldTemplate = useSelector(isInProgressCreateOrderFieldTemplateSelector);
  const sender = useSelector(getOrderFieldTemplateSenderSelector);
  const orderFormModels = useSelector(getOrderFormModelsSelector);
  const schemaHelper = useContext(SchemaHelperContext);

  useEffect(() => {
    if (isInProgressUpdateOrderFieldTemplate || isInProgressCreateOrderFieldTemplate) {
      setInProgress(true);
    } else if (inProgress) {
      setInProgress(false);
      onEnd && onEnd();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInProgressUpdateOrderFieldTemplate, isInProgressCreateOrderFieldTemplate]);

  const submit = () => {
    const orderData = getCreateOrderFieldTemplateRequestPayload({
      orderFormModels,
      companyTimezone,
      schemaHelper,
    });
    if (orderFieldTemplateId) {
      dispatch(updateOrderFieldTemplate(orderData, templateId, sender.id));
    } else {
      dispatch(createOrderFieldTemplate(orderData, templateId, sender.id));
    }
  };

  return (
    <Button
      onClick={submit}
      disabled={inProgress}
      variant="contained"
      size="small"
      color="primary"
      className={classes.button}
    >
      {inProgress && <CircularProgress className={classes.progress} size={20} />}
      <Trans>Save</Trans>
    </Button>
  );
};

export default SaveOrderFieldTemplateBtn;
