import React, { useEffect, useMemo, useState } from 'react';

import { useIsCanShowMap } from '@yojee/ui/new-order-booking/components/helpers/utils';

export type ConfigType = {
  usingRatingFeature: boolean;
  isShowMap: boolean;
  changeTab: () => void;
  bookingInfoSectionsReactComponentKey: number;

  // For charge tab
  allowToEditChargeForCompletedOrder: boolean;
  isSaveChargesBtnEnable: boolean;
  isChargesInvalid: boolean;
  isShowInvoiceRedoReasonDialog: boolean;
  isShowExportFilterDialog: boolean;
  isShowAcceptTransferingOrderConfirmDialog: boolean;
  saveChargesBtnClickHandler?: () => void;

  // For booking page only
  isRequirePayment: boolean;
  isShowCharge: boolean;
  isHideSenderSection: boolean;
  isSenderNotYetLogin: boolean;
  BookingPageShowLoginDialogBtn?: () => void;

  // For preview order form from manage
  isForceOrderFormReadOnly: false;

  setConfigValue?: (fn: (config: ConfigType) => ConfigType) => void;
};

const defaultConfigContextValue: ConfigType = {
  usingRatingFeature: false,
  isShowMap: false,
  changeTab: () => {},
  bookingInfoSectionsReactComponentKey: 1,

  // For charge tab
  allowToEditChargeForCompletedOrder: false,
  isSaveChargesBtnEnable: false,
  isChargesInvalid: false,
  isShowInvoiceRedoReasonDialog: false,
  isShowExportFilterDialog: false,
  isShowAcceptTransferingOrderConfirmDialog: false,

  // For booking page only
  isRequirePayment: false,
  isShowCharge: false,
  isHideSenderSection: false,
  isSenderNotYetLogin: false,

  // For preview order form from manage
  isForceOrderFormReadOnly: false,
};

export const ConfigContext = React.createContext(defaultConfigContextValue);

// use this hook will allow we have ability to change config value
export const useConfigContextValue = (config: ConfigType) => {
  const [value, setValue] = useState(defaultConfigContextValue);
  const isCanShowMap = useIsCanShowMap();

  useEffect(() => {
    setValue((prev) => ({
      ...prev,
      isShowMap: isCanShowMap,
    }));
  }, [isCanShowMap]);

  useEffect(() => {
    setValue((prev) => ({ ...prev, ...config }));
  }, [config]);

  return useMemo(
    () => ({
      ...value,
      setConfigValue: setValue,
    }),
    [value]
  );
};

export const SchemaHelperContext = React.createContext(undefined);
