export const scrollToErrorField = (root?: HTMLElement | null) => {
  const element = root?.querySelector<HTMLDivElement>(`[class*="MuiFormHelperText"][class*="Mui-error"]`);

  if (element) {
    element.style.scrollMarginTop = '140px';
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};
