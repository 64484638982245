export default function getExtraProps({ name, field: { category, type } }) {
  switch (category) {
    case 'order':
      switch (name) {
        case 'external_id':
          return {
            inputProps: { maxLength: 255 },
          };
      }
      break;
    case 'task':
      switch (name) {
        case 'address':
        case 'address2':
        case 'country':
        case 'postal_code':
        case 'state':
        case 'city':
        case 'contact_company':
        case 'contact_email':
        case 'contact_name':
          return {
            inputProps: { maxLength: 255 },
          };
      }
      break;
    case 'item':
      switch (name) {
        case 'info':
        case 'description':
          return {
            inputProps: { maxLength: 5000 },
          };

        case 'length':
        case 'width':
          return {
            isDisplayUnit: false,
          };

        case 'external_customer_id':
        case 'external_customer_id2':
        case 'external_customer_id3':
          return {
            inputProps: { maxLength: 255 },
          };
      }
      break;
    case 'container':
      switch (name) {
        case 'container_no':
          return {
            inputProps: { maxLength: 255 },
          };
        case 'seal_no':
        case 'slot_reference':
        case 'tare_weight':
        case 'description':
          return {
            inputProps: { maxLength: 255 },
          };
      }
      break;
    case 'vessel':
      switch (type) {
        case 'string':
          return {
            inputProps: { maxLength: 255 },
          };
      }
      break;
  }

  return {};
}
